import styled, { css } from 'styled-components';
import { Table as BTable } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';

export const Container = styled.div`
	background: ${p => p.theme.white};
	margin: 0 26px;
	padding: 30px 20px;
	min-height: 60vh;
`;

export const InfoContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	span {
		margin: 0 10px;
	}
	label {
		width: 130px;
		color: ${p => p.theme.gray_2};
		margin: 0 10px;
	}
`;

export const SectionTitle = styled.b`
	font-size: 1.2em;
`;

export const ConfusionMatrixContainer = styled.div`
	background-color: ${p => p.theme.white};
	padding: 5px 0;
	overflow: hidden;
	height: calc(100vh - 236px);
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	position: relative;
`;

export const SingleConfusionMatrix = styled.div`
	width: 155px;
	height: 155px;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 4px;
	border: solid 1px ${p => p.theme.gray};
	${({ backGroundColor }) =>
		backGroundColor &&
		css`
			background-color: ${backGroundColor};
		`}
	p {
		text-align: center;
		font-weight: 700;
		margin: 0;
	}
	p span {
		display: block;
		margin: 4px 0;
		color: ${p => p.theme.green_2};
	}
	p span:first-of-type {
		color: ${p => p.theme.white};
	}
	p span:last-of-type {
		color: ${p => p.theme.red_3};
	}
	${({ color }) =>
		color &&
		css`
			p span {
				color: ${color}!important;
			}
		`}
`;

export const ConfusionMatrixRow = styled.div`
	width: max-content;
	margin: 0 10%;
	overflow: hidden;
`;

export const YTicks = styled.div`
	float: left;
	width: 155px;
	height: 155px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 4px;
`;

export const XTicks = styled.div`
	float: left;
	width: 155px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 4px;
`;

export const XAxisTitle = styled.div`
	transform: rotate(270deg);
	position: absolute;
	top: 40%;
	left: 5%;
	font-weight: 700;
`;

export const YAxisTitle = styled.div`
	font-weight: 700;
	clear: both;
	margin: 15px calc(10% + 163px) 0;
	width: 660px;
	text-align: center;
`;

export const IFrame = styled.iframe`
	height: calc(100vh - 242px);
	width: 100%;
`;

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 20px;
`;

export const FiledWrapper = styled.div`
	width: 46%;
	margin: 10px 2%;
	background: ${p => p.theme.gray_13};
	padding: 10px 0;
	overflow: hidden;
	span {
		width: 28%;
		position: relative;
		top: 7px;
		left: 2%;
		font-weight: 700;
		float: left;
	}
	select,
	input {
		background: ${p => p.theme.gray_11};
		outline: none;
		color: ${p => p.theme.green};
		width: 70%;
		border: 1px solid ${p => p.theme.gray_8};
		box-shadow: none;
		float: left;
	}
`;

export const RangeSliderWrapper = styled.div`
	width: 46%;
	margin: 10px 2%;
	background: ${p => p.theme.gray_13};
	overflow: hidden;
	padding: 10px 0;
	float: left;
	span {
		font-weight: 700;
	}
`;

export const ImagesTableWrap = styled.div`
	border-radius: 8px;
	width: 75%;
	float: left;
	margin: 50px 0 10px 0;
	-webkit-box-shadow: 0px 0px 5px 6px rgba(226 226 226 / 60%);
	box-shadow: 0px 0px 5px 6px rgba(226 226 226 / 60%);
`;

export const ImagesTable = styled(BTable)`
	background-color: ${p => p.theme.white};
	margin: 0;
	td {
		width: 100px;
		text-align: center;
		vertical-align: middle;
	}
	td:first-of-type {
		font-weight: 600;
		text-transform: uppercase;
	}
	img {
		width: 100px;
	}
	p {
		color: ${p => p.theme.green};
		font-weight: 400;
		margin: 5px 0;
	}
`;

export const TDContent = styled.div`
	text-align: center;
`;

export const BarChartWrapper = styled.div`
	width: 23%;
	float: left;
	height: 500px;
	margin-top: 42px;
	-webkit-box-shadow: 0px 0px 5px 6px rgba(226 226 226 / 60%);
	box-shadow: 0px 0px 5px 6px rgba(226 226 226 / 60%);
`;

export const ContentWrapper = styled.div`
	overflow: hidden;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const LoadingWrap = styled.div`
	padding: 24vh 0;
	text-align: center;
`;

export const ButtonsWrap = styled.div`
	width: 100%;
	overflow: hidden;
	padding: 10px 0;
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	float: right;
	:first-of-type {
		margin-left: 20px;
	}
`;
