import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import format from 'string-template';
import {
	assignToZero,
	assignToTntc,
	previewSpotCount,
	fetchSpotParameters,
	fetchCompactView,
} from 'core/store/predictions';
import { post } from 'core/api';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import * as endpoints from 'core/consts/endpoints';
import { base64StringToBlobUrl } from 'helpers';
import { When } from 'components/When';
import ExperimentRow from '../SharedComponents/ExperimentRow';
import ExperimentConfig from '../SharedComponents/ExperimentConfig';
import { INITIAL_PREDICTION_DATA_MINIO_URL } from 'core/consts';
import NonExistingImage from 'assets/img/missingImage.png';
import * as Styled from '../styled';

const SPOTS_TYPES = ['pool_tested_with_itself', 'medium', 'effector'];

export default function ExperimentView({
	compactViewData,
	predictionDataId,
	folderName,
	predictionId,
	refreshCompactView,
	currentExperimentId,
	tableHeader,
}) {
	const tableHeaderToDisplay = ['', ...tableHeader];
	const dispatch = useDispatch();
	const [isSpotsCountVisible, setIsSpotCountVisible] = useState(false);
	const [imageKey, setImageKey] = useState('');
	const [previewedImageUrl, setPreviewedImageUrl] = useState('');
	const [selectedImage, setSelectedImage] = useState({
		rowIndex: null,
		colIndex: null,
		imageName: null,
		preCalculatedImgUrl: null,
	});
	const [selectedImageExtension, setSelectedImageExtension] = useState(
		'.CTL'
	);
	const [hasError, setHasError] = useState(false);
	const [isImageLoading, setImageLoading] = useState(false);
	const [isSpotLoading, setIsSpotLoading] = useState(false);
	const [previewedSpotCount, setPreviewedSpotCount] = useState(0);
	const [experimentId, setExperimentId] = useState('');

	const applySpotsUrl = format(endpoints.APPLY_SPOTS, {
		predictionId,
	});

	const { data: previewSpotCountData } = useNamespace({
		namespace: namespaces.PREVIEW_SPOT_COUNT,
	});
	const {
		data: spotParametersData = {},
		loading: spotParametersLoading,
	} = useNamespace({
		namespace: namespaces.SPOT_PARAMETERES,
		autoClear: false,
	});
	useNamespace({
		namespace: namespaces.ASSIGN_TO_ZERO_PATCH,
		onSuccess: () => {
			dispatch(fetchCompactView(predictionId, experimentId));
		},
	});
	useNamespace({
		namespace: namespaces.ASSIGN_TO_TNTC_PATCH,
		onSuccess: () => {
			dispatch(fetchCompactView(predictionId, experimentId));
		},
	});
	useEffect(() => {
		if (previewSpotCountData?.image) {
			const { image } = previewSpotCountData;
			const url = base64StringToBlobUrl(image, 'image/png');
			setPreviewedImageUrl(url);
			setImageLoading(false);
		}
		setPreviewedSpotCount(previewSpotCountData?.spots ?? 0);
	}, [previewSpotCountData]);

	const getImageUrl = (
		imageName: string,
		missingImage: boolean,
		imageExtension: string
	) => {
		if (!!missingImage) {
			return NonExistingImage;
		}
		return `${INITIAL_PREDICTION_DATA_MINIO_URL}/${predictionDataId}/${folderName}/${imageName}.${imageExtension}`;
	};

	const selectedImageUrl = !!selectedImage.preCalculatedImgUrl
		? selectedImage.preCalculatedImgUrl
		: `${INITIAL_PREDICTION_DATA_MINIO_URL}/${predictionDataId}/${folderName}/${selectedImage.imageName}.${selectedImageExtension}`;

	const handleImageClick = (
		imageName,
		rowIndex,
		colIndex,
		experimentId,
		imageKey,
		preCalculatedImgUrl,
		count,
		imageExtension
	) => {
		setPreviewedImageUrl('');
		setSelectedImageExtension(imageExtension);
		setExperimentId(experimentId);
		setImageKey(imageKey);
		if (
			selectedImage.rowIndex === rowIndex &&
			selectedImage.colIndex === colIndex &&
			selectedImage.imageName === imageName
		) {
			setSelectedImage({
				rowIndex: null,
				colIndex: null,
				imageName: null,
				preCalculatedImgUrl: null,
			});
			setIsSpotCountVisible(false);
		} else {
			dispatch(fetchSpotParameters(predictionId, experimentId, imageKey));
			const imageToDisplay =
				count > 0
					? preCalculatedImgUrl
					: `${INITIAL_PREDICTION_DATA_MINIO_URL}/${predictionDataId}/${folderName}/${imageName}.${imageExtension}`;
			setSelectedImage({
				rowIndex,
				colIndex,
				imageName,
				preCalculatedImgUrl: imageToDisplay,
			});
			setIsSpotCountVisible(true);
		}
	};

	const assignToZeroToTntcData = {
		predictionDataId,
		experimentId,
		imageKey,
		imageName: `${folderName}/${selectedImage.imageName}.${selectedImageExtension}`,
		predictionId,
	};

	const handleAssignToZero = () => {
		return dispatch(assignToZero(assignToZeroToTntcData));
	};

	const handleAssignToTNTC = () => {
		return dispatch(assignToTntc(assignToZeroToTntcData));
	};

	const handlePreviewClick = (config, algorithm) => {
		setHasError(false);
		setImageLoading(true);
		const data = {
			...config,
			method: !!config['method'] ? config['method'] : 'A',
			imageSize: parseInt(config['imageSize']),
			algorithm,
			predictionDataId,
			imageName: `${folderName}/${selectedImage.imageName}.${selectedImageExtension}`,
			ImageKey: imageKey,
			ExperimentId: experimentId,
		};
		return dispatch(previewSpotCount(data, predictionId));
	};

	const getSingleSpot = async config => {
		await post(applySpotsUrl, config);
	};

	const getArrowImages = async (config, { data, uniqueId }) => {
		const singleSpotConfig = config;
		for (const exp of data) {
			singleSpotConfig.imageName = `${folderName}/${exp.imageName}.${selectedImageExtension}`;
			singleSpotConfig.ImageKey = exp.imageKey;
			singleSpotConfig.ExperimentId = uniqueId;
			if (
				singleSpotConfig.ExperimentId &&
				singleSpotConfig.ImageKey &&
				!exp.missingImage
			) {
				await getSingleSpot(singleSpotConfig);
			}
		}
	};

	async function handleApplySpotCount(config, algorithm, ApplyForAll) {
		setHasError(false);
		setIsSpotLoading(true);
		if (!ApplyForAll) {
			const data = {
				...config,
				method: !!config['method'] ? config['method'] : 'A',
				imageSize: parseInt(config['imageSize']),
				algorithm,
				predictionDataId,
				imageName: `${folderName}/${selectedImage.imageName}.${selectedImageExtension}`,
				ImageKey: imageKey,
				ExperimentId: experimentId,
			};
			try {
				await post(applySpotsUrl, data);
				refreshCompactView();
			} catch (e) {
				setHasError(true);
			} finally {
				setIsSpotLoading(false);
			}
		} else {
			try {
				for (const experiment of compactViewData) {
					const data = {
						...config,
						method: !!config['method'] ? config['method'] : 'A',
						imageSize: parseInt(config['imageSize']),
						algorithm,
						predictionDataId,
					};
					await getArrowImages(data, experiment);
				}
				refreshCompactView();
			} catch (e) {
				setHasError(true);
			} finally {
				setIsSpotLoading(false);
			}
		}
	}
	const experimentViewData = compactViewData.filter(exp => {
		const { uniqueId, rowName } = exp;
		return (
			(currentExperimentId === uniqueId &&
				rowName !== '' &&
				!SPOTS_TYPES.includes(rowName)) ||
			uniqueId === ''
		);
	});
	return (
		<Styled.Container>
			<Styled.Table bordered id='experiment-images'>
				<thead>
					<tr>
						{tableHeaderToDisplay.map(rowHeader => (
							<th key={rowHeader}>{rowHeader}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{experimentViewData?.map((exp, index) => {
						return (
							<ExperimentRow
								rowData={exp}
								getImageUrl={getImageUrl}
								key={index}
								rowIndex={index}
								experimentId={currentExperimentId}
								handleImageClick={handleImageClick}
								selectedImage={selectedImage}
								spotsType={SPOTS_TYPES}
							/>
						);
					})}
				</tbody>
			</Styled.Table>
			<When condition={isSpotsCountVisible}>
				<ExperimentConfig
					onPreviewClick={handlePreviewClick}
					onApplyClick={handleApplySpotCount}
					selectedImage={selectedImageUrl}
					previewedImageUrl={previewedImageUrl}
					isSpotLoading={isSpotLoading}
					hasError={hasError}
					isImageLoading={isImageLoading}
					key={selectedImageUrl}
					onAssignZeroClick={handleAssignToZero}
					onAssignTntcClick={handleAssignToTNTC}
					spotParametersData={spotParametersData}
					previewedSpotCount={previewedSpotCount}
				/>
			</When>
			<When condition={!!spotParametersLoading}>
				<Styled.LoadingWrap>
					<Spinner animation='border'></Spinner> Loading Parameters
					...
				</Styled.LoadingWrap>
			</When>
		</Styled.Container>
	);
}
