export function parseStringToBool(yesStr = 'Yes', noStr = 'No') {
	return value => {
		switch (value) {
			case yesStr:
				return true;
			case noStr:
				return false;
			default:
				return false;
		}
	};
}

export function formatBoolToString(yesStr = 'Yes', noStr = 'No') {
	return value => {
		switch (value) {
			case true:
				return yesStr;
			case false:
				return noStr;
			default:
				return noStr;
		}
	};
}

export function converStringToBool(str) {
	return ['yes', 'true', '1'].includes(str);
}
