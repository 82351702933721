import React, { memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateAndTime } from 'helpers';
import { TRAINING_IN_VITRO_DATA } from 'core/routes';
import * as namespaces from 'core/consts/namespaces';
import { Table } from 'components/Table';
import PusherMessage from 'components/PusherMessage';
import { CHANNELS } from 'core/consts/pusherConfig';
import { currentWorkspaceIdSelector } from 'core/store/auth';
import { fetchTrainingData } from 'core/store/trainingsInVitro';
import { ModalProvider } from 'components/Modal';
import { StatusIcon } from 'components/StatusIcon';
import {
	DateFilter,
	dateBetweenFilterFn,
} from 'components/Filters/DateFilter/DateFilter';
import { When } from 'components/When';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import { StatusFilter } from 'components/Filters/StatusFilter';
import TDSummaryLogsModal from './LogsModals/TDSummaryLogsModal';
import TrainingDataDeleteModal from './DeleteModals/TrainingDataDeleteModal';
import * as Styled from '../styled';
import { TrainingDataEntry } from '../types';

enum TDStatusEnum {
	Created = 'Created',
	Checking = 'Checking',
	Preprocessing = 'Preprocessing',
	Ready = 'Ready',
	Invalid = 'Invalid',
}

interface Props {
	data: TrainingDataEntry[];
	loading?: boolean;
}

const getPermissions = (status: string) => {
	return {
		canViewLogs: status !== TDStatusEnum.Created,
		canViewResult: status === TDStatusEnum.Ready,
	};
};

const TrainingDataTable = memo(function DataTable({ data, loading }: Props) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const workspaceId = useSelector(currentWorkspaceIdSelector);

	const refreshTrainingsData = ({ workspaceId: receivedWorkspaceId }) => {
		if (workspaceId === receivedWorkspaceId) {
			return dispatch(fetchTrainingData());
		}
		return;
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Status',
				accessor: 'status',
				disableSortBy: true,
				Filter: props =>
					StatusFilter({
						...props,
						filterType: 'trainingData',
					}),
				Cell: ({ value }) => <StatusIcon status={value} />,
			},
			{
				Header: 'Name',
				accessor: 'name',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TRAINING_INVITRO_DATA,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'User',
				accessor: 'userName',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TRAINING_INVITRO_DATA,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'Time Created',
				accessor: 'createdOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
			},
			{
				Header: 'For Validation',
				accessor: 'isValidationData',
				canFilter: false,
				disableFilters: true,
				Cell: ({ value }) => value && <span>X</span>,
			},
			{
				id: 'actions',
				disableSortBy: true,
				canFilter: false,
				disableFilters: true,
				Cell: ({
					row: {
						original: { status, trainingDataId },
					},
				}) => (
					<When condition={getPermissions(status).canViewLogs}>
						<ModalProvider
							title='Training Data Check Summary'
							component={TDSummaryLogsModal}
							modalStyles={{ size: 'lg' }}
							modalProps={{
								id: trainingDataId,
							}}
							trigger={
								<Styled.LogButton variant='light'>
									Logs
								</Styled.LogButton>
							}
						/>
					</When>
				),
			},
			{
				id: 'delete',
				width: 25,
				disableSortBy: true,
				canFilter: false,
				disableFilters: true,
				Cell: ({
					row: {
						original: { trainingDataId, name },
					},
				}) => (
					<ModalProvider
						title='Confirm Deletion of Training Data'
						component={TrainingDataDeleteModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							id: trainingDataId,
							name,
						}}
						trigger={
							<FontAwesomeIcon
								icon={faTrashAlt}
								color={theme.red_4}
							/>
						}
					/>
				),
			},
		],
		[theme]
	);

	const handleRowClick = ({ original: { trainingDataId, status } }) => {
		if (getPermissions(status).canViewResult) {
			history.push(`${TRAINING_IN_VITRO_DATA}/${trainingDataId}`);
		}
	};

	return (
		<Styled.Container>
			<PusherMessage
				callback={refreshTrainingsData}
				event={CHANNELS.trainingsData}
			/>
			<Table
				columns={columns}
				data={data}
				onRowClick={handleRowClick}
				loading={loading}
				withFilters={!!data.length}
			/>
		</Styled.Container>
	);
});

export default TrainingDataTable;
