export const trainingFormInitialValues = {
	experimentType: 'InVitro',
	trainingDataAugmentation: {
		flipProbability: 0.5,
		angle: 180,
		minimumGamma: 0.9,
		maximumGamma: 1.1,
		gray: false,
		flip: true,
		gammaContrast: true,
		rotation: true,
	},
	trainingModelParameters: {
		inputShape: 224,
		learningRate: 0.0001,
		batchSize: 6,
		classesNumber: 3,
		epochsNumber: 200,
		savedModels: 50,
		dropout: 0.5,
		weightDecay: 0.0001,
		loss: 'sparse_categorical_crossentropy',
		optimizer: 'Adam',
		model: 'CNN_LSTM_Variable',
		baseCNN: 'ResNet18',
	},
	trainingConfiguration: {
		radius: 95,
		gpusNumber: 1,
		maskMode: false,
		mirroredMode: false,
		mcDropoutRuns: 50,
		earlyStopping: false,
		earlyStoppingPatience: 10,
	},
};
