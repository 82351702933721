import styled from 'styled-components';
import { Modal as BModal } from 'react-bootstrap';

export const Modal = styled(BModal)`
	& .modal-header {
		background-color: ${p => p.theme.white};
		border-bottom: none;
	}
	& .modal-title {
		color: ${p => p.theme.gray_2};
		font-size: 1.25em;
	}
`;
