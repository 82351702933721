import { padSingleDigit } from 'helpers';

export const REFRESH_INTERVAL = 15000;

export const INITIAL_PREDICTION_DATA_MINIO_URL =
	process.env.REACT_APP_MINIO_URL ?? window['MINIO_URL'];

export const SPOT_COUNT_MINIO_URL =
	process.env.REACT_APP_MINIO_SPOT_COUNT_URL ??
	window['MINIO_SPOT_COUNT_URL'];

export const API_URL = process.env.REACT_APP_API_URL ?? window['API_URL'];

export const WELLS_IMAGES_BG_COLOR = [
	{ type: 'position', color: '#8EC346' },
	{ type: 'pool_tested_with_itself', color: '#CC1236' },
	{ type: 'negative_control', color: '#264100' },
	{ type: 'positive_control', color: '#124BCC' },
];

export const PLATE_VIEW_DATA_OPTIONS = [
	{ label: 'Compact View', value: 'compactView' },
	{ label: 'Full Plate View', value: 'fullPlateView' },
	{ label: 'Experiment View', value: 'experimentView' },
];

export const TABLE_LEGEND_SQUARES = [
	{ color: '#8EC346', text: 'Experiment' },
	{ color: '#CC1236', text: 'Pool Tested With Itself' },
	{ color: '#264100', text: 'Negative Control' },
	{ color: '#124BCC', text: 'Positive Control' },
];

export const EXVIVO = 'ExVivo';
export const INVITRO = 'InVitro';

export enum INPUT_GENERATION_VIEWS {
	plateBarcode = 'plateBarcode',
	plateLayout = 'plateLayout',
	sampleInfos = 'sampleInfos',
	target = 'target',
}

export const PLATE_SIZE = [96, 384];

export const ROWS_COUNTER = { 96: 13, 384: 25 };

export const ROWS_HEADER = Array.from(Array(ROWS_COUNTER).keys()).map(item => {
	if (item > 0) {
		return padSingleDigit(item);
	}
	return '';
});

export const COLUMNS_HEADER = {
	96: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
	384: [
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
	],
};

export const PLATELAYOUT_SELECT_OPTIONS = [
	{
		label: 'Cell Groups',
		value: 'Cell Groups',
	},
	{
		label: 'Stimulus in IVS',
		value: 'Stimulus in IVS',
	},
	{
		label: 'Stimulus in Readout',
		value: 'Stimulus in Readout',
	},
];
