import React, { useRef } from 'react';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'components/ProgressBar';
import { When } from 'components/When';
import * as Styled from './styled';

const FORM_FIELDS = {
	name: 'name',
	imagesFolder: 'imagesFolder',
	edaFolder: 'edaFolder',
	plateLayoutFolder: 'plateLayoutFolder',
	sampleFolder: 'sampleFolder',
	targetFolder: 'targetFolder',
	isValidationData: 'isValidationData',
};

export default function AddTrainingDataForm({
	imagesPathUploadProgress,
	setImagesPathUploadProgress,
	edaUploadProgress,
	setEdaUploadProgress,
	plateLayoutUploadProgress,
	setPlateLayoutUploadProgress,
	sampleUploadProgress,
	setSampleUploadProgress,
	targetUploadProgress,
	setTargetUploadProgress,
	exvivoTrainingData,
	isNameDisabled,
	handleNameChange,
	handleIsValidationChange,
	isNameUsed,
	zipFile,
	children,
}) {
	const hiddenImagesFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenImagesFileInputRef = useRef<HTMLInputElement>(null);
	const hiddenEdaFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenEdaFileInputRef = useRef<HTMLInputElement>(null);
	const hiddenPlateLayoutFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenPlateLayoutFileInputRef = useRef<HTMLInputElement>(null);
	const hiddenSampleFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenSampleFileInputRef = useRef<HTMLInputElement>(null);
	const hiddenTargetFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenTargetFileInputRef = useRef<HTMLInputElement>(null);

	const handleInputFileChange = (e: any, state, setState): void => {
		zipFile(state, setState, e.target, state.endpoint);
	};

	const handleClick = ref => {
		if (!!ref?.current) ref.current.click();
	};
	return (
		<Styled.FormWrap>
			<Styled.Content>
				<Row>
					<Col>
						<Form.Label>
							<b>Name</b>
						</Form.Label>
						<Form.Control
							name={FORM_FIELDS.name}
							placeholder='Type a name for the exvivo training data'
							type='text'
							onChange={handleNameChange}
							value={exvivoTrainingData.name}
							disabled={isNameDisabled}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={imagesPathUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={imagesPathUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!imagesPathUploadProgress.isProgressBarDisplayed &&
								!imagesPathUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>Images Path</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenImagesFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											imagesPathUploadProgress,
											setImagesPathUploadProgress
										)
									}
									ref={hiddenImagesFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenImagesFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											imagesPathUploadProgress,
											setImagesPathUploadProgress
										)
									}
									ref={hiddenImagesFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								imagesPathUploadProgress.isProgressBarDisplayed &&
								!imagesPathUploadProgress.zipping
							}>
							<ProgressBar
								fileName={imagesPathUploadProgress.fileName}
								percentage={
									imagesPathUploadProgress?.percentage
								}
								now={`${imagesPathUploadProgress?.bytesUploaded}`}
								size={`${imagesPathUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={edaUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={edaUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!edaUploadProgress.isProgressBarDisplayed &&
								!edaUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>EDA</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenEdaFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											edaUploadProgress,
											setEdaUploadProgress
										)
									}
									ref={hiddenEdaFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenEdaFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											edaUploadProgress,
											setEdaUploadProgress
										)
									}
									ref={hiddenEdaFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								edaUploadProgress.isProgressBarDisplayed &&
								!edaUploadProgress.zipping
							}>
							<ProgressBar
								fileName={edaUploadProgress.fileName}
								percentage={edaUploadProgress?.percentage}
								now={`${edaUploadProgress?.bytesUploaded}`}
								size={`${edaUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={plateLayoutUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={plateLayoutUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!plateLayoutUploadProgress.isProgressBarDisplayed &&
								!plateLayoutUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>
									Plate Layout
								</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(
											hiddenPlateLayoutFolderInputRef
										)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											plateLayoutUploadProgress,
											setPlateLayoutUploadProgress
										)
									}
									ref={hiddenPlateLayoutFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(
											hiddenPlateLayoutFileInputRef
										)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											plateLayoutUploadProgress,
											setPlateLayoutUploadProgress
										)
									}
									ref={hiddenPlateLayoutFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								plateLayoutUploadProgress.isProgressBarDisplayed &&
								!plateLayoutUploadProgress.zipping
							}>
							<ProgressBar
								fileName={plateLayoutUploadProgress.fileName}
								percentage={
									plateLayoutUploadProgress?.percentage
								}
								now={`${plateLayoutUploadProgress?.bytesUploaded}`}
								size={`${plateLayoutUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={sampleUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={sampleUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!sampleUploadProgress.isProgressBarDisplayed &&
								!sampleUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>
									Sample Folder
								</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenSampleFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											sampleUploadProgress,
											setSampleUploadProgress
										)
									}
									ref={hiddenSampleFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenSampleFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											sampleUploadProgress,
											setSampleUploadProgress
										)
									}
									ref={hiddenSampleFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								sampleUploadProgress.isProgressBarDisplayed &&
								!sampleUploadProgress.zipping
							}>
							<ProgressBar
								fileName={sampleUploadProgress.fileName}
								percentage={sampleUploadProgress?.percentage}
								now={`${sampleUploadProgress?.bytesUploaded}`}
								size={`${sampleUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={targetUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={targetUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!targetUploadProgress.isProgressBarDisplayed &&
								!targetUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>
									Target Folder
								</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenTargetFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											targetUploadProgress,
											setTargetUploadProgress
										)
									}
									ref={hiddenTargetFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenTargetFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											targetUploadProgress,
											setTargetUploadProgress
										)
									}
									ref={hiddenTargetFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								targetUploadProgress.isProgressBarDisplayed &&
								!targetUploadProgress.zipping
							}>
							<ProgressBar
								fileName={targetUploadProgress.fileName}
								percentage={targetUploadProgress?.percentage}
								now={`${targetUploadProgress?.bytesUploaded}`}
								size={`${targetUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<Styled.UploadFileButtonWrap>
							<Styled.FormLabel>Data For</Styled.FormLabel>
							<Form.Check
								name={FORM_FIELDS.isValidationData}
								type='radio'
								label='Training'
								checked={!exvivoTrainingData.isValidationData}
								onChange={() => handleIsValidationChange(false)}
							/>
							<Form.Check
								name={FORM_FIELDS.isValidationData}
								type='radio'
								label='validation'
								checked={exvivoTrainingData.isValidationData}
								onChange={() => handleIsValidationChange(true)}
							/>
						</Styled.UploadFileButtonWrap>
					</Col>
				</Row>
			</Styled.Content>
			{children}
		</Styled.FormWrap>
	);
}
