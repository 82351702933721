import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Styled from './styled';

export function dateBetweenFilterFn(rows, id, filterValue) {
	if (!filterValue) return rows;
	const start = moment(filterValue[0]).subtract(1, 'day');
	const end = moment(filterValue[1]).add(1, 'day');
	return rows.filter(val => moment(val.original[id]).isBetween(start, end));
}

const currentDate = new Date();

export const DateFilter = ({ column: { setFilter, Header } }: any) => {
	const [startDate, setStartDate] = useState(currentDate);
	const [endDate, setEndDate] = useState(currentDate);

	const handleSetDate = () => {
		setFilter((old = []) => [old && old[0], endDate]);
		setFilter((old = []) => [startDate, old && old[1]]);
	};

	const handleClearFilter = () => {
		setFilter(null);
		setStartDate(currentDate);
		setEndDate(currentDate);
	};

	const handleCancel = () => {
		document.body.click();
	};
	return (
		<Styled.Container>
			<Styled.Header>
				<Styled.Title>Filter By {Header}</Styled.Title>
				<Styled.Link onClick={handleClearFilter}>
					Clear selection
				</Styled.Link>
			</Styled.Header>
			<Styled.DateContainer>
				<DatePicker
					selected={startDate}
					onChange={date => {
						setStartDate(date);
					}}
					selectsStart
					startDate={startDate}
					endDate={endDate}
					inline
					peekNextMonth
					showMonthDropdown
					showYearDropdown
				/>
				<DatePicker
					selected={endDate}
					onChange={date => {
						setEndDate(date);
					}}
					selectsEnd
					startDate={startDate}
					endDate={endDate}
					inline
					peekNextMonth
					showMonthDropdown
					showYearDropdown
				/>
			</Styled.DateContainer>

			<Styled.ButtonContainer>
				<Styled.CancelButton variant='danger' onClick={handleCancel}>
					Cancel
				</Styled.CancelButton>
				<Styled.SuccessButton variant='success' onClick={handleSetDate}>
					Set Date
				</Styled.SuccessButton>
			</Styled.ButtonContainer>
		</Styled.Container>
	);
};
