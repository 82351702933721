import { createSelector } from 'reselect';
import { updateRepository, getNamespace } from 'core/store/repository';
import * as namespaces from 'core/consts/namespaces';
import { FILTER } from 'core/consts/namespaces';

export function updateFilter(filtername, data) {
	return updateRepository({
		namespace: FILTER,
		compute: old => {
			return { ...old, [filtername]: data };
		},
	});
}

export const filtersSelector = getNamespace(namespaces.FILTER);

export const singleFilterSelector = filtername =>
	createSelector(filtersSelector, results => {
		if (results) {
			return results[filtername];
		}
		return null;
	});
