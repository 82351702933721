import styled from 'styled-components';
import { Button as RButton } from 'components/Button';

export const Container = styled.div`
	width: calc(100vw - 308px);
	min-height: 60vh;
	overflow-x: scroll;
	margin: 20px;
	background-color: ${p => p.theme.white};
	position: relative;
	top: -20px;
	table {
		th {
			white-space: nowrap;
		}
		th:last-of-type
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1),
		th:nth-last-child(2)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1),
		th:nth-last-child(3)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1) {
			position: absolute;
			left: -23.5rem;
		}
		th:last-of-type
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1)
			div,
		th:nth-last-child(2)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1)
			div,
		th:nth-last-child(3)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1)
			div {
			position: relative;
			left: 0;
		}
		tr {
			cursor: pointer;
		}
		th:first-of-type {
			width: 1px;
			padding: 0 8px;
		}
	}
	& .__react_component_tooltip {
		max-width: 30vw;
	}
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-top-left-radius: 44px;
	border-bottom-left-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const WarningButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.yellow_2};
	border-top-right-radius: 44px;
	border-bottom-right-radius: 44px;
`;

export const DownloadButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.gray_4};
	border-top-right-radius: 44px;
	border-bottom-right-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.gray_7};
	}
`;
