import React, { Fragment } from 'react';
import Router from 'Router';
import { GlobalStyle } from 'core/styles/global';

function App() {
	return (
		<Fragment>
			<GlobalStyle />
			<Router />
		</Fragment>
	);
}

export default App;
