import React, { useState, Fragment, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SelectCallback } from 'react-bootstrap/esm/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { WORKSPACE_HOME } from 'core/routes';
import { formatDateAndTime } from 'helpers';
import { ModalProvider } from 'components/Modal';
import { Nav, Tab } from 'react-bootstrap';
import queryString from 'query-string';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import { userSelector, projectIdSelector } from 'core/store/auth';
import {
	fetchPredictionsResults,
	predictionResultsSelector,
	fetchPrediction,
	predictionSelector,
	predictionResultsloadingSelector,
	fetchReviews,
	reviewsSelector,
	inProgressReviewSelector,
	applyPredictionSettings,
	hidePredictionSettingsModal,
} from 'core/store/predictions';
import { currentProjectSelector } from 'core/store/clusters';
import * as ROUTES from 'core/routes';
import { Link } from 'components/Link';
import { SubHeader } from 'components/SubHeader';
import { PREDICTIONS } from 'core/routes';
import { FormContainer, InputAdapter } from 'components/Form';
import PusherMessage from 'components/PusherMessage';
import { ROLES } from 'core/consts/roles';
import { CHANNELS } from 'core/consts/pusherConfig';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { currentClusterSelector } from 'core/store/clusters';
import ExperimentsTable from './components/ExperimentsTable';
import ReviewsTable from './components/ReviewsTable';
import StartReviewModal from './components/StartReview/StartReviewModal';
import ProjectSettingsModal from './components/ProjectSettingsModal';
import * as Styled from './styled';

const EXPERIMENTS_FIELDS = {
	search: 'search',
};

const TABS = {
	experiments: { eventKey: 'experiments', name: 'Experiments' },
	reviews: { eventKey: 'reviews', name: 'Reviews' },
};

const DEFAULT_ACTIVE_TAB = TABS.experiments.eventKey;

export default function PredictionsDetails() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const { projectId } = useSelector(projectIdSelector);
	const currentProject = useSelector(currentProjectSelector(projectId));
	const { userId } = useSelector(userSelector);
	const currentCluster = useSelector(currentClusterSelector);
	const reviews = useSelector(reviewsSelector);
	const inProgressReview = useSelector(inProgressReviewSelector);
	const { predictionId }: { predictionId: string } = useParams();
	const results = useSelector(predictionResultsSelector);
	const resultsLoading = useSelector(predictionResultsloadingSelector);
	const { loading: predictionSettingsLoading } = useNamespace({
		namespace: namespaces.PREDICTION_SETTINGS,
	});
	const {
		predictionName,
		isProjectSettingsPopUpHidden,
		isPredictionSettingsUpToDate,
	} = useSelector(predictionSelector);
	const { view } = queryString.parse(location.search);
	const canManageCurrentCluster =
		currentCluster?.role === ROLES.admin ||
		currentCluster?.role === ROLES.supervisor;
	const canCreateNewReview = !inProgressReview;
	const [tab, setTab] = useState(view || DEFAULT_ACTIVE_TAB);
	useEffect(() => {
		if (!projectId) {
			history.push(WORKSPACE_HOME);
		}
		if (!!projectId) {
			const route =
				ROUTES.PREDICTION_DETAILS.split(':')[0] + predictionId;
			history.push(`${route}?view=${tab}`);
			dispatch(fetchPrediction(predictionId));
			switch (tab) {
				case TABS.experiments.eventKey:
					return dispatch(fetchPredictionsResults(predictionId));
				case TABS.reviews.eventKey:
					return dispatch(fetchReviews(predictionId));
			}
		}
	}, [dispatch, history, tab, predictionId, projectId]);
	const isReviewsTabSelected = tab === TABS.reviews.eventKey;
	const addReviewDisabled = !canManageCurrentCluster || !canCreateNewReview;
	const addReviewVariant = !!addReviewDisabled ? 'secondary' : 'primary';

	const formatEvaluation = evaluation => {
		const {
			userName,
			label,
			startedOn,
			borderCase,
			responseStrength,
			subject,
			comment,
		} = evaluation;
		return `User : ${userName} - Label : ${label} - DateTime : ${formatDateAndTime(
			startedOn
		)} - BorderCase : ${borderCase.toString()} - ResponseStrength : ${responseStrength} - Comment : ${subject} - Detailed Comment : ${
			!!comment.length ? comment : 'NA'
		}`;
	};

	const handleExportTable = () => {
		try {
			const reviewsWithEvaluations = results.filter(
				result => !!result.Evaluations
			);
			const maxLength = !!reviewsWithEvaluations.length
				? Math.max.apply(
						Math,
						reviewsWithEvaluations.map(function (result) {
							return result.Evaluations.length;
						})
				  )
				: 0;
			const evaluationsColumnNames = Array.from(Array(maxLength)).map(
				(_, index) => {
					return `Evaluation ${index + 1}`;
				}
			);

			const tsvData = results
				.map(e => {
					return Object.values(e)
						.map(value => {
							if (Array.isArray(value)) {
								const reversedEvaluations = value.reverse();
								return reversedEvaluations
									.map(v => {
										return formatEvaluation(v);
									})
									.join('\t');
							}
							return value;
						})
						.join('\t');
				})
				.join('\n');
			const columnNames = Object.keys(results[0])
				.filter(key => key !== 'Evaluations')
				.concat(evaluationsColumnNames)
				.join('\t');
			const fileContent = columnNames + '\n' + tsvData;

			const blob = new Blob([fileContent], {
				type: 'text/tsv;charset=utf-8',
			});
			saveAs(blob, `${predictionId}_results.tsv`);
		} catch (e) {
			return;
		}
	};

	const handleSearchClick = () => {
		//@TODO
		// to be implemented
	};

	const refreshExperiments = ({ predictionId: receivedPredictionId }) => {
		if (predictionId === receivedPredictionId) {
			return dispatch(fetchPredictionsResults(predictionId));
		}
		return;
	};

	const applySettings = () => {
		dispatch(applyPredictionSettings(predictionId));
	};

	const handleHidePredictionSettingsModal = () => {
		dispatch(hidePredictionSettingsModal(predictionId));
	};

	return (
		<Styled.Wrapper>
			<PusherMessage
				callback={refreshExperiments}
				event={CHANNELS.experiments}
			/>
			<FormContainer
				onSubmit={handleSearchClick}
				formID='predictions'
				component={props => (
					<Fragment>
						<SubHeader
							pre={
								<span>
									Projects {'> '}
									<Link to={`${PREDICTIONS}?view=results`}>
										{currentProject?.projectName}
									</Link>
								</span>
							}
							current={predictionName}
							rightContent={
								<Styled.Form onSubmit={props.handleSubmit}>
									<Styled.Input
										placeholder='Research'
										showValid={false}
										name={EXPERIMENTS_FIELDS.search}
										component={InputAdapter}
										type='text'
									/>
									<Styled.SearchIcon icon={faSearch} />
								</Styled.Form>
							}
						/>
					</Fragment>
				)}
			/>
			<Tab.Container
				defaultActiveKey={tab}
				onSelect={setTab as SelectCallback}>
				<Styled.TabBar>
					<Nav variant='tabs'>
						<Nav.Item>
							<Nav.Link {...TABS.experiments}>
								{TABS.experiments.name}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link {...TABS.reviews}>
								{TABS.reviews.name}
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						<When
							condition={
								!!isProjectSettingsPopUpHidden &&
								!isPredictionSettingsUpToDate
							}>
							<Styled.DetectSpotCountButton
								variant='success'
								onClick={applySettings}
								loading={predictionSettingsLoading}>
								Detect Spot Count Review
							</Styled.DetectSpotCountButton>
						</When>
						<When condition={!isReviewsTabSelected}>
							<Styled.SuccessButton
								variant='success'
								onClick={handleExportTable}>
								Export as TSV &nbsp;
								<FontAwesomeIcon icon={faDownload} />
							</Styled.SuccessButton>
						</When>
						<When condition={!!isReviewsTabSelected}>
							<ModalProvider
								component={StartReviewModal}
								title='Start Review'
								trigger={
									<Styled.SuccessButton
										disabled={addReviewDisabled}
										variant={addReviewVariant}>
										Add Review
									</Styled.SuccessButton>
								}
							/>
						</When>
					</div>
				</Styled.TabBar>
				<Tab.Content>
					<Tab.Pane {...TABS.experiments}>
						<ExperimentsTable
							data={results}
							loading={resultsLoading}
						/>
						<When
							condition={
								!isProjectSettingsPopUpHidden && !resultsLoading
							}>
							<ModalProvider
								component={ProjectSettingsModal}
								title='Changes in project settings'
								openModalOnLoad
								modalProps={{
									applySettings,
									hidePredictionSettingsModal: handleHidePredictionSettingsModal,
								}}
							/>
						</When>
					</Tab.Pane>
					<Tab.Pane {...TABS.reviews}>
						<ReviewsTable
							data={reviews}
							loading={resultsLoading}
							userId={userId}
							predictionId={predictionId}
						/>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</Styled.Wrapper>
	);
}
