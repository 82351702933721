import { padSingleDigit } from 'helpers';

async function handleSingleCardSelection(
	selectedCard,
	lastSelectedWell,
	singleCardSelectedWells,
	setPlateBarcode,
	plateBarcode,
	setSelectedWells,
	selectedWells,
	availableWellsType,
	setIsSelectionChanged
) {
	if (!singleCardSelectedWells?.id) {
		setPlateBarcode({
			...plateBarcode,
			currentPlateBarcode: {
				...plateBarcode.currentPlateBarcode,
				[availableWellsType]: plateBarcode.currentPlateBarcode[
					availableWellsType
				].filter(elem => elem !== lastSelectedWell),
			},
		});
		setSelectedWells(
			selectedWells.map(well => {
				if (well.id === selectedCard) {
					return {
						id: well.id,
						selectedWells: [
							...well.selectedWells,
							lastSelectedWell,
						],
					};
				}
				return {
					id: well.id,
					selectedWells: well.selectedWells,
				};
			})
		);
		setIsSelectionChanged(true);
	} else {
		setPlateBarcode({
			...plateBarcode,
			currentPlateBarcode: {
				...plateBarcode.currentPlateBarcode,
				[availableWellsType]: [
					...plateBarcode.currentPlateBarcode[availableWellsType],
					lastSelectedWell,
				],
			},
		});
		setSelectedWells(
			selectedWells.map(well => {
				if (well.id === selectedCard) {
					return {
						id: well.id,
						selectedWells: well.selectedWells.filter(
							well => well !== lastSelectedWell
						),
					};
				}
				return {
					id: well.id,
					selectedWells: well.selectedWells,
				};
			})
		);
		setIsSelectionChanged(true);
	}
}

async function handleMultipleCardSelection(
	selectedCard,
	lastSelectedWell,
	firstSelectedWell,
	setPlateBarcode,
	plateBarcode,
	setSelectedWells,
	selectedWells,
	ROWS_COUNTER,
	COLUMNS_HEADER,
	availableWellsType,
	setIsSelectionChanged
) {
	const firstLineNumber = firstSelectedWell.substring(1);
	const firstColChar = firstSelectedWell[0];
	const lastLineNumber = lastSelectedWell.substring(1);
	const LastColChar = lastSelectedWell[0];
	const cols = Array.from(Array(ROWS_COUNTER - 1).keys())
		.map(item => {
			return item + 1;
		})
		.filter(
			elem =>
				(elem >= parseInt(firstLineNumber) &&
					elem <= parseInt(lastLineNumber)) ||
				(elem <= parseInt(firstLineNumber) &&
					elem >= parseInt(lastLineNumber))
		);
	const firstCharIndex = COLUMNS_HEADER.findIndex(
		char => char === firstColChar
	);
	const lastCharIndex = COLUMNS_HEADER.findIndex(
		char => char === LastColChar
	);
	const rows = COLUMNS_HEADER.filter(
		(_, index) =>
			(index >= firstCharIndex && index <= lastCharIndex) ||
			(index <= firstCharIndex && index >= lastCharIndex)
	);
	const selectedWellsNesteddArray = !!rows?.length
		? rows.map(row =>
				cols
					.map(col => {
						const colId = padSingleDigit(col);
						return (
							plateBarcode.currentPlateBarcode[
								availableWellsType
							].includes(`${row}${colId}`) && `${row}${colId}`
						);
					})
					.filter(value => !!value && value)
		  )
		: [];
	const deselectedWellsNesteddArray = !!rows?.length
		? rows.map(row =>
				cols
					.map(col => {
						const colId = padSingleDigit(col);
						return (
							!plateBarcode.currentPlateBarcode[
								availableWellsType
							].includes(`${row}${colId}`) && `${row}${colId}`
						);
					})
					.filter(value => !!value && value)
		  )
		: [];
	const selectedWellsArray = !!selectedWellsNesteddArray?.length
		? selectedWellsNesteddArray.reduce((prev = [], next = []) =>
				prev.concat(next)
		  )
		: [];

	const deselectedWellsArray = !!deselectedWellsNesteddArray?.length
		? deselectedWellsNesteddArray.reduce((prev = [], next = []) =>
				prev.concat(next)
		  )
		: [];
	setSelectedWells(
		selectedWells.map(well => {
			if (well.id === selectedCard) {
				const selectedWellsWithMultiDeselection =
					well.selectedWells.filter(
						well => !deselectedWellsArray.includes(well)
					);
				return {
					id: well.id,
					selectedWells: [
						...selectedWellsWithMultiDeselection,
						...selectedWellsArray,
					],
				};
			}
			return {
				id: well.id,
				selectedWells: well.selectedWells,
			};
		})
	);
	setPlateBarcode({
		...plateBarcode,
		currentPlateBarcode: {
			...plateBarcode.currentPlateBarcode,
			[availableWellsType]: plateBarcode.currentPlateBarcode[
				availableWellsType
			].filter(item => !selectedWellsArray.includes(item)),
		},
	});
	setIsSelectionChanged(true);
}

export async function handleMouseUpWellsSelection(
	selectedCard,
	lastSelectedWell,
	firstSelectedWell,
	singleCardSelectedWells,
	setPlateBarcode,
	plateBarcode,
	setSelectedWells,
	selectedWells,
	ROWS_COUNTER,
	COLUMNS_HEADER,
	availableWellsType,
	setIsSelectionChanged
) {
	const isMultipleCardSelected =
		!!selectedCard && lastSelectedWell !== firstSelectedWell;
	const isSingleCardSelected =
		!!selectedCard && lastSelectedWell === firstSelectedWell;
	if (isSingleCardSelected) {
		handleSingleCardSelection(
			selectedCard,
			lastSelectedWell,
			singleCardSelectedWells,
			setPlateBarcode,
			plateBarcode,
			setSelectedWells,
			selectedWells,
			availableWellsType,
			setIsSelectionChanged
		);
	}
	if (isMultipleCardSelected) {
		handleMultipleCardSelection(
			selectedCard,
			lastSelectedWell,
			firstSelectedWell,
			setPlateBarcode,
			plateBarcode,
			setSelectedWells,
			selectedWells,
			ROWS_COUNTER,
			COLUMNS_HEADER,
			availableWellsType,
			setIsSelectionChanged
		);
	}
}
