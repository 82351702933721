import React from 'react';
import get from 'lodash.get';
import { useFormState } from 'react-final-form';
import { Form } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import * as Styled from './styled';
import {
	CheckFields,
	CheckFieldsWithInput,
	FORM_FIELDS,
} from './consts/dataAugmentationFields';

export default function DataAugmentationSubForm() {
	const { values: formValues } = useFormState();
	return (
		<Styled.Content>
			<b>Data Augmentation</b>
			<Styled.Fields>
				{CheckFieldsWithInput.map(field => (
					<Styled.CheckContainer key={field.name}>
						<Field name={field.activator} type='checkbox'>
							{({ input }) => (
								<Form.Check {...input} label={field.label} />
							)}
						</Field>
						<Field
							showValid={false}
							name={field.name}
							component={InputAdapter}
							type='number'
							min='0'
							step='0.01'
							disabled={!get(formValues, field.activator)}
						/>
						<span>{field.suffix}</span>
					</Styled.CheckContainer>
				))}
				<Styled.CheckContainer>
					<Field name={FORM_FIELDS.gammaContrast} type='checkbox'>
						{({ input }) => (
							<Form.Check {...input} label='Gamma Contrast' />
						)}
					</Field>
					<span>Min</span>
					<Field
						showValid={false}
						type='number'
						name={FORM_FIELDS.gammaContrastMin}
						disabled={!get(formValues, FORM_FIELDS.gammaContrast)}
						component={InputAdapter}
					/>
					<span>Max</span>
					<Field
						showValid={false}
						type='number'
						name={FORM_FIELDS.gammaContrastMax}
						disabled={!get(formValues, FORM_FIELDS.gammaContrast)}
						component={InputAdapter}
					/>
				</Styled.CheckContainer>
				{CheckFields.map(field => (
					<Field name={field.name} type='checkbox' key={field.name}>
						{({ input }) => (
							<Styled.CheckContainer>
								<Form.Check {...input} label={field.label} />
							</Styled.CheckContainer>
						)}
					</Field>
				))}
			</Styled.Fields>
		</Styled.Content>
	);
}
