import React, { Fragment } from 'react';

interface Props {
	condition: boolean;
	children: React.ReactNode;
}

export function When({ condition, children }: Props) {
	return <Fragment>{condition && children}</Fragment>;
}
