import React from 'react';
import NotFoundSvg from 'assets/error/not_found.svg';
import ErrorPage from './ErrorPage';

export default function NotFound() {
	return (
		<ErrorPage
			message="OOPS! This is not what you're looking for..."
			image={NotFoundSvg}
		/>
	);
}
