import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { SelectCallback } from 'react-bootstrap/esm/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { faSearch, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import {
	fetchTrainingData,
	fetchTrainings,
	fetchTrainingParams,
	trainingDataSelector,
	trainingsSelector,
	trainingloadingSelector,
	trainingDataloadingSelector,
} from 'core/store/trainingsInVitro';
import { accessRightsSelector } from 'core/store/auth';
import * as ROUTES from 'core/routes';
import { ACCESS_RIGHTS } from 'core/consts/roles';
import { ModalProvider } from 'components/Modal';
import { SubHeader } from 'components/SubHeader';
import { FormContainer, InputAdapter } from 'components/Form';
import AddTrainingDataModal from './components/AddTrainingDataModal';
import TrainingResultsTable from './components/TrainingResultsTable';
import TrainingDataTable from './components/TrainingDataTable';
import * as Styled from './styled';

const TABS = {
	results: { eventKey: 'results', name: 'Results' },
	data: { eventKey: 'data', name: 'Data' },
};

const TRAINING_FIELDS = {
	search: 'search',
};

const DEFAULT_ACTIVE_TAB = TABS.results.eventKey;

export default function TrainingsInVitro() {
	const location = useLocation();
	const history = useHistory();
	const trainingData = useSelector(trainingDataSelector);
	const trainings = useSelector(trainingsSelector);
	const accessRights = useSelector(accessRightsSelector);
	const trainingsLoading = useSelector(trainingloadingSelector);
	const trainingsDataLoading = useSelector(trainingDataloadingSelector);
	const dispatch = useDispatch();
	const { view } = queryString.parse(location.search);
	const [tab, setTab] = useState(view || DEFAULT_ACTIVE_TAB);

	useEffect(() => {
		dispatch(fetchTrainingParams());
	}, [dispatch]);

	useEffect(() => {
		history.push(`${ROUTES.TRAININGS_IN_VITRO}?view=${tab}`);
		switch (tab) {
			case TABS.results.eventKey:
				return dispatch(fetchTrainings());
			case TABS.data.eventKey:
				return dispatch(fetchTrainingData());
		}
	}, [dispatch, history, tab]);

	const isDataTabSelected = tab === TABS.data.eventKey;
	const handleAddClick = () => {
		if (!isDataTabSelected) {
			history.push(ROUTES.NEW_TRAINING_IN_VITRO);
		}
	};

	const [canCreateData, canViewData, canRunTraining] = [
		accessRights.includes(ACCESS_RIGHTS.createTrainingData),
		accessRights.includes(ACCESS_RIGHTS.viewTrainingData),
		accessRights.includes(ACCESS_RIGHTS.runTrainings),
	];

	const handleSearchClick = () => {
		//@TODO
		// to be implemented
	};

	return (
		<Styled.Wrapper>
			<FormContainer
				onSubmit={handleSearchClick}
				formID='training'
				component={props => (
					<Fragment>
						<SubHeader
							pre='Trainings InVitro'
							rightContent={
								<Styled.Form onSubmit={props.handleSubmit}>
									<Styled.Input
										placeholder='Research'
										showValid={false}
										name={TRAINING_FIELDS.search}
										component={InputAdapter}
										type='text'
									/>
									<Styled.SearchIcon icon={faSearch} />
								</Styled.Form>
							}
						/>
					</Fragment>
				)}
			/>
			<Tab.Container
				defaultActiveKey={tab}
				onSelect={setTab as SelectCallback}>
				<Styled.TabBar canViewData={canViewData}>
					<Nav variant='tabs'>
						<Nav.Item>
							<Nav.Link {...TABS.results}>
								{TABS.results.name}
							</Nav.Link>
						</Nav.Item>
						<When condition={canViewData}>
							<Nav.Item>
								<Nav.Link {...TABS.data}>
									<Styled.Icon icon={faDatabase} />
									{TABS.data.name}
								</Nav.Link>
							</Nav.Item>
						</When>
					</Nav>
					<div>
						<When condition={!isDataTabSelected && canRunTraining}>
							<Styled.AddButton
								variant='success'
								onClick={handleAddClick}>
								New Trainings
							</Styled.AddButton>
						</When>
						<When condition={isDataTabSelected && canCreateData}>
							<ModalProvider
								component={AddTrainingDataModal}
								title='Add New Training InVitro Data'
								trigger={
									<Styled.AddButton
										variant='success'
										onClick={handleAddClick}>
										Add new data
									</Styled.AddButton>
								}
								modalStyles={{ size: 'lg' }}
							/>
						</When>
					</div>
				</Styled.TabBar>
				<Tab.Content>
					<Tab.Pane {...TABS.results}>
						<TrainingResultsTable
							data={trainings}
							loading={trainingsLoading}
						/>
					</Tab.Pane>
					<Tab.Pane {...TABS.data}>
						<TrainingDataTable
							data={trainingData}
							loading={trainingsDataLoading}
						/>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</Styled.Wrapper>
	);
}
