import styled from 'styled-components';
import { Alert as AAlert } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';

export const Container = styled.div`
	margin: 0 20px 20px;
	padding: 0;
	background-color: ${p => p.theme.white};
	max-height: 85vh;
	overflow: auto;
	tr {
		cursor: pointer;
	}
`;

export const AddButton = styled(RButton)`
	border: none;
	float: right;
	font-size: 14px;
	padding: 5px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const Content = styled.div`
	padding: 10px;
	input[type='text'],
	select {
		margin: 5px 0 10px 0;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 0 5%;
	span {
		margin-right: 10px;
	}
	button {
		width: 44%;
	}
`;

export const SaveButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const DeleteButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px 20px;
	background-color: ${p => p.theme.red};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const CreateInputFileButtonWrapper = styled.div`
	width: 100%;
	overflow: hidden;
`;
export const CheckBoxContainer = styled.div`
	position: relative;
	padding-top: 10px;
	margin-left: 10px;
	input[type='checkbox'] {
		opacity: 0;
		z-index: 1;
	}
	label {
		position: relative;
		border-radius: 50%;
		left: -28px;
		top: -3.3px;
		z-index: 0;
	}
	label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		margin: 4px;
		width: 18px;
		height: 18px;
		transition: transform 0.28s ease;
		border: 2px solid ${p => p.theme.gray};
		background: transparent;
		border-radius: 50%;
	}
	label:after {
		content: '';
		display: block;
		width: 10px;
		height: 5px;
		border-bottom: 2px solid ${p => p.theme.gray};
		border-left: 2px solid ${p => p.theme.gray};
		transform: rotate(-45deg) scale(1);
		position: absolute;
		top: 9px;
		left: 8px;
	}
	input[type='checkbox']:checked ~ label::before {
		color: ${p => p.theme.white};
		border: 2px solid ${p => p.color || p.theme.green};
		background: ${p => p.color || p.theme.green};
	}

	input[type='checkbox']:checked ~ label::after {
		-webkit-transform: rotate(-45deg) scale(1);
		transform: rotate(-45deg) scale(1);
		border-bottom: 2px solid ${p => p.theme.white};
		border-left: 2px solid ${p => p.theme.white};
	}

	label {
		min-height: 34px;
		display: block;
		padding-left: 40px;
		margin-bottom: 0;
		font-weight: normal;
		cursor: pointer;
		vertical-align: sub;
	}

	input[type='checkbox']:focus + label::before {
		outline: 0;
	}
`;

export const TabBar = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 20px 0 20px;
	& .nav {
		width: 50%;
		& .nav-item {
			width: 150px;
			position: relative;
			left: 40px;
			&:nth-child(2) {
				left: 80px;
			}
		}
	}
	& .nav-link {
		text-align: center;
		font-weight: bold;
		color: ${p => p.theme.border};
		border-bottom: 5px solid ${p => p.theme.gray_9};
		&:hover {
			border-color: transparent;
			border-bottom: 5px solid ${p => p.theme.gray_9};
		}
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: transparent;
		border-color: transparent;
		color: ${p => p.theme.green};
		border-bottom: 5px solid ${p => p.theme.green};
		svg {
			color: ${p => p.theme.green};
		}
	}
`;
