import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { Modal } from 'react-bootstrap';
import JSZip from 'jszip';
import format from 'string-template';
import { handleResumableUpload } from 'helpers';
import {
	RESUMABLE_INVITRO_TRAINIG_DATA_IMAGES,
	RESUMABLE_INVITRO_TRAINIG_DATA_EDA,
	RESUMABLE_INVITRO_TRAINIG_DATA_REAC,
} from 'core/consts/endpoints';
import {
	createTrainingData,
	checkInvitroTrainingDataExistence,
} from 'core/store/trainingsInVitro';
import { API_URL } from 'core/consts';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { When } from 'components/When';
import AddTrainingDataForm from './AddTrainingDataForm';
import ErrorMessages from 'components/ErrorMessages';
import * as Styled from '../styled';

export default function AddTrainingDataModal({ closeModal }) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_ADDED_TRAINING_INVITRO_DATA,
		onSuccess: closeModal,
	});
	const { data: isNameUsed = true, error: checkNameError } = useNamespace({
		namespace: namespaces.CHECK_TRAINING_INVITRO_DATA_NAME_EXISTENCE,
	});
	const [invitroTrainingData, setInvitroTrainingData] = useState({
		name: '',
		isValidationData: false,
		isDisabled: false,
	});
	const [imagesPathUploadProgress, setImagesPathUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_INVITRO_TRAINIG_DATA_IMAGES}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const [edaUploadProgress, setEdaUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_INVITRO_TRAINIG_DATA_EDA}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const [reacUploadProgress, setReacUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_INVITRO_TRAINIG_DATA_REAC}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});

	const errors = !!trace
		? trace.split('-- ').filter(err => !!err.length)
		: [];
	const handleCreateData = () =>
		dispatch(
			createTrainingData({
				name: invitroTrainingData.name,
				isValidationData: invitroTrainingData.isValidationData,
			})
		);
	const zipFile = (state, setState, target, endpoint): void => {
		const endpointUpload = `${format(endpoint, {
			name: invitroTrainingData.name.replaceAll(' ', '_'),
		})}`;
		setState({
			...state,
			zipping: true,
		});
		setInvitroTrainingData({ ...invitroTrainingData, isDisabled: true });
		const zip = new JSZip();
		const fileName =
			target.files[0].webkitRelativePath.split('/')[0] ||
			target.files[0].name.replace(/\.[^/.]+$/, '');
		for (let file = 0; file < target.files.length; file++) {
			zip.file(
				target.files[file].webkitRelativePath ||
					target.files[file].name,
				target.files[file],
				target.files[file]
			);
		}

		zip.generateAsync({
			type: 'blob',
		})
			.then(content => {
				if (content) {
					handleResumableUpload(
						content,
						fileName,
						endpointUpload,
						setState,
						state
					);
				}
			})
			.catch(() =>
				setState({
					...state,
					zipError: true,
				})
			);
	};

	const handleDebounceNameChange = debounce(nameValue => {
		dispatch(checkInvitroTrainingDataExistence(nameValue));
	}, 500);

	const handleNameChange = evt => {
		setInvitroTrainingData({
			...invitroTrainingData,
			name: evt.target.value,
		});
		handleDebounceNameChange(evt.target.value);
	};

	const handleIsValidationChange = isValidation => {
		setInvitroTrainingData({
			...invitroTrainingData,
			isValidationData: isValidation,
		});
	};

	const canSubmit =
		imagesPathUploadProgress.isUploaded &&
		edaUploadProgress.isUploaded &&
		reacUploadProgress.isUploaded;

	return (
		<AddTrainingDataForm
			imagesPathUploadProgress={imagesPathUploadProgress}
			setImagesPathUploadProgress={setImagesPathUploadProgress}
			edaUploadProgress={edaUploadProgress}
			setEdaUploadProgress={setEdaUploadProgress}
			reacUploadProgress={reacUploadProgress}
			setReacUploadProgress={setReacUploadProgress}
			invitroTrainingData={invitroTrainingData}
			isNameDisabled={invitroTrainingData.isDisabled}
			handleNameChange={handleNameChange}
			handleIsValidationChange={handleIsValidationChange}
			isNameUsed={isNameUsed || checkNameError}
			zipFile={zipFile}>
			<When condition={error}>
				<ErrorMessages errors={errors} />
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='danger' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.SaveButton
					variant='success'
					loading={loading}
					onClick={handleCreateData}
					disabled={!canSubmit}>
					Save changes
				</Styled.SaveButton>
			</Modal.Footer>
		</AddTrainingDataForm>
	);
}
