import MockAdapter from 'axios-mock-adapter';

const GET_USER_WORKSPACES = '/users/getUserWorkspaces';
const CONNECT_USER_TO_WORKSPACE = '/connectUserToWorkspace';

const isMock = process.env.mock || false;

function mockApiResponse(result) {
	return {
		result,
		status: 'success',
		errorCode: null,
		errorMessage: null,
	};
}
const token = 'example_token';

export default function setupMock(instance) {
	if (isMock) {
		const mock = new MockAdapter(instance);
		mock.onPost('/account/sign-in', {
			email: 'test',
			password: 'password',
		}).reply(
			200,
			mockApiResponse({ accessToken: token, accessRights: [] })
		);

		mock.onPost(GET_USER_WORKSPACES, {
			token,
		}).reply(200, mockApiResponse([{ id: 29 }]));

		mock.onPost(CONNECT_USER_TO_WORKSPACE, {
			id: 29,
		}).reply(
			200,
			mockApiResponse({
				accessToken: token,
				accessRights: ['ViewPredictions'],
			})
		);
	}
}
