import styled from 'styled-components';
import { Alert as AAlert } from 'react-bootstrap';

export const Alert = styled(AAlert)`
	margin: 10px;
	p:first-of-type {
		margin-bottom: 0;
	}
	p:nth-child(2) {
		margin-top: 1rem;
	}
`;
