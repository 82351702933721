import React from 'react';
import { useDispatch } from 'react-redux';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	createVisit,
	fetchVisitsWithSelectedWells,
} from 'core/store/InputGeneration';
import CreateVisitForm from './CreateVisitForm';
import { FormContainer } from 'components/Form';

export default function CreateVisitModal({
	closeModal,
	workspaceDataId,
	plateId,
}) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_VISIT,
		onSuccess: () => {
			closeModal();
			dispatch(fetchVisitsWithSelectedWells(workspaceDataId, plateId));
		},
	});
	const handleCreateVisit = data => {
		dispatch(createVisit(data, workspaceDataId));
	};

	return (
		<FormContainer
			formID='create-visit'
			onSubmit={handleCreateVisit}
			component={props => (
				<CreateVisitForm
					{...props}
					loading={loading}
					closeModal={closeModal}
					trace={trace}
					error={error}
				/>
			)}
		/>
	);
}
