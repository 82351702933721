import React from 'react';
import { useDispatch } from 'react-redux';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	createReadout,
	fetchReadoutWithSelectedWells,
} from 'core/store/InputGeneration';
import CreateReadoutForm from './CreateReadoutForm';
import { FormContainer } from 'components/Form';

export default function CreateReadoutModal({
	closeModal,
	workspaceDataId,
	plateId,
}) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_READOUT,
		onSuccess: () => {
			closeModal();
			dispatch(fetchReadoutWithSelectedWells(workspaceDataId, plateId));
		},
	});
	const handleCreateReadout = data => {
		dispatch(createReadout(data, workspaceDataId));
	};

	return (
		<FormContainer
			formID='create-Readout'
			onSubmit={handleCreateReadout}
			component={props => (
				<CreateReadoutForm
					{...props}
					loading={loading}
					closeModal={closeModal}
					trace={trace}
					error={error}
				/>
			)}
		/>
	);
}
