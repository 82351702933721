import React, { useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import UserDetails from 'components/userDetails';
import * as Styled from '../styled';

const ADD_REVIEW_FIELDS = {
	search: 'search',
	name: 'name',
	type: 'type',
	reviewers: 'reviewers',
};
export default function EditReviewForm({ handleSubmit, children, users }) {
	const { values } = useFormState();
	const projectMembers = useMemo(() => {
		if (!!values.search) {
			return users.filter(user =>
				Object.values(user)
					.join()
					.toLowerCase()
					.includes(values.search.toLowerCase())
			);
		}
		return users;
	}, [values.search, users]);

	const handleAddUser = (fields, user) => {
		fields.push({
			user,
		});
	};

	const handleRemoveUser = (fields, index) => {
		fields.remove(index);
	};

	const getSingleUser = ({ userId }) => {
		if (!!users) {
			return users.find(u => u.userId === userId);
		}
		return {};
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<Styled.Row>
					<Col>
						<Field
							showValid={false}
							name={ADD_REVIEW_FIELDS.name}
							placeholder='Review name...'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Styled.Row>
				<Styled.Row>
					<Col>
						<Form.Label>
							<b>Review Type</b>
						</Form.Label>
						<Field
							name={ADD_REVIEW_FIELDS.type}
							type='radio'
							value='1'>
							{({ input }) => {
								return (
									<Form.Check
										{...input}
										label='Regular Review'
										checked={input.checked}
									/>
								);
							}}
						</Field>
						<Field
							name={ADD_REVIEW_FIELDS.type}
							type='radio'
							value='2'>
							{({ input }) => (
								<Form.Check
									{...input}
									label='Blind Review'
									checked={input.checked}
								/>
							)}
						</Field>
					</Col>
				</Styled.Row>
				<Styled.Row>
					<Col>
						<FieldArray name={ADD_REVIEW_FIELDS.reviewers}>
							{({ fields }) => (
								<div>
									<When condition={!!fields.value?.length}>
										<Styled.Row>
											<Col>
												<Form.Label>
													<b>Assign Reviewers</b>
												</Form.Label>
											</Col>
										</Styled.Row>
									</When>
									<When condition={!!users?.length}>
										{fields.map((name, index) => (
											<UserDetails
												key={name}
												user={getSingleUser(
													fields.value[index].user
												)}
												onButtonClick={() =>
													handleRemoveUser(
														fields,
														index
													)
												}
												selectedAsMember={
													true
												}></UserDetails>
										))}
									</When>

									<Styled.InputWrapper>
										<Field
											name={ADD_REVIEW_FIELDS.search}
											placeholder='Search'
											component={InputAdapter}
										/>
										<FontAwesomeIcon icon={faSearch} />
									</Styled.InputWrapper>
									<p>Active Members</p>
									<When condition={!users?.length}>
										<Styled.Alert variant='danger'>
											No users to assign as reviewer
										</Styled.Alert>
									</When>
									<Styled.UsersWrap>
										{projectMembers?.map(user => (
											<UserDetails
												key={user.userId}
												user={user}
												onButtonClick={() =>
													handleAddUser(fields, user)
												}
												selectedAsMember={false}
												selectedList={fields.value?.map(
													list => list.user
												)}
											/>
										))}
									</Styled.UsersWrap>
								</div>
							)}
						</FieldArray>
					</Col>
				</Styled.Row>
			</Styled.Content>
			{children}
		</Form>
	);
}
