import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { When } from 'components/When';
import { WORKSPACE_HOME } from 'core/routes';
import { ROLES } from 'core/consts/roles';
import { FormContainer } from 'components/Form';
import {
	authWorkspacesSelector,
	userRoleSelector,
	connectUserToWorkspace,
} from 'core/store/auth';
import { logout } from 'core/store/auth';
import BionTechLogoIcon from 'assets/logos/biontech-icon-logo.svg';
import BionTechLogo from 'assets/logos/biontech-se-logo.svg';
import SelectWorkspaceForm from './components/SelectWorkspaceForm';
import NoWorkspaces from './components/NoWorkspaces';

import * as Styled from './styled';

export default function SelectWorkspace() {
	const dispatch = useDispatch();
	const history = useHistory();
	const workspaces = useSelector(authWorkspacesSelector);
	const userRole = useSelector(userRoleSelector);
	const isAdmin = userRole === ROLES.admin;
	useEffect(() => {
		if (!isAdmin && workspaces.length === 1) {
			const { workspaceId } = workspaces[0];
			dispatch(connectUserToWorkspace({ workspaceId }));
			history.push(WORKSPACE_HOME);
		}
	}, [dispatch, history, workspaces, isAdmin]);

	const hasMultipleWorkspace = !!workspaces.length && workspaces.length !== 1;

	const handleLogout = () => {
		dispatch(logout());
	};
	const handleSubmit = ({ workspaceId: { value: workspaceId } }) => {
		if (!workspaceId) return;
		dispatch(connectUserToWorkspace({ workspaceId }));
		history.push(WORKSPACE_HOME);
	};

	return (
		<Styled.Container>
			<Styled.LogoContainer>
				<Styled.LogoIcon src={BionTechLogoIcon} alt='' />
				<Styled.Title>Elispot</Styled.Title>
				<Styled.Logo src={BionTechLogo} alt='' />
			</Styled.LogoContainer>
			<h1>Login to Workspace</h1>
			<When condition={!workspaces.length}>
				<NoWorkspaces handleLogout={handleLogout} />
			</When>
			<When condition={hasMultipleWorkspace || isAdmin}>
				<Styled.FormContent>
					<FormContainer
						formID='workspaces-selector'
						onSubmit={handleSubmit}
						component={props => (
							<SelectWorkspaceForm
								{...props}
								handleLogout={handleLogout}
								workspaces={workspaces}
							/>
						)}
					/>
				</Styled.FormContent>
			</When>
			<Styled.BottomContainer>
				<p>Copyright 2021 BioNTech</p>
				<p>Terms & conditions</p>
				<p>Cookie policy</p>
				<p>Privacy policy</p>
			</Styled.BottomContainer>
		</Styled.Container>
	);
}
