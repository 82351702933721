import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { langSelector } from 'core/store/i18n';

const messagesCache = {};

export default function AsyncIntlProvider({ children }) {
	const locale = useSelector(langSelector);
	const messages = getMessages(locale);
	return (
		<IntlProvider locale={locale} messages={messages}>
			{children}
		</IntlProvider>
	);
}

function getMessages(locale) {
	if (messagesCache[locale]) {
		return messagesCache[locale];
	}
	throw loadMessages(locale);
}

async function loadMessages(locale) {
	const messages = await import(`./translations/${locale}.json`);
	messagesCache[locale] = messages;
	return messages;
}
