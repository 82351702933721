import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ADMIN_HOME, SELECT_WORKSPACE } from 'core/routes';
import { ADMIN_PORTAL_WORKSPACE_ID } from 'core/consts/roles';
import { connectUserToWorkspace } from 'core/store/auth';
import BionTechLogoIcon from 'assets/logos/biontech-icon-logo.svg';
import BionTechLogo from 'assets/logos/biontech-se-logo.svg';

import * as Styled from './styled';

export default function AdminLogin() {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleLoginToPortal = () => {
		dispatch(
			connectUserToWorkspace({ workspaceId: ADMIN_PORTAL_WORKSPACE_ID })
		);
		history.push(ADMIN_HOME);
	};

	const handleRedirectToWorkspace = () => {
		history.push(SELECT_WORKSPACE);
	};

	return (
		<Styled.Container>
			<Styled.LogoContainer>
				<Styled.LogoIcon src={BionTechLogoIcon} alt='' />
				<Styled.Title>Elispot</Styled.Title>
				<Styled.Logo src={BionTechLogo} alt='' />
			</Styled.LogoContainer>
			<h1>
				You’ve got control! <br /> Keep all workspaces in check.
			</h1>
			<Styled.ButtonContainer>
				<Styled.Button onClick={handleLoginToPortal}>
					Login to portal
				</Styled.Button>
				<Styled.Button onClick={handleRedirectToWorkspace}>
					Login to workspace
				</Styled.Button>
			</Styled.ButtonContainer>
			<Styled.BottomContainer>
				<p>Copyright 2021 BioNTech</p>
				<p>Terms & conditions</p>
				<p>Cookie policy</p>
				<p>Privacy policy</p>
			</Styled.BottomContainer>
		</Styled.Container>
	);
}
