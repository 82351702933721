import format from 'string-template';
import { createSelector } from 'reselect';
import * as namespaces from 'core/consts/namespaces';
import { fetchInit, getData, getLoadingState } from 'core/store/repository';
import {
	ADMIN_WORKSPACES,
	SINGLE_WORKSPACE,
	WORKSPACE_USERS,
	EXTERNAL_WORKSPACE_USERS,
	ADD_WORKSPACE_MEMBERS,
	USER_WORKSPACES,
	WORKSPACE_CLUSTER_JOINERS,
	WORKSPACE_PATHS_SETTINGS,
	WORKSPACE_PATHS,
} from 'core/consts/endpoints';

export function fetchWorkspaces() {
	return fetchInit({
		url: ADMIN_WORKSPACES,
		namespace: namespaces.WORKSPACES,
	});
}

export function fetchUserWorkspaces(userId) {
	const url = format(USER_WORKSPACES, { userId });
	return fetchInit({
		url,
		namespace: namespaces.WORKSPACES,
	});
}

export function fetchWorkspaceUsers() {
	return fetchInit({
		url: WORKSPACE_USERS,
		namespace: namespaces.WORKSPACE_USERS,
	});
}

export function fetchWorkspaceClusterJoiners() {
	return fetchInit({
		url: WORKSPACE_CLUSTER_JOINERS,
		namespace: namespaces.WORKSPACE_CLUSTER_JOINERS,
	});
}

export function fetchExternalWorkspaceUsers(workspaceId) {
	const url = format(EXTERNAL_WORKSPACE_USERS, { workspaceId });

	return fetchInit({
		url,
		namespace: namespaces.EXTERNAL_WORKSPACE_USERS,
	});
}

export function createWorkspace(data) {
	return fetchInit({
		url: ADMIN_WORKSPACES,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_WORKSPACE,
		successCb: fetchWorkspaces,
	});
}

export function deleteWorkspace(workspaceId) {
	const namespace = format(namespaces.DELETED_WORKSPACE, {
		id: workspaceId,
	});
	const url = format(SINGLE_WORKSPACE, { workspaceId });
	return fetchInit({
		url,
		namespace,
		config: { method: 'delete' },
		successCb: fetchWorkspaces,
	});
}

export function addWorkspaceMembers(data, cb) {
	return fetchInit({
		url: ADD_WORKSPACE_MEMBERS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.ADDED_WORKSPACE_MEMBERS,
		successCb: cb,
	});
}

export function deleteWorkspaceUser(userId) {
	const namespace = format(namespaces.DELETED_WORKSPACE_USER, {
		id: userId,
	});
	return fetchInit({
		url: WORKSPACE_USERS,
		namespace,
		config: { method: 'delete', data: { userId } },
		successCb: fetchWorkspaceUsers,
	});
}

export function updateWorkspaceUserRole(data) {
	const { userId } = data;
	const namespace = format(namespaces.UPDATED_WORKSPACE_USER, {
		id: userId,
	});
	return fetchInit({
		url: WORKSPACE_USERS,
		namespace,
		config: { method: 'PATCH', data },
		successCb: fetchWorkspaceUsers,
	});
}

export function fetchWorkspacePaths(workspaceId) {
	const url = format(WORKSPACE_PATHS, { workspaceId });
	return fetchInit({
		url,
		namespace: namespaces.WORKSPACE_PATHS,
	});
}
export function updateWorkspacePathsSettings(data, workspaceId) {
	const url = format(WORKSPACE_PATHS_SETTINGS, { workspaceId });
	const namespace = format(namespaces.UPDATED_WORKSPACE_PATHS_SETTINGS, {
		id: workspaceId,
	});
	return fetchInit({
		url,
		namespace,
		config: { method: 'PATCH', data },
		successCb: () => fetchWorkspacePaths(workspaceId),
	});
}
export const workspacesSelector = getData(namespaces.WORKSPACES);

export const workspacesLoadingSelector = getLoadingState(namespaces.WORKSPACES);

export const externalWorskpaceUsersSelector = getData(
	namespaces.EXTERNAL_WORKSPACE_USERS
);

export const currentWorkspaceSelector = (id: string) => {
	return createSelector(workspacesSelector, workspaces =>
		workspaces.find(workspace => workspace.workspaceId === id)
	);
};
