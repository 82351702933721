import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import * as Styled from '../styled';

export default function DisplayStimulus({ stimulus, onStimulusChange }) {
	const theme = useTheme();
	return (
		<Styled.DisplayStimulus>
			<p>Select Stimulus in IVS to display : </p>
			{stimulus.map(({ stimulusName, isSelected }) => {
				return (
					<Styled.SingleStimulus
						key={stimulusName}
						onClick={() => onStimulusChange(stimulusName)}>
						<When condition={!!isSelected}>
							<FontAwesomeIcon icon={faEye} color={theme.black} />
						</When>
						<When condition={!isSelected}>
							<FontAwesomeIcon
								icon={faEyeSlash}
								color={theme.gary_3}
							/>
						</When>
						<p>{stimulusName}</p>
					</Styled.SingleStimulus>
				);
			})}
		</Styled.DisplayStimulus>
	);
}
