import styled, { css } from 'styled-components';
import { Alert as AAlert, Form as BForm } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'components/Form';

export const Wrapper = styled.div`
	margin-top: 10px;
`;

export const Container = styled.div`
	margin: 20px;
	position: relative;
	top: -20px;
	background-color: ${p => p.theme.white};
	table {
		tr {
			cursor: pointer;
		}
		th:first-of-type {
			width: 1px;
			padding: 0 8px;
		}
	}
`;

export const TabBar = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 20px 0 20px;
	position: relative;

	& .nav {
		width: 50%;
		& .nav-item {
			width: 150px;
			position: relative;
			left: 40px;
			&:nth-child(2) {
				left: 80px;
			}
		}
	}
	& .nav-link {
		text-align: center;
		font-weight: bold;
		color: ${p => p.theme.border};
		border-bottom: 5px solid ${p => p.theme.gray_9};
		&:hover {
			border-color: transparent;
			border-bottom: 5px solid ${p => p.theme.gray_9};
		}
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: transparent;
		border-color: transparent;
		color: ${p => p.theme.green};
		border-bottom: 5px solid ${p => p.theme.green};
		svg {
			color: ${p => p.theme.green};
		}
	}
	& .nav-tabs {
		${({ canViewData }) =>
			!canViewData &&
			css`
				border-bottom: none;
			`}
	}
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const AddButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const LogButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 0 15px;
	border-radius: 44px;
	background-color: ${p => p.theme.gray_8};
`;

export const SearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;
	top: 12px;
	color: ${p => p.theme.border};
`;

export const Input = styled(Field)`
	border-radius: 80px;
	border: none;
	background: ${p => p.theme.gray_6};
	padding: 0 60px;
`;

export const Icon = styled(FontAwesomeIcon)`
	position: relative;
	right: 15px;
	color: ${p => p.theme.border};
`;

export const Form = styled(BForm)`
	position: relative;
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;

export const DeleteButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.red};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const SaveButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;
