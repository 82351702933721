import React from 'react';
import * as Styled from './styled';

interface Props {
	percentage: number;
	backGroundColor: string;
	color: string;
	value: string;
	loss?: number;
}
export default function ConfusionMatrixCell({
	value,
	percentage,
	loss,
	backGroundColor,
	color,
}: Props) {
	return (
		<Styled.SingleConfusionMatrix
			percentage={percentage}
			backGroundColor={backGroundColor}
			color={color}>
			{!!value && (
				<p>
					<span>{value}</span>
					<span>{(percentage * 100).toFixed(2)}%</span>
					{loss && <span>{(loss * 100).toFixed(2)}%</span>}
				</p>
			)}
		</Styled.SingleConfusionMatrix>
	);
}
