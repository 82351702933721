import React from 'react';
import { A } from 'components/A';
import * as Styled from '../styled';

export default function NoWorkspaces({ handleLogout }) {
	return (
		<Styled.Content>
			<p>
				You don't belong to any workspace , please contact your admin!
			</p>
			<A onClick={handleLogout}>(Log out)</A>
		</Styled.Content>
	);
}
