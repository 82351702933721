import styled from 'styled-components';

export const ImageSvg = styled.img`
	width: 1.25em;
`;

export const SelectedUser = styled.div`
	padding: 5px 10px;
	width: 100%;
	margin: 10px auto;
	display: flex;
	align-items: center;
	background-color: ${p => p.theme.gray_6};
	span {
		margin-left: 15px;
		display: block;
	}
	button {
		margin-left: auto;
		margin-bottom: auto;
	}
	button svg {
		color: ${p => p.theme.red_4};
	}
`;

export const User = styled.div`
	padding: 10px;
	width: 100%;
	margin: 5px auto;
	display: flex;
	align-items: center;
	:hover {
		background-color: ${p => p.theme.gray_6};
		cursor: pointer;
	}
	:hover button {
		opacity: 1;
	}
	span {
		margin-left: 15px;
	}
	button {
		margin-left: auto;
		opacity: 0;
	}
	button svg {
		color: ${p => p.theme.green};
	}
`;
