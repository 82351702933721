import React, { useMemo } from 'react';
import { Table } from 'components/Table';
import * as Styled from './styled';

export default function ExperimentDetailsTable({ experiment }) {
	const columns = useMemo(() => {
		return [
			...Object.keys(experiment)
				.filter(key => {
					return (
						![
							'expert_label',
							'predicted_label',
							'confidence',
							'Evaluations',
							'missing_images_str',
						].includes(key) &&
						!key.startsWith('count_') &&
						!key.startsWith('img_')
					);
				})
				.map(key =>
					key === 'ReviewStatus'
						? {
								Header: 'Review Status',
								accessor: key,
								disableSortBy: true,
						  }
						: {
								Header: key,
								accessor: key,
								disableSortBy: true,
								Cell: ({ value }) => {
									const cellValue = value ?? '';
									return cellValue.toString();
								},
						  }
				),
		];
	}, [experiment]);
	return (
		<Styled.TableWrap>
			<Table data={[experiment]} columns={columns} />
		</Styled.TableWrap>
	);
}
