import React from 'react';
import { useDispatch } from 'react-redux';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { EXVIVO, INVITRO } from 'core/consts';
import {
	createWorkspaceData,
	fetchWorkspaceDataTemplates,
} from 'core/store/InputGeneration';
import CreateInputFileForm from './CreateInputFileForm';
import { FormContainer } from 'components/Form';
import { formatBoolToString } from 'components/Form/helpers';

export default function CreateInputFileModal({ closeModal, workspaceId }) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_WORSKPACE_DATA,
		onSuccess: closeModal,
	});

	const { data: templates } = useNamespace({
		namespace: namespaces.TEMPLATES_BY_SIZE,
	});
	const handleCreateInputFile = data => {
		const experimentType = formatBoolToString(
			EXVIVO,
			INVITRO
		)(data?.experimentType);
		const formattedData = {
			...data,
			workspaceDataTemplateId: data?.workspaceDataTemplateId ?? '',
			isFromTemplate: !!data?.workspaceDataTemplateId,
			experimentType,
		};
		dispatch(createWorkspaceData(formattedData, workspaceId));
	};
	const getTemplates = size =>
		dispatch(fetchWorkspaceDataTemplates(workspaceId, size));
	return (
		<FormContainer
			formID='create-input-file'
			onSubmit={handleCreateInputFile}
			component={props => (
				<CreateInputFileForm
					{...props}
					loading={loading}
					closeModal={closeModal}
					trace={trace}
					error={error}
					getTemplates={getTemplates}
					templates={templates}
				/>
			)}
		/>
	);
}
