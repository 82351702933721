import styled from 'styled-components';
import { Alert as AAlert } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';

export const Content = styled.div`
	padding: 10px;
	input[type='text'],
	select {
		margin: 5px 0 10px 0;
	}
`;

export const Fields = styled.div`
	padding: 0 30px 0 30px;
	margin-top: 10px;
`;

export const CheckContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
	label {
		width: 200px;
	}
	input[type='number'] {
		width: 100px;
	}
	input[type='number']::-webkit-inner-spin-button {
		opacity: 1;
	}
	span {
		margin: 0 10px 0 10px;
	}
`;

export const ButtonWrapper = styled.div`
	padding: 30px;
	span {
		margin-right: 10px;
	}
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;
