import { createSelector } from 'reselect';
import partition from 'lodash.partition';
import format from 'string-template';
import * as namespaces from 'core/consts/namespaces';
import {
	fetchInit,
	getData,
	getNamespace,
	getLoadingState,
} from 'core/store/repository';
import {
	TRAININGS,
	TRAINING_INVITRO_DATA,
	TRAININGS_INVITRO,
	TRAININGS_PARAMS,
	SINGLE_TRAINING,
	SINGLE_TRAINING_DATA,
	TRAINING_MODEL_TROUBLESHOOTING,
	SET_CHOSEN_MODEL,
	TD_CHECK_SUMMARY,
	TD_PREPROCESSING_SUMMARY,
	TRAINING_RESULTS,
	TRAINING_EVALUATION,
	CHECK_TRAININGS_INVITRO_DATA_NAME_EXISTENCE,
	TRAINING_DATA_FILE_URL,
} from 'core/consts/endpoints';

export function fetchTrainingData() {
	return fetchInit({
		url: TRAINING_INVITRO_DATA,
		namespace: namespaces.TRAINING_INVITRO_DATA,
	});
}

export function fetchTrainings() {
	return fetchInit({
		url: TRAININGS_INVITRO,
		namespace: namespaces.TRAININGS,
	});
}

export function fetchTraining(id: string) {
	const url = format(SINGLE_TRAINING, { id });
	const namespace = format(namespaces.SINGLE_TRAINING, { id });
	return fetchInit({
		url,
		namespace,
	});
}

export function createTrainingData(data) {
	return fetchInit({
		url: TRAINING_INVITRO_DATA,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_TRAINING_INVITRO_DATA,
		successCb: fetchTrainingData,
	});
}
export function checkInvitroTrainingDataExistence(name) {
	const url = format(CHECK_TRAININGS_INVITRO_DATA_NAME_EXISTENCE, { name });
	return fetchInit({
		url,
		namespace: namespaces.CHECK_TRAINING_INVITRO_DATA_NAME_EXISTENCE,
	});
}

export function createTraining(data) {
	return fetchInit({
		url: TRAININGS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_TRAINING,
	});
}

export function fetchTrainingParams() {
	return fetchInit({
		url: TRAININGS_PARAMS,
		namespace: namespaces.TRAINING_PARAMS,
	});
}

export function fetchModelTroubleshooting(data, trainingId) {
	const url = format(TRAINING_MODEL_TROUBLESHOOTING, { trainingId });
	return fetchInit({
		url: url,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.TRAINING_MODEL_TROUBLESHOOTING,
	});
}

export function reloadModelTroubleshooting(trainingId) {
	const url = format(TRAINING_MODEL_TROUBLESHOOTING, { trainingId });
	return fetchInit({
		url: url,
		config: {
			method: 'PUT',
		},
		namespace: namespaces.TRAINING_MODEL_TROUBLESHOOTING,
	});
}

export function deleteTraining(id) {
	const namespace = format(namespaces.DELETED_TRAINING, { id });
	const url = format(SINGLE_TRAINING, { id });
	return fetchInit({
		url,
		namespace,
		config: { method: 'delete' },
		successCb: fetchTrainings,
	});
}
export function deleteTrainingData(id) {
	const namespace = format(namespaces.DELETED_TRAINING_DATA, {
		id,
	});
	const url = format(SINGLE_TRAINING_DATA, { trainingDataId: id });
	return fetchInit({
		url,
		namespace,
		config: { method: 'delete' },
		successCb: fetchTrainingData,
	});
}

export function setAsChosenModel(id: string) {
	const url = format(SET_CHOSEN_MODEL, { id });
	return fetchInit({
		url,
		namespace: `chosen_model_${id}`,
		config: { method: 'post' },
		successCb: () => fetchTraining(id),
		autoClear: true,
	});
}

export function checkTrainingDataSummary(trainingDataId) {
	const url = format(TD_CHECK_SUMMARY, { trainingDataId });
	const namespace = format(namespaces.TRAINING_DATA_SUMMARY, {
		id: trainingDataId,
	});
	return fetchInit({
		url,
		namespace,
	});
}

export function fetchTDPreprocessingSummary(trainingDataId) {
	const url = format(TD_PREPROCESSING_SUMMARY, { trainingDataId });
	const namespace = format(namespaces.TRAINING_DATA_SUMMARY, {
		id: trainingDataId,
	});
	return fetchInit({
		url,
		namespace,
	});
}

export function fetchTrainingResults(id) {
	const namespace = format(namespaces.TRAINING_RESULTS, {
		id,
	});
	const url = format(TRAINING_RESULTS, { id });
	return fetchInit({
		url,
		namespace,
	});
}

export function fetchTrainingEvaluation(id) {
	const url = format(TRAINING_EVALUATION, { id });
	return fetchInit({
		url,
		namespace: namespaces.TRAINING_EVALUATION,
	});
}

export function fetchSingleTrainingData(id: string) {
	const url = format(SINGLE_TRAINING_DATA, { trainingDataId: id });
	return fetchInit({
		url,
		namespace: namespaces.SINGLE_TRAINING_DATA,
	});
}

export function fetchTrainingDataFileUrl(trainingDataId, fileName) {
	const namespace = namespaces.TRAINING_DATA_FILE_URL;
	const url = format(TRAINING_DATA_FILE_URL, {
		trainingDataId,
		fileName,
	});
	return fetchInit({
		url,
		namespace,
	});
}

export const trainingDataSelector = getData(namespaces.TRAINING_INVITRO_DATA);
export const trainingsSelector = getData(namespaces.TRAININGS);

export const trainingFromCollectionSelector = (id: string) => {
	return createSelector(trainingsSelector, trainings =>
		trainings.find(t => t.trainingId === id)
	);
};

export const trainingFromNamespaceSelector = getData(
	namespaces.SINGLE_TRAINING,
	null
);

export const trainingSelector = (id: string) => {
	return createSelector(
		trainingFromCollectionSelector(id),
		trainingFromNamespaceSelector,
		(trainingFromCollection, trainingFromNamespace) => {
			if (trainingFromCollection) return trainingFromCollection;
			if (trainingFromNamespace !== null) {
				return trainingFromNamespace;
			}
		}
	);
};

export const addedTrainingDataSelector = getNamespace(
	namespaces.NEW_ADDED_TRAINING_INVITRO_DATA
);

export const addedTrainingSelector = getNamespace(
	namespaces.NEW_ADDED_TRAINING
);

export const trainingParamsSelector = getData(namespaces.TRAINING_PARAMS);

export const trainingDataSummarySelector = id => {
	const namespace = format(namespaces.TRAINING_DATA_SUMMARY, { id });
	return getData(namespace, {});
};

export const trainingloadingSelector = getLoadingState(namespaces.TRAININGS);
export const trainingDataloadingSelector = getLoadingState(
	namespaces.TRAINING_INVITRO_DATA
);

export const partitionedTrainingDataSelector = createSelector(
	trainingDataSelector,
	data => partition(data, ({ isValidationData }) => !isValidationData)
);
