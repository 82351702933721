import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as Styled from './styled';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';

interface Props {
	icon: IconProp;
	extraChildIcon: IconProp;
	options: any[];
	name: string;
	onClick: Function;
	keyName: string;
	children?: React.ReactNode;
	onSettingsIconClick?: Function;
}

export const Dropdown = ({
	icon,
	name,
	options,
	onClick,
	keyName,
	children,
	extraChildIcon,
	onSettingsIconClick,
}: Props) => {
	return (
		<Styled.Dropdown>
			<Styled.DropdownToggle>
				<FontAwesomeIcon icon={icon} />
				<span>{name}</span>
			</Styled.DropdownToggle>
			<When condition={!!onSettingsIconClick}>
				<Styled.SettingIcon
					icon={faCog}
					onClick={onSettingsIconClick}
				/>
			</When>
			<Styled.DropdownMenu>
				{options.map(item => (
					<Styled.Container key={item[keyName]}>
						<FontAwesomeIcon icon={icon} />
						<Styled.DropdownItem onClick={() => onClick(item)}>
							<Styled.Text>{item[keyName]}</Styled.Text>
						</Styled.DropdownItem>
					</Styled.Container>
				))}
				<Styled.Container>
					<FontAwesomeIcon icon={extraChildIcon} />
					<Styled.DropdownItem>{children}</Styled.DropdownItem>
				</Styled.Container>
			</Styled.DropdownMenu>
		</Styled.Dropdown>
	);
};
