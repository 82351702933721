import styled, { css } from 'styled-components';

export const Avatar = styled.div`
	text-transform: uppercase;
	background: ${p => p.theme.gray_5};
	height: 42px;
	width: 42px;
	${({ size }) =>
		size &&
		css`
			width: ${size}!important;
			height: ${size}!important;
		`}

	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${p => p.theme.white};
	margin-left: 15px;
	float: left;
`;
