import React from 'react';
import { When } from 'components/When';
import * as Styled from './styled';

interface Props {
	component: React.ComponentType<any>;
	cardProps?: Record<string, any>;
	clickHandler?: Function;
	isSelected?: boolean;
	color?: string;
	changeColorHandler?: Function;
}

export default function CardProvider({
	component: Component,
	cardProps,
	clickHandler,
	isSelected,
	color = '',
	changeColorHandler,
}: Props) {
	return (
		<Styled.Card
			onClick={!!clickHandler ? clickHandler : () => null}
			$isSelected={isSelected}>
			<Component {...cardProps} />
			<When condition={!!color}>
				<Styled.ColorRect $color={color} onClick={changeColorHandler} />
			</When>
		</Styled.Card>
	);
}
