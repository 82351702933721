import styled from 'styled-components';

export * from '../styled';

export const Container = styled.div`
	display: flex;
	background-color: ${p => p.theme.white};
	flex-direction: column;
	width: 11rem;
	box-shadow: 0 0 6px ${p => p.theme.lightDark};
	border-radius: 12px;
`;

export const ListItem = styled.li`
	padding: 5px;
	text-align: center;
	background-color: ${p => (p.isActive ? p.theme.gray_6 : 'transparent')};
	&:hover {
		background-color: ${p => p.theme.gray_6};
	}
`;

export const Text = styled.span`
	font-weight: 100;
	margin: 5px;
	text-align: center;
	color: ${p => p.color};
	text-transform: capitalize;
`;
