import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faBrain, faUsers, faRocket } from '@fortawesome/free-solid-svg-icons';
import { ACCESS_RIGHTS } from 'core/consts/roles';

import {
	ADMIN_HOME,
	PREDICTIONS,
	PREDICTION_DATA,
	TRAININGS_IN_VITRO,
	TRAININGS_EX_VIVO,
	TRAINING_IN_VITRO_DATA,
	TRAINING_EX_VIVO_DATA,
	ADMIN_USERS,
	INPUT_FILE_GENERATION,
} from 'core/routes';

export const NavSectionRole = {
	predictions: {
		role: ACCESS_RIGHTS.viewPredictions,
		link: PREDICTIONS,
		subPages: [PREDICTION_DATA],
	},
	trainingsInVitro: {
		role: ACCESS_RIGHTS.viewTrainings,
		link: TRAININGS_IN_VITRO,
		subPages: [TRAINING_IN_VITRO_DATA],
	},
	trainingsExVivo: {
		role: ACCESS_RIGHTS.viewTrainings,
		link: TRAININGS_EX_VIVO,
		subPages: [TRAINING_EX_VIVO_DATA],
	},
	inputGeneration: {
		role: ACCESS_RIGHTS.viewPredictions,
		link: INPUT_FILE_GENERATION,
		subPages: [],
	},
};

interface NavItemConfig {
	name: string;
	link: string;
	icon: FontAwesomeIconProps['icon'];
	subPages: string[];
	role?: string;
}

export const adminNavItems: NavItemConfig[] = [
	{
		name: 'Workspaces',
		link: ADMIN_HOME,
		subPages: [],
		icon: faRocket,
	},
	{
		name: 'User Management',
		link: ADMIN_USERS,
		subPages: [],
		icon: faUsers,
	},
];

const navItems: NavItemConfig[] = [
	/*	{
		name: 'Predictions',
		icon: faMicroscope,
		...NavSectionRole.predictions,
	},*/
	{
		name: 'Trainings InVitro',
		icon: faBrain,
		...NavSectionRole.trainingsInVitro,
	},
	{
		name: 'Trainings ExVivo',
		icon: faBrain,
		...NavSectionRole.trainingsExVivo,
	},
	{
		name: 'Input Files Creation',
		icon: faBrain,
		...NavSectionRole.inputGeneration,
	},
];

export default navItems;
