import React, { useState } from 'react';
import { WELLS_IMAGES_BG_COLOR } from 'core/consts';
import FullPlateRow from './FullPlateRow';
import DisplayStimulus from './DisplayStimulus';
import * as Styled from '../styled';

const COLUMN_COUNTER = 13;

const ROWS_HEADER = Array.from(Array(COLUMN_COUNTER).keys()).map(item => {
	if (item > 0) {
		return item;
	}
	return '';
});

const COLUMNS_HEADER = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export default function FullPlate({
	fullplateData,
	folderName,
	predictionDataId,
	currentStimulusInIVS,
}) {
	const fullplateDataWithBgColor = fullplateData.map(well => {
		if (!!well.imageKey.length) {
			const borderColor = WELLS_IMAGES_BG_COLOR.find(wellColor => {
				return well.imageKey.startsWith(wellColor.type);
			});
			return { ...well, borderColor: borderColor?.color };
		}
		return well;
	});
	const nestedFullPlate = COLUMNS_HEADER.map(colHeader => {
		return fullplateDataWithBgColor.filter(plate =>
			plate.imageName.startsWith(colHeader)
		);
	});
	const stimilusInIVSList = Array.from(
		new Set(
			fullplateData
				.map(well => well.stimulusInIvs)
				.filter(stimulusName => !!stimulusName)
		)
	);
	const stimilusInIVSArray = stimilusInIVSList.map(item => ({
		stimulusName: item,
		isSelected: currentStimulusInIVS === item,
	}));

	const [stimulus, setStimulus] = useState<any[]>(stimilusInIVSArray);

	const handleStimulusChange = stimulusName => {
		const singleStimulus = stimulus.find(
			item => item.stimulusName === stimulusName
		);
		const newStimulus = stimulus.map(item => {
			if (item.stimulusName === stimulusName) {
				return {
					stimulusName: singleStimulus.stimulusName,
					isSelected: !singleStimulus.isSelected,
				};
			}
			return item;
		});
		setStimulus(newStimulus);
	};
	return (
		<Styled.FullPlateContainer>
			<DisplayStimulus
				stimulus={stimulus}
				onStimulusChange={handleStimulusChange}
			/>
			<Styled.FullPlateTable bordered id={'full-plate-images'}>
				<thead>
					<tr>
						{ROWS_HEADER.map(rowHeader => (
							<th key={rowHeader}>{rowHeader}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{nestedFullPlate.map((plate, index) => (
						<FullPlateRow
							key={index}
							plateRowData={plate}
							folderName={folderName}
							predictionDataId={predictionDataId}
							columnHeader={COLUMNS_HEADER[index]}
							stimilusInIVSList={stimulus}
						/>
					))}
				</tbody>
			</Styled.FullPlateTable>
		</Styled.FullPlateContainer>
	);
}
