import styled from 'styled-components';

export const Container = styled.div`
	margin: 20px;
	background-color: ${p => p.theme.white};
	position: relative;

	table {
		tr {
			cursor: pointer;
		}
		th:first-of-type {
			width: 1px;
			padding: 0 8px;
		}
	}
`;

export const SubHeaderWrapper = styled.div`
	width: calc(100vw - 250px);
`;
