import styled from 'styled-components';
import { Dropdown as BDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Dropdown = styled(BDropdown)`
	margin-left: 10px;
	margin-right: 15px;
	&:hover {
		svg {
			display: initial;
		}
	}
	button {
		&:after {
			position: relative;
			top: 2px;
		}
	}
`;

export const DropdownToggle = styled(BDropdown.Toggle)`
	background-color: transparent;
	border: transparent;
	font-weight: bold;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 14px;
	padding: 10px 5px 10px 10px;
	font-size: 14px;
	span {
		position: relative;
		right: -30px;
	}
	svg {
		position: absolute;
	}
	&:hover,
	&:focus,
	&:active,
	&:visited,
	&:not(:disabled),
	&:target {
		background-color: transparent !important;
		border: transparent;
		box-shadow: none !important;
	}
`;
export const DropdownMenu = styled(BDropdown.Menu)`
	background: ${p => p.theme.gray_7};
	border-radius: 12px;
	width: 100%;
	padding: 0;
`;

export const Text = styled.span`
	font-weight: bold;
	color: ${p => p.theme.gray};
	padding-left: 10px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${p => p.theme.gray_2};

	&:nth-child(1) {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		border-top: none;
	}
	&:nth-last-child(1) {
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		border-top: none;
		border-bottom: none;
	}
	span {
		color: ${p => p.theme.gray};
		white-space: pre-wrap;
		font-size: 13px;
	}
	svg {
		color: ${p => p.theme.gray};
		transition: fill 0.3s ease;
		width: 20px !important;
		height: 20px;
		position: relative;
		left: 15px;
	}
	&:hover {
		background: ${p => p.theme.green_4};
		span {
			color: ${p => p.theme.white};
		}
		svg {
			color: ${p => p.theme.white}!important;
		}
	}
`;

export const DropdownItem = styled(BDropdown.Item)`
	&:hover {
		background: transparent;
	}
`;

export const IconContainer = styled.div`
	display: flex;
`;

export const SettingIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 25px;
	color: ${p => p.theme.white};
	top: 16px;
	width: 12px !important;
	height: 12px !important;
	display: none;
	cursor: pointer;
`;
