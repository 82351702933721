import reducerRegistry from '../reducerRegistry';

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export function clearErrorMessage() {
	return {
		type: RESET_ERROR_MESSAGE,
	};
}

const reducerName = 'error';
export default function reducer(state = null, action) {
	const { type, error } = action;

	if (type === RESET_ERROR_MESSAGE) {
		return null;
	} else if (error) {
		return error;
	}

	return state;
}

reducerRegistry.register(reducerName, reducer);

export const errorSelector = state => state.error;
