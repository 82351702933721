import styled from 'styled-components';

export const Layout = styled.div`
	display: grid;
	grid-template-columns: 250px 1fr;
	position: relative;
	background: ${p => p.theme.white};
	width: 100vw;
	overflow-x: hidden;
`;

export const RightContainer = styled.div`
	display: grid;
	grid-template-rows: 65px 1fr 60px;
	max-height: calc(100vh - 63px);
	overflow-y: auto;
`;

export const Content = styled.div``;
