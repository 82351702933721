import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { When } from 'components/When';
import AddNewPredictionForm from 'containers/Predictions/components/AddNewPredictionForm';
import { FormContainer } from 'components/Form';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	readyPredictionDataInVitroSelector,
	readyPredictionDataExVivoSelector,
	fetchInVitroPredictionData,
	fetchExVivoPredictionData,
	createPrediction,
} from 'core/store/predictions';
import * as Styled from '../styled';

export default function AddNewPredictionModal({ closeModal }) {
	const dispatch = useDispatch();
	const readyPredictionDataInVitro = useSelector(
		readyPredictionDataInVitroSelector
	);
	const readyPredictionDataExVivo = useSelector(
		readyPredictionDataExVivoSelector
	);
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_ADDED_PREDICTION,
		onSuccess: closeModal,
	});
	useEffect(() => {
		dispatch(fetchInVitroPredictionData());
		dispatch(fetchExVivoPredictionData());
	}, [dispatch]);

	const handleAddNewPrediction = data => {
		const { name, predictionDataId } = data;
		dispatch(createPrediction({ name, predictionDataId }));
	};

	return (
		<FormContainer
			formID='start-prediction'
			onSubmit={handleAddNewPrediction}
			component={props => (
				<AddNewPredictionForm
					{...props}
					readyPredictionDataInVitro={readyPredictionDataInVitro}
					readyPredictionDataExVivo={readyPredictionDataExVivo}
					loading={loading}
					closeModal={closeModal}>
					<When condition={error}>
						<Styled.Alert variant='danger'>{trace}</Styled.Alert>
					</When>
				</AddNewPredictionForm>
			)}
		/>
	);
}
