export const PredictionAccessRights = {
	viewPredictions: 'ViewPredictions',
	viewPredictionsData: 'ViewPredictionsData',
	createPredictionsData: 'CreatePredictionsData',
	runPredictions: 'RunPredictions',
};

export const TrainingsAccessRights = {
	viewTrainings: 'ViewTrainings',
	viewTrainingData: 'ViewTrainingsData',
	createTrainingData: 'CreateTrainingsData',
	runTrainings: 'RunTrainings',
	deleteTrainings: 'DeleteTrainings',
};

export const WoskpaceAccessRights = {
	CreateWorkspace: 'CreateWorkspace',
	CreateCluster: 'CreateCluster',
	DeleteCluster: 'DeleteCluster',
	AddUserToWorkspace: 'AddUserToWorkspace',
	RemoveUserFromWorkspace: 'RemoveUserFromWorkspace',
};

export const ACCESS_RIGHTS = {
	...PredictionAccessRights,
	...TrainingsAccessRights,
	...WoskpaceAccessRights,
};

export const WORKSPACES_ROLES = {
	predictions: Object.values(PredictionAccessRights),
	trainings: Object.values(TrainingsAccessRights),
};

export const ROLES = {
	admin: 'Admin',
	user: 'User',
	supervisor: 'Supervisor',
	bioInformatician: 'BioInformatician',
};

export const USER_ROLES = [ROLES.admin, ROLES.user, ROLES.supervisor];
export const WORKSPACE_USER_ROLES = [
	ROLES.supervisor,
	ROLES.user,
	ROLES.bioInformatician,
];
export const CLUSTER_USER_ROLES = [ROLES.user, ROLES.supervisor];

export enum ROLE_ID {
	Api = 1,
	Admin,
	Supervisor,
	BioInformatician,
	User,
	Unknown,
}
export const ADMIN_PORTAL_WORKSPACE_ID = 'AdminProtal';
export const ADMIN_PORTAL_WORKSPACE_LABEL = 'Admin Portal';
export const userRoleKey =
	'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const userRolesSelectOptions = WORKSPACE_USER_ROLES.map(role => {
	return {
		label: role,
		value: role,
	};
});

export const clusterSelectOptions = userRolesSelectOptions.filter(
	({ label }) => label !== ROLES.bioInformatician
);

export const ELISPOT_COUNT_USERNAME = 'Spot Count Automation';
export const PREDICTED_USERNAME = 'Predicted';
