import React from 'react';
import { ProgressBar as BProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

export const Container = styled.div`
	margin: 20px 0;
	span {
		float: right;
		margin: 5px 0 10px;
		font-size: 12px;
		color: ${p => p.theme.black};
		font-weight: 600;
		margin-right: 2%;
	}
	span:first-of-type {
		float: left;
		color: ${p => p.theme.gray_2};
		margin-left: 2%;
		svg {
			margin-right: 10px;
		}
	}
`;

export const StyledProgressBar = styled(BProgressBar)`
	border-radius: 10px;
	&& .progress-bar {
		background: ${p => p.theme.green};
	}
`;

interface Props {
	fileName: string;
	percentage: number;
	now: string;
	size: string;
}

export const ProgressBar = ({ fileName, percentage, now, size }: Props) => {
	return (
		<Container>
			<StyledProgressBar now={percentage} />
			<span>
				<FontAwesomeIcon icon={faFile} />
				{fileName}
			</span>
			<span>{`${percentage}% / ${now} / ${size}`}</span>
		</Container>
	);
};
