import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { WORKSPACE_HOME } from 'core/routes';
import { SelectCallback } from 'react-bootstrap/esm/helpers';
import { Nav, Tab } from 'react-bootstrap';
import queryString from 'query-string';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ACCESS_RIGHTS } from 'core/consts/roles';
import { When } from 'components/When';
import {
	fetchPredictionData,
	fetchPredictions,
	predictionsSelector,
	predictionDataSelector,
	predictionloadingSelector,
	predictionDataloadingSelector,
} from 'core/store/predictions';
import { accessRightsSelector, projectIdSelector } from 'core/store/auth';
import { currentProjectSelector } from 'core/store/clusters';
import * as ROUTES from 'core/routes';
import { ModalProvider } from 'components/Modal';
import { SubHeader } from 'components/SubHeader';
import { FormContainer, InputAdapter } from 'components/Form';
import PredictionsResultsTable from './components/PredictionsResultsTable';
import AddPredictionDataModal from './components/AddPredictionDataModal';
import AddNewPredictionModal from './components/AddNewPredictionModal';
import PredictionDataTable from './components/PredictionDataTable';
import * as Styled from './styled';

const PREDICTIONS_FIELDS = {
	search: 'search',
};

const TABS = {
	results: { eventKey: 'results', name: 'Results' },
	data: { eventKey: 'data', name: 'Data' },
};

const DEFAULT_ACTIVE_TAB = TABS.results.eventKey;

export default function Predictions() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const predictionData = useSelector(predictionDataSelector);
	const { projectId } = useSelector(projectIdSelector);
	const currentProject = useSelector(currentProjectSelector(projectId));
	const predictions = useSelector(predictionsSelector);
	const accessRights = useSelector(accessRightsSelector);
	const predictionsLoading = useSelector(predictionloadingSelector);
	const predictionsDataLoading = useSelector(predictionDataloadingSelector);
	const { view } = queryString.parse(location.search);
	const [tab, setTab] = useState(view || DEFAULT_ACTIVE_TAB);

	useEffect(() => {
		if (!projectId) {
			history.push(WORKSPACE_HOME);
		}
		if (!!projectId) {
			history.push(`${ROUTES.PREDICTIONS}?view=${tab}`);
			switch (tab) {
				case TABS.results.eventKey:
					return dispatch(fetchPredictions());
				case TABS.data.eventKey:
					return dispatch(fetchPredictionData());
			}
		}
	}, [dispatch, history, tab, projectId]);

	const isDataTabSelected = tab === TABS.data.eventKey;

	const [canCreateData, canViewData, canRunPrediction] = [
		accessRights.includes(ACCESS_RIGHTS.createPredictionsData),
		accessRights.includes(ACCESS_RIGHTS.viewPredictionsData),
		accessRights.includes(ACCESS_RIGHTS.runPredictions),
	];

	const handleSearchClick = () => {
		//@TODO
		// to be implemented
	};

	return (
		<Styled.Wrapper>
			<FormContainer
				onSubmit={handleSearchClick}
				formID='predictions'
				component={props => (
					<Fragment>
						<SubHeader
							pre='Projects'
							current={currentProject?.projectName}
							rightContent={
								<Styled.Form onSubmit={props.handleSubmit}>
									<Styled.Input
										placeholder='Research'
										showValid={false}
										name={PREDICTIONS_FIELDS.search}
										component={InputAdapter}
										type='text'
									/>
									<Styled.SearchIcon icon={faSearch} />
								</Styled.Form>
							}
						/>
					</Fragment>
				)}
			/>
			<Tab.Container
				defaultActiveKey={tab}
				onSelect={setTab as SelectCallback}>
				<Styled.TabBar canViewData={canViewData}>
					<Nav variant='tabs'>
						<Nav.Item>
							<Nav.Link {...TABS.results}>
								{TABS.results.name}
							</Nav.Link>
						</Nav.Item>
						<When condition={canViewData}>
							<Nav.Item>
								<Nav.Link {...TABS.data}>
									{TABS.data.name}
								</Nav.Link>
							</Nav.Item>
						</When>
					</Nav>
					<div>
						<When
							condition={!isDataTabSelected && canRunPrediction}>
							<ModalProvider
								component={AddNewPredictionModal}
								title='Add New Prediction'
								trigger={
									<Styled.AddButton variant='success'>
										Add new Prediction
									</Styled.AddButton>
								}
							/>
						</When>
						<When condition={isDataTabSelected && canCreateData}>
							<ModalProvider
								component={AddPredictionDataModal}
								title='Add New Prediction Data'
								trigger={
									<Styled.AddButton variant='success'>
										Add new data
									</Styled.AddButton>
								}
								modalStyles={{ size: 'lg' }}
							/>
						</When>
					</div>
				</Styled.TabBar>
				<Tab.Content>
					<Tab.Pane {...TABS.results}>
						<PredictionsResultsTable
							data={predictions}
							loading={predictionsLoading}
							projectId={projectId}
						/>
					</Tab.Pane>
					<Tab.Pane {...TABS.data}>
						<PredictionDataTable
							data={predictionData}
							loading={predictionsDataLoading}
							projectId={projectId}
						/>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</Styled.Wrapper>
	);
}
