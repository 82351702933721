const colors = ['#FF1520', '#FF1520', '#fff'];
const backGroundColors = ['#FFF5EC', '#FFECDB', '#92C848'];

function arrayColumnsSum(array) {
	return array.reduce((a, b) => a.map((x, i) => x + (b[i] || 0)));
}

function sumArray(array) {
	return array.reduce((a, b) => a + b);
}

function transposeMatrix(matrix) {
	return matrix[0].map((_, i) => matrix.map(row => row[i]));
}

function indexArray(array) {
	let indexedArray = array.map(function (e, i) {
		return { val: e };
	});
	indexedArray.sort(function (x, y) {
		return x.val > y.val ? 1 : x.val === y.val ? 0 : -1;
	});
	return indexedArray;
}

function calculPercents(array) {
	const percents: any[] = [];
	let colMaxValArray: any[] = [];
	array.forEach((di, mainIndex) => {
		let finalRow;
		let percentage;

		const indexedArray = indexArray(di);
		const verticalArray = array.map(function (x) {
			return x[mainIndex];
		});
		const maxVerticalValue = verticalArray
			.slice(0, -1)
			.reduce(function (prev, current) {
				return prev > current ? prev : current;
			});
		mainIndex < di.length - 1 && colMaxValArray.push(maxVerticalValue);
		if (mainIndex === di.length - 1) {
			finalRow = di.map((x, i) => {
				percentage =
					i < di.length - 1
						? colMaxValArray[i] / x
						: colMaxValArray.reduce((a, b) => a + b, 0) / x;
				return {
					value: x,
					percentage,
					loss: 1 - percentage,
					backGroundColor: `rgba(42, 51, 43, ${percentage})`,
				};
			});
		} else {
			finalRow = di.map((x, index) => {
				if (index < di.length - 1) {
					const ind = indexedArray.findIndex(t => t.val === x);
					percentage = x / array[array.length - 1][di.length - 1];
					return {
						value: x,
						percentage,
						backGroundColor: backGroundColors[ind],
						color: colors[ind],
					};
				}
				const maxValue = di
					.slice(0, -1)
					.reduce(function (prev, current) {
						return prev > current ? prev : current;
					});
				percentage = maxValue / di[di.length - 1];
				return {
					value: x,
					percentage,
					loss: 1 - percentage,
					backGroundColor: `rgba(42, 51, 43, ${percentage})`,
				};
			});
		}

		percents.push(finalRow);
	});
	return percents;
}

export const prepareData = rawData => {
	try {
		const parsedCM = JSON.parse(rawData);
		const transposedParsedCM = transposeMatrix(parsedCM);
		const sumRows = transposedParsedCM.map(l => {
			return [...l, sumArray(l)];
		});
		const sumColumns = [...sumRows, arrayColumnsSum(sumRows)];
		const data = calculPercents(sumColumns);
		return data;
	} catch (e) {
		return [];
	}
};
