export const FORM_FIELDS = {
	flip: 'trainingDataAugmentation.flip',
	flipProbability: 'trainingDataAugmentation.flipProbability',
	rotate: 'trainingDataAugmentation.rotation',
	angle: 'trainingDataAugmentation.angle',
	gammaContrast: 'trainingDataAugmentation.gammaContrast',
	gammaContrastMin: 'trainingDataAugmentation.minimumGamma',
	gammaContrastMax: 'trainingDataAugmentation.maximumGamma',
	gray: 'trainingDataAugmentation.gray',
};

export const CheckFieldsWithInput = [
	{
		name: FORM_FIELDS.flipProbability,
		label: 'Flip',
		activator: FORM_FIELDS.flip,
	},
	{
		name: FORM_FIELDS.angle,
		label: 'Rotate',
		activator: FORM_FIELDS.rotate,
		suffix: 'Degrees',
	},
];

export const CheckFields = [
	{
		name: FORM_FIELDS.gray,
		label: 'Gray',
	},
];
