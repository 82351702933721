import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { SelectAdapter, Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import {
	labels,
	borderCase,
	strengthResponse,
	includeInTraining,
} from '../evaluationConfig';
import * as Styled from '../styled';

const FORM_FIELDS = {
	label: 'label',
	borderCase: 'borderCase',
	responseStrength: 'responseStrength',
	subject: 'subject',
	comment: 'comment',
	includeInTraining: 'includeInTraining',
};

export default function CreateEvaluationForm({
	handleSubmit,
	children,
	commentOptions,
}) {
	const labelsOptions = labels?.map(v => ({
		label: v,
		value: v,
	}));

	const borderCaseOptions = borderCase?.map(v => ({
		label: v.toString().charAt(0).toUpperCase() + v.toString().slice(1),
		value: v,
	}));

	const strengthResponseOptions = strengthResponse?.map(v => ({
		label: v,
		value: v,
	}));

	const includeInTrainingOptions = includeInTraining?.map(v => ({
		label: v.toString().charAt(0).toUpperCase() + v.toString().slice(1),
		value: v,
	}));

	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<Row>
					<Col>
						<Form.Label>
							<b>Label</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name={FORM_FIELDS.label}
							validate={validateRequired()}
							options={labelsOptions}
							placeholder='Select Data'
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Border Case</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name={FORM_FIELDS.borderCase}
							validate={validateRequired()}
							options={borderCaseOptions}
							placeholder='Select Data'
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Response Strength</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name={FORM_FIELDS.responseStrength}
							validate={validateRequired()}
							options={strengthResponseOptions}
							placeholder='Select Data'
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Include in training</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name={FORM_FIELDS.includeInTraining}
							validate={validateRequired()}
							options={includeInTrainingOptions}
							placeholder='Select Data'
							defaultValue='true'
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Comment</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name={FORM_FIELDS.subject}
							validate={validateRequired()}
							options={commentOptions}
							placeholder='Select Data'
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Detailed Comment</b>
						</Form.Label>
						<Field
							showValid={false}
							name={FORM_FIELDS.comment}
							placeholder='Comment'
							component={InputAdapter}
							type='text'
							as='textarea'
							rows={4}
						/>
					</Col>
				</Row>
			</Styled.Content>
			{children}
		</Form>
	);
}
