import format from 'string-template';
import { post, get } from 'core/api';
import * as endpoints from 'core/consts/endpoints';

export function login({ email, password }) {
	return post(endpoints.LOGIN_URL, { email, password });
}

export function register(details) {
	return post(endpoints.REGISTER_URL, details);
}

export function logout() {
	return post(endpoints.LOGOUT_URL);
}

export function getUserWorkspaces(userId) {
	const endpoint = format(endpoints.USER_WORKSPACES, { userId });
	return get(endpoint);
}

export function getAdminWorkspaces() {
	return get(endpoints.ADMIN_WORKSPACES);
}

export function connectUserToWorkspace(userId, workspaceId) {
	const endpoint = format(endpoints.CONNECT_TO_WORKSPACE, {
		userId,
		workspaceId,
	});
	return post(endpoint);
}

export function getUserDetails(userId) {
	const endpoint = format(endpoints.SINGLE_USER, { userId });
	return get(endpoint);
}

export function getClusters() {
	return get(endpoints.CLUSTERS);
}

export function getClusterProjects(clusterId) {
	const endpoint = format(endpoints.CLUSTER_PROJECTS, { clusterId });
	return get(endpoint);
}

export function confirmAccount(token) {
	return post(endpoints.CONFIRM_ACCOUNT, { token });
}
