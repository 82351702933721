import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrashAlt,
	faPlusSquare,
	faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { Button as RButton } from 'components/Button';
import { ModalProvider } from 'components/Modal';
import DeleteStudy from './DeleteStudy';
import DeleteSample from './DeleteSample';
import ResetSample from './ResetSample';
import CreateSampleModal from './CreateSample/CreateSampleModal';
import * as Styled from './styled';

export default function SampleCardContent({
	studyId,
	subjectId,
	analyticalStudyId,
	workspaceDataId,
	id,
	samples,
	plateId,
	handleSampleClick,
	selectedSample,
	changeColorHandler,
}) {
	const theme = useTheme();
	return (
		<Styled.CardContent>
			<Styled.CardCell>
				<p>Subject ID</p>
				<span>{subjectId}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Study ID</p>
				<span>{studyId}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Analytical Study ID</p>
				<span>{analyticalStudyId}</span>
			</Styled.CardCell>
			<ModalProvider
				component={DeleteStudy}
				title='Delete Study'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faTrashAlt}
							color={theme.red_4}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					id,
					studyId,
					plateId,
				}}
			/>
			<ModalProvider
				component={CreateSampleModal}
				title='Add Sample'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faPlusSquare}
							color={theme.green}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					plateId,
					id,
				}}
			/>
			{!samples?.length && (
				<Styled.EmptyCardMessage>
					<p>Please add a Sample</p>
				</Styled.EmptyCardMessage>
			)}
			{!!samples?.length && (
				<Styled.Samples>
					{samples.map(
						({
							id: sampleIdentifier,
							sampleId,
							sampleDate,
							mesWorkOrder,
							color,
						}) => (
							<Styled.Sample
								key={sampleId}
								onClick={() =>
									handleSampleClick(sampleIdentifier)
								}
								$isSelected={
									selectedSample === sampleIdentifier
								}>
								<Styled.CardCell>
									<p>Sample Date</p>
									<span>{sampleDate}</span>
								</Styled.CardCell>
								<Styled.CardCell>
									<p>Sample Id</p>
									<span>{sampleId}</span>
								</Styled.CardCell>
								<Styled.CardCell>
									<p>MES Work Order</p>
									<span>{mesWorkOrder}</span>
								</Styled.CardCell>
								<Styled.ColorRect
									$color={color}
									onClick={() =>
										changeColorHandler(
											'Sample',
											sampleIdentifier,
											id
										)
									}
								/>
								<ModalProvider
									component={ResetSample}
									title='Reset Sample'
									trigger={
										<RButton variant='link'>
											<FontAwesomeIcon
												icon={faCircleNotch}
												color={theme.yellow}
											/>
										</RButton>
									}
									modalProps={{
										workspaceDataId,
										plateId,
										sampleIdentifier,
										sampleId,
									}}
								/>
								<ModalProvider
									component={DeleteSample}
									title='Delete Sample'
									trigger={
										<RButton variant='link'>
											<FontAwesomeIcon
												icon={faTrashAlt}
												color={theme.red_4}
											/>
										</RButton>
									}
									modalProps={{
										workspaceDataId,
										studyId: id,
										plateId,
										sampleIdentifier,
										sampleId,
									}}
								/>
							</Styled.Sample>
						)
					)}
				</Styled.Samples>
			)}
		</Styled.CardContent>
	);
}
