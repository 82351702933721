import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimesCircle,
	faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Styled from './styled';

export default function TrainingConfiguration({ data }) {
	const { dataAugmentation, modelParameters, configuration } = data;
	return (
		<Styled.Container>
			<Row>
				<Col>
					<Styled.SectionTitle>Data Augmentation</Styled.SectionTitle>
					<Styled.InfoContainer>
						<span>Flip:</span>
						<b>{dataAugmentation.flipProbability}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Rotate:</span>
						<b>{dataAugmentation.angle}</b>
						<span>Degrees</span>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Gamma Contrast</span>
						<span>Min:</span>
						<b>{dataAugmentation.minimumGamma}</b>
						<span>Max:</span>
						<b>{dataAugmentation.maximumGamma}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Shuffling:</span>
						<FontAwesomeIcon
							icon={
								dataAugmentation.shuffling
									? faCheckCircle
									: faTimesCircle
							}
						/>
					</Styled.InfoContainer>
				</Col>
				<Col>
					<Styled.SectionTitle>Model Parameters</Styled.SectionTitle>
					<Styled.InfoContainer>
						<span>Model:</span>
						<b>{modelParameters.model}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Model Variant:</span>
						<b>{modelParameters.modelVariant ?? 'N/A'}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Learning Rate:</span>
						<b>{modelParameters.learningRate}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Batch Size:</span>
						<b>{modelParameters.batchSize}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Loss:</span>
						<b>{modelParameters.loss}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Classes:</span>
						<b>{modelParameters.classesNumber}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Epochs:</span>
						<b>{modelParameters.epochsNumber}</b>
					</Styled.InfoContainer>
					<Styled.InfoContainer>
						<span>Optimizer:</span>
						<b>{modelParameters.optimizer}</b>
					</Styled.InfoContainer>
				</Col>
			</Row>
			<hr />
			<Styled.SectionTitle>Configuration</Styled.SectionTitle>
			<Styled.InfoContainer>
				<span>Radius :</span>
				<b>{configuration.radius}</b>
			</Styled.InfoContainer>
			<Styled.InfoContainer>
				<span>GPUs:</span>
				<b>{configuration.gpusNumber}</b>
			</Styled.InfoContainer>
			<Styled.InfoContainer>
				<span>Mask Mode:</span>
				<FontAwesomeIcon
					icon={
						configuration.maskMode ? faCheckCircle : faTimesCircle
					}
				/>
			</Styled.InfoContainer>
			<Styled.InfoContainer>
				<span>Mirrored Mode:</span>
				<FontAwesomeIcon
					icon={
						configuration.mirroredMode
							? faCheckCircle
							: faTimesCircle
					}
				/>
			</Styled.InfoContainer>
		</Styled.Container>
	);
}
