import format from 'string-template';
import { createSelector } from 'reselect';
import { fetchInit, getData } from 'core/store/repository';
import { clusterIdSelector } from 'core/store/auth';
import * as namespaces from 'core/consts/namespaces';
import {
	USER_CLUSTERS,
	CLUSTER_PROJECTS,
	CLUSTER_MEMBERS,
	PROJECTS,
	ADD_CLUSTER_MEMBERS,
	CLUSTERS,
	EXTERNAL_PROJECT_USERS,
	EXTERNAL_ClUSTERS_USERS,
	ADD_PROJECT_MEMBERS,
	PROJECT_MEMBERS,
	ALL_PROJECT_MEMBERS,
	SINGLE_PROJECT,
	SINGLE_CLUSTER,
	PROJECT_SETTINGS,
	DEFAULT_PROJECT_SETTINGS,
	PROJECT_NECASES_SETTINGS,
	PROJECT_PATHS_SETTINGS,
	CLUSTER_PATHS,
	CLUSTER_PATHS_SETTINGS,
} from 'core/consts/endpoints';

export function fetchUserClusters(userId) {
	const url = format(USER_CLUSTERS, { userId });
	return fetchInit({
		url,
		namespace: namespaces.USER_CLUSTERS,
	});
}

export function fetchClusterProjects(clusterId, userId) {
	const url = format(CLUSTER_PROJECTS, { clusterId });
	return fetchInit({
		url,
		namespace: namespaces.SELECTED_CLUSTER_PORJECTS,
		successCb: () => fetchUserClusters(userId),
	});
}

export function fetchClusterMembers(clusterId) {
	return fetchInit({
		url: CLUSTER_MEMBERS,
		namespace: namespaces.SELECTED_CLUSTER_MEMBERS,
		config: { params: { clusterId } },
	});
}

export function fetchProjectMembers(clusterId, projectId) {
	return fetchInit({
		url: PROJECT_MEMBERS,
		namespace: namespaces.SELECTED_PROJECT_MEMBERS,
		config: { params: { clusterId, projectId } },
	});
}

export function fetchAllProjectMembers(clusterId, projectId) {
	return fetchInit({
		url: ALL_PROJECT_MEMBERS,
		namespace: namespaces.SELECTED_PROJECT_ALL_MEMBERS,
		config: { params: { clusterId, projectId } },
	});
}

export function fetchExternalClustersUsers(clusterId) {
	const url = format(EXTERNAL_ClUSTERS_USERS, { clusterId });

	return fetchInit({
		url,
		namespace: namespaces.EXTERNAL_ClUSTERS_USERS,
	});
}

export function updateClusterUserRole(data) {
	const { userId, clusterId } = data;
	const namespace = format(namespaces.UPDATED_CLUSTER_USER, {
		id: userId,
	});
	return fetchInit({
		url: CLUSTER_MEMBERS,
		namespace,
		config: { method: 'PATCH', data },
		successCb: () => fetchClusterMembers(clusterId),
	});
}

export function deleteClusterUserRole(data) {
	const { userId, clusterId } = data;
	const namespace = format(namespaces.DELETED_CLUSTER_USER, {
		id: userId,
	});
	return fetchInit({
		url: CLUSTER_MEMBERS,
		namespace,
		config: { method: 'delete', data },
		successCb: () => fetchClusterMembers(clusterId),
	});
}

export function createProject(data, userId) {
	return fetchInit({
		url: PROJECTS,
		namespace: namespaces.NEW_ADDED_PROJECT,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchClusterProjects(data.clusterId, userId),
	});
}

export function addClusterMembers(data) {
	return fetchInit({
		url: ADD_CLUSTER_MEMBERS,
		namespace: namespaces.ADDED_CLUSTER_MEMBERS,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchClusterMembers(data.clusterId),
	});
}

export function createCluster(data, userId) {
	return fetchInit({
		url: CLUSTERS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_CLUSTER,
		successCb: () => fetchUserClusters(userId),
	});
}

export function createClusterUsers(data, userId) {
	return fetchInit({
		url: ADD_CLUSTER_MEMBERS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.ADDED_WORKSPACE_MEMBERS,
		successCb: () => fetchUserClusters(userId),
	});
}

export function deleteWorkspaceCluster(clusterId, userId) {
	const namespace = format(namespaces.DELETED_CLUSTER, {
		id: clusterId,
	});
	const url = format(SINGLE_CLUSTER, { clusterId });
	return fetchInit({
		url,
		namespace,
		config: { method: 'delete' },
		successCb: () => fetchUserClusters(userId),
	});
}

export function fetchExternalProjectUsers(projectId) {
	const url = format(EXTERNAL_PROJECT_USERS, { projectId });

	return fetchInit({
		url,
		namespace: namespaces.EXTERNAL_PROJECT_USERS,
	});
}

export function addProjectMembers(data) {
	const { clusterId, projectId } = data;
	const url = format(ADD_PROJECT_MEMBERS, { clusterId });
	return fetchInit({
		url,
		namespace: namespaces.ADDED_PROJECT_MEMBERS,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchProjectMembers(clusterId, projectId),
	});
}

export function deleteProjectMember(data) {
	const { clusterId, projectId, userId } = data;
	const namespace = format(namespaces.DELETED_PROJECT_USER, {
		id: userId,
	});
	return fetchInit({
		url: PROJECT_MEMBERS,
		namespace,
		config: { method: 'delete', data },
		successCb: () => fetchProjectMembers(clusterId, projectId),
	});
}

export function deleteProject(data) {
	const { clusterId, projectId, userId } = data;
	const namespace = format(namespaces.DELETED_PROJECT, {
		id: projectId,
	});
	const url = format(SINGLE_PROJECT, { projectId });
	return fetchInit({
		url,
		namespace,
		config: { method: 'delete' },
		successCb: () => fetchClusterProjects(clusterId, userId),
	});
}

export function fetchProjectSettings(projectId) {
	const url = format(PROJECT_SETTINGS, { projectId });
	return fetchInit({
		url,
		namespace: namespaces.PROJECT_SETTINGS,
	});
}

export function updateProjectNeCasesSettings(data, projectId) {
	const url = format(PROJECT_NECASES_SETTINGS, { projectId });
	const namespace = format(namespaces.UPDATED_PROJECT_NECASES_SETTINGS, {
		id: projectId,
	});
	return fetchInit({
		url,
		namespace,
		config: { method: 'PATCH', data },
		successCb: () => fetchProjectSettings(projectId),
	});
}

export function setProjectSettings(projectId) {
	const url = format(DEFAULT_PROJECT_SETTINGS, { projectId });
	const namespace = format(namespaces.UPDATED_PROJECT_NECASES_SETTINGS, {
		id: projectId,
	});
	return fetchInit({
		url,
		namespace,
		successCb: () => fetchProjectSettings(projectId),
	});
}

export function updateProjectPathsSettings(data, projectId) {
	const url = format(PROJECT_PATHS_SETTINGS, { projectId });
	const namespace = format(namespaces.UPDATED_PROJECT_PATHS_SETTINGS, {
		id: projectId,
	});
	return fetchInit({
		url,
		namespace,
		config: { method: 'PATCH', data },
		successCb: () => fetchProjectSettings(projectId),
	});
}

export function fetchClusterPaths(clusterId) {
	const url = format(CLUSTER_PATHS, { clusterId });
	return fetchInit({
		url,
		namespace: namespaces.CLUSTER_PATHS,
	});
}

export function updateClusterPathsSettings(data, clusterId) {
	const url = format(CLUSTER_PATHS_SETTINGS, { clusterId });
	const namespace = format(namespaces.UPDATED_CLUSTER_PATHS_SETTINGS, {
		id: clusterId,
	});
	return fetchInit({
		url,
		namespace,
		config: { method: 'PATCH', data },
		successCb: () => fetchClusterPaths(clusterId),
	});
}
export const userClustersSelector = getData(namespaces.USER_CLUSTERS);

export const currentClusterSelector = createSelector(
	clusterIdSelector,
	userClustersSelector,
	(currentCluster, clusters) => {
		return clusters.find(
			cluster => cluster.clusterId === currentCluster?.clusterId
		);
	}
);

export const currentProjectSelector = (projectId: string) => {
	return createSelector(currentClusterSelector, clusters => {
		return clusters?.projects.find(
			project => project.projectId === projectId
		);
	});
};
