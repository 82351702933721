import React, { memo, useMemo, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { PREDICTIONS } from 'core/routes';
import { Table } from 'components/Table';
import { When } from 'components/When';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import * as Styled from './styled';

interface Props {
	data: any[];
	loading: boolean;
}

const ExperimentsTable = memo(function ExperimentsResults({
	data,
	loading,
}: Props) {
	const history = useHistory();
	const theme = useTheme();
	const { predictionId }: { predictionId: string } = useParams();
	const handleRowClick = ({ values: { Unique_ID } }) => {
		const experimentUrl = `${PREDICTIONS}/${predictionId}/${Unique_ID}`;
		history.push(experimentUrl);
	};
	const filterName = 'experiments';
	const isRowActive = row => {
		return (
			!row.well_requirements_not_met &&
			!row.missing_images &&
			!row.missing_platebarcode
		);
	};

	const columns = useMemo(() => {
		if (data && data[0]) {
			return Object.keys(data[0])
				.filter(
					key =>
						key !== 'Evaluations' &&
						!key.startsWith('count_') &&
						!key.startsWith('img_') &&
						key !== 'missing_images_str'
				)
				.map(key => {
					if (key === 'ReviewStatus') {
						return {
							Header: 'Review Status',
							accessor: key,
							Filter: props =>
								SelectFilter({
									...props,
									customData: data,
								}),
							filter: multiSelectFilter,
							Cell: ({ value }) => (
								<Fragment>
									<When condition={value === 'Conflict'}>
										<Styled.ConflictStatus>
											Conflict
										</Styled.ConflictStatus>
									</When>
									<When condition={value === 'NotReviewed'}>
										<Styled.NotReviewedStatus>
											Not Reviewed
										</Styled.NotReviewedStatus>
									</When>
									<When condition={value === 'Reviewed'}>
										<Styled.ReviewedStatus>
											Reviewed
										</Styled.ReviewedStatus>
									</When>
									<When condition={value === 'UnderReview'}>
										<Styled.UnderReviewStatus>
											Under Review
										</Styled.UnderReviewStatus>
									</When>
								</Fragment>
							),
						};
					}
					return {
						Header: key,
						accessor: key,
						Cell: ({ value }) => {
							const cellValue = value ?? '';
							return cellValue.toString();
						},
						Filter: props =>
							SelectFilter({
								...props,
								customData: data,
							}),
						filter: multiSelectFilter,
					};
				});
		}
		return [];
	}, [data]);

	return (
		<Styled.Container>
			<Table
				data={data}
				columns={columns}
				onRowClick={handleRowClick}
				withPagination={true}
				loading={loading}
				withFilters={!!data?.length}
				filterName={filterName}
				rowProps={row => ({
					style: {
						background: isRowActive(row.values)
							? theme.white
							: theme.gray_8,
					},
				})}
			/>
		</Styled.Container>
	);
});
export default ExperimentsTable;
