import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FAQ } from 'core/routes';
import { When } from 'components/When';
import * as Styled from './styled';

interface Props {
	pre?: string | React.ReactElement;
	current?: string | React.ReactElement;
	rightContent?: React.ReactElement;
}

export function SubHeader({ pre, current, rightContent }: Props) {
	return (
		<Styled.Container>
			{pre}
			<span>
				&nbsp;
				{'>'}
				&nbsp;
			</span>
			<When condition={!!current}>
				<Styled.CurrentPage>{current}</Styled.CurrentPage>
			</When>
			<Styled.RightContainer>
				<Styled.FaqLink to={FAQ}>
					<FontAwesomeIcon icon={faQuestionCircle} />
				</Styled.FaqLink>
				<Styled.RightElementWrapper>
					{rightContent && React.cloneElement(rightContent)}
				</Styled.RightElementWrapper>
			</Styled.RightContainer>
		</Styled.Container>
	);
}
