import React, { Fragment, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { faTrashAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faEmptyStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateAndTime } from 'helpers';
import { ModalProvider } from 'components/Modal';
import { Table } from 'components/Table';
import PusherMessage from 'components/PusherMessage';
import { TRAININGS_IN_VITRO } from 'core/routes';
import { CHANNELS } from 'core/consts/pusherConfig';
import { currentWorkspaceIdSelector } from 'core/store/auth';
import { fetchTrainings } from 'core/store/trainingsInVitro';
import * as namespaces from 'core/consts/namespaces';
import { When } from 'components/When';
import { StatusIcon } from 'components/StatusIcon';
import { StatusFilter } from 'components/Filters/StatusFilter';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import {
	dateBetweenFilterFn,
	DateFilter,
} from 'components/Filters/DateFilter/DateFilter';
import TrainingDeleteModal from './DeleteModals/TrainingDeleteModal';
import * as Styled from '../styled';
import { TrainingResultEntry } from '../types';

enum TrainingStatusEnum {
	Created = 'Created',
	Training = 'Training',
	Evaluating = 'Evaluating',
	Succeeded = 'Succeeded',
	Failed = 'Failed',
	Cancelled = 'Cancelled',
}

interface Props {
	data: TrainingResultEntry[];
	loading: boolean;
}

const TrainingResultsTable = memo(function TrainingResults({
	data,
	loading,
}: Props) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const workspaceId = useSelector(currentWorkspaceIdSelector);

	const refreshTrainings = ({ workspaceId: receivedWorkspaceId }) => {
		if (workspaceId === receivedWorkspaceId) {
			return dispatch(fetchTrainings());
		}
		return;
	};

	const handleRowClick = ({ original: { trainingId, status } }) => {
		if (status === TrainingStatusEnum.Succeeded) {
			history.push(`${TRAININGS_IN_VITRO}/${trainingId}`);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Chosen Model',
				accessor: 'isChosenModel',
				disableSortBy: true,
				disableFilters: true,
				Cell: ({ value }) => (
					<Fragment>
						<When condition={!!value}>
							<FontAwesomeIcon
								icon={faStar}
								color={theme.yellow}
							/>
						</When>
						<When condition={!value}>
							<FontAwesomeIcon
								icon={faEmptyStar}
								color={theme.gray}
							/>
						</When>
					</Fragment>
				),
			},
			{
				Header: 'Status',
				accessor: 'status',
				Filter: props =>
					StatusFilter({
						...props,
						filterType: 'training',
					}),
				disableSortBy: true,
				Cell: ({ value }) => <StatusIcon status={value} />,
			},
			{
				Header: 'Name',
				Filter: props =>
					SelectFilter({ ...props, namespace: namespaces.TRAININGS }),
				filter: multiSelectFilter,
				accessor: 'trainingName',
			},
			{
				Header: 'Model',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TRAININGS,
					}),
				filter: multiSelectFilter,
				accessor: 'modelParameters.model',
			},
			{
				Header: 'Data',
				Filter: props =>
					SelectFilter({ ...props, namespace: namespaces.TRAININGS }),
				filter: multiSelectFilter,
				accessor: 'trainingDataName',
			},
			{
				Header: 'User',
				Filter: props =>
					SelectFilter({ ...props, namespace: namespaces.TRAININGS }),
				filter: multiSelectFilter,
				accessor: 'userName',
			},
			{
				Header: 'Time Started',
				accessor: 'startedOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
			},
			{
				Header: 'Time Finished',
				accessor: 'finishedOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) =>
					value && <span>{formatDateAndTime(value)}</span>,
			},
			{
				Header: 'Accuracy',
				accessor: 'accuracy',
				disableFilters: true,
				Cell: ({ value }) =>
					value && <span>{(value * 100).toFixed(2)} %</span>,
			},
			{
				id: 'delete',
				disableSortBy: true,
				width: 25,
				disableFilters: true,
				Cell: ({
					row: {
						original: { trainingId, trainingName },
					},
				}) => (
					<ModalProvider
						title='Confirm Deletion of Training'
						component={TrainingDeleteModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							id: trainingId,
							name: trainingName,
						}}
						trigger={
							<FontAwesomeIcon
								icon={faTrashAlt}
								color={theme.red_4}
							/>
						}
					/>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[theme]
	);
	return (
		<Styled.Container>
			<PusherMessage
				callback={refreshTrainings}
				event={CHANNELS.trainings}
			/>
			<Table
				columns={columns}
				data={data}
				onRowClick={handleRowClick}
				loading={loading}
				withFilters={!!data.length}
			/>
		</Styled.Container>
	);
});

export default TrainingResultsTable;
