import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import format from 'string-template';
import { Api, handleResponse } from 'core/api';
import {
	FETCH_ERROR,
	FETCH_INIT,
	FETCH_SUCCESS,
	FETCH_LATEST,
	fetchOptions,
	fetchClear,
	getNamespace,
	UPDATE_REPOSITORY,
	SAVE_UPDATE_REPOSITORY,
	UPDATE_REPOSITORY_FAILED,
} from './';

function* fetchData(action: { options: fetchOptions; type: string }) {
	const {
		namespace,
		url,
		config: { method = 'get', data = null, params = null } = {},
		successCb,
		errorCb,
		autoClear,
		selector,
	} = action.options;

	let selectedState;
	if (selector) {
		selectedState = yield select(selector);
	}
	const formattedUrl = selector ? format(url, selectedState) : url;
	const callFunction = () => Api({ method, url: formattedUrl, data, params });
	try {
		const response = yield call(callFunction);
		const result = handleResponse(response.data);
		yield put({
			type: FETCH_SUCCESS,
			payload: result,
			namespace,
		});
		if (successCb) {
			yield put(successCb(result));
		}
		if (autoClear) {
			yield put(fetchClear(namespace));
		}
	} catch (e) {
		if (errorCb) {
			yield put(errorCb(e));
		}
		yield put({
			type: FETCH_ERROR,
			message: e.message,
			namespace,
		});
	}
}

function* updateRepoSaga(action) {
	try {
		const { compute, namespace } = action.options;
		const namespaceData = yield select(getNamespace(namespace));
		const newValue = compute(namespaceData);
		yield put({ type: SAVE_UPDATE_REPOSITORY, namespace, newValue });
	} catch (error) {
		yield put({ type: UPDATE_REPOSITORY_FAILED, message: error });
	}
}

export default function* repoSaga() {
	yield takeEvery(FETCH_INIT, fetchData);
	yield takeLatest(FETCH_LATEST, fetchData);
	yield takeLatest(UPDATE_REPOSITORY, updateRepoSaga);
}
