import styled from 'styled-components';
import { Dropdown as BDropdown, Table as BTable } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
	@media (max-width: 767px) {
		.table-responsive .dropdown-menu {
			position: static !important;
		}
	}
	@media (min-width: 768px) {
		.table-responsive {
			overflow: visible;
		}
	}
`;

export const Table = styled(BTable)`
	text-align: center;
	th {
		position: relative;
		background-color: transparent;
		color: ${p => p.theme.gray_2};
		font-size: 0.88em;
		padding: 20px 0;
		text-transform: uppercase;
		vertical-align: middle !important;
	}
	button {
		width: 100%;
	}
	td {
		vertical-align: middle;
	}
	tr:hover td {
		background-color: ${p => p.theme.gray_6};
	}
`;

export const PaginationWrap = styled.div`
	.pagination li {
		margin-left: 3px;
	}

	.page-link {
		color: ${p => p.theme.green};
		background-color: ${p => p.theme.green_6};
		font-weight: 600;
	}
	.page-item:last-of-type .page-link,
	.page-item:first-of-type .page-link {
		color: ${p => p.theme.white};
		background-color: ${p => p.theme.green};
		border-bottom-right-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}
	.page-item.disabled .page-link {
		color: ${p => p.theme.gray_2};
		background-color: ${p => p.theme.gray_6};
	}
`;

export const PageInfo = styled.div`
	display: flex;
	align-items: center;
	margin-left: 10px;
`;

export const SortIcon = styled(FontAwesomeIcon)`
	position: relative;
	left: 5px;
	bottom: 2px;
`;

export const HeaderContent = styled.div`
	display: flex;
	align-items: center;
	margin-left: 10px;
	justify-content: center;
`;

export const SortUpIcon = styled(FontAwesomeIcon)`
	position: relative;
	left: 5px;
	top: 3px;
`;

export const Dropdown = styled(BDropdown)`
	margin-left: 10px;
	margin-right: 15px;
	position: relative;
`;

export const DropdownToggle = styled(BDropdown.Toggle)`
	border: transparent;
	font-weight: bold;
	width: 38px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 14px;
	padding: 10px 5px 10px 10px;
	font-size: 14px;
	span {
		position: relative;
		right: -30px;
	}
	svg {
		color: ${p => p.theme.gray_7};
	}
	&:hover,
	&:focus,
	&:active,
	&:visited,
	&:not(:disabled),
	&:target {
		background-color: transparent !important;
		border: transparent;
		box-shadow: none !important;
	}
`;

export const DropdownMenu = styled(BDropdown.Menu)`
	background: transparent;
	border-radius: 12px;
	width: 100%;
	padding: 0;
	border: none;
	top: 36px !important;
	transform: none !important;
`;

export const Image = styled.img`
	width: ${p => (p.isActive ? '20px;' : ' 12px')};
	position: relative;
	right: 10px;
`;
