import styled from 'styled-components';
export * from '../styled';

export const LogsContainer = styled.div`
	height: 600px;
`;

export const TabsContainer = styled.div`
	& .nav-link {
		text-align: center;
		color: unset;
		font-weight: bold;
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: ${p => p.theme.green};
	}
`;

export const SubTrainingDetails = styled.div`
	overflow: hidden;
	margin-bottom: 15px;
	:last-of-type {
		margin-bottom: 0;
	}
	p {
		width: 50%;
		float: left;
	}
	p:first-of-type {
		font-size: 1.2rem;
		width: 100%;
		color: ${p => p.theme.green};
	}
`;
