import React from 'react';
import * as Styled from './styled';
import { A } from 'components/A';

export default function Footer() {
	return (
		<Styled.FooterContainer>
			Designed and Developed by &nbsp;
			<A
				href='https://instadeep.com'
				target='_blank'
				rel='noopener noreferrer'>
				InstaDeep
			</A>
		</Styled.FooterContainer>
	);
}
