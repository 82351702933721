import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button as RButton } from 'components/Button';
import { ModalProvider } from 'components/Modal';
import DeleteReadout from '../DeleteModals/DeleteReadout';
import ResetReadout from '../ResetModals/ResetReadout';
import * as Styled from './styled';

export default function ReadoutCardContent({
	name,
	typeOfStimulusInReadout,
	stimulusFormatInReadout,
	workspaceDataId,
	stimulusInReadoutId,
	plateId,
}) {
	const theme = useTheme();

	return (
		<Styled.CardContent>
			<Styled.CardCell>
				<p>Type of Stimulus In Readout</p>
				<span>{typeOfStimulusInReadout}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Stimulus In Readout</p>
				<span>{name}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Stimulus Format In Readout</p>
				<span>{stimulusFormatInReadout}</span>
			</Styled.CardCell>
			<ModalProvider
				component={ResetReadout}
				title='Reset Stimulus In Readout'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faCircleNotch}
							color={theme.yellow}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					stimulusInReadoutId,
					name,
					plateId,
				}}
			/>
			<ModalProvider
				component={DeleteReadout}
				title='Delete Stimulus In Readout'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faTrashAlt}
							color={theme.red_4}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					stimulusInReadoutId,
					name,
					plateId,
				}}
			/>
		</Styled.CardContent>
	);
}
