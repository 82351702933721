import React from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import RDatePicker from 'react-datepicker';

export const Container = styled.div`
	.react-datepicker-wrapper {
		display: block;
		input {
			display: block;
			width: 100%;
			height: calc(1.5em + 0.75rem + 2px);
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: ${p => p.theme.white};
			background-clip: padding-box;
			border: 1px solid ${p => p.theme.border_2};
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;
		}
		input:focus {
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
		}
	}
`;

export const DatePicker = ({ ...props }) => {
	return (
		<Container>
			<RDatePicker {...props} />
		</Container>
	);
};
