import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import format from 'string-template';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import * as namespaces from 'core/consts/namespaces';
import { Button } from 'components/Button';
import useNamespace from 'hooks/useNamespace';
import { deleteTrainingData } from 'core/store/trainingsInVitro';
import * as Styled from '../../styled';

export default function TrainingDataDeleteModal({ closeModal, id, name }) {
	const dispatch = useDispatch();
	const handleDelete = trainingDataId => {
		dispatch(deleteTrainingData(trainingDataId));
	};
	const namespace = format(namespaces.DELETED_TRAINING_DATA, {
		id,
	});
	const { error, trace, loading } = useNamespace({
		namespace,
		onSuccess: closeModal,
	});
	return (
		<Fragment>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Body>
				<b>Are you sure you want to delete {name} ?</b>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='success' onClick={closeModal}>
					No
				</Button>
				<Button
					variant='danger'
					onClick={() => handleDelete(id)}
					loading={loading}>
					Delete
				</Button>
			</Modal.Footer>
		</Fragment>
	);
}
