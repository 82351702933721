import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormContainer } from 'components/Form';
import { SubHeader } from 'components/SubHeader';
import { Link } from 'components/Link';
import useNamespace from 'hooks/useNamespace';
import * as ROUTES from 'core/routes';
import * as namespaces from 'core/consts/namespaces';
import {
	createTraining,
	fetchTrainingData,
	trainingDataSelector,
	fetchTrainingParams,
	trainingParamsSelector,
} from 'core/store/trainingsInVitro';
import * as Styled from './styled';
import NewTrainingConfigurationForm from './components/NewTrainingConfigurationForm';
import { trainingFormInitialValues } from './components/consts';

export default function NewInVitroTraining() {
	const dispatch = useDispatch();
	const history = useHistory();
	const trainingData = useSelector(trainingDataSelector);
	const trainingParams = useSelector(trainingParamsSelector);
	useNamespace({
		namespace: namespaces.NEW_ADDED_TRAINING,
		onSuccess: () => history.push(ROUTES.TRAININGS_IN_VITRO),
	});

	useEffect(() => {
		if (!trainingData.length) {
			dispatch(fetchTrainingData());
		}
		if (!trainingParams?.models) {
			dispatch(fetchTrainingParams());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleAddNewTraining = data => {
		dispatch(createTraining(data));
	};

	return (
		<Fragment>
			<SubHeader
				pre={
					<Link to={`${ROUTES.TRAININGS_IN_VITRO}?view=results`}>
						Trainings InVitro
					</Link>
				}
				current='Add New Training'
			/>
			<Styled.Container>
				<FormContainer
					onSubmit={handleAddNewTraining}
					formID='new-training'
					component={NewTrainingConfigurationForm}
					initialValues={trainingFormInitialValues}
				/>
			</Styled.Container>
		</Fragment>
	);
}
