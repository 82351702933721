import React, { useMemo, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, SelectAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import * as Styled from './styled';

export default function DataSelectionSubForm({ trainingData, validationData }) {
	const trainingDataOptions = useMemo(() => {
		return trainingData.map(
			({ trainingDataId, name, isValidationData }) => ({
				label: name,
				value: trainingDataId,
				isValidationData,
			})
		);
	}, [trainingData]);

	const validationDataOptions = useMemo(() => {
		return validationData.map(
			({ trainingDataId, name, isValidationData }) => ({
				label: name,
				value: trainingDataId,
				isValidationData,
			})
		);
	}, [validationData]);

	return (
		<Fragment>
			<Styled.Content>
				<b>Training Data</b>
				<Row>
					<Col xs='6'>
						<Field
							showValid={false}
							component={SelectAdapter}
							name='trainingDataId'
							validate={validateRequired()}
							options={trainingDataOptions}
						/>
					</Col>
				</Row>
			</Styled.Content>
			<Styled.Content>
				<b>Validation Data</b>
				<Row>
					<Col xs='6'>
						<Field
							showValid={false}
							component={SelectAdapter}
							name='validationDataId'
							validate={validateRequired()}
							options={validationDataOptions}
						/>
					</Col>
				</Row>
			</Styled.Content>
		</Fragment>
	);
}
