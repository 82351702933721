import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { ModalProvider } from 'components/Modal';
import { Button } from 'components/Button';
import EditReviewModal from './ManageReview/ManageReviewModal';

import { ReviewsnDataEntry } from '../types';
interface Props {
	review: ReviewsnDataEntry;
	userId: string;
	predictionId: string;
}

export default function ManageReviewButton({
	review,
	userId,
	predictionId,
}: Props) {
	const theme = useTheme();
	if (review?.ownerId === userId && review?.status !== 'Done') {
		return (
			<ModalProvider
				component={EditReviewModal}
				title='Manage Review'
				modalProps={{
					reviewers: review?.reviewers,
					reviewName: review?.reviewName,
					type: review?.type,
					predictionId: predictionId,
					reviewId: review?.reviewId,
				}}
				trigger={
					<Button variant='link'>
						<FontAwesomeIcon icon={faCog} color={theme.green} />
					</Button>
				}
			/>
		);
	} else if (review?.ownerId === userId && review?.status === 'Done') {
		return (
			<ModalProvider
				component={EditReviewModal}
				title='Manage Review'
				modalProps={{
					reviewers: review?.reviewers,
					reviewName: review?.reviewName,
					type: review?.type,
					predictionId: predictionId,
					reviewId: review?.reviewId,
				}}
				trigger={
					<Button variant='link' disabled={true}>
						<FontAwesomeIcon icon={faCog} color={theme.gray_2} />
					</Button>
				}
			/>
		);
	}
	return <span></span>;
}
