import React, { useEffect } from 'react';
import { useRouteMatch, match, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faRocket,
	faShareAlt,
	faSignOutAlt,
	faCog,
} from '@fortawesome/free-solid-svg-icons';
import { ADMIN_HOME, WORKSPACES } from 'core/routes';
import _navItems from 'core/_nav';
import {
	ACCESS_RIGHTS,
	ADMIN_PORTAL_WORKSPACE_LABEL,
	ROLES,
} from 'core/consts/roles';
import {
	currentWorkspaceIdSelector,
	accessRightsSelector,
	connectUserToWorkspace,
	userWorkspacesSelector,
	connectUserToProject,
	disconnectUserToProject,
	connectToCluster,
	userSelector,
	userRoleSelector,
	clusterIdSelector,
	projectIdSelector,
} from 'core/store/auth';
import {
	currentWorkspaceSelector,
	fetchWorkspaces,
	fetchUserWorkspaces,
} from 'core/store/workspaces';
import { fetchUserClusters, userClustersSelector } from 'core/store/clusters';
import { ModalProvider } from 'components/Modal';
import { Collapse } from 'components/Collapse';
import { Dropdown } from 'components/Dropdown';
import { When } from 'components/When';
import * as Styled from './styled';
import SignOutModal from './SignOutModal';

export const PortalWorkspace = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userRole = useSelector(userRoleSelector);
	const { userId } = useSelector(userSelector);
	const clusters = useSelector(userClustersSelector);
	const accessRights = useSelector(accessRightsSelector);
	const userWorkspaces = useSelector(userWorkspacesSelector);
	const currentWorkspaceId = useSelector(currentWorkspaceIdSelector);
	const currentWorkspace = useSelector(
		currentWorkspaceSelector(currentWorkspaceId)
	);
	const currentCluster = useSelector(clusterIdSelector);
	const currentProject = useSelector(projectIdSelector);
	useEffect(() => {
		if (userRole === ROLES.admin) {
			dispatch(fetchWorkspaces());
		} else {
			dispatch(fetchUserWorkspaces(userId));
		}
		dispatch(fetchUserClusters(userId));
	}, [dispatch, userId, currentWorkspaceId, userRole]);

	const match: match<any> | null = useRouteMatch('/workspace/:section/');

	const [canCreateCluster, canAddUserToWorkspace] = [
		accessRights.includes(ACCESS_RIGHTS.CreateCluster),
		accessRights.includes(ACCESS_RIGHTS.AddUserToWorkspace),
	];

	const handleWorkspaceClick = ({ workspaceId, workspaceName }) => {
		dispatch(connectUserToWorkspace({ workspaceId }));
		if (workspaceName === ADMIN_PORTAL_WORKSPACE_LABEL) {
			history.push(ADMIN_HOME);
		} else {
			history.push(WORKSPACES);
		}
	};

	const handleProjectClick = (clusterId, projectId) => {
		dispatch(connectUserToProject({ clusterId, projectId }));
		history.push(WORKSPACES);
	};
	const handleProjectSettingClick = (e, clusterId, projectId) => {
		e.stopPropagation();
		dispatch(connectUserToProject({ clusterId, projectId }));
		history.push(`${WORKSPACES}?view=project-management`);
	};
	const handleWorkspaceManagementClick = () => {
		dispatch(disconnectUserToProject());
		history.push(WORKSPACES);
	};
	const handleSettingsClick = (e, clusterId) => {
		e.stopPropagation();
		dispatch(connectToCluster(clusterId));
		history.push(WORKSPACES);
	};
	const isNonClusterNavSelected = _navItems
		.filter(item => accessRights.includes(item.role))
		.map(item => {
			const isOtherNavSelected =
				match?.url === item.link ||
				item.subPages.includes(match?.url || '');
			return isOtherNavSelected;
		});
	const isClusterSelected = !isNonClusterNavSelected.some(
		selected => !!selected
	);
	const canManageCurrentWorkspace = canCreateCluster || canAddUserToWorkspace;

	return (
		<Styled.Container>
			<Dropdown
				name={currentWorkspace?.workspaceName}
				icon={faRocket}
				keyName='workspaceName'
				onClick={handleWorkspaceClick}
				extraChildIcon={faSignOutAlt}
				options={userWorkspaces}
				onSettingsIconClick={
					canManageCurrentWorkspace && handleWorkspaceManagementClick
				}>
				<ModalProvider
					component={SignOutModal}
					title={`Sign out of ${currentWorkspace?.workspaceName}`}
					trigger={
						<Styled.Text>
							Sign out of{' '}
							<span>{currentWorkspace?.workspaceName}</span>
						</Styled.Text>
					}
				/>
			</Dropdown>
			<Styled.MenuList>
				{_navItems
					.filter(item => accessRights.includes(item.role))
					.map(item => {
						const isSelected =
							match?.url === item.link ||
							item.subPages.includes(match?.url || '');
						return (
							<Styled.MenuItem
								key={item.name}
								isSelected={isSelected}
								isExternal>
								<Styled.ItemLink to={item.link}>
									<FontAwesomeIcon icon={item.icon} />
									<Styled.Text>{item.name}</Styled.Text>
								</Styled.ItemLink>
							</Styled.MenuItem>
						);
					})}
			</Styled.MenuList>
			{clusters?.map(({ clusterId, clusterName, projects, role }) => {
				const canManageCurrentCluster =
					role === ROLES.admin || role === ROLES.supervisor;
				return (
					<Collapse
						key={clusterId}
						name={clusterName}
						icon={faShareAlt}
						shouldRenderSetting={canManageCurrentCluster}
						onSettingClick={e => handleSettingsClick(e, clusterId)}
						isSelected={false}
						currentCluster={
							clusterId === currentCluster.clusterId &&
							!!isClusterSelected
						}>
						<Styled.MenuList>
							{projects?.map(({ projectId, projectName }) => {
								return (
									<Styled.MenuItem
										key={projectId}
										isSelected={
											projectId ===
												currentProject.projectId &&
											!!isClusterSelected
										}>
										<Styled.ProjectItem>
											<Styled.ProjectsIcon />
											<Styled.Text
												onClick={() =>
													handleProjectClick(
														clusterId,
														projectId
													)
												}>
												{projectName}
											</Styled.Text>
											<When
												condition={
													canManageCurrentCluster
												}>
												<Styled.IconContainer>
													<Styled.SettingIcon
														icon={faCog}
														onClick={e =>
															handleProjectSettingClick(
																e,
																clusterId,
																projectId
															)
														}
													/>
												</Styled.IconContainer>
											</When>
										</Styled.ProjectItem>
									</Styled.MenuItem>
								);
							})}
						</Styled.MenuList>
					</Collapse>
				);
			})}
		</Styled.Container>
	);
};
