import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import arrayMutators from 'final-form-arrays';
import { When } from 'components/When';
import { Button } from 'components/Button';
import { FormContainer } from 'components/Form';
import { fetchAllProjectMembers } from 'core/store/clusters';
import { projectIdSelector, clusterIdSelector } from 'core/store/auth';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { createReview } from 'core/store/predictions';
import StartReviewForm from './StartReviewForm';
import * as Styled from '../styled';

interface Params {
	predictionId: string;
}

export default function StartReviewModal({ closeModal }) {
	const { predictionId }: Params = useParams();
	const dispatch = useDispatch();
	const { projectId } = useSelector(projectIdSelector);
	const { clusterId } = useSelector(clusterIdSelector);
	const users = useNamespace({
		namespace: namespaces.SELECTED_PROJECT_ALL_MEMBERS,
		autoClear: false,
	});
	useEffect(() => {
		return dispatch(fetchAllProjectMembers(clusterId, projectId));
	}, [dispatch, clusterId, projectId]);

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_ADDED_REVIEW,
		onSuccess: closeModal,
	});

	const handleCreateData = data => {
		const { type, reviewers, name } = data;
		const reviewersData = reviewers?.map(({ user: { userId } }) => userId);

		const reviewData = {
			name,
			reviewers: reviewersData,
			predictionId,
			type: parseInt(type),
		};
		dispatch(createReview(reviewData));
	};

	return (
		<FormContainer
			formID='data-training'
			onSubmit={handleCreateData}
			mutators={{
				...arrayMutators,
			}}
			component={props => (
				<StartReviewForm {...props} users={users.data}>
					<When condition={error}>
						<Styled.Alert variant='danger'>{trace}</Styled.Alert>
					</When>
					<Modal.Footer>
						<Button variant='danger' onClick={closeModal}>
							Cancel
						</Button>
						<Button
							variant='success'
							type='submit'
							loading={loading}>
							Save changes
						</Button>
					</Modal.Footer>
				</StartReviewForm>
			)}
		/>
	);
}
