import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const selectedMenuItem = css`
	background: ${p => p.theme.green_4};
	span {
		color: ${p => p.theme.white};
	}
	svg {
		color: ${p => p.theme.white}!important;
		display: initial;
	}
`;

export const Container = styled.div`
	margin-top: 10px;
	width: 100%;
`;

export const CollapseContainer = styled.div`
	cursor: pointer;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 15px;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 5px 10px 10px;
	border-radius: 14px;
	font-size: 14px;
	${({ isSelected }) => isSelected && selectedMenuItem}
	&:hover {
		${selectedMenuItem}
	}
	svg {
		transition: fill 0.3s ease;
		color: ${p => p.theme.gray};
		width: 20px;
		height: 20px;
	}
`;

export const Text = styled.span`
	font-weight: bold;
	color: ${p => p.theme.gray};
	padding-left: 10px;
`;

export const Icon = styled(FontAwesomeIcon)`
	position: relative;
	top: 10px;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
	position: relative;
	top: 4px;
`;
export const IconContainer = styled.div`
	display: flex;
`;

export const SettingIcon = styled(FontAwesomeIcon)`
	position: relative;
	top: 10px;
	right: 10px;
	width: 12px !important;
	height: 12px !important;
	display: none;
`;
