import React, { useCallback } from 'react';
import NonExistingImage from 'assets/img/missingImage.png';
import { INITIAL_PREDICTION_DATA_MINIO_URL } from 'core/consts';
import * as Styled from '../styled';

export default function FullPlateRow({
	plateRowData,
	folderName,
	predictionDataId,
	columnHeader,
	stimilusInIVSList,
}) {
	const getImageUrl = (
		missingImage: boolean,
		imageName: string,
		imageExtension: string
	) => {
		if (missingImage) {
			return NonExistingImage;
		}
		return `${INITIAL_PREDICTION_DATA_MINIO_URL}/${predictionDataId}/${folderName}/${imageName}.${imageExtension}`;
	};
	const getBorderColor = useCallback(
		(borderColor, plateRowStimulusInIVS) => {
			const isWellSelected = stimilusInIVSList.find(
				item =>
					item.stimulusName === plateRowStimulusInIVS &&
					item.isSelected
			);
			if (isWellSelected?.isSelected) {
				return borderColor;
			}
			return null;
		},
		[stimilusInIVSList]
	);

	const count = plateCount => {
		if (plateCount !== -9) {
			return plateCount === -1 ? 'TNTC' : plateCount;
		}
		return null;
	};

	return (
		<tr>
			<td>{columnHeader}</td>
			{plateRowData.map(plate => {
				return (
					<Styled.FullPlateColmun key={plate.imageName}>
						<Styled.FullPlateImageWrap
							borderColor={getBorderColor(
								plate.borderColor,
								plate.stimulusInIvs
							)}>
							<img
								src={getImageUrl(
									plate.missingImage,
									plate.imageName,
									plate.imageExtension
								)}
								alt='Single well'
							/>
							<p>{count(plate.count)}</p>
						</Styled.FullPlateImageWrap>
					</Styled.FullPlateColmun>
				);
			})}
		</tr>
	);
}
