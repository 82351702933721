import React, { Fragment, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faThumbsUp,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/Button';
import { Table } from 'components/Table';
import { When } from 'components/When';
import { Avatar } from 'components/Avatar';
import { ModalProvider } from 'components/Modal';
import { predictionSelector } from 'core/store/predictions';
import { formatAvatarText, formatDateAndTime } from 'helpers';
import { EvaluationDataEntry, ReviewsnDataEntry } from '../types';
import { userSelector } from 'core/store/auth';
import { PREDICTED_USERNAME, ELISPOT_COUNT_USERNAME } from 'core/consts/roles';
import CreateEvaluationModal from './CreateEvaluation/CreateEvaluationModal';
import UpdateEvaluationModal from './UpdateEvaluation/UpdateEvaluationModal';
import DeleteEvaluation from './DeleteEvaluation';
import ApproveEvaluation from './ApproveEvaluation';
import CommentAltEdit from 'assets/img/commentAltEdit.svg';
import * as Styled from './styled';

interface Props {
	data: EvaluationDataEntry[];
	currentReview: ReviewsnDataEntry;
	loading: boolean;
	predictionId: string;
	experiment: any;
	reviews: ReviewsnDataEntry[];
}

export default function EvaluationTable({
	data,
	loading,
	currentReview,
	predictionId,
	experiment,
	reviews,
}: Props) {
	const theme = useTheme();
	const { Unique_ID, predicted_label, probability } = experiment;
	const { finishedOn } = useSelector(predictionSelector);
	const currentUser = useSelector(userSelector);
	const isValidExp =
		!experiment.well_requirements_not_met &&
		!experiment.missing_images &&
		!experiment.missing_platebarcode;
	const predictedRow = [
		{
			borderCase: null,
			comment: `Confidence: ${probability}`,
			label: predicted_label,
			responseStrength: null,
			reviewName: null,
			startedOn: finishedOn,
			subject: null,
			userId: null,
			userName: 'Predicted',
			includeInTraining: null,
		},
	];

	const canEvaluate =
		!!currentReview &&
		currentReview.reviewers.some(u => u.userId === currentUser.userId);
	const getReviewById = useCallback(
		reviewId => {
			return reviews.filter(r => r.reviewId === reviewId)[0];
		},
		[reviews]
	);
	const closedReview = useCallback(review => {
		return !!review && review.status === 'Done';
	}, []);

	const submittedReview = useCallback((isClosedReview, reviewer) => {
		return !isClosedReview && !!reviewer && !!reviewer.length
			? reviewer[0].submitted
			: !!isClosedReview;
	}, []);

	const visibleReviews = reviews
		.filter(r => {
			const isClosedReview = closedReview(r);
			return (
				r.ownerId === currentUser.userId ||
				r.type !== 'Blind' ||
				isClosedReview
			);
		})
		.map(r => r.reviewId);
	const visibleEvaluations = data.filter(
		evaluation =>
			visibleReviews.includes(evaluation.reviewId) ||
			evaluation.userId === currentUser.userId
	);
	const evaluationsData = [...visibleEvaluations, ...predictedRow];
	const columns = useMemo(
		() => [
			{
				Header: 'Reviewer',
				accessor: 'userName',
				disableSortBy: true,
				Cell: ({
					row: {
						original: { userName, approverName },
					},
				}) => (
					<Fragment>
						<When condition={userName !== ELISPOT_COUNT_USERNAME}>
							<When condition={userName !== PREDICTED_USERNAME}>
								<Avatar size='50px'>
									{formatAvatarText(`${userName}`)}
								</Avatar>
							</When>
							<span>{userName}</span>
						</When>
						<When condition={userName === ELISPOT_COUNT_USERNAME}>
							<Avatar size='50px'>
								{formatAvatarText(`${approverName}`)}
							</Avatar>
							<span>{approverName}</span>
						</When>
					</Fragment>
				),
			},
			{
				Header: 'Review Name',
				accessor: 'reviewName',
				disableSortBy: true,
				Cell: ({ value }) => value && <span>{value}</span>,
			},
			{
				Header: 'Date',
				accessor: 'startedOn',
				disableSortBy: true,
				Cell: ({ value }) =>
					value && <span>{formatDateAndTime(value)}</span>,
			},
			{
				Header: 'Label',
				accessor: 'label',
				disableSortBy: true,
				Cell: ({ value }) => value && <span>{value}</span>,
			},
			{
				Header: 'Response Strength',
				accessor: 'responseStrength',
				disableSortBy: true,
				Cell: ({ value }) => value && <span>{value}</span>,
			},
			{
				Header: 'Border Case',
				accessor: 'borderCase',
				disableSortBy: true,
				Cell: ({
					row: {
						original: { userName, borderCase },
					},
				}) => {
					return (
						borderCase !== null &&
						userName !== ELISPOT_COUNT_USERNAME && (
							<Styled.CapitalizedSpan>
								{borderCase.toString()}
							</Styled.CapitalizedSpan>
						)
					);
				},
			},
			{
				Header: 'Include In Training',
				accessor: 'includeInTraining',
				disableSortBy: true,
				Cell: ({
					row: {
						original: { userName, includeInTraining },
					},
				}) => {
					return (
						includeInTraining !== null &&
						userName !== ELISPOT_COUNT_USERNAME && (
							<Styled.CapitalizedSpan>
								{includeInTraining.toString()}
							</Styled.CapitalizedSpan>
						)
					);
				},
			},
			{
				Header: 'Comment',
				accessor: 'subject',
				disableSortBy: true,
				Cell: ({ value }) => value && <span>{value}</span>,
			},
			{
				Header: 'Detailed Comment',
				accessor: 'comment',
				disableSortBy: true,
				Cell: ({ value }) => value && <span>{value}</span>,
			},
			{
				id: 'editComment',
				width: 25,
				disableSortBy: true,
				canFilter: false,
				disableFilters: true,
				Cell: ({
					row: {
						original: {
							evaluationId,
							userName,
							userId,
							label,
							comment,
							borderCase,
							responseStrength,
							subject,
							reviewId,
							includeInTraining,
						},
					},
				}) => {
					const review = getReviewById(reviewId);
					const isClosedReview = closedReview(review);
					const reviewer = review?.reviewers.filter(
						u => u.userId === userId
					);
					const hasSubmittedReview = submittedReview(
						isClosedReview,
						reviewer
					);
					if (
						userName !== 'Predicted' &&
						currentUser.userId === userId &&
						!hasSubmittedReview
					) {
						return (
							<ModalProvider
								component={UpdateEvaluationModal}
								title='Update Evaluation'
								trigger={
									<Button variant='link'>
										<Styled.SvgImage
											src={CommentAltEdit}
											alt='edit comment'
										/>
									</Button>
								}
								modalProps={{
									label,
									comment,
									borderCase,
									responseStrength,
									subject,
									reviewId: currentReview?.reviewId,
									evaluationId,
									Unique_ID,
									predictionId,
									includeInTraining,
								}}
							/>
						);
					}
					return <span></span>;
				},
			},
			{
				id: 'approveEvaluation',
				width: 25,
				disableSortBy: true,
				canFilter: false,
				disableFilters: true,
				Cell: ({
					row: {
						original: {
							userName,
							evaluationId,
							reviewId,
							isApproved,
						},
					},
				}) => {
					const review = getReviewById(reviewId);
					const isClosedReview = closedReview(review);
					if (
						(userName !== 'Predicted' &&
							review?.ownerId === currentUser.userId &&
							!isClosedReview) ||
						review?.ownerName === ELISPOT_COUNT_USERNAME
					) {
						if (isApproved) {
							return (
								<Button variant='link' disabled>
									<FontAwesomeIcon
										icon={faThumbsUp}
										color={theme.green}
									/>
								</Button>
							);
						}
						return (
							<ModalProvider
								title={'Approve Evaluation'}
								component={ApproveEvaluation}
								modalProps={{
									reviewId,
									evaluationId,
									expertLabelUid: Unique_ID,
									predictionId,
								}}
								modalStyles={{ size: 'lg' }}
								trigger={
									<Button variant='link'>
										<FontAwesomeIcon
											icon={faCheck}
											color={theme.green}
										/>
									</Button>
								}
							/>
						);
					}
					return <span></span>;
				},
			},
			{
				id: 'deleteComment',
				width: 25,
				disableSortBy: true,
				canFilter: false,
				disableFilters: true,
				Cell: ({
					row: {
						original: { userName, evaluationId, reviewId },
					},
				}) => {
					const review = getReviewById(reviewId);
					const isClosedReview = closedReview(review);
					if (
						(userName !== 'Predicted' &&
							review?.ownerId === currentUser.userId &&
							!isClosedReview) ||
						review?.ownerName === ELISPOT_COUNT_USERNAME
					) {
						return (
							<ModalProvider
								title={'Delete Evaluation'}
								component={DeleteEvaluation}
								modalProps={{
									reviewId,
									evaluationId,
									expertLabelUid: Unique_ID,
									predictionId,
								}}
								modalStyles={{ size: 'lg' }}
								trigger={
									<Button variant='link'>
										<FontAwesomeIcon
											icon={faTrashAlt}
											color={theme.red_4}
										/>
									</Button>
								}
							/>
						);
					}
					return <span></span>;
				},
			},
		],
		[
			theme,
			currentUser.userId,
			Unique_ID,
			predictionId,
			currentReview?.reviewId,
			getReviewById,
			closedReview,
			submittedReview,
		]
	);
	return (
		<Styled.ReviewsContainer>
			<Styled.ReviewsTitleWrap>
				<h3>Reviews</h3>
				<When condition={!!canEvaluate && !!isValidExp}>
					<ModalProvider
						component={CreateEvaluationModal}
						title='Evaluate'
						trigger={
							<Styled.SuccessButton variant='success'>
								Evaluate
							</Styled.SuccessButton>
						}
						modalProps={{
							reviewId: currentReview?.reviewId,
							predictionId,
							Unique_ID,
						}}
					/>
				</When>
			</Styled.ReviewsTitleWrap>
			<Table data={evaluationsData} columns={columns} loading={loading} />
		</Styled.ReviewsContainer>
	);
}
