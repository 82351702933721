import React, { Fragment } from 'react';
import { When } from 'components/When';
import * as Styled from './styled';

interface Props {
	errors: [];
}

export default function ErrorMessages({ errors }: Props) {
	return (
		<Fragment>
			<When condition={errors.length > 1}>
				<Styled.Alert variant='danger'>
					{errors?.map((err, index) => (
						<p key={index}>
							{index}. {err}
						</p>
					))}
				</Styled.Alert>
			</When>
			<When condition={!!errors.length && errors.length === 1}>
				<Styled.Alert variant='danger'>
					{errors?.map((err, index) => (
						<p key={index}>{err}</p>
					))}
				</Styled.Alert>
			</When>
		</Fragment>
	);
}
