import React from 'react';
import * as Styled from './styled';

export default function MobileLayout({ children }) {
	return (
		<Styled.Layout>
			<Styled.Header></Styled.Header>
			<Styled.Content>{children}</Styled.Content>
		</Styled.Layout>
	);
}
