import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faDownload } from '@fortawesome/free-solid-svg-icons';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	fetchWorkspaceData,
	fetchVisits,
	updateVisitWells,
	fetchVisitsWithSelectedWells,
	fetchStimulusInIvs,
	fetchIVSWithSelectedWells,
	updateIvsWells,
	fetchStimulusInReadout,
	fetchReadoutWithSelectedWells,
	updateReadoutWells,
	ChangeCardColor,
} from 'core/store/InputGeneration';
import { SubHeader } from 'components/SubHeader';
import { ModalProvider } from 'components/Modal';
import { Link } from 'components/Link';
import { When } from 'components/When';
import { INPUT_FILE_GENERATION } from 'core/routes';
import {
	INPUT_GENERATION_VIEWS,
	COLUMNS_HEADER,
	PLATELAYOUT_SELECT_OPTIONS,
	ROWS_COUNTER,
	EXVIVO,
} from 'core/consts';
import { platesRowsHeaders } from 'helpers';
import { handleMouseUpWellsSelection } from './helpers';
import ToggleButtons from './ToggleButtons';
import CreateVisitModal from './PlateLayoutComponents/CreateVisit/CreateVisitModal';
import CreateIVSModal from './PlateLayoutComponents/CreateIVS/CreateIVSModal';
import CreateReadoutModal from './PlateLayoutComponents/CreateReadout/CreateReadoutModal';
import GroupCells from './PlateLayoutComponents/GroupCells';
import IVS from './PlateLayoutComponents/IVS';
import Readout from './PlateLayoutComponents/Readout';
import DownloadPlateLayoutModal from './PlateLayoutComponents/DownloadPlateLayoutModal';
import * as Styled from './styled';

const BUTTONS = [
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.plateBarcode,
		title: 'Plate Barcode',
	},
	{
		active: true,
		view: INPUT_GENERATION_VIEWS.plateLayout,
		title: 'Plate Layout',
	},
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.sampleInfos,
		title: 'Sample Information',
	},
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.target,
		title: 'Target',
	},
];

export default function PlateLayout({ workspaceDataId }) {
	const dispatch = useDispatch();
	const [selectedCard, setSelectedCard] = useState({
		index: -1,
		id: null,
	});
	const [selectedWells, setSelectedWells] = useState<any[]>([]);
	const [isSelectionChanged, setIsSelectionChanged] = useState(false);
	const [firstSelectedWell, setFirstSelectedWell] = useState('');
	const [
		selectedPlateLayoutWellsType,
		setSelectedPlateLayoutWellsType,
	] = useState(PLATELAYOUT_SELECT_OPTIONS[0].value);
	const [plateBarcode, setPlateBarcode] = useState({
		index: 1,
		size: 0,
		currentPlateBarcode: {
			plateId: null,
			plateBarcode: null,
			availableGroupCells: [] as any[],
			availableStimulusInIvsWells: [] as any[],
			availableStimulusInReadoutWells: [] as any[],
		},
	});
	const { data: workspaceData } = useNamespace({
		namespace: namespaces.WORSKPACE_DATA,
	});

	const { data: visits } = useNamespace({
		namespace: namespaces.VISITS,
	});
	const { data: stimulusInIvs } = useNamespace({
		namespace: namespaces.STIMULUS_IN_IVS,
	});
	const { data: stimulusInReadout } = useNamespace({
		namespace: namespaces.STIMULUS_IN_READOUT,
	});
	const {
		data: visitsSelectedWells,
		isInitialFetch: visitsWithSelectedWellsLoading,
	} = useNamespace({
		namespace: namespaces.VISITS_WITH_SELECTED_WELLS,
	});
	const {
		data: ivsSelectedWells,
		isInitialFetch: ivsWithSelectedWellsLoading,
	} = useNamespace({
		namespace: namespaces.IVS_WITH_SELECTED_WELLS,
	});
	const {
		data: readoutSelectedWells,
		isInitialFetch: readoutWithSelectedWellsLoading,
	} = useNamespace({
		namespace: namespaces.READOUT_WITH_SELECTED_WELLS,
	});
	const { success: changedColorSuccess } = useNamespace({
		namespace: namespaces.CHANGED_COLOR,
	});
	const isVisitsSelected =
		selectedPlateLayoutWellsType === PLATELAYOUT_SELECT_OPTIONS[0].value;
	const isIVSSelected =
		selectedPlateLayoutWellsType === PLATELAYOUT_SELECT_OPTIONS[1].value;
	const isReadoutSelected =
		selectedPlateLayoutWellsType === PLATELAYOUT_SELECT_OPTIONS[2].value;

	useNamespace({
		namespace: namespaces.UPDATED_WELLS,
		onSuccess: () => {
			dispatch(fetchWorkspaceData(workspaceDataId));
		},
	});

	useNamespace({
		namespace: namespaces.RESETED_WELLS,
		onSuccess: () => {
			dispatch(fetchWorkspaceData(workspaceDataId));
		},
	});

	useNamespace({
		namespace: namespaces.DELETED_WELLS,
		onSuccess: () => {
			dispatch(fetchWorkspaceData(workspaceDataId));
			clearCardSelection();
		},
	});

	useEffect(() => {
		if (workspaceData?.plates?.length) {
			setPlateBarcode({
				...plateBarcode,
				size: workspaceData.size,
				currentPlateBarcode:
					workspaceData.plates[plateBarcode.index - 1],
			});
		}
		// eslint-disable-next-line
	}, [workspaceData]);
	useEffect(() => {
		if (workspaceData?.visits?.length) {
			if (isVisitsSelected && (!visits || changedColorSuccess)) {
				dispatch(fetchVisits(workspaceDataId));
			}
			if (isIVSSelected && (!stimulusInIvs || changedColorSuccess)) {
				dispatch(fetchStimulusInIvs(workspaceDataId));
			}
			if (
				isReadoutSelected &&
				(!stimulusInReadout || changedColorSuccess)
			) {
				dispatch(fetchStimulusInReadout(workspaceDataId));
			}
		}
	}, [
		plateBarcode,
		dispatch,
		selectedPlateLayoutWellsType,
		workspaceDataId,
		workspaceData,
		visits,
		stimulusInIvs,
		isVisitsSelected,
		isIVSSelected,
		isReadoutSelected,
		stimulusInReadout,
		changedColorSuccess,
	]);
	useEffect(() => {
		if (!!plateBarcode?.currentPlateBarcode?.plateId) {
			dispatch(
				fetchVisitsWithSelectedWells(
					workspaceDataId,
					plateBarcode.currentPlateBarcode.plateId
				)
			);
			dispatch(
				fetchIVSWithSelectedWells(
					workspaceDataId,
					plateBarcode.currentPlateBarcode.plateId
				)
			);
			dispatch(
				fetchReadoutWithSelectedWells(
					workspaceDataId,
					plateBarcode.currentPlateBarcode.plateId
				)
			);
		}
	}, [dispatch, plateBarcode.currentPlateBarcode.plateId, workspaceDataId]);

	useEffect(() => {
		dispatch(fetchWorkspaceData(workspaceDataId));
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (isVisitsSelected) {
			setSelectedWells(
				!!visitsSelectedWells
					? visitsSelectedWells.map(wells => ({
							selectedWells: wells?.selectedWells,
							id: wells?.visitId,
					  }))
					: []
			);
		}
		if (isIVSSelected) {
			setSelectedWells(
				!!ivsSelectedWells
					? ivsSelectedWells.map(wells => ({
							selectedWells: wells?.selectedWells,
							id: wells?.stimulusInIvsId,
					  }))
					: []
			);
		}
		if (isReadoutSelected) {
			setSelectedWells(
				!!readoutSelectedWells
					? readoutSelectedWells.map(wells => ({
							selectedWells: wells?.selectedWells,
							id: wells?.stimulusInReadoutId,
					  }))
					: []
			);
		}
		// eslint-disable-next-line
	}, [
		selectedCard,
		isVisitsSelected,
		isIVSSelected,
		isReadoutSelected,
		visitsSelectedWells,
		ivsSelectedWells,
		readoutSelectedWells,
	]);
	const handlePlateBarcodeChange = index => {
		setPlateBarcode({
			...plateBarcode,
			index,
			currentPlateBarcode: workspaceData.plates[index - 1],
		});
		setSelectedCard({ index: -1, id: null });
	};

	const cardClickHandler = (index, id) => {
		setSelectedCard({ index, id });
		setPlateBarcode({
			...plateBarcode,
			currentPlateBarcode: workspaceData.plates[plateBarcode.index - 1],
		});
	};

	const clearCardSelection = () => {
		setSelectedCard({ index: -1, id: null });
	};
	const getSingleCardBySelectedWellPosition = position =>
		selectedWells?.find(({ selectedWells: wells }) =>
			wells?.find(pos => pos === position)
		);

	const handleOnMouseDown = (position, isWellSelectable) => {
		if (!!isWellSelectable && !!selectedCard?.id) {
			setFirstSelectedWell(position);
		}
	};

	const handleOnMouseUP = (lastSelectedWell, availableWellsType) => {
		const singleCardSelectedWells = getSingleCardBySelectedWellPosition(
			lastSelectedWell
		);
		handleMouseUpWellsSelection(
			selectedCard?.id,
			lastSelectedWell,
			firstSelectedWell,
			singleCardSelectedWells,
			setPlateBarcode,
			plateBarcode,
			setSelectedWells,
			selectedWells,
			ROWS_COUNTER[plateBarcode.size],
			COLUMNS_HEADER[plateBarcode.size],
			availableWellsType,
			setIsSelectionChanged
		);
	};

	const handleUpdateWells = () => {
		setIsSelectionChanged(false);
		if (isVisitsSelected) {
			const tmpWells = selectedWells.find(
				({ id }) => id === visits[selectedCard.index].visitId
			);
			const formattedData = {
				wells: tmpWells.selectedWells,
			};
			dispatch(
				updateVisitWells(
					formattedData,
					workspaceDataId,
					workspaceData.plates[plateBarcode.index - 1].plateId,
					visits[selectedCard.index].visitId
				)
			);
		}
		if (isIVSSelected) {
			const tmpWells = selectedWells.find(
				({ id }) =>
					id === stimulusInIvs[selectedCard.index].stimulusInIvsId
			);
			const formattedData = {
				wells: tmpWells.selectedWells,
			};
			dispatch(
				updateIvsWells(
					formattedData,
					workspaceDataId,
					workspaceData.plates[plateBarcode.index - 1].plateId,
					stimulusInIvs[selectedCard.index].stimulusInIvsId
				)
			);
		}
		if (isReadoutSelected) {
			const tmpWells = selectedWells.find(
				({ id }) =>
					id ===
					stimulusInReadout[selectedCard.index].stimulusInReadoutId
			);
			const formattedData = {
				wells: tmpWells.selectedWells,
			};
			dispatch(
				updateReadoutWells(
					formattedData,
					workspaceDataId,
					workspaceData.plates[plateBarcode.index - 1].plateId,
					stimulusInReadout[selectedCard.index].stimulusInReadoutId
				)
			);
		}
	};
	useEffect(() => {
		if (isSelectionChanged) {
			handleUpdateWells();
		}
		// eslint-disable-next-line
	}, [isSelectionChanged]);
	const handleChangeWellsType = value => {
		setSelectedPlateLayoutWellsType(value);
		setSelectedCard({ index: -1, id: null });
		setSelectedWells([]);
	};
	const changeColorHandler = (type, id) => {
		const data = {};
		dispatch(ChangeCardColor(data, workspaceDataId, type, id));
	};

	const columnHeader = !!plateBarcode.size
		? COLUMNS_HEADER[plateBarcode.size]
		: [];

	const rowsHeader = platesRowsHeaders(ROWS_COUNTER[plateBarcode.size]);
	return (
		<>
			<SubHeader
				current={workspaceData?.name}
				pre={
					<span>
						<Link to={INPUT_FILE_GENERATION}>
							Input File Creation
						</Link>
					</span>
				}
			/>
			<Styled.Container>
				<Styled.ContainerHeaderWrapper>
					<ToggleButtons Buttons={BUTTONS} />
					<Form.Control
						as='select'
						onChange={evt =>
							handleChangeWellsType(evt.target.value)
						}
						value={selectedPlateLayoutWellsType}>
						{PLATELAYOUT_SELECT_OPTIONS.map(
							({ label, value }, index) => {
								if (
									workspaceData?.experimentType === EXVIVO &&
									index === 1
								) {
									return (
										<option
											key={value}
											value={value}
											disabled>
											{label}
										</option>
									);
								}
								return (
									<option key={value} value={value}>
										{label}
									</option>
								);
							}
						)}
					</Form.Control>
					<When condition={isVisitsSelected}>
						<ModalProvider
							component={CreateVisitModal}
							title='Add Visit'
							trigger={
								<Styled.AddButton variant='success'>
									Add Visit
									<FontAwesomeIcon icon={faPlusSquare} />
								</Styled.AddButton>
							}
							modalProps={{
								plateId:
									plateBarcode.currentPlateBarcode.plateId,
								workspaceDataId,
							}}
						/>
					</When>
					<When condition={isIVSSelected}>
						<ModalProvider
							component={CreateIVSModal}
							title='Add Stimulus In IVS'
							trigger={
								<Styled.AddButton variant='success'>
									Add Stimulus IVS
									<FontAwesomeIcon icon={faPlusSquare} />
								</Styled.AddButton>
							}
							modalProps={{
								plateId:
									plateBarcode.currentPlateBarcode.plateId,
								workspaceDataId,
							}}
						/>
					</When>
					<When condition={isReadoutSelected}>
						<ModalProvider
							component={CreateReadoutModal}
							title='Add Stimulus In Readout'
							trigger={
								<Styled.AddButton variant='success'>
									Add Stimulus Readout
									<FontAwesomeIcon icon={faPlusSquare} />
								</Styled.AddButton>
							}
							modalProps={{
								plateId:
									plateBarcode.currentPlateBarcode.plateId,
								workspaceDataId,
							}}
						/>
					</When>
					<ModalProvider
						component={DownloadPlateLayoutModal}
						title='Download PlateLayout'
						trigger={
							<Styled.DownloadButton variant='success'>
								<FontAwesomeIcon icon={faDownload} />
							</Styled.DownloadButton>
						}
						modalProps={{
							workspaceDataId,
						}}
					/>
				</Styled.ContainerHeaderWrapper>
				<Styled.Content>
					<When condition={isVisitsSelected}>
						<GroupCells
							visits={visits}
							cardClickHandler={cardClickHandler}
							changeColorHandler={changeColorHandler}
							workspaceData={workspaceData}
							handlePlateBarcodeChange={handlePlateBarcodeChange}
							plateBarcode={plateBarcode}
							columnHeader={columnHeader}
							rowsHeader={rowsHeader}
							handleOnMouseDown={handleOnMouseDown}
							handleOnMouseUP={handleOnMouseUP}
							selectedCard={selectedCard}
							workspaceDataId={workspaceDataId}
							availablewells={
								plateBarcode.currentPlateBarcode
									.availableGroupCells
							}
							availableWellsType='availableGroupCells'
							getSingleCardBySelectedWellPosition={
								getSingleCardBySelectedWellPosition
							}
							stimulusInReadoutData={
								workspaceData?.stimulusInReadouts
							}
							stimulusInIvsData={workspaceData?.stimulusInIvs}
							plateSize={plateBarcode.size}
							loading={visitsWithSelectedWellsLoading}
						/>
					</When>
					<When condition={isIVSSelected}>
						<IVS
							ivs={stimulusInIvs}
							cardClickHandler={cardClickHandler}
							changeColorHandler={changeColorHandler}
							workspaceData={workspaceData}
							handlePlateBarcodeChange={handlePlateBarcodeChange}
							plateBarcode={plateBarcode}
							columnHeader={columnHeader}
							rowsHeader={rowsHeader}
							handleOnMouseDown={handleOnMouseDown}
							handleOnMouseUP={handleOnMouseUP}
							selectedCard={selectedCard}
							workspaceDataId={workspaceDataId}
							availablewells={
								plateBarcode.currentPlateBarcode
									.availableStimulusInIvsWells
							}
							availableWellsType='availableStimulusInIvsWells'
							getSingleCardBySelectedWellPosition={
								getSingleCardBySelectedWellPosition
							}
							visits={workspaceData?.visits}
							stimulusInReadoutData={
								workspaceData?.stimulusInReadouts
							}
							plateSize={plateBarcode.size}
							loading={ivsWithSelectedWellsLoading}
						/>
					</When>
					<When condition={isReadoutSelected}>
						<Readout
							readout={stimulusInReadout}
							cardClickHandler={cardClickHandler}
							changeColorHandler={changeColorHandler}
							workspaceData={workspaceData}
							handlePlateBarcodeChange={handlePlateBarcodeChange}
							plateBarcode={plateBarcode}
							columnHeader={columnHeader}
							rowsHeader={rowsHeader}
							handleOnMouseDown={handleOnMouseDown}
							handleOnMouseUP={handleOnMouseUP}
							selectedCard={selectedCard}
							workspaceDataId={workspaceDataId}
							availablewells={
								plateBarcode.currentPlateBarcode
									.availableStimulusInReadoutWells
							}
							availableWellsType='availableStimulusInReadoutWells'
							getSingleCardBySelectedWellPosition={
								getSingleCardBySelectedWellPosition
							}
							visits={workspaceData?.visits}
							stimulusInIvsData={workspaceData?.stimulusInIvs}
							plateSize={plateBarcode.size}
							loading={readoutWithSelectedWellsLoading}
						/>
					</When>
				</Styled.Content>
			</Styled.Container>
		</>
	);
}
