import styled from 'styled-components';
import { Button as BButton, ButtonProps, Spinner } from 'react-bootstrap';

export const StyledButton = styled(BButton)`
	border: none;
	font-weight: bold;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	span[role='status'] {
		margin-right: 10px;
	}
`;

interface Props extends ButtonProps {
	loading?: boolean;
}

export const Button = ({ loading, children, ...props }: Props) => {
	return (
		<StyledButton {...props}>
			<Container>
				{loading && (
					<Spinner
						as='span'
						animation='grow'
						size='sm'
						role='status'
					/>
				)}
				{children}
			</Container>
		</StyledButton>
	);
};
