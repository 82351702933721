import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { clearErrorMessage } from 'core/store/errors';
import { routesConfig, HOME, SELECT_WORKSPACE, ADMIN_LOGIN } from 'core/routes';
import AuthorizationController from 'core/auth/AuthorizationController';
import { ROLES } from 'core/consts/roles';
import { userRoleSelector } from 'core/store/auth';

const DesktopRoutes = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const userRole = useSelector(userRoleSelector);
	const isAdmin = userRole === ROLES.admin;

	useEffect(() => {
		// clear error field on location change
		dispatch(clearErrorMessage());
	}, [location, dispatch]);

	return (
		<Switch>
			<Redirect
				exact
				from={HOME}
				to={isAdmin ? ADMIN_LOGIN : SELECT_WORKSPACE}
			/>
			{routesConfig.map(route => (
				<Route
					exact={!!route.exact}
					key={route.path}
					path={route.path}
					render={props => (
						<AuthorizationController route={route} {...props}>
							<route.component />
						</AuthorizationController>
					)}
				/>
			))}
		</Switch>
	);
};

export default function Router() {
	return (
		<Fragment>
			<DesktopRoutes />
		</Fragment>
	);
}
