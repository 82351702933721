import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Icon } from 'assets/workspaces/projects.svg';
import { Button as RButton } from 'components/Button';

const selectedMenuItem = css`
	background: ${p => p.theme.green_4};
	span {
		color: ${p => p.theme.white};
	}
	svg {
		color: ${p => p.theme.white} !important;
		path {
			fill: ${p => p.theme.white} !important;
		}
	}
`;

const isExternalMenuItem = css`
	position: relative;
	margin-bottom: 8px;
	span {
		margin-left: 14px;
	}
	a {
		margin-left: -12px;
	}
`;

export const Container = styled.div`
	margin-top: 50px;
`;

export const MenuList = styled.ul`
	margin-top: 15px;
	padding: 0;
	list-style: none;
	margin-left: 10px;
	margin-right: 15px;
`;

export const MenuItem = styled.li`
	border-radius: 14px;
	a {
		align-items: center;
		margin-left: 12px;
	}
	${({ isExternal }) => isExternal && isExternalMenuItem}

	${({ isSelected }) => isSelected && selectedMenuItem}
  &:hover {
		${selectedMenuItem}
	}
`;

export const ItemLink = styled(NavLink)`
	cursor: pointer;
	display: flex;
	padding: 9px 24px;
	text-decoration: none !important;
	font-size: 14px;
	svg {
		transition: fill 0.3s ease;
		color: ${p => p.theme.gray};
		width: 26px;
		height: 26px;
	}
`;

export const ProjectItem = styled.div`
	cursor: pointer;
	display: flex;
	padding: 9px 24px;
	text-decoration: none !important;
	font-size: 14px;
	svg {
		transition: fill 0.3s ease;
		color: ${p => p.theme.gray};
		width: 26px;
		height: 26px;
	}
`;

export const Text = styled.span`
	font-weight: bold;
	color: ${p => p.theme.gray};
	white-space: nowrap;
	width: 115px;
	overflow: hidden;
	text-overflow: ellipsis;
	span {
		color: ${p => p.theme.green};
	}
`;

export const ProjectsIcon = styled(Icon)`
	width: 20px !important;
	height: 20px !important;
	margin-right: 15px;
`;

export const IconContainer = styled.div`
	display: flex;
`;

export const SettingIcon = styled(FontAwesomeIcon)`
	position: relative;
	top: 5px;
	right: 0;
	width: 12px !important;
	height: 12px !important;
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.gray_7};
	}
`;

export const DeleteButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.red_4};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;
