import format from 'string-template';
import { fetchInit } from 'core/store/repository';
import * as namespaces from 'core/consts/namespaces';
import {
	WORKSPACE_DATA_LIST,
	NEW_WORSKPACE_DATA,
	SINGLE_WORSKPACE_DATA,
	WORSKPACE_DATA_PLATE,
	DELETE_VISIT,
	UPDATE_VISIT_WELLS,
	DELETE_IVS,
	UPDATE_IVS_WELLS,
	UPDATE_READOUT_WELLS,
	DELETE_READOUT,
	DOWNLOAD_TARGETS,
	STUDY,
	SAMPLE,
	UPDATE_SAMPLE_WELLS,
	DOWNLOAD_PLATELAYOUTS,
	DOWNLOAD_SAMPLE_INFOS,
	TEMPLATES_BY_SIZE,
	CHANGE_CARD_COLOR,
	SINGLE_TARGET,
	RESET_WELLS,
} from 'core/consts/endpoints';

export function fetchWorkspaceDataList(workspaceId) {
	const url = format(WORKSPACE_DATA_LIST, { workspaceId });
	return fetchInit({
		url,
		namespace: namespaces.WORKSPACE_DATA_LIST,
	});
}

export function createWorkspaceData(data, workspaceId) {
	return fetchInit({
		url: NEW_WORSKPACE_DATA,
		namespace: namespaces.NEW_WORSKPACE_DATA,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchWorkspaceDataList(workspaceId),
	});
}

export function editWorkspaceData(data, workspaceId, workspaceDataId) {
	const url = format(SINGLE_WORSKPACE_DATA, { workspaceDataId });
	return fetchInit({
		url,
		namespace: namespaces.NEW_WORSKPACE_DATA,
		config: {
			method: 'patch',
			data,
		},
		successCb: () => fetchWorkspaceDataList(workspaceId),
	});
}

export function deleteWorkspaceData(workspaceDataId, workspaceId) {
	const url = format(SINGLE_WORSKPACE_DATA, { workspaceDataId });
	return fetchInit({
		url,
		namespace: namespaces.DELETED_WORSKPACE_DATA,
		config: { method: 'delete' },
		successCb: () => fetchWorkspaceDataList(workspaceId),
	});
}

export function fetchWorkspaceDataTemplates(workspaceId, size) {
	const url = format(TEMPLATES_BY_SIZE, { workspaceId, size });
	return fetchInit({
		url,
		namespace: namespaces.TEMPLATES_BY_SIZE,
	});
}

export function fetchWorkspaceData(workspaceDataId) {
	const url = format(SINGLE_WORSKPACE_DATA, { workspaceDataId });
	return fetchInit({
		url,
		namespace: namespaces.WORSKPACE_DATA,
	});
}

export function createWorkspaceDataPlates(data, workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/plates`, { workspaceDataId });
	return fetchInit({
		url,
		namespace: namespaces.NEW_WORSKPACE_DATA_PLATES,
		config: {
			method: 'post',
			data,
		},
	});
}

export function deleteWorkspaceDataPlates(workspaceDataId, plateId) {
	const namespace = format(namespaces.DELETE_WORSKPACE_DATA_PLATE, {
		workspaceDataId,
		plateId,
	});
	const url = format(WORSKPACE_DATA_PLATE, {
		workspaceDataId,
		plateId,
	});
	return fetchInit({
		url,
		namespace,
		config: { method: 'delete' },
		successCb: () => fetchWorkspaceData(workspaceDataId),
	});
}

export function fetchVisits(workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/visits`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.VISITS,
	});
}

export function createVisit(data, workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/visits`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.NEW_VISIT,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchVisits(workspaceDataId),
	});
}

export function fetchVisitsWithSelectedWells(workspaceDataId, plateId) {
	const url = format(`${WORSKPACE_DATA_PLATE}/groupCells`, {
		workspaceDataId,
		plateId,
	});
	return fetchInit({
		url,
		namespace: namespaces.VISITS_WITH_SELECTED_WELLS,
	});
}

export function updateVisitWells(data, workspaceDataId, plateId, visitId) {
	const url = format(UPDATE_VISIT_WELLS, {
		workspaceDataId,
		plateId,
		visitId,
	});
	return fetchInit({
		url,
		namespace: namespaces.UPDATED_WELLS,
		config: {
			method: 'patch',
			data,
		},
		autoClear: true,
		successCb: () => fetchVisitsWithSelectedWells(workspaceDataId, plateId),
	});
}

export function deleteVisit(workspaceDataId, visitId) {
	const url = format(DELETE_VISIT, {
		workspaceDataId,
		visitId,
	});

	return fetchInit({
		url,
		namespace: namespaces.DELETED_WELLS,
		config: { method: 'delete' },
		autoClear: true,
		successCb: () => fetchVisits(workspaceDataId),
	});
}

export function fetchStimulusInIvs(workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/stimulusInIvs`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.STIMULUS_IN_IVS,
	});
}

export function createIVS(data, workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/stimulusInIvs`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.NEW_IVS,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchStimulusInIvs(workspaceDataId),
	});
}

export function deleteIVS(workspaceDataId, stimulusInIvsId) {
	const url = format(DELETE_IVS, {
		workspaceDataId,
		stimulusInIvsId,
	});

	return fetchInit({
		url,
		namespace: namespaces.DELETED_WELLS,
		config: { method: 'delete' },
		autoClear: true,
		successCb: () => fetchStimulusInIvs(workspaceDataId),
	});
}

export function fetchIVSWithSelectedWells(workspaceDataId, plateId) {
	const url = format(`${WORSKPACE_DATA_PLATE}/stimulusInIvsWells`, {
		workspaceDataId,
		plateId,
	});
	return fetchInit({
		url,
		namespace: namespaces.IVS_WITH_SELECTED_WELLS,
	});
}

export function updateIvsWells(
	data,
	workspaceDataId,
	plateId,
	stimulusInIvsId
) {
	const url = format(UPDATE_IVS_WELLS, {
		workspaceDataId,
		plateId,
		stimulusInIvsId,
	});
	return fetchInit({
		url,
		namespace: namespaces.UPDATED_WELLS,
		config: {
			method: 'patch',
			data,
		},
		autoClear: true,
		successCb: () => fetchIVSWithSelectedWells(workspaceDataId, plateId),
	});
}

export function fetchStimulusInReadout(workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/stimulusInReadout`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.STIMULUS_IN_READOUT,
	});
}

export function createReadout(data, workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/stimulusInReadout`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.NEW_READOUT,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchStimulusInReadout(workspaceDataId),
	});
}

export function fetchReadoutWithSelectedWells(workspaceDataId, plateId) {
	const url = format(`${WORSKPACE_DATA_PLATE}/stimulusInReadoutWells`, {
		workspaceDataId,
		plateId,
	});
	return fetchInit({
		url,
		namespace: namespaces.READOUT_WITH_SELECTED_WELLS,
	});
}

export function updateReadoutWells(
	data,
	workspaceDataId,
	plateId,
	stimulusInReadoutId
) {
	const url = format(UPDATE_READOUT_WELLS, {
		workspaceDataId,
		plateId,
		stimulusInReadoutId,
	});
	return fetchInit({
		url,
		namespace: namespaces.UPDATED_WELLS,
		config: {
			method: 'patch',
			data,
		},
		autoClear: true,
		successCb: () =>
			fetchReadoutWithSelectedWells(workspaceDataId, plateId),
	});
}

export function deleteReadout(workspaceDataId, stimulusInReadoutId) {
	const url = format(DELETE_READOUT, {
		workspaceDataId,
		stimulusInReadoutId,
	});
	return fetchInit({
		url,
		namespace: namespaces.DELETED_WELLS,
		config: { method: 'delete' },
		autoClear: true,
		successCb: () => fetchStimulusInReadout(workspaceDataId),
	});
}

export function fetchTargets(workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/targets`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.TARGETS,
	});
}

export function uploadTargetFile(data, workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/targets`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		config: {
			method: 'patch',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data,
		},
		namespace: namespaces.UPLOADED_TARGET,
		successCb: () => fetchTargets(workspaceDataId),
	});
}

export function downloadTargets(workspaceDataId) {
	const url = format(DOWNLOAD_TARGETS, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.DOWNLOADED_TARGETS,
	});
}

export function updateSingleTarget(data, workspaceDataId, targetId) {
	const url = format(SINGLE_TARGET, {
		workspaceDataId,
		targetId,
	});
	return fetchInit({
		url,
		config: {
			method: 'patch',
			data,
		},
		namespace: namespaces.UPDATED_TARGET,
		successCb: () => fetchTargets(workspaceDataId),
	});
}

export function downloadPlateLayout(
	workspaceDataId,
	isAssociatedGroupsEnabled
) {
	const url = format(DOWNLOAD_PLATELAYOUTS, {
		workspaceDataId,
		isAssociatedGroupsEnabled,
	});
	return fetchInit({
		url,
		namespace: namespaces.DOWNLOADED_PLATELAYOUTS,
	});
}

export function ChangeCardColor(data, workspaceDataId, type, id) {
	const url = format(CHANGE_CARD_COLOR, {
		workspaceDataId,
		type,
		id,
	});
	return fetchInit({
		url,
		namespace: namespaces.CHANGED_COLOR,
		config: { method: 'patch', data },
		autoClear: true,
		successCb: () => fetchWorkspaceData(workspaceDataId),
	});
}

export function fetchStudies(workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/studies`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.STUDIES,
	});
}

export function createStudy(data, workspaceDataId) {
	const url = format(`${SINGLE_WORSKPACE_DATA}/studies`, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.NEW_STUDY,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchStudies(workspaceDataId),
	});
}

export function createSample(data, workspaceDataId, studyId) {
	const url = format(`${STUDY}/samples`, {
		workspaceDataId,
		studyId,
	});
	return fetchInit({
		url,
		namespace: namespaces.NEW_SAPMLE,
		config: {
			method: 'post',
			data,
		},
		successCb: () => fetchStudies(workspaceDataId),
	});
}

export function fetchSapmlesWithSelectedWells(workspaceDataId, plateId) {
	const url = format(`${WORSKPACE_DATA_PLATE}/sampleWells`, {
		workspaceDataId,
		plateId,
	});
	return fetchInit({
		url,
		namespace: namespaces.SAMPLES_WITH_SELECTED_WELLS,
	});
}

export function deleteStudy(workspaceDataId, studyId) {
	const url = format(STUDY, {
		workspaceDataId,
		studyId,
	});

	return fetchInit({
		url,
		namespace: namespaces.DELETED_WELLS,
		config: { method: 'delete' },
		autoClear: true,
		successCb: () => fetchStudies(workspaceDataId),
	});
}

export function deleteSample(workspaceDataId, studyId, sampleId) {
	const url = format(SAMPLE, {
		workspaceDataId,
		studyId,
		sampleId,
	});

	return fetchInit({
		url,
		namespace: namespaces.DELETED_WELLS,
		config: { method: 'delete' },
		autoClear: true,
		successCb: () => fetchStudies(workspaceDataId),
	});
}

export function updateSampleWells(
	data,
	workspaceDataId,
	plateId,
	studyId,
	sampleId
) {
	const url = format(UPDATE_SAMPLE_WELLS, {
		workspaceDataId,
		plateId,
		studyId,
		sampleId,
	});
	return fetchInit({
		url,
		namespace: namespaces.UPDATED_WELLS,
		config: {
			method: 'patch',
			data,
		},
		autoClear: true,
		successCb: () =>
			fetchSapmlesWithSelectedWells(workspaceDataId, plateId),
	});
}

export function downloadSampleInfos(workspaceDataId) {
	const url = format(DOWNLOAD_SAMPLE_INFOS, {
		workspaceDataId,
	});
	return fetchInit({
		url,
		namespace: namespaces.DOWNLOADED_SAMPLE_INFOS,
	});
}

export function resetWells(groupType, groupId, workspaceDataId, plateId) {
	const url = format(RESET_WELLS, { workspaceDataId, groupType, groupId });
	return fetchInit({
		url,
		namespace: namespaces.RESETED_WELLS,
		config: {
			method: 'patch',
		},
		autoClear: true,
		successCb: () =>
			fetchSapmlesWithSelectedWells(workspaceDataId, plateId),
	});
}
