import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox } from 'components/CheckBox';

export * from '../styled';

export const Container = styled.div`
	display: flex;
	background-color: ${p => p.theme.white};
	flex-direction: column;
	width: 25rem;
	border-radius: 12px;
	box-shadow: 0 0 6px ${p => p.theme.lightDark};
`;

export const SelectContainer = styled.div`
	font-weight: 100;
	text-transform: capitalize;
`;

export const SubHeader = styled.div`
	height: 35px;
	background-color: ${p => p.theme.primaryDark};
	padding: 5px 0;
`;

export const FilterContent = styled.div`
	padding: 15px;
	position: relative;
`;

export const FilterCheckBoxContainer = styled.div`
	display: flex;
	justify-content: space-between;
	input[type='checkbox'] ~ label {
		text-decoration: underline;
	}
	input[type='checkbox']:checked ~ label {
		color: ${p => p.theme.green};
	}
`;

export const ClearAllCheckBox = styled(CheckBox)`
	input[type='checkbox']:checked ~ label {
		color: ${p => p.theme.red_5};
	}
`;

export const Input = styled(Form.Control)`
	border-radius: 80px;
	border: none;
	background: ${p => p.theme.gray_6};
	padding: 0 20px;
	margin: 10px;
	position: relative;
	right: 12px;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 25px;
	bottom: 37px;
	color: ${p => p.theme.border};
`;

export const Text = styled.span`
	color: ${p => p.theme.red_5};
`;
