import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchFileUsingSignedUrl } from 'helpers';
import { Link } from 'components/Link';
import * as ROUTES from 'core/routes';
import { SubHeader } from 'components/SubHeader';
import { Table } from 'components/Table';
import { Button } from 'components/Button';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	fetchTDPreprocessingSummary,
	fetchSingleTrainingData,
	fetchTrainingDataFileUrl,
} from 'core/store/trainingsExvivo';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import * as Styled from './styled';

enum FileNamesEnum {
	CleanedFile = 'cleanedFile',
	DroppedFile = 'droppedFile',
}

export default function TrainingExVivoDataDetails() {
	const { trainingDataId }: { trainingDataId: string } = useParams();
	const dispatch = useDispatch();
	const [fileInfos, setFileInfos] = useState({ fileName: '', fileUrl: '' });
	const { data: reports = {} } = useNamespace({
		namespace: namespaces.TRAINING_DATA_SUMMARY,
	});

	const { data: singleTrainingData = {} } = useNamespace({
		namespace: namespaces.SINGLE_TRAINING_DATA,
	});

	const { data: trainingDataFileUrl = {} } = useNamespace({
		namespace: namespaces.TRAINING_DATA_FILE_URL,
		autoClear: true,
	});

	useEffect(() => {
		dispatch(fetchSingleTrainingData(trainingDataId));
	}, [trainingDataId, dispatch]);

	useEffect(() => {
		if (!!trainingDataFileUrl?.url)
			setFileInfos({
				...fileInfos,
				fileUrl: trainingDataFileUrl.url,
			});
		// eslint-disable-next-line
	}, [trainingDataFileUrl]);

	useEffect(() => {
		if (!!fileInfos.fileUrl && !!fileInfos.fileName) {
			const { fileName, fileUrl } = fileInfos;
			setFileInfos({ fileName: '', fileUrl: '' });
			fetchFileUsingSignedUrl(fileUrl, false)
				.then(result => {
					try {
						const content = new Blob([result], {
							type: 'application/json',
						});
						saveAs(
							content,
							`${fileName}_preprocessing_${trainingDataId}.json`
						);
					} catch (e) {
						return;
					}
				})
				.catch(err => console.error(err));
		}
	}, [trainingDataId, dispatch, trainingDataFileUrl, fileInfos]);
	const data = useMemo(() => {
		try {
			return JSON.parse(reports.cleaned);
		} catch (e) {
			return [];
		}
	}, [reports.cleaned]);

	const columns = useMemo(() => {
		try {
			return Object.keys(data[0]).map(key => ({
				Header: key,
				accessor: key,
				Filter: props =>
					SelectFilter({
						...props,
						customData: data,
					}),
				filter: multiSelectFilter,
			}));
		} catch (e) {
			return [];
		}
	}, [data]);

	useEffect(() => {
		dispatch(fetchTDPreprocessingSummary(trainingDataId));
	}, [dispatch, trainingDataId]);

	const downloadFile = fileName => {
		setFileInfos({ ...fileInfos, fileName });
		dispatch(fetchTrainingDataFileUrl(trainingDataId, fileName));
	};
	return (
		<Fragment>
			<Styled.SubHeaderWrapper>
				<SubHeader
					pre={
						<Link to={`${ROUTES.TRAININGS_EX_VIVO}?view=data`}>
							Trainings ExVivo Data
						</Link>
					}
					current={singleTrainingData?.name}
					rightContent={
						<ButtonGroup>
							<Button
								variant='success'
								onClick={() =>
									downloadFile(FileNamesEnum.CleanedFile)
								}>
								Cleaned &nbsp;
								<FontAwesomeIcon icon={faDownload} />
							</Button>
							<Button
								variant='warning'
								onClick={() =>
									downloadFile(FileNamesEnum.DroppedFile)
								}>
								Dropped &nbsp;
								<FontAwesomeIcon icon={faDownload} />
							</Button>
						</ButtonGroup>
					}
				/>
			</Styled.SubHeaderWrapper>
			<Styled.Container>
				<Table
					data={data}
					columns={columns}
					withPagination={true}
					withFilters={!!data?.length}
				/>
			</Styled.Container>
		</Fragment>
	);
}
