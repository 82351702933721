import React from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import * as Styled from '../../styled';
export default function CreateSampleForm({
	handleSubmit,
	loading,
	trace,
	error,
	closeModal,
}) {
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.ModalContent>
				<Row>
					<Col>
						<Form.Label>
							<b>Subject ID</b>
						</Form.Label>
						<Field
							showValid={false}
							name='subjectId'
							placeholder='Type the Subject ID'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Study ID</b>
						</Form.Label>
						<Field
							showValid={false}
							name='studyId'
							placeholder='Type the Study ID'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Analytical Study ID</b>
						</Form.Label>
						<Field
							showValid={false}
							name='analyticalStudyId'
							placeholder='Type the Analytical Study ID'
							type='text'
							component={InputAdapter}
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
			</Styled.ModalContent>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={closeModal}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Save</span>
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
