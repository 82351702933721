import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button as RButton } from 'components/Button';
import { ModalProvider } from 'components/Modal';
import DeleteVisit from '../DeleteModals/DeleteVisit';
import ResetVisit from '../ResetModals/ResetVisit';
import * as Styled from './styled';

export default function VisitCardContent({
	name,
	numberOfCells,
	typeOfCells,
	numberOfTargetCells,
	workspaceDataId,
	visitId,
	plateId,
}) {
	const theme = useTheme();

	return (
		<Styled.CardContent>
			<Styled.CardCell>
				<p>Visit</p>
				<span>{name}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Number Of Cells</p>
				<span>{numberOfCells}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Type Of Cells</p>
				<span>{typeOfCells}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Number Of Target Cells</p>
				<span>{numberOfTargetCells}</span>
			</Styled.CardCell>
			<ModalProvider
				component={ResetVisit}
				title='Reset Visit'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faCircleNotch}
							color={theme.yellow}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					visitId,
					name,
					plateId,
				}}
			/>
			<ModalProvider
				component={DeleteVisit}
				title='Delete Visit'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faTrashAlt}
							color={theme.red_4}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					visitId,
					name,
					plateId,
				}}
			/>
		</Styled.CardContent>
	);
}
