import { produce } from 'immer';
import { createSelector } from 'reselect';
import { fetchInit } from 'core/store/repository';
import * as namespaces from 'core/consts/namespaces';
import {
	CONFIRM_ACCOUNT,
	SEND_RESET_PASSWORD,
	RESET_PASSWORD,
} from 'core/consts/endpoints';
import reducerRegistry from '../reducerRegistry';
import { checkJwtToken } from './utils';
import {
	ADMIN_PORTAL_WORKSPACE_ID,
	ROLES,
	userRoleKey,
	ADMIN_PORTAL_WORKSPACE_LABEL,
} from 'core/consts/roles';
import { workspacesSelector } from 'core/store/workspaces';
export const LOGIN = 'app/auth/LOGIN';
export const LOGOUT = 'app/auth/LOGOUT';
export const REGISTER = 'app/auth/REGISTER';
export const USER_CONNECTED_TO_WORKSPACE =
	'app/auth/USER_CONNECTED_TO_WORKSPACE';
export const USER_LOGIN_FAILED = 'app/auth/USER_LOGIN_FAILED';
export const USER_REGISTER_SUCCEEDED = 'app/auth/USER_REGISTER_SUCCEEDED';
export const USER_REGISTER_FAILED = 'app/auth/USER_REGISTER_FAILED';
export const USER_LOGOUT_SUCCEEDED = 'app/auth/USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'app/auth/USER_LOGOUT_FAILED';
export const USER_FIRST_LOGIN_SUCCEEDED = 'app/auth/USER_FIRST_LOGIN_SUCCEEDED';
export const SAVE_USER_WORKSPACES = 'app/auth/SAVE_USER_WORKSPACES';
export const SAVE_USER_CLUSTERS = 'app/auth/SAVE_USER_CLUSTERS';
export const USER_CLUSTERS_ERROR = 'app/auth/USER_CLUSTERS_ERROR';
export const USER_WITH_NO_WORKSPACE = 'app/auth/USER_WITH_NO_WORKSPACE';
export const CONNECT_USER_TO_WORKSPACE = 'app/auth/CONNECT_USER_TO_WORKSPACE';
export const CONNECT_USER_TO_PROJECT = 'app/auth/CONNECT_USER_TO_PROJECT';
export const CONNECT_ADMIN_TO_PORTAL = 'app/auth/CONNECT_ADMIN_TO_PORTAL';
export const DISCONNECT_FROM_WORKSPACE = 'app/auth/DISCONNECT_FROM_WORKSPACE';
export const DISCONNECT_FROM_PROJECT = 'app/auth/DISCONNECT_FROM_PROJECT';
export const CONNECT_TO_CLUSTER = 'app/auth/MANAGE_CLUSTER';
export const ACCOUNT_CONFIRMATION = 'app/auth/ACCOUNT_CONFIRMATION';
export const authInitialState = {
	user: {
		currentProjectId: null,
		currentClusterId: null,
		clusters: [],
		workspaces: [],
		claims: [],
		userId: null,
		accessRights: [],
		userRole: null,
	},
	token: null,
	connectionProceeded: false,
};

const reducerName = 'auth';

export function login({ email, password }) {
	return {
		type: LOGIN,
		payload: {
			email,
			password,
		},
	};
}

export function logout() {
	return {
		type: LOGOUT,
	};
}

export function register({ data, history }) {
	return {
		type: REGISTER,
		payload: { data, history },
	};
}

export function connectUserToWorkspace({ workspaceId }) {
	return {
		type: CONNECT_USER_TO_WORKSPACE,
		payload: { workspaceId },
	};
}

export function connectUserToProject({ clusterId, projectId }) {
	return {
		type: CONNECT_USER_TO_PROJECT,
		payload: { clusterId, projectId },
	};
}

export function disconnectUserToProject() {
	return {
		type: DISCONNECT_FROM_PROJECT,
	};
}

export function connectToCluster(currentClusterId) {
	return {
		type: CONNECT_TO_CLUSTER,
		payload: { currentClusterId },
	};
}

export function confirmAccount(token) {
	return fetchInit({
		url: CONFIRM_ACCOUNT,
		namespace: namespaces.ACCOUNT_CONFIRMATION,
		config: {
			method: 'post',
			data: { token },
		},
	});
}

export function requestResetPassword(email) {
	return fetchInit({
		url: SEND_RESET_PASSWORD,
		namespace: namespaces.SEND_RESET_PASSWORD,
		config: {
			method: 'post',
			data: { email },
		},
	});
}

export function resetPassword(password, token) {
	return fetchInit({
		url: RESET_PASSWORD,
		namespace: namespaces.RESET_PASSWORD,
		config: {
			method: 'post',
			data: { password, token },
		},
		//	autoClear: true,
	});
}

export default function reducer(state = authInitialState, action) {
	switch (action.type) {
		case USER_FIRST_LOGIN_SUCCEEDED:
			return produce(state, draft => {
				const { token, userRole, ...rest } = action.payload;
				draft.token = token;
				draft.user.userRole = userRole;
				Object.keys(rest).forEach(key => {
					draft.user[key] = rest[key];
				});
			});

		case USER_CONNECTED_TO_WORKSPACE:
			return produce(state, draft => {
				const { token, workspaces, ...rest } = action.payload;
				draft.token = token;
				Object.keys(rest).forEach(key => {
					draft.user[key] = rest[key];
				});
			});
		case SAVE_USER_WORKSPACES:
			return produce(state, draft => {
				draft.user.workspaces = action.workspaces;
			});

		case USER_WITH_NO_WORKSPACE:
			return produce(state, draft => {
				draft.connectionProceeded = true;
			});

		case SAVE_USER_CLUSTERS:
			return produce(state, draft => {
				const { clusters } = action.payload;
				draft.user.clusters = clusters;
				draft.user.currentClusterId = null;
				draft.user.currentProjectId = null;
				draft.connectionProceeded = true;
			});
		case CONNECT_ADMIN_TO_PORTAL:
			return produce(state, draft => {
				draft.connectionProceeded = true;
				draft.user.claims['workspace_id'] = ADMIN_PORTAL_WORKSPACE_ID;
			});
		case DISCONNECT_FROM_WORKSPACE:
			return produce(state, draft => {
				draft.connectionProceeded = false;
			});
		case CONNECT_USER_TO_PROJECT:
			const { clusterId, projectId } = action.payload;
			return produce(state, draft => {
				draft.user.currentClusterId = clusterId;
				draft.user.currentProjectId = projectId;
			});
		case DISCONNECT_FROM_PROJECT:
			return produce(state, draft => {
				draft.user.currentProjectId = null;
				draft.user.currentClusterId = null;
			});
		case CONNECT_TO_CLUSTER:
			const { currentClusterId } = action.payload;
			return produce(state, draft => {
				draft.user.currentProjectId = null;
				draft.user.currentClusterId = currentClusterId;
			});
		default:
			return state;
	}
}

reducerRegistry.register(reducerName, reducer);

export const auth = state => state.auth;

export const isAuthorizedSelector = createSelector(auth, ({ token }) =>
	checkJwtToken(token)
);

export const authWorkspacesSelector = createSelector(
	auth,
	({ user: { workspaces } }) => workspaces || []
);

export const hasAccessRight = (accessRight: string) => {
	return createSelector(auth, auth => {
		if (!auth?.user.accessRights) return false;
		return auth.user.accessRights.includes(accessRight);
	});
};

export const accessRightsSelector = createSelector(
	auth,
	auth => auth.user.accessRights
);

export const userRoleSelector = createSelector(auth, auth => {
	const userRole =
		auth.user.claims[userRoleKey] !== 'Unknown'
			? auth.user.claims[userRoleKey]
			: auth.user.userRole;
	return userRole;
});

export const currentWorkspaceIdSelector = createSelector(
	auth,
	({ user: { claims } }) => claims.workspace_id
);

export const isAdminPortalSelector = createSelector(auth, auth => {
	return auth?.user.claims.workspace_id === ADMIN_PORTAL_WORKSPACE_ID;
});

export const userFullNameSelector = createSelector(
	auth,
	auth => auth.user.claims?.full_name
);

export const connectionProceededSelector = createSelector(
	auth,
	({ connectionProceeded }) => connectionProceeded
);

export const userSelector = createSelector(auth, ({ user }) => user);
export const projectIdSelector = createSelector(
	auth,
	({ user: { currentProjectId } }) => ({
		projectId: currentProjectId,
	})
);

export const clustersSelector = createSelector(
	auth,
	({ user: { clusters } }) => clusters || []
);

export const projectsSelector = createSelector(
	auth,
	({ user: { projects } }) => projects || []
);

export const clusterIdSelector = createSelector(
	auth,
	({ user: { currentClusterId } }) => ({
		clusterId: currentClusterId,
	})
);

export const userWorkspacesSelector = createSelector(
	workspacesSelector,
	userRoleSelector,
	currentWorkspaceIdSelector,
	(workspaces, userRole, currentWorkspaceId) => {
		const userWorkspaces = workspaces.filter(
			({ workspaceId }) => workspaceId !== currentWorkspaceId
		);
		if (userRole === ROLES.admin) {
			const adminPortal = {
				workspaceName: ADMIN_PORTAL_WORKSPACE_LABEL,
				workspaceId: ADMIN_PORTAL_WORKSPACE_ID,
			};
			return [...userWorkspaces, adminPortal];
		}
		return userWorkspaces;
	}
);
