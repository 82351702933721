// users
export const LOGIN_URL = '/account/sign-in';
export const LOGOUT_URL = '/account/sign-out';
export const REGISTER_URL = '/account/sign-up';
export const CONNECT_TO_WORKSPACE = 'account/{userId}/connect/{workspaceId}';
export const USER_WORKSPACES = '/users/{userId}/workspaces';
export const SINGLE_USER = '/users/{userId}';
export const ACTIVATE_USER = '/account/{userId}/activate';
export const DEACTIVATE_USER = '/account/{userId}/deactivate';
export const CONFIRM_ACCOUNT = '/account/confirm';
export const SEND_RESET_PASSWORD = '/account/send-password-reset';
export const RESET_PASSWORD = '/account/reset-password';

// Clusters
export const CLUSTERS = '/clusters';
export const SINGLE_CLUSTER = '/clusters/{clusterId}';
export const CLUSTER_PROJECTS = '/clusters/{clusterId}/projects';
export const ADD_CLUSTER_MEMBERS = '/users/clusters/bulk';
export const USER_CLUSTERS = '/users/{userId}/clusters';
export const CLUSTER_MEMBERS = '/clusterusers';
export const CLUSTER_PATHS = '/clusterusers/{clusterId}/settings';
export const CLUSTER_PATHS_SETTINGS = '/clusters/{clusterId}/folderpaths';

// TRAININGS
export const TRAINING_INVITRO_DATA = '/trainingData/invitro';
export const TRAINING_EXVIVO_DATA = '/trainingData/exvivo';
export const SINGLE_TRAINING_DATA = '/trainingData/{trainingDataId}';
export const TRAININGS = '/trainings';
export const TRAININGS_INVITRO = '/trainings/invitro';
export const TRAININGS_EXVIVO = '/trainings/exvivo';
export const SINGLE_TRAINING = '/trainings/{id}';
export const TRAINING_MODEL_TROUBLESHOOTING =
	'trainings/{trainingId}/troubleshooting';
export const SET_CHOSEN_MODEL = '/trainings/{id}/set-chosen-model';
export const TRAININGS_PARAMS = '/trainings/params';
export const TD_CHECK_SUMMARY = '/trainingData/{trainingDataId}/check-summary';
export const TD_PREPROCESSING_SUMMARY =
	'/trainingData/{trainingDataId}/preprocessing-summary';
export const TRAINING_RESULTS = '/trainings/{id}/results';
export const TRAINING_EVALUATION = '/trainings/{id}/evaluation';
export const CHECK_TRAININGS_INVITRO_DATA_NAME_EXISTENCE =
	'/trainingData/invitro/checkNameExistence/{name}';
export const CHECK_TRAININGS_EXVIVO_DATA_NAME_EXISTENCE =
	'/trainingData/exvivo/checkNameExistence/{name}';
export const TRAINING_DATA_FILE_URL =
	'trainingdata/{trainingDataId}/preprocessing-summary/{fileName}';
// PREDICTIONS
export const PREDICTION_DATA_URL = '/projects/{projectId}/predictionData';
export const PREDICTION_DATA_IN_VITRO =
	'/projects/{projectId}/inVitroPredictionData';
export const PREDICTION_DATA_EX_VIVO =
	'/projects/{projectId}/exVivoPredictionData';
export const PREDICTIONS = '/projects/{projectId}/predictions';
export const SINGLE_PREDICTION =
	'/projects/{projectId}/predictions/{predictionId}';
export const SINGLE_PREDICTION_PATCH = 'predictions/{predictionId}/experiments';
export const PREDICTION_RESULTS = '/predictions/{predictionId}/results';
export const PD_CHECK_SUMMARY =
	'/predictionData/{predictionDataId}/check-summary';
export const PD_PREPROCESSING_SUMMARY =
	'/predictionData/{predictionDataId}/preprocessing-summary';
export const PREDICTION_DATA_FILE_URL =
	'/predictionData/{predictionDataId}/preprocessing-summary/{fileName}';
export const SINGLE_PREDICTION_DATA =
	'/projects/{projectId}/predictionData/{predictionDataId}';
export const RUN_SPOT_COUNT = '/predictions/spots/run';
export const PREVIEW_SPOT_COUNT = '/predictions/{predictionId}/spots/preview';
export const PREDICTION_RESULTS_FAMILY =
	'/predictions/{predictionId}/results/{experimentUniqueId}/family';
export const ASSIGN_TO_ZERO = '/predictions/{predictionId}/spots/assigntozero';
export const ASSIGN_TO_TNTC = '/predictions/{predictionId}/spots/assigntotntc';
export const APPLY_SPOTS = '/predictions/{predictionId}/spots/apply';
export const SPOT_PARAMETERES =
	'/predictions/{predictionId}/results/{experimentId}/spotParameters/{imageKey}';
export const FULL_PLATE = '/predictions/{predictionId}/fullplate/{plateId}';
export const COMPACT_VIEW =
	'/predictions/{predictionId}/compactview/{experimentId}';

export const PREDICTION_SETTINGS =
	'/predictions/{predictionId}/applyNeCasesUpdates';

export const HIDE_PREDICTION_MODAL =
	'/predictions/{predictionId}/ignoreNeCasesUpdates';
export const CHECK_PREDICTION_DATA_NAME_EXISTENCE =
	'/projects/{projectId}/predictiondata/checkNameExistence/{name}';
// WOESPACES
export const ADMIN_WORKSPACES = '/workspaces';
export const SINGLE_WORKSPACE = '/workspaces/{workspaceId}';

// USERS
export const USERS = '/users';
export const WORKSPACE_USERS = '/workspaceusers';
export const EXTERNAL_WORKSPACE_USERS =
	'/workspaceusers/{workspaceId}/external';
export const WORKSPACE_CLUSTER_JOINERS = '/workspaceusers/cluster/joiners';
export const EXTERNAL_ClUSTERS_USERS = '/clusterusers/{clusterId}/external';
export const ADD_WORKSPACE_MEMBERS = '/users/workspaces/bulk';
export const EXTERNAL_PROJECT_USERS = '/projectusers/{projectId}/external';
export const WORKSPACE_PATHS = '/workspaceusers/{workspaceId}/settings';
export const WORKSPACE_PATHS_SETTINGS = '/workspaces/{workspaceId}/folderpaths';

//PROJECTS
export const PROJECTS = '/projects';
export const SINGLE_PROJECT = '/projects/{projectId}';
export const ADD_PROJECT_MEMBERS = '/users/clusters/{clusterId}/projects/bulk';
export const PROJECT_MEMBERS = '/projectusers';
export const ALL_PROJECT_MEMBERS = '/projectusers/all';
export const DEFAULT_PROJECT_SETTINGS = '/projects/{projectId}/defaultnecases';
export const PROJECT_SETTINGS = '/projectusers/{projectId}/settings';
export const PROJECT_PATHS_SETTINGS = '/projects/{projectId}/folderpaths';
export const PROJECT_NECASES_SETTINGS = '/projects/{projectId}/necases';

//REVIEWS
export const REVIEWS = '/reviews';
export const REVIEW = '/reviews/{reviewId}';
export const REVIEWS_BY_PREDICTION = '/reviews/{predictionId}';
export const PREDEFINED_COMMENTS = '/reviews/comments';
export const EVALUATIONS = '/reviews/evaluations';
export const APPROVE_EVALUATIONS = '/reviews/approve';
export const SUBMIT_REVIEW = '/reviews/submit';
export const CLOSE_REVIEW = '/reviews/close';

// Input Generation
export const WORKSPACE_DATA_LIST = '/workspacesData/workspace/{workspaceId}';
export const NEW_WORSKPACE_DATA = '/workspacesData';
export const SINGLE_WORSKPACE_DATA = '/workspacesData/{workspaceDataId}';
export const WORSKPACE_DATA_PLATE =
	'/workspacesData/{workspaceDataId}/plates/{plateId}';
export const UPDATE_VISIT_WELLS =
	'/workspacesData/{workspaceDataId}/plates/{plateId}/visits/{visitId}/wells';
export const DELETE_VISIT =
	'/workspacesData/{workspaceDataId}/visits/{visitId}';
export const DELETE_IVS =
	'/workspacesData/{workspaceDataId}/StimulusInIvs/{stimulusInIvsId}';
export const UPDATE_IVS_WELLS =
	'/workspacesData/{workspaceDataId}/plates/{plateId}/stimulusInIvs/{stimulusInIvsId}/wells';
export const UPDATE_READOUT_WELLS =
	'/workspacesData/{workspaceDataId}/plates/{plateId}/stimulusInReadout/{stimulusInReadoutId}/wells';
export const DELETE_READOUT =
	'/workspacesData/{workspaceDataId}/StimulusInReadout/{stimulusInReadoutId}';
export const DOWNLOAD_TARGETS = '/workspacesData/{workspaceDataId}/targets';
export const SINGLE_TARGET =
	'/workspacesData/{workspaceDataId}/targets/{targetId}';
export const STUDY = '/workspacesData/{workspaceDataId}/studies/{studyId}';
export const SAMPLE =
	'/workspacesData/{workspaceDataId}/studies/{studyId}/samples/{sampleId}';
export const UPDATE_SAMPLE_WELLS =
	'/workspacesData/{workspaceDataId}/plates/{plateId}/studies/{studyId}/samples/{sampleId}/wells';
export const DOWNLOAD_PLATELAYOUTS =
	'/workspacesData/{workspaceDataId}/platelayout/export/{isAssociatedGroupsEnabled}';
export const DOWNLOAD_SAMPLE_INFOS =
	'/workspacesData/{workspaceDataId}/sampleinformation/export';
export const RESET_WELLS =
	'/workspacesData/{workspaceDataId}/{groupType}/{groupId}/resetWells';
export const TEMPLATES_BY_SIZE =
	'/workspacesData/workspace/{workspaceId}/size={size}';

export const CHANGE_CARD_COLOR =
	'/workspacesData/{workspaceDataId}/{type}/updateGroupColor/{id}';

// Resumable Upload

/* Prediction Data */
export const RESUMABLE_PREDICTION_DATA_IMAGES =
	'/resumable-upload/predictionData/{name}/images';
export const RESUMABLE_PREDICTION_DATA_PLATELAYOUT =
	'/resumable-upload/predictionData/{name}/platelayout';
export const RESUMABLE_PREDICTION_DATA_SAMPLE =
	'/resumable-upload/predictionData/{name}/sample';
export const RESUMABLE_PREDICTION_DATA_TARGET =
	'/resumable-upload/predictionData/{name}/target';
export const RESUMABLE_PREDICTION_DATA_FACS =
	'/resumable-upload/predictionData/{name}/facs';

/* Invitro Training Data */
export const RESUMABLE_INVITRO_TRAINIG_DATA_IMAGES =
	'/resumable-upload/inVitroTrainingData/{name}/images';
export const RESUMABLE_INVITRO_TRAINIG_DATA_EDA =
	'/resumable-upload/inVitroTrainingData/{name}/eda';
export const RESUMABLE_INVITRO_TRAINIG_DATA_REAC =
	'/resumable-upload/inVitroTrainingData/{name}/reac';

/* Exvivo Training Data */
export const RESUMABLE_EXVIVO_TRAINIG_DATA_IMAGES =
	'/resumable-upload/exVivoTrainingData/{name}/images';
export const RESUMABLE_EXVIVO_TRAINIG_DATA_EDA =
	'/resumable-upload/exVivoTrainingData/{name}/eda';
export const RESUMABLE_EXVIVO_TRAINIG_DATA_PLATELAYOUT =
	'/resumable-upload/exVivoTrainingData/{name}/platelayout';
export const RESUMABLE_EXVIVO_TRAINIG_DATA_SAMPLE =
	'/resumable-upload/exVivoTrainingData/{name}/sample';
export const RESUMABLE_EXVIVO_TRAINIG_DATA_TARGET =
	'/resumable-upload/exVivoTrainingData/{name}/target';
