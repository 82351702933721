import React, { Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import * as namespaces from 'core/consts/namespaces';
import useNamespace from 'hooks/useNamespace';
import { fetchTraining } from 'core/store/trainingsInVitro';
import { formatDateAndTime } from 'helpers';
import { getPredictionByTrainingName } from 'core/store/predictions';
import * as Styled from '../styled';

export default function TrainingDetailsModal({ closeModal, name }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { trainingId } = useSelector(getPredictionByTrainingName(name));
	const {
		data: {
			configuration,
			dataAugmentation,
			modelParameters,
			status,
			trainingDataName,
			accuracy,
			userName,
			isChosenModel,
			finishedOn,
			startedOn,
		} = {},
	} = useNamespace({
		namespace: namespaces.SINGLE_TRAINING,
	});

	useEffect(() => {
		dispatch(fetchTraining(trainingId));
	}, [dispatch, trainingId]);

	const getIconProps = useCallback(
		condition => {
			return condition
				? { icon: faCheckCircle, color: theme.green }
				: { icon: faBan, color: theme.red_2 };
		},
		[theme]
	);

	return (
		<Fragment>
			<Modal.Body>
				<Styled.SubTrainingDetails>
					<p>
						<b>Training Details</b>
					</p>
					<p>
						<b>Training ID : </b>
						{trainingId}
					</p>
					<p>
						<b>Training Name : </b>
						{name}
					</p>
					<p>
						<b>Training Data Name : </b>
						{trainingDataName}
					</p>
					<p>
						<b>Status : </b>
						{status}
					</p>
					<p>
						<b>Is Chosen Model : </b>
						<FontAwesomeIcon {...getIconProps(isChosenModel)} />
					</p>
					<p>
						<b>Accuracy : </b>
						{accuracy}
					</p>
					<p>
						<b>User Name : </b>
						{userName}
					</p>
					<p>
						<b>Time Started : </b>
						{formatDateAndTime(startedOn)}
					</p>
					<p>
						<b>Time Finished : </b>
						{finishedOn && formatDateAndTime(finishedOn)}
					</p>
				</Styled.SubTrainingDetails>
				<Styled.SubTrainingDetails>
					<p>
						<b>Data Augmentation </b>
					</p>
					<p>
						<b>Flip : </b>
						<FontAwesomeIcon
							{...getIconProps(dataAugmentation?.flip)}
						/>
					</p>
					<p>
						<b>Flip Probability : </b>
						{dataAugmentation?.flipProbability}
					</p>
					<p>
						<b>Gamma Contrast : </b>
						<FontAwesomeIcon
							{...getIconProps(dataAugmentation?.gammaContrast)}
						/>
					</p>
					<p>
						<b>Minimum Gamma : </b>
						{dataAugmentation?.minimumGamma}
					</p>
					<p>
						<b>Maximum Gamma : </b>
						{dataAugmentation?.maximumGamma}
					</p>
					<p>
						<b>Rotation : </b>
						<FontAwesomeIcon
							{...getIconProps(dataAugmentation?.rotation)}
						/>
					</p>
					<p>
						<b>Angle : </b>
						{dataAugmentation?.angle}
					</p>
					<p>
						<b>Shuffling : </b>
						<FontAwesomeIcon
							{...getIconProps(dataAugmentation?.shuffling)}
						/>
					</p>
				</Styled.SubTrainingDetails>
				<Styled.SubTrainingDetails>
					<p>
						<b>Model Parameters </b>
					</p>
					<p>
						<b>Model : </b>
						{modelParameters?.model}
					</p>
					<p>
						<b>Model Variant : </b>
						{modelParameters?.modelVariant}
					</p>
					<p>
						<b>Batch Sizet : </b>
						{modelParameters?.batchSize}
					</p>
					<p>
						<b>Classes Number : </b>
						{modelParameters?.classesNumber}
					</p>
					<p>
						<b>Epochs Number : </b>
						{modelParameters?.epochsNumber}
					</p>
					<p>
						<b>Learning Rate : </b>
						{modelParameters?.learningRate}
					</p>
					<p>
						<b>Saved Models : </b>
						{modelParameters?.savedModels}
					</p>
					<p>
						<b>Loss : </b>
						{modelParameters?.loss}
					</p>
					<p>
						<b>Optimizer : </b>
						{modelParameters?.optimizer}
					</p>
					<p>
						<b>Input Shape : </b>
						{modelParameters?.inputShape}
					</p>
					<p>
						<b>Input Mode : </b>
						{modelParameters?.inputMode}
					</p>
				</Styled.SubTrainingDetails>
				<Styled.SubTrainingDetails>
					<p>
						<b>Configuration </b>
					</p>
					<p>
						<b>Radius : </b>
						{configuration?.radius}
					</p>
					<p>
						<b>Mask Mode : </b>
						<FontAwesomeIcon
							{...getIconProps(configuration?.maskMode)}
						/>
					</p>
					<p>
						<b>Mirrored Mode : </b>
						<FontAwesomeIcon
							{...getIconProps(configuration?.mirroredMode)}
						/>
					</p>
					<p>
						<b>GPUs Number : </b>
						{configuration?.gpusNumber}
					</p>
				</Styled.SubTrainingDetails>
			</Modal.Body>
			<Modal.Footer>
				<Styled.CancelButton variant='danger' onClick={closeModal}>
					Close
				</Styled.CancelButton>
			</Modal.Footer>
		</Fragment>
	);
}
