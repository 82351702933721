import React from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import * as Styled from '../../styled';
export default function CreateReadoutForm({
	handleSubmit,
	loading,
	trace,
	error,
	closeModal,
}) {
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.ModalContent>
				<Row>
					<Col>
						<Form.Label>
							<b>Stimulus in Readout</b>
						</Form.Label>
						<Field
							showValid={false}
							name='name'
							placeholder='Type the name'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Type Of Stimulus in Readout</b>
						</Form.Label>
						<Field
							showValid={false}
							name='typeOfStimulusInReadout'
							placeholder='Type the type of stimulus in Readout'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b> Stimulus Format in Readout</b>
						</Form.Label>
						<Field
							showValid={false}
							name='stimulusFormatInReadout'
							placeholder='Type the stimulus format in Readout'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
			</Styled.ModalContent>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={closeModal}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Save</span>
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
