import React from 'react';
import {
	BarChart as RBarChart,
	Bar,
	XAxis,
	YAxis,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import * as Styled from './styled';

export const BarChart = ({ data, title }) => {
	return (
		<Styled.Wrapper>
			<p>{title}</p>
			<ResponsiveContainer width='100%' height='90%'>
				<RBarChart width={150} height={40} data={data}>
					<XAxis dataKey='name' />
					<YAxis
						domain={[0, 1]}
						label={{
							value: 'Probability',
							angle: -90,
							position: 'insideLeft',
							dy: 30,
						}}
					/>
					<Tooltip />
					<Bar dataKey='value' fill='#549485' />
				</RBarChart>
			</ResponsiveContainer>
		</Styled.Wrapper>
	);
};
