import styled from 'styled-components';
import { Form as BForm } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'components/Form';

export const Wrapper = styled.div`
	margin-top: 10px;
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;

export const DetectSpotCountButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.yellow_2};
	border-radius: 44px;
	margin-right: 20px;
	&:hover,
	:focus,
	:active {
		background-color: ${p => p.theme.yellow_2};
	}
`;

export const TabBar = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 20px 0 20px;
	& .nav {
		width: 50%;
		& .nav-item {
			width: 150px;
			position: relative;
			left: 40px;
			&:nth-child(2) {
				left: 80px;
			}
		}
	}
	& .nav-link {
		text-align: center;
		font-weight: bold;
		color: ${p => p.theme.border};
		border-bottom: 5px solid ${p => p.theme.gray_9};
		&:hover {
			border-color: transparent;
			border-bottom: 5px solid ${p => p.theme.gray_9};
		}
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: transparent;
		border-color: transparent;
		color: ${p => p.theme.green};
		border-bottom: 5px solid ${p => p.theme.green};
		svg {
			color: ${p => p.theme.green};
		}
	}
`;

export const Form = styled(BForm)`
	position: relative;
`;

export const Input = styled(Field)`
	border-radius: 80px;
	border: none;
	background: ${p => p.theme.gray_6};
	padding: 0 60px;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;
	top: 12px;
	color: ${p => p.theme.border};
`;
