import styled from 'styled-components';

export const Wrapper = styled.div`
	height: 100%;
	text {
		font-weight: 600 !important;
	}
	p {
		text-align: center;
		font-weight: 600;
	}
`;
