import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { approveEvaluation } from 'core/store/predictions';
import * as Styled from '../styled';

export default function ApproveEvaluation({
	closeModal,
	reviewId,
	evaluationId,
	predictionId,
	expertLabelUid,
}) {
	const dispatch = useDispatch();
	const handleApprove = () => {
		const data = { reviewId, evaluationId };
		dispatch(approveEvaluation(data, predictionId, expertLabelUid));
	};

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.APPROVED_EVALUATION,
		onSuccess: closeModal,
	});

	return (
		<Fragment>
			<Modal.Body>
				<b>{'Are you sure you want to Approve this evaluation?'}</b>
			</Modal.Body>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='success' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.ApproveButton onClick={handleApprove} loading={loading}>
					Approve
				</Styled.ApproveButton>
			</Modal.Footer>
		</Fragment>
	);
}
