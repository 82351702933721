import React from 'react';
import { useDispatch } from 'react-redux';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	createIVS,
	fetchIVSWithSelectedWells,
} from 'core/store/InputGeneration';
import CreateIVSForm from './CreateIVSForm';
import { FormContainer } from 'components/Form';

export default function CreateIVSModal({
	closeModal,
	workspaceDataId,
	plateId,
}) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_IVS,
		onSuccess: () => {
			closeModal();
			dispatch(fetchIVSWithSelectedWells(workspaceDataId, plateId));
		},
	});
	const handleCreateIVS = data => {
		dispatch(createIVS(data, workspaceDataId));
	};

	return (
		<FormContainer
			formID='create-IVS'
			onSubmit={handleCreateIVS}
			component={props => (
				<CreateIVSForm
					{...props}
					loading={loading}
					closeModal={closeModal}
					trace={trace}
					error={error}
				/>
			)}
		/>
	);
}
