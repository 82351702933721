import styled from 'styled-components';

export const FoldersWrap = styled.div`
	width: calc(100% - 40px);
	margin: 0 20px 20px 20px;
	div:nth-child(even) {
		margin-right: 2%;
	}
	h4 {
		margin: 25px 0;
	}
`;

export const FolderWrap = styled.div`
	overflow: hidden;
	float: left;
	width: 44%;
	padding-right: 10%;
	display: flex;
	justify-content: space-between;
	p {
		float: left;
		padding: 5px 0;
	}
`;

export const FolderTitle = styled.p`
	text-transform: uppercase;
	font-weight: 700;
	margin-right: 40px;
`;

export const FolderPath = styled.p`
	padding: 5px 50px 5px 20px !important;
	background: ${p => p.theme.gray};
	border-radius: 8px;
	min-width: 300px;
`;
