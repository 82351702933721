import React from 'react';
import * as Styled from './styled';

export default function FoldersPath({ flodersData }) {
	const {
		imagesFolder,
		plateLayoutFolder,
		sampleFolder,
		targetFolder,
		facsFolder,
	} = flodersData;
	return (
		<Styled.FoldersWrap>
			<h4>Source Folders</h4>
			<Styled.FolderWrap>
				<Styled.FolderTitle>Images Path</Styled.FolderTitle>
				<Styled.FolderPath>{imagesFolder}</Styled.FolderPath>
			</Styled.FolderWrap>
			<Styled.FolderWrap>
				<Styled.FolderTitle>Plate Layout</Styled.FolderTitle>
				<Styled.FolderPath>{plateLayoutFolder}</Styled.FolderPath>
			</Styled.FolderWrap>
			<Styled.FolderWrap>
				<Styled.FolderTitle>Sample</Styled.FolderTitle>
				<Styled.FolderPath>{sampleFolder}</Styled.FolderPath>
			</Styled.FolderWrap>
			<Styled.FolderWrap>
				<Styled.FolderTitle>Target</Styled.FolderTitle>
				<Styled.FolderPath>{targetFolder}</Styled.FolderPath>
			</Styled.FolderWrap>
			<Styled.FolderWrap>
				<Styled.FolderTitle>Facs</Styled.FolderTitle>
				<Styled.FolderPath>{facsFolder}</Styled.FolderPath>
			</Styled.FolderWrap>
		</Styled.FoldersWrap>
	);
}
