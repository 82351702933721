import styled from 'styled-components';

export const FooterContainer = styled.div`
	align-items: center;
	font-size: 0.85em;
	justify-content: center;
	background: ${p => p.theme.white};
	bottom: 0;
	box-shadow: ${p => p.theme.boxShadow};
	display: flex;
	height: 60px;
	padding: 0 20px 0 20px;
	position: absolute;
	width: 100%;
`;
