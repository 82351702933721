import React from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { DatePicker } from 'components/DatePicker';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import * as Styled from '../../styled';
export default function CreateSampleForm({
	handleSubmit,
	loading,
	trace,
	error,
	closeModal,
}) {
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.ModalContent>
				<Row>
					<Col>
						<Form.Label>
							<b>Sample ID</b>
						</Form.Label>
						<Field
							showValid={false}
							name='sampleId'
							placeholder='Type the Sample ID'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Sample Date</b>
						</Form.Label>
						<Field name='sampleDate' type='text'>
							{({ input }) => (
								<DatePicker
									selected={input.value || new Date()}
									onChange={(date: Date | null) => {
										input.onChange(date);
									}}
									dateFormat='dd.MM.yyyy'
								/>
							)}
						</Field>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>MES Work Order</b>
						</Form.Label>
						<Field
							showValid={false}
							name='mesWorkOrder'
							placeholder='Type the MES Work Order'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
			</Styled.ModalContent>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={closeModal}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Save</span>
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
