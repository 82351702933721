import styled, { css } from 'styled-components';
import { Card as BCard } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';

type CardProps = {
	$isSelected?: boolean;
};

export const Card = styled(BCard)<CardProps>`
	display: block;
	overflow: hidden;
	-webkit-box-shadow: 0px 1px 12px 1px #d8d6d6;
	box-shadow: 0px 1px 12px 1px #d8d6d6;
	width: 94%;
	border-radius: 10px;
	margin: 10px 3%;
	padding: 0 10px;
	cursor: pointer;
	position: relative;
	${({ $isSelected }) =>
		$isSelected &&
		css`
			-webkit-box-shadow: inset 0px 0px 0px 4px
				${(p): string => p.theme.green_8};
			-moz-box-shadow: inset 0px 0px 0px 4px
				${(p): string => p.theme.green_8};
			box-shadow: inset 0px 0px 0px 4px ${(p): string => p.theme.green_8};
		`}
`;
type ColorRectProps = {
	$color?: string;
};
export const ColorRect = styled(RButton)<ColorRectProps>`
	width: 20px;
	height: 20px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	padding: 0;
	border-radius: 5px;
	${({ $color }) =>
		!!$color &&
		css`
			background-color: ${$color};
			:hover,
			:active,
			:focus {
				background-color: ${$color};
				box-shadow: none;
			}
		`}
`;
