import styled from 'styled-components';
import { Alert as AAlert } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';

export const Content = styled.div`
	padding: 10px;
	input[type='text'],
	select {
		margin: 5px 0 10px 0;
	}
`;

export const LogsContainer = styled.div`
	height: 600px;
`;

export const TabsContainer = styled.div`
	& .nav-link {
		text-align: center;
		color: unset;
		font-weight: bold;
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: ${p => p.theme.green};
	}
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;

export const Status = styled.div`
	display: flex;
	align-items: center;
	span {
		font-weight: 700;
		margin-left: 20px;
		line-height: 40px;
	}
`;

export const UploadFileButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 80px;
	margin: 15px 0;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	width: 100%;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
	&:disabled {
		background-color: ${p => p.theme.gray_10};
	}
`;

export const FormWrap = styled.div`
	input[type='file'] {
		display: none;
	}
	label {
		margin-bottom: 0;
	}
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const FormLabel = styled.p`
	font-size: 20px;
	font-weight: 700;
	color: ${p => p.theme.secondaryDark};
`;

export const UploadFolderButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 80px;
	margin: 15px 0;
	background-color: ${p => p.theme.white};
	color: ${p => p.theme.secondaryDark};
	border: 3px solid ${p => p.theme.secondaryDark};
	border-radius: 44px;
	width: 100%;
	&:hover {
		background-color: ${p => p.theme.secondaryDark};
		color: ${p => p.theme.white};
		border: 3px solid ${p => p.theme.secondaryDark};
	}
	&:disabled {
		background-color: ${p => p.theme.gray_10};
		border: none;
	}
`;

export const UploadFileButtonWrap = styled.div`
	width: 100%;
	${UploadFileButton}, ${UploadFolderButton} {
		width: 35%;
		float: left;
		margin: 15px 2%;
		padding: 10px 40px;
		svg {
			margin-left: 10px;
			margin-top: -3px;
		}
	}
	${FormLabel} {
		width: 20%;
		float: left;
		margin-top: 20px;
	}
	.form-check {
		width: 30%;
		float: left;
		margin-top: 23px;
	}
`;
