import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useFormState } from 'react-final-form';
import { Field, SearchableSelectAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import * as Styled from '../styled';

const FORM_FIELDS = {
	workspaceName: 'workspaceName',
	workspaceId: 'workspaceId',
};

export default function SelectWorkspaceForm({ handleSubmit, workspaces }) {
	const { values, errors } = useFormState();
	const searchList = useMemo(() => {
		if (!!values.workspaceName) {
			return workspaces.filter(({ workspaceName }) =>
				workspaceName
					.toLowerCase()
					.includes(values?.workspaceName?.toLowerCase())
			);
		}
		return workspaces;
	}, [values.workspaceName, workspaces]);

	const workspaceDataOptions = useMemo(() => {
		return searchList.map(({ workspaceName, workspaceId }) => {
			return { label: workspaceName, value: workspaceId };
		});
	}, [searchList]);

	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<Styled.SelectWrapper>
					<Field
						showValid={false}
						name={FORM_FIELDS.workspaceId}
						component={SearchableSelectAdapter}
						validate={validateRequired()}
						options={workspaceDataOptions}
						placeholder='Select Workspace'
						error={errors[FORM_FIELDS.workspaceId]}
						classNamePrefix='react-select'
						className='react-select-container'
					/>
				</Styled.SelectWrapper>
				<Styled.Button type='submit'>Access Workspace</Styled.Button>
			</Styled.Content>
		</Form>
	);
}
