import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { PREDICTION_DATA } from 'core/routes';
import * as namespaces from 'core/consts/namespaces';
import { fetchPredictionData } from 'core/store/predictions';
import { CHANNELS } from 'core/consts/pusherConfig';
import { formatDateAndTime } from 'helpers';
import { ModalProvider } from 'components/Modal';
import { Table } from 'components/Table';
import { StatusIcon } from 'components/StatusIcon';
import PusherMessage from 'components/PusherMessage';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import { StatusFilter } from 'components/Filters/StatusFilter';
import {
	dateBetweenFilterFn,
	DateFilter,
} from 'components/Filters/DateFilter/DateFilter';
import PDSummaryLogsModal from './LogsModals/PDSummaryLogsModal';
import PredictionDataDeleteModal from './DeleteModals/PredictionDataDeleteModal';
import * as Styled from '../styled';
import { PredictionDataEntry } from '../types';

enum PDStatusEnum {
	Created = 'Created',
	Checking = 'Checking',
	Preprocessing = 'Preprocessing',
	Ready = 'Ready',
	Invalid = 'Invalid',
}
interface Props {
	data: PredictionDataEntry[];
	loading: boolean;
	projectId: string;
}
const getPermissions = (status: string) => {
	return {
		canViewLogs: status !== PDStatusEnum.Ready,
		canViewResult: status === PDStatusEnum.Ready,
	};
};

const PredictionDataTable = memo(function DataTable({
	data,
	loading,
	projectId,
}: Props) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const history = useHistory();

	const refreshPredictionsData = ({ projectId: receivedProjectId }) => {
		if (projectId === receivedProjectId) {
			return dispatch(fetchPredictionData());
		}
		return;
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Status',
				accessor: 'status',
				disableSortBy: true,
				Filter: props =>
					StatusFilter({
						...props,
						filterType: 'predictionData',
					}),
				Cell: ({ value }) => <StatusIcon status={value} />,
			},
			{
				Header: 'Name',
				accessor: 'name',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.PREDICTION_DATA,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'User',
				accessor: 'userName',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.PREDICTION_DATA,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'Time Created',
				accessor: 'createdOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
			},
			{
				id: 'logs',
				disableSortBy: true,
				disableFilters: true,
				Cell: ({
					row: {
						original: { predictionDataId },
					},
				}) => (
					<ModalProvider
						title='Logs'
						component={PDSummaryLogsModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							id: predictionDataId,
						}}
						trigger={
							<Styled.LogButton variant='light'>
								Logs
							</Styled.LogButton>
						}
					/>
				),
			},
			{
				id: 'delete',
				disableSortBy: true,
				disableFilters: true,
				width: 25,
				Cell: ({
					row: {
						original: { predictionDataId, name },
					},
				}) => (
					<ModalProvider
						title='Confirm Deletion of Prediction Data'
						component={PredictionDataDeleteModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							id: predictionDataId,
							name,
						}}
						trigger={
							<FontAwesomeIcon
								icon={faTrashAlt}
								color={theme.red_4}
							/>
						}
					/>
				),
			},
		],
		[theme]
	);

	const handleRowClick = ({ original: { predictionDataId, status } }) => {
		if (getPermissions(status).canViewResult) {
			history.push(`${PREDICTION_DATA}/${predictionDataId}`);
		}
	};

	return (
		<Styled.Container>
			<PusherMessage
				callback={refreshPredictionsData}
				event={CHANNELS.predictionsData}
			/>
			<Table
				columns={columns}
				data={data}
				onRowClick={handleRowClick}
				loading={loading}
				withFilters={!!data.length}
			/>
		</Styled.Container>
	);
});
export default PredictionDataTable;
