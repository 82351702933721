import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button as RButton } from 'components/Button';
import { ModalProvider } from 'components/Modal';
import DeleteIVS from '../DeleteModals/DeleteIVS';
import ResetIVS from '../ResetModals/ResetIVS';
import * as Styled from './styled';

export default function IvsCardContent({
	name,
	typeOfStimulusInIvs,
	stimulusFormatInIvs,
	workspaceDataId,
	stimulusInIvsId,
	plateId,
}) {
	const theme = useTheme();

	return (
		<Styled.CardContent>
			<Styled.CardCell>
				<p>Type of Stimulus In IVS</p>
				<span>{typeOfStimulusInIvs}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Stimulus In IVS</p>
				<span>{name}</span>
			</Styled.CardCell>
			<Styled.CardCell>
				<p>Stimulus Format In IVS</p>
				<span>{stimulusFormatInIvs}</span>
			</Styled.CardCell>
			<ModalProvider
				component={ResetIVS}
				title='Reset Stimulus In IVS'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faCircleNotch}
							color={theme.yellow}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					stimulusInIvsId,
					name,
					plateId,
				}}
			/>
			<ModalProvider
				component={DeleteIVS}
				title='Delete Stimulus In IVS'
				trigger={
					<RButton variant='link'>
						<FontAwesomeIcon
							icon={faTrashAlt}
							color={theme.red_4}
						/>
					</RButton>
				}
				modalProps={{
					workspaceDataId,
					stimulusInIvsId,
					name,
					plateId,
				}}
			/>
		</Styled.CardContent>
	);
}
