export const labels = [0, 1, 2];

export const borderCase = [true, false];

export const strengthResponse = [
	'N/A',
	'No Response',
	'Very weak',
	'Weak',
	'Medium',
	'Strong',
	'Very strong',
];

export const includeInTraining = [true, false];
