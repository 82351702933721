import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as globalTypes from 'core/store/globalTypes';
import {
	getUserWorkspaces,
	login,
	logout,
	register,
	connectUserToWorkspace,
	getClusters,
	getAdminWorkspaces,
} from 'core/services/user';
import { EMAIL_VERIFICATION as EMAIL_VERIFICATION_ROUTE } from 'core/routes';

import {
	LOGIN,
	LOGOUT,
	REGISTER,
	USER_LOGIN_FAILED,
	USER_CONNECTED_TO_WORKSPACE,
	USER_FIRST_LOGIN_SUCCEEDED,
	USER_REGISTER_FAILED,
	USER_LOGOUT_SUCCEEDED,
	USER_REGISTER_SUCCEEDED,
	USER_LOGOUT_FAILED,
	USER_WITH_NO_WORKSPACE,
	CONNECT_USER_TO_WORKSPACE,
	SAVE_USER_WORKSPACES,
	SAVE_USER_CLUSTERS,
	USER_CLUSTERS_ERROR,
	userSelector,
	CONNECT_ADMIN_TO_PORTAL,
	DISCONNECT_FROM_WORKSPACE,
} from './';
import {
	ROLES,
	ADMIN_PORTAL_WORKSPACE_ID,
	userRoleKey,
} from 'core/consts/roles';

function* registerSaga(action) {
	try {
		const response = yield call(register, action.payload.data);
		const { history } = action.payload;
		yield put({
			type: USER_REGISTER_SUCCEEDED,
			payload: response,
		});
		history.push(EMAIL_VERIFICATION_ROUTE);
	} catch (e) {
		yield put({ type: USER_REGISTER_FAILED, error: e.message });
	}
}

function* logoutSaga() {
	try {
		const response = yield call(logout);
		yield put({ type: USER_LOGOUT_SUCCEEDED, payload: response });
	} catch (e) {
		yield put({ type: USER_LOGOUT_FAILED, error: e.message });
	}
	yield put({ type: globalTypes.RESET_STATE });
}

export function* loginSaga(action) {
	try {
		const loginResponse = yield call(login, action.payload);
		const { accessToken: token, ...rest } = loginResponse;
		const userRole = loginResponse.claims[userRoleKey];

		yield put({
			type: USER_FIRST_LOGIN_SUCCEEDED,
			payload: { token, userRole, ...rest },
		});
	} catch (e) {
		yield put({ type: USER_LOGIN_FAILED, error: e.message });
	}
}

//TODO  replace object to action creator
export function* connectUserToWorkspaceSaga(action) {
	const { workspaceId } = action.payload;
	const { userId } = yield select(userSelector);
	yield put({
		type: DISCONNECT_FROM_WORKSPACE,
	});
	if (workspaceId !== ADMIN_PORTAL_WORKSPACE_ID) {
		const connectionResponse = yield call(
			connectUserToWorkspace,
			userId,
			workspaceId
		);
		const { accessToken: token, ...rest } = connectionResponse;
		return yield put({
			type: USER_CONNECTED_TO_WORKSPACE,
			payload: {
				token,
				...rest,
			},
		});
	}
	return yield put({
		type: CONNECT_ADMIN_TO_PORTAL,
	});
}

export function* getUserWorkspacesSaga(action) {
	const { userId, claims } = yield select(userSelector);
	const userRole = claims[userRoleKey];
	if (userRole !== ROLES.admin) {
		const workspaces = yield call(getUserWorkspaces, userId);
		if (!!workspaces.length) {
			return yield put({ type: SAVE_USER_WORKSPACES, workspaces });
		}
		yield put({ type: USER_WITH_NO_WORKSPACE });
	} else {
		const workspaces = yield call(getAdminWorkspaces);
		return yield put({ type: SAVE_USER_WORKSPACES, workspaces });
	}
}

export function* getWorkspaceClustersSaga(action) {
	try {
		const clusters = yield call(getClusters);
		yield put({
			type: SAVE_USER_CLUSTERS,
			payload: {
				clusters,
			},
		});
	} catch (error) {
		yield put({ type: USER_CLUSTERS_ERROR, error });
	}
}

export default function* authSaga() {
	yield takeLatest(LOGIN, loginSaga);
	yield takeLatest(LOGOUT, logoutSaga);
	yield takeLatest(REGISTER, registerSaga);
	yield takeLatest(USER_FIRST_LOGIN_SUCCEEDED, getUserWorkspacesSaga);
	yield takeLatest(CONNECT_USER_TO_WORKSPACE, connectUserToWorkspaceSaga);
	yield takeLatest(USER_CONNECTED_TO_WORKSPACE, getWorkspaceClustersSaga);
}
