import { createSelector } from 'reselect';
import format from 'string-template';
import {
	fetchInit,
	getData,
	getNamespace,
	getLoadingState,
} from 'core/store/repository';
import { projectIdSelector } from 'core/store/auth';
import * as namespaces from 'core/consts/namespaces';
import {
	PREDICTION_DATA_URL,
	PREDICTION_DATA_IN_VITRO,
	PREDICTION_DATA_EX_VIVO,
	PREDICTIONS,
	PD_CHECK_SUMMARY,
	PD_PREPROCESSING_SUMMARY,
	PREDICTION_RESULTS,
	SINGLE_PREDICTION,
	SINGLE_PREDICTION_PATCH,
	SINGLE_PREDICTION_DATA,
	REVIEWS,
	REVIEW,
	REVIEWS_BY_PREDICTION,
	PREDEFINED_COMMENTS,
	EVALUATIONS,
	SUBMIT_REVIEW,
	CLOSE_REVIEW,
	PREDICTION_RESULTS_FAMILY,
	ASSIGN_TO_ZERO,
	ASSIGN_TO_TNTC,
	PREVIEW_SPOT_COUNT,
	SPOT_PARAMETERES,
	FULL_PLATE,
	COMPACT_VIEW,
	APPROVE_EVALUATIONS,
	PREDICTION_SETTINGS,
	HIDE_PREDICTION_MODAL,
	CHECK_PREDICTION_DATA_NAME_EXISTENCE,
	PREDICTION_DATA_FILE_URL,
} from 'core/consts/endpoints';

export function fetchPredictionData() {
	return fetchInit({
		url: PREDICTION_DATA_URL,
		namespace: namespaces.PREDICTION_DATA,
		selector: projectIdSelector,
	});
}

export function fetchInVitroPredictionData() {
	return fetchInit({
		url: PREDICTION_DATA_IN_VITRO,
		namespace: namespaces.PREDICTION_DATA_IN_VITRO,
		selector: projectIdSelector,
	});
}
export function fetchExVivoPredictionData() {
	return fetchInit({
		url: PREDICTION_DATA_EX_VIVO,
		namespace: namespaces.PREDICTION_DATA_EX_VIVO,
		selector: projectIdSelector,
	});
}

export function fetchPredictions() {
	return fetchInit({
		url: PREDICTIONS,
		namespace: namespaces.PREDICTIONS,
		selector: projectIdSelector,
	});
}

export function fetchPrediction(predictionId) {
	return fetchInit({
		url: SINGLE_PREDICTION,
		namespace: namespaces.SINGLE_PREDICTION,
		selector: createSelector(projectIdSelector, ({ projectId }) => ({
			projectId,
			predictionId,
		})),
	});
}

export function createPredictionData(data) {
	return fetchInit({
		url: PREDICTION_DATA_URL,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_PREDICTION_DATA,
		successCb: fetchPredictionData,
		selector: projectIdSelector,
	});
}

export function createPrediction(data) {
	return fetchInit({
		url: PREDICTIONS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_PREDICTION,
		successCb: fetchPredictions,
		selector: projectIdSelector,
	});
}

export function deletePrediction(predictionId) {
	const namespace = format(namespaces.DELETED_PREDICTION, {
		id: predictionId,
	});

	return fetchInit({
		url: SINGLE_PREDICTION,
		namespace,
		config: { method: 'delete' },
		selector: createSelector(projectIdSelector, ({ projectId }) => ({
			projectId,
			predictionId,
		})),
		successCb: fetchPredictions,
	});
}

export function deletePredictionData(predictionDataId) {
	const namespace = format(namespaces.DELETED_PREDICTION_DATA, {
		id: predictionDataId,
	});

	return fetchInit({
		url: SINGLE_PREDICTION_DATA,
		namespace,
		config: { method: 'delete' },
		selector: createSelector(projectIdSelector, ({ projectId }) => ({
			projectId,
			predictionDataId,
		})),
		successCb: fetchPredictionData,
	});
}

export function checkPredictionDataExistence(name) {
	return fetchInit({
		url: CHECK_PREDICTION_DATA_NAME_EXISTENCE,
		namespace: namespaces.CHECK_PREDICTION_DATA_NAME_EXISTENCE,
		selector: createSelector(projectIdSelector, ({ projectId }) => ({
			projectId,
			name,
		})),
	});
}

export function checkPredictionDataSummary(predictionDataId) {
	const namespace = format(namespaces.PREDICTION_DATA_SUMMARY, {
		id: predictionDataId,
	});
	const url = format(PD_CHECK_SUMMARY, { predictionDataId });
	return fetchInit({
		url,
		namespace,
	});
}

export function fetchPDPreprocessingSummary(predictionDataId) {
	const namespace = format(namespaces.PREDICTION_DATA_SUMMARY, {
		id: predictionDataId,
	});
	const url = format(PD_PREPROCESSING_SUMMARY, { predictionDataId });
	return fetchInit({
		url,
		namespace,
	});
}

export function fetchPredictionsResults(predictionId) {
	const url = format(PREDICTION_RESULTS, { predictionId });
	return fetchInit({
		url,
		namespace: namespaces.PREDICTION_RESULTS,
	});
}

export function fetchPredictionsResultsFamily(
	predictionId,
	experimentUniqueId
) {
	const url = format(PREDICTION_RESULTS_FAMILY, {
		predictionId,
		experimentUniqueId,
	});
	return fetchInit({
		url,
		namespace: namespaces.PREDICTION_RESULTS_FAMILY,
	});
}

export function patchPredictionExperiment(predictionId, data) {
	const url = format(SINGLE_PREDICTION_PATCH, { predictionId });
	return fetchInit({
		url,
		config: { method: 'PATCH', data },
		namespace: namespaces.SINGLE_PREDICTION_PATCH,
		autoClear: true,
		successCb: () => fetchPredictionsResults(predictionId),
	});
}

export function fetchPredictionDataById(predictionDataId) {
	return fetchInit({
		url: SINGLE_PREDICTION_DATA,
		namespace: namespaces.SINGLE_PREDICTION_DATA,
		selector: createSelector(projectIdSelector, ({ projectId }) => ({
			projectId,
			predictionDataId,
		})),
	});
}

export function fetchReviews(predictionId) {
	const url = format(REVIEWS_BY_PREDICTION, { predictionId });
	return fetchInit({
		url,
		namespace: namespaces.REVIEWS,
	});
}

export function createReview(data) {
	return fetchInit({
		url: REVIEWS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_REVIEW,
		autoClear: true,
		successCb: () => fetchReviews(data.predictionId),
	});
}

export function updateReview(data, predictionId) {
	const { reviewId } = data;
	const url = format(REVIEW, { reviewId });
	return fetchInit({
		url,
		config: {
			method: 'patch',
			data,
		},
		namespace: namespaces.UPDATED_REVIEW,
		autoClear: true,
		successCb: () => fetchReviews(predictionId),
	});
}

function customFetchEvaluations(predictionId, expertLabelUid) {
	return fetchInit({
		url: EVALUATIONS,
		namespace: namespaces.EVALUATIONS,
		config: {
			params: { predictionId, expertLabelUid },
		},
		successCb: () => fetchReviews(predictionId),
	});
}

export function createEvaluation(data) {
	return fetchInit({
		url: EVALUATIONS,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.NEW_ADDED_EVALUATION,
		autoClear: true,
		successCb: () =>
			customFetchEvaluations(data.predictionId, data.expertLabelUid),
	});
}

export function updateEvaluation(data, predictionId, expertLabelUid) {
	const namespace = format(namespaces.UPDATED_EVALUATION, {
		id: data.reviewId,
	});

	return fetchInit({
		url: EVALUATIONS,
		config: {
			method: 'patch',
			data,
		},
		namespace,
		successCb: () => fetchEvaluations(predictionId, expertLabelUid),
	});
}

export function fetchEvaluations(predictionId, expertLabelUid) {
	return fetchInit({
		url: EVALUATIONS,
		namespace: namespaces.EVALUATIONS,
		config: {
			params: { predictionId, expertLabelUid },
		},
	});
}

export function fetchPredifinedComments() {
	return fetchInit({
		url: PREDEFINED_COMMENTS,
		namespace: namespaces.PREDEFINED_COMMENTS,
	});
}

export function submitReview(data, predictionId) {
	return fetchInit({
		url: SUBMIT_REVIEW,
		config: {
			method: 'post',
			data,
		},
		namespace: namespaces.SUBMITTED_REVIEW,
		autoClear: true,
		successCb: () => fetchReviews(predictionId),
	});
}

export function closeReview(reviewId, predictionId) {
	return fetchInit({
		url: CLOSE_REVIEW,
		config: {
			method: 'post',
			params: { reviewId },
		},
		namespace: namespaces.CLOSED_REVIEW,
		autoClear: true,
		successCb: () => fetchReviews(predictionId),
	});
}

export function deleteReview(reviewId, predictionId) {
	const url = format(REVIEW, { reviewId });
	return fetchInit({
		url,
		config: {
			method: 'delete',
		},
		namespace: namespaces.DELETED_REVIEW,
		autoClear: true,
		successCb: () => fetchReviews(predictionId),
	});
}

export function deleteEvaluation(data, predictionId, expertLabelUid) {
	return fetchInit({
		url: EVALUATIONS,
		config: {
			method: 'delete',
			data,
		},
		namespace: namespaces.DELETED_EVALUATION,
		autoClear: true,
		successCb: () => fetchEvaluations(predictionId, expertLabelUid),
	});
}

export function approveEvaluation(data, predictionId, expertLabelUid) {
	return fetchInit({
		url: APPROVE_EVALUATIONS,
		config: {
			method: 'patch',
			data,
		},
		namespace: namespaces.APPROVED_EVALUATION,
		autoClear: true,
		successCb: () => fetchEvaluations(predictionId, expertLabelUid),
	});
}

export function fetchCompactView(predictionId, experimentId) {
	const url = format(COMPACT_VIEW, {
		predictionId,
		experimentId,
	});
	return fetchInit({
		url,
		namespace: namespaces.COMPACT_VIEW,
		autoClear: false,
	});
}

export function fetchFullPlate(predictionId, plateId) {
	const url = format(FULL_PLATE, { predictionId, plateId });
	return fetchInit({
		url,
		namespace: namespaces.PREDICTION_FULL_PLATE,
	});
}

export function applyPredictionSettings(predictionId) {
	const url = format(PREDICTION_SETTINGS, { predictionId });
	return fetchInit({
		url,
		config: {
			method: 'patch',
		},
		namespace: namespaces.PREDICTION_SETTINGS,
		successCb: () => fetchPrediction(predictionId),
	});
}

export function hidePredictionSettingsModal(predictionId) {
	const url = format(HIDE_PREDICTION_MODAL, { predictionId });
	return fetchInit({
		url,
		config: {
			method: 'patch',
		},
		namespace: namespaces.HIDE_PREDICTION_MODAL,
		successCb: () => fetchPrediction(predictionId),
	});
}

export function fetchPredictionDataFileUrl(predictionDataId, fileName) {
	const namespace = namespaces.PREDICTION_DATA_FILE_URL;
	const url = format(PREDICTION_DATA_FILE_URL, {
		predictionDataId,
		fileName,
	});
	return fetchInit({
		url,
		namespace,
	});
}

export const reviewsSelector = getData(namespaces.REVIEWS);
export const predefinedCommentsSelector = getData(
	namespaces.PREDEFINED_COMMENTS
);

export const inProgressReviewSelector = createSelector(
	reviewsSelector,
	reviews => {
		if (reviews) {
			return reviews.find(({ status }) => status === 'InProgress');
		}
	}
);

export const readyPredictionDataInVitroSelector = getData(
	namespaces.PREDICTION_DATA_IN_VITRO
);

export const readyPredictionDataExVivoSelector = getData(
	namespaces.PREDICTION_DATA_EX_VIVO
);

export const predictionDataSelector = getData(namespaces.PREDICTION_DATA);
export const predictionsSelector = getData(namespaces.PREDICTIONS);

export const addedPredictionDataSelector = getNamespace(
	namespaces.NEW_ADDED_PREDICTION_DATA
);

export const addedPredictionSelector = getNamespace(
	namespaces.NEW_ADDED_PREDICTION
);

export const predictionDataSummarySelector = id => {
	const namespace = format(namespaces.PREDICTION_DATA_SUMMARY, { id });
	return getData(namespace, {});
};

export const predictionResultsSelector = createSelector(
	getData(namespaces.PREDICTION_RESULTS),
	data => {
		try {
			return JSON.parse(data.results);
		} catch (e) {
			return null;
		}
	}
);

export const predictionResultsFamliySelector = createSelector(
	getData(namespaces.PREDICTION_RESULTS_FAMILY),
	data => {
		try {
			return JSON.parse(data.results);
		} catch (e) {
			return null;
		}
	}
);

export function previewSpotCount(data, predictionId) {
	const url = format(PREVIEW_SPOT_COUNT, {
		predictionId,
	});
	return fetchInit({
		url,
		config: {
			method: 'POST',
			data,
		},
		namespace: namespaces.PREVIEW_SPOT_COUNT,
		autoClear: false,
	});
}

export function fetchSpotParameters(predictionId, experimentId, imageKey) {
	const url = format(SPOT_PARAMETERES, {
		predictionId,
		experimentId,
		imageKey,
	});
	return fetchInit({
		url,
		namespace: namespaces.SPOT_PARAMETERES,
		autoClear: false,
	});
}

export function assignToZero(data) {
	const url = format(ASSIGN_TO_ZERO, {
		predictionId: data.predictionId,
	});
	return fetchInit({
		url,
		config: {
			method: 'PATCH',
			data,
		},
		namespace: namespaces.ASSIGN_TO_ZERO_PATCH,
		autoClear: true,
		successCb: () =>
			fetchPredictionsResultsFamily(data.predictionId, data.experimentId),
	});
}

export function assignToTntc(data) {
	const url = format(ASSIGN_TO_TNTC, {
		predictionId: data.predictionId,
	});
	return fetchInit({
		url,
		config: {
			method: 'PATCH',
			data,
		},
		namespace: namespaces.ASSIGN_TO_TNTC_PATCH,
		autoClear: true,
		successCb: () =>
			fetchPredictionsResultsFamily(data.predictionId, data.experimentId),
	});
}

export const experimentDetailsSelector = experimentId =>
	createSelector(predictionResultsSelector, results => {
		if (results) {
			return results.find(({ Unique_ID }) => Unique_ID === experimentId);
		}
	});

export const experimentSelector = experiment =>
	createSelector(predictionResultsSelector, results => {
		if (results) {
			return results.filter(
				result =>
					result['Plate Number'] === experiment['Plate Number'] &&
					result['Plate Barcode'] === experiment['Plate Barcode'] &&
					result['Stimulus in IVS'] === experiment['Stimulus in IVS']
			);
		}
	});
export const predictionSelector = getData(namespaces.SINGLE_PREDICTION, {});

export const getPredictionByTrainingName = trainingName =>
	createSelector(predictionsSelector, predictions =>
		predictions.find(prediction => prediction.trainingName === trainingName)
	);

export const predictionloadingSelector = getLoadingState(
	namespaces.PREDICTIONS
);

export const predictionResultsloadingSelector = getLoadingState(
	namespaces.PREDICTION_RESULTS
);

export const predictionDataloadingSelector = getLoadingState(
	namespaces.PREDICTION_DATA
);
