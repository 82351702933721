import React from 'react';
import { Form } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import { CheckFields, FORM_FIELDS } from './consts/trainingConfigFields';
import * as Styled from './styled';

export default function TrainingConfigSubForm({ trainingParams }) {
	return (
		<Styled.Content>
			<b>Training Configuration</b>
			<Styled.Fields>
				<Styled.CheckContainer key={FORM_FIELDS.radius}>
					<Form.Label>Radius:</Form.Label>
					<Field
						showValid={false}
						name={FORM_FIELDS.radius}
						component={InputAdapter}
						type='number'
						min='0'
						max='100'
					/>
				</Styled.CheckContainer>
				<Styled.CheckContainer key={FORM_FIELDS.gpus}>
					<Form.Label>GPUs :</Form.Label>
					<Field
						showValid={false}
						name={FORM_FIELDS.gpus}
						component={InputAdapter}
						type='number'
					/>
				</Styled.CheckContainer>
				<Styled.CheckContainer key={FORM_FIELDS.mcDropoutRuns}>
					<Form.Label>MC Dropout Runs</Form.Label>
					<Field
						showValid={false}
						name={FORM_FIELDS.mcDropoutRuns}
						component={InputAdapter}
						type='number'
						min='1'
						max='100'
					/>
				</Styled.CheckContainer>
				<Styled.CheckContainer key={FORM_FIELDS.earlyStoppingPatience}>
					<Form.Label>Early Stopping Patience</Form.Label>
					<Field
						showValid={false}
						name={FORM_FIELDS.earlyStoppingPatience}
						component={InputAdapter}
						type='number'
						min='0'
						max='20'
					/>
				</Styled.CheckContainer>
				{CheckFields.map(field => (
					<Field name={field.name} type='checkbox' key={field.name}>
						{({ input }) => (
							<Styled.CheckContainer>
								<Form.Check {...input} label={field.label} />
							</Styled.CheckContainer>
						)}
					</Field>
				))}
			</Styled.Fields>
		</Styled.Content>
	);
}
