import React, { useMemo } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useFormState } from 'react-final-form';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectAdapter, Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import { EXVIVO, INVITRO } from 'core/consts';
import * as Styled from '../styled';

export default function AddNewPredictionForm({
	handleSubmit,
	readyPredictionDataInVitro,
	readyPredictionDataExVivo,
	loading,
	closeModal,
}) {
	const dataTypeOptions = [
		{
			label: INVITRO,
			value: INVITRO,
		},
		{
			label: EXVIVO,
			value: EXVIVO,
		},
	];
	const { values } = useFormState();

	const predictionDataOptions = useMemo(() => {
		if (!!values?.dataType) {
			if (values.dataType === EXVIVO) {
				return readyPredictionDataExVivo.map(
					({ predictionDataId, name }) => ({
						label: name,
						value: predictionDataId,
					})
				);
			}
			if (values.dataType === INVITRO) {
				return readyPredictionDataInVitro.map(
					({ predictionDataId, name }) => ({
						label: name,
						value: predictionDataId,
					})
				);
			}
		}
	}, [values, readyPredictionDataExVivo, readyPredictionDataInVitro]);
	const isDataTypeSelected = useMemo(() => {
		return !!values?.dataType;
	}, [values]);
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<Row>
					<Col>
						<Form.Label>
							<b>Name</b>
						</Form.Label>
						<Field
							showValid={false}
							name='name'
							placeholder='Type a name for the prediction'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Data Type</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name='dataType'
							validate={validateRequired()}
							options={dataTypeOptions}
							placeholder='Select Data'
						/>
					</Col>
				</Row>
				<When condition={!!isDataTypeSelected}>
					<Row>
						<Col>
							<Form.Label>
								<b>Choose Data</b>
							</Form.Label>
							<Field
								showValid={false}
								component={SelectAdapter}
								name='predictionDataId'
								validate={validateRequired()}
								options={predictionDataOptions}
								placeholder='Select Data'
							/>
						</Col>
					</Row>
				</When>
			</Styled.Content>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={closeModal}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Run prediction</span>
						<FontAwesomeIcon icon={faPlay} />
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
