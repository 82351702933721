import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import format from 'string-template';
import { Modal } from 'react-bootstrap';
import { FormContainer } from 'components/Form';
import { When } from 'components/When';
import {
	fetchPredifinedComments,
	predefinedCommentsSelector,
	updateEvaluation,
} from 'core/store/predictions';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import UpdateEvaluationForm from './UpdateEvaluationForm';
import * as Styled from '../styled';

export default function UpdateEvaluationModal({
	closeModal,
	label,
	comment,
	borderCase,
	responseStrength,
	subject,
	reviewId,
	evaluationId,
	Unique_ID,
	predictionId,
	includeInTraining,
}) {
	const dispatch = useDispatch();
	const predefinedComments = useSelector(predefinedCommentsSelector);
	const predefinedCommentsOptions = predefinedComments?.map(v => ({
		label: v,
		value: v,
	}));

	useEffect(() => {
		return dispatch(fetchPredifinedComments());
	}, [dispatch]);

	const handleUpdateEvaluation = ({
		label,
		borderCase,
		responseStrength,
		subject,
		comment,
		includeInTraining,
	}) => {
		const evaluationData = {
			borderCase: borderCase.toString() === 'true' ? true : false,
			label,
			responseStrength,
			subject,
			comment: !!comment ? comment : '',
			reviewId,
			evaluationId,
			includeInTraining,
		};
		dispatch(updateEvaluation(evaluationData, predictionId, Unique_ID));
	};
	const namespace = format(namespaces.UPDATED_EVALUATION, {
		id: reviewId,
	});
	const { error, trace, loading } = useNamespace({
		namespace,
		onSuccess: closeModal,
	});

	const initialFormState = {
		label,
		comment,
		borderCase,
		responseStrength,
		subject,
		includeInTraining,
	};

	return (
		<FormContainer
			formID='start-prediction'
			onSubmit={handleUpdateEvaluation}
			initialValues={initialFormState}
			component={props => (
				<UpdateEvaluationForm
					{...props}
					commentOptions={predefinedCommentsOptions}>
					<When condition={error}>
						<Styled.Alert variant='danger'>{trace}</Styled.Alert>
					</When>
					<Modal.Footer>
						<Styled.ButtonWrapper>
							<Styled.CancelButton
								variant='danger'
								onClick={closeModal}>
								Cancel
							</Styled.CancelButton>
							<Styled.ModalSaveButton
								variant='success'
								type='submit'
								loading={loading}>
								<span>Validate</span>
							</Styled.ModalSaveButton>
						</Styled.ButtonWrapper>
					</Modal.Footer>
				</UpdateEvaluationForm>
			)}
		/>
	);
}
