import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { INPUT_FILE_GENERATION } from 'core/routes';
import * as Styled from '../styled';

interface Props {
	Buttons: {
		active: boolean;
		view: string;
		title: string;
		onClick?: () => void;
	}[];
	disabled?: boolean;
}

export default function ToggleButtons({ Buttons, disabled = false }: Props) {
	const history = useHistory();
	const { workspaceDataId }: { workspaceDataId: string } = useParams();
	const handlNavButtonClick = (view: string, onClick?: (view) => void) => {
		if (!!onClick) {
			return onClick(view);
		}
		return history.push(
			`${INPUT_FILE_GENERATION}/${workspaceDataId}?view=${view}`
		);
	};
	return (
		<Styled.StepsContainer>
			<Styled.ButtonGroup aria-label='Input Generation Nav'>
				{Buttons.map(({ active, view, title, onClick }) => (
					<Styled.ToggleButton
						key={view}
						variant='secondary'
						active={active}
						onClick={() => handlNavButtonClick(view, onClick)}
						disabled={disabled}>
						{title}
					</Styled.ToggleButton>
				))}
			</Styled.ButtonGroup>
		</Styled.StepsContainer>
	);
}
