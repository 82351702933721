import React, { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { When } from 'components/When';

const ValidationErrorAlert = ({
	hasValidationErrors,
	submitFailed,
	msg = 'Please fix the highlighted errors above.',
}) => (
	<Fragment>
		<When condition={hasValidationErrors && submitFailed}>
			<Alert variant='danger'>{msg}</Alert>
		</When>
	</Fragment>
);

export default ValidationErrorAlert;
