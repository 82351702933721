import styled from 'styled-components';

export const Container = styled.div`
	margin: 20px;
	padding: 20px;
	background-color: ${p => p.theme.white};
	max-height: 85vh;
	overflow: auto;
`;

export const FormWrapper = styled.div`
	display: flex;
`;
