import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
	color: ${p => p.theme.green_2};
	text-decoration: none;
	&:hover {
		color: ${p => p.theme.primary};
	}
`;
