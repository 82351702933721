import styled from 'styled-components';

export const Layout = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	display: flex;
	position: sticky;
	background-color: ${p => p.theme.primaryDark};
	top: 0;
	height: 55px;
`;

export const Content = styled.div`
	display: flex;
	margin: auto;
`;
