import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faPauseCircle,
	faClock,
	faExclamationCircle,
	faStopCircle,
} from '@fortawesome/free-solid-svg-icons';

export function StatusIcon({ status }) {
	const theme = useTheme();

	const iconProps = useMemo(() => {
		switch (status) {
			case 'Succeeded':
			case 'Ready':
				return { icon: faCheckCircle, color: theme.green };
			case 'Created':
				return { icon: faPauseCircle, color: theme.green_2 };
			case 'Checking':
			case 'Preprocessing':
			case 'Processing':
			case 'Training':
			case 'Evaluating':
				return { icon: faClock, color: theme.yellow };
			case 'Failed':
			case 'Invalid':
				return { icon: faExclamationCircle, color: theme.red_2 };
			case 'stopped':
				return { icon: faStopCircle, color: theme.gray_2 };
			default:
				return { icon: faExclamationCircle, color: theme.red_2 };
		}
	}, [status, theme]);

	return <FontAwesomeIcon {...iconProps} />;
}
