import React, { useMemo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { PREDICTIONS } from 'core/routes';
import { fetchPredictions } from 'core/store/predictions';
import { CHANNELS } from 'core/consts/pusherConfig';
import * as namespaces from 'core/consts/namespaces';
import { formatDateAndTime } from 'helpers';
import { Table } from 'components/Table';
import { ModalProvider } from 'components/Modal';
import { Button } from 'components/Button';
import { StatusIcon } from 'components/StatusIcon';
import { When } from 'components/When';
import PusherMessage from 'components/PusherMessage';
import { StatusFilter } from 'components/Filters/StatusFilter';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import {
	dateBetweenFilterFn,
	DateFilter,
} from 'components/Filters/DateFilter/DateFilter';
import PredictionDeleteModal from './DeleteModals/PredictionDeleteModal';
import TrainingDetailsModal from './TrainingDetailsModal';
import * as Styled from '../styled';
import { PredictionResultEntry } from '../types';

interface Props {
	data: PredictionResultEntry[];
	loading: boolean;
	projectId: string;
}

export default function PredictionsResultsTable({
	data,
	loading,
	projectId,
}: Props) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const history = useHistory();
	const handleRowClick = ({ original: { predictionId, status } }) => {
		if (status === 'Succeeded')
			history.push(`${PREDICTIONS}/${predictionId}`);
	};

	const refreshPredictions = ({ projectId: receivedProjectId }) => {
		if (projectId === receivedProjectId) {
			return dispatch(fetchPredictions());
		}
		return;
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Status',
				accessor: 'status',
				disableSortBy: true,
				Filter: props =>
					StatusFilter({
						...props,
						filterType: 'prediction',
					}),
				Cell: ({ value }) => <StatusIcon status={value} />,
			},
			{
				Header: 'Review Status',
				accessor: 'reviewStatus',
				disableSortBy: true,
				Filter: props =>
					StatusFilter({
						...props,
						filterType: 'review',
					}),
				Cell: ({ value }) => (
					<Fragment>
						<When condition={value === 'UnderReview'}>
							<Styled.UnderReviewStatus>
								Under Review
							</Styled.UnderReviewStatus>
						</When>
						<When condition={value === 'NotReviewed'}>
							<Styled.NotReviewedStatus>
								Not Reviewed
							</Styled.NotReviewedStatus>
						</When>
						<When condition={value === 'Reviewed'}>
							<Styled.ReviewedStatus>
								Reviewed
							</Styled.ReviewedStatus>
						</When>
					</Fragment>
				),
			},
			{
				Header: 'Name',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.PREDICTIONS,
					}),
				filter: multiSelectFilter,
				accessor: 'predictionName',
			},
			{
				Header: 'Data',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.PREDICTIONS,
					}),
				filter: multiSelectFilter,
				accessor: 'predictionDataName',
			},
			{
				Header: 'User',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.PREDICTIONS,
					}),
				filter: multiSelectFilter,
				accessor: 'userName',
			},
			{
				Header: 'Model',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.PREDICTIONS,
					}),
				filter: multiSelectFilter,
				accessor: 'trainingModel',
			},
			{
				Header: 'Training Name',
				accessor: 'trainingName',
				disableFilters: true,
				Cell: ({
					row: {
						original: { trainingName: name },
					},
				}) => (
					<ModalProvider
						title='Taining Details'
						component={TrainingDetailsModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							name,
						}}
						trigger={<Button variant='link'>{name}</Button>}
					/>
				),
			},
			{
				Header: 'Time Created',
				accessor: 'startedOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
			},
			{
				Header: 'Time Finished',
				accessor: 'finishedOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) =>
					value && <span>{formatDateAndTime(value)}</span>,
			},
			{
				id: 'logs',
				disableSortBy: true,
				disableFilters: true,
			},
			{
				id: 'delete',
				disableSortBy: true,
				disableFilters: true,
				width: 25,
				Cell: ({
					row: {
						original: { predictionId, predictionName },
					},
				}) => (
					<ModalProvider
						title='Confirm Deletion of Prediction'
						component={PredictionDeleteModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							id: predictionId,
							name: predictionName,
						}}
						trigger={
							<FontAwesomeIcon
								icon={faTrashAlt}
								color={theme.red_4}
							/>
						}
					/>
				),
			},
		],
		[theme]
	);

	return (
		<Styled.Container>
			<PusherMessage
				callback={refreshPredictions}
				event={CHANNELS.predictions}
			/>
			<Table
				columns={columns}
				data={data}
				onRowClick={handleRowClick}
				loading={loading}
				withFilters={!!data.length}
			/>
		</Styled.Container>
	);
}
