import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { Modal } from 'react-bootstrap';
import JSZip from 'jszip';
import format from 'string-template';
import { handleResumableUpload } from 'helpers';
import {
	RESUMABLE_PREDICTION_DATA_IMAGES,
	RESUMABLE_PREDICTION_DATA_PLATELAYOUT,
	RESUMABLE_PREDICTION_DATA_SAMPLE,
	RESUMABLE_PREDICTION_DATA_TARGET,
	RESUMABLE_PREDICTION_DATA_FACS,
} from 'core/consts/endpoints';
import {
	createPredictionData,
	checkPredictionDataExistence,
} from 'core/store/predictions';
import { API_URL } from 'core/consts';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { When } from 'components/When';
import ErrorMessages from 'components/ErrorMessages';
import AddPredictionDataForm from 'containers/Predictions/components/AddPredictionDataForm';
import * as Styled from '../styled';

export default function AddPredictionDataModal({ closeModal }) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_ADDED_PREDICTION_DATA,
		onSuccess: closeModal,
	});
	const { data: isNameUsed = true, error: checkNameError } = useNamespace({
		namespace: namespaces.CHECK_PREDICTION_DATA_NAME_EXISTENCE,
	});
	const [predictionData, setPredictionData] = useState({
		name: '',
		isDisabled: false,
	});
	const [isNameEmpty, setIsNameEmpty] = useState(true);
	const [imagesPathUploadProgress, setImagesPathUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_PREDICTION_DATA_IMAGES}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const [plateLayoutUploadProgress, setPlateLayoutUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_PREDICTION_DATA_PLATELAYOUT}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const [sampleUploadProgress, setSampleUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_PREDICTION_DATA_SAMPLE}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const [targetUploadProgress, setTargetUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_PREDICTION_DATA_TARGET}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const [facsUploadProgress, setFacsUploadProgress] = useState({
		endpoint: `${API_URL}${RESUMABLE_PREDICTION_DATA_FACS}`,
		fileName: '',
		percentage: 0,
		bytesUploaded: '0 Byte',
		bytesTotal: '0 Byte',
		isProgressBarDisplayed: false,
		zipping: false,
		zipError: false,
		isUploaded: false,
	});
	const errors = !!trace
		? trace.split('-- ').filter(err => !!err.length)
		: [];
	const handleCreateData = () =>
		dispatch(createPredictionData({ name: predictionData.name }));

	const zipFile = (state, setState, target, endpoint): void => {
		const endpointUpload = `${format(endpoint, {
			name: predictionData.name.replaceAll(' ', '_'),
		})}`;
		setState({
			...state,
			zipping: true,
		});
		setPredictionData({ ...predictionData, isDisabled: true });
		const zip = new JSZip();
		const fileName =
			target.files[0].webkitRelativePath.split('/')[0] ||
			target.files[0].name.replace(/\.[^/.]+$/, '');
		for (let file = 0; file < target.files.length; file++) {
			zip.file(
				target.files[file].webkitRelativePath ||
					target.files[file].name,
				target.files[file]
			);
		}
		zip.generateAsync({
			type: 'blob',
			compression: 'DEFLATE',
			compressionOptions: {
				level: 7,
			},
		})
			.then(content => {
				if (content) {
					handleResumableUpload(
						content,
						fileName,
						endpointUpload,
						setState,
						state
					);
				}
			})
			.catch(() =>
				setState({
					...state,
					zipError: true,
				})
			);
	};

	const handleDebounceNameChange = debounce(nameValue => {
		if (!!nameValue) {
			setIsNameEmpty(false);
			dispatch(checkPredictionDataExistence(nameValue));
		} else {
			setIsNameEmpty(true);
		}
	}, 500);

	const handleNameChange = evt => {
		setPredictionData({
			...predictionData,
			name: evt.target.value.replace(/\s/g, ''),
		});
		handleDebounceNameChange(evt.target.value);
	};

	const isSaveChangesButtonActive =
		imagesPathUploadProgress.isUploaded &&
		sampleUploadProgress.isUploaded &&
		targetUploadProgress.isUploaded &&
		plateLayoutUploadProgress.isUploaded;
	const isFormDisabled = !isNameEmpty ? isNameUsed || checkNameError : true;
	return (
		<AddPredictionDataForm
			plateLayoutUploadProgress={plateLayoutUploadProgress}
			setPlateLayoutUploadProgress={setPlateLayoutUploadProgress}
			imagesPathUploadProgress={imagesPathUploadProgress}
			setImagesPathUploadProgress={setImagesPathUploadProgress}
			sampleUploadProgress={sampleUploadProgress}
			setSampleUploadProgress={setSampleUploadProgress}
			targetUploadProgress={targetUploadProgress}
			setTargetUploadProgress={setTargetUploadProgress}
			facsUploadProgress={facsUploadProgress}
			setFacsUploadProgress={setFacsUploadProgress}
			name={predictionData.name}
			isNameDisabled={predictionData.isDisabled}
			handleNameChange={handleNameChange}
			isFormDisabled={isFormDisabled}
			zipFile={zipFile}>
			<When condition={error}>
				<ErrorMessages errors={errors} />
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='danger' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.SaveButton
					variant='success'
					loading={loading}
					onClick={handleCreateData}
					disabled={!isSaveChangesButtonActive}>
					Save changes
				</Styled.SaveButton>
			</Modal.Footer>
		</AddPredictionDataForm>
	);
}
