import React from 'react';
import SelectWorkspace from 'containers/SelectWorkspace';
import NotFound from 'components/ErrorPages/NotFound';
import Predictions from 'containers/Predictions';
import TrainingsInVitro from 'containers/TrainingsInVitro';
import TrainingsExVivo from 'containers/TrainingsExVivo';
import NewInVitroTraining from 'containers/NewInVitroTraining';
import NewExVivoTraining from 'containers/NewExVivoTraining';
import TrainingInVitroDetails from 'containers/TrainingInVitroDetails';
import TrainingExVivoDetails from 'containers/TrainingExVivoDetails';
import PredictionDetails from 'containers/PredictionDetails';
import ExperimentDetails from 'containers/ExperimentDetails';
import InputFilesGeneration from 'containers/InputFilesGeneration';
import InputFilesGenerationDetails from 'containers/InputFilesGenerationDetails';
import PredictionDataDetails from 'containers/PredictionDataDetails';
import TrainingInVitroDataDetails from 'containers/TrainingInVitroDataDetails';
import TrainingExVivoDataDetails from 'containers/TrainingExVivoDataDetails';
import AdminLogin from 'containers/AdminLogin';
import { ACCESS_RIGHTS } from 'core/consts/roles';
import { hasAccessRight, isAdminPortalSelector } from 'core/store/auth';

const Workspaces = React.lazy(() => import('containers/Workspaces'));
const AdminWorkspaces = React.lazy(() => import('containers/AdminWorkspaces'));
const AdminUsers = React.lazy(() => import('containers/AdminUsers'));
const Home = React.lazy(() => import('containers/Home'));
const ResetPassword = React.lazy(() => import('containers/ResetPassword'));
const Login = React.lazy(() => import('containers/Login'));
const EmailVerification = React.lazy(() =>
	import('containers/EmailVerification')
);
const EmailConfirmation = React.lazy(() =>
	import('containers/EmailConfirmation')
);
const ServerErrorPage = React.lazy(() =>
	import('components/ErrorPages/ServerError')
);
const ForbiddenPage = React.lazy(() =>
	import('components/ErrorPages/Unauthorized')
);
const Faq = React.lazy(() => import('containers/Faq'));

export const WORKSPACE_HOME = '/home';
export const WORKSPACES = '/workspace';
export const SELECT_WORKSPACE = '/select-workspace';
export const ADMIN_LOGIN = '/admin-login';
export const PREDICTIONS = '/workspace/predictions';
export const TRAININGS_IN_VITRO = '/workspace/trainings-invitro';
export const TRAININGS_EX_VIVO = '/workspace/trainings-exvivo';
export const NEW_TRAINING_IN_VITRO = '/workspace/trainings-invitro/new';
export const NEW_TRAINING_EX_VIVO = '/workspace/trainings-exvivo/new';
export const TRAINING_IN_VITRO_DETAILS =
	'/workspace/trainings-invitro/:trainingId';
export const TRAINING_EX_VIVO_DETAILS =
	'/workspace/trainings-exvivo/:trainingId';
export const PREDICTION_DETAILS = '/workspace/predictions/:predictionId';
export const PREDICTION_DATA = '/workspace/predictionData';
export const TRAINING_IN_VITRO_DATA =
	'/workspace/trainings-invitro/trainingData';
export const TRAINING_EX_VIVO_DATA = '/workspace/trainings-exvivo/trainingData';
export const EXPERIMENT_DETAILS =
	'/workspace/predictions/:predictionId/:experimentId';
export const FAQ = '/faq';
export const LOGIN = '/login';
export const HOME = '/';
export const RESET_PASSWORD = '/reset-password/:token';
export const EMAIL_VERIFICATION = '/email-verif';
export const EMAIL_CONFIRMATION = '/confirm/:token';
export const INTERNAL_ERROR = '/500';
export const FORBIDDEN = '/403';
export const ADMIN_HOME = '/admin/workspaces';
export const ADMIN_USERS = '/admin/users';
export const INPUT_FILE_GENERATION = '/workspace/input-file-generation';

export interface RouteConfig {
	offLayout?: boolean;
	exact?: boolean;
	path: string;
	component: any;
	checkAccess?: Function;
	publicPath?: boolean;
}

export const routesConfig: RouteConfig[] = [
	{
		exact: true,
		path: LOGIN,
		component: Login,
		publicPath: true,
	},
	{
		exact: true,
		path: EMAIL_VERIFICATION,
		component: EmailVerification,
		publicPath: true,
	},
	{
		exact: true,
		path: EMAIL_CONFIRMATION,
		component: EmailConfirmation,
		publicPath: true,
	},
	{
		exact: true,
		path: RESET_PASSWORD,
		component: ResetPassword,
		publicPath: true,
	},
	{
		exact: true,
		path: SELECT_WORKSPACE,
		component: SelectWorkspace,
		offLayout: true,
	},
	{
		exact: true,
		path: ADMIN_LOGIN,
		component: AdminLogin,
		offLayout: true,
	},
	{
		exact: true,
		path: WORKSPACES,
		component: Workspaces,
	},
	{
		exact: true,
		path: WORKSPACE_HOME,
		component: Home,
	},
	{
		exact: true,
		path: FAQ,
		component: Faq,
		offLayout: true,
	},
	{
		exact: true,
		path: ADMIN_HOME,
		component: AdminWorkspaces,
		checkAccess: isAdminPortalSelector,
	},
	{
		exact: true,
		path: ADMIN_USERS,
		component: AdminUsers,
		checkAccess: isAdminPortalSelector,
	},
	{
		exact: true,
		path: PREDICTIONS,
		component: Predictions,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewPredictions),
	},
	{
		exact: true,
		path: TRAININGS_IN_VITRO,
		component: TrainingsInVitro,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewTrainings),
	},
	{
		exact: true,
		path: TRAININGS_EX_VIVO,
		component: TrainingsExVivo,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewTrainings),
	},
	{
		exact: true,
		path: NEW_TRAINING_IN_VITRO,
		component: NewInVitroTraining,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.runTrainings),
	},
	{
		exact: true,
		path: NEW_TRAINING_EX_VIVO,
		component: NewExVivoTraining,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.runTrainings),
	},
	{
		exact: true,
		path: TRAINING_IN_VITRO_DETAILS,
		component: TrainingInVitroDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewTrainings),
	},
	{
		exact: true,
		path: TRAINING_EX_VIVO_DETAILS,
		component: TrainingExVivoDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewTrainings),
	},
	{
		exact: true,
		path: PREDICTION_DETAILS,
		component: PredictionDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewPredictions),
	},
	{
		exact: true,
		path: `${PREDICTION_DATA}/:predictionDataId`,
		component: PredictionDataDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewPredictionsData),
	},
	{
		exact: true,
		path: `${TRAINING_IN_VITRO_DATA}/:trainingDataId`,
		component: TrainingInVitroDataDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewTrainingData),
	},
	{
		exact: true,
		path: `${TRAINING_EX_VIVO_DATA}/:trainingDataId`,
		component: TrainingExVivoDataDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewTrainingData),
	},
	{
		exact: true,
		path: EXPERIMENT_DETAILS,
		component: ExperimentDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewPredictions),
	},
	{
		exact: true,
		path: INPUT_FILE_GENERATION,
		component: InputFilesGeneration,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewPredictions),
	},
	{
		exact: true,
		path: `${INPUT_FILE_GENERATION}/:workspaceDataId`,
		component: InputFilesGenerationDetails,
		checkAccess: hasAccessRight(ACCESS_RIGHTS.viewPredictions),
	},

	{
		exact: true,
		path: INTERNAL_ERROR,
		component: ServerErrorPage,
		offLayout: true,
	},
	{
		exact: true,
		path: FORBIDDEN,
		component: ForbiddenPage,
		offLayout: true,
	},
	{
		path: '*',
		component: NotFound,
		offLayout: true,
		publicPath: true,
	},
];
