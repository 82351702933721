import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as namespaces from 'core/consts/namespaces';
import { updateSingleTarget } from 'core/store/InputGeneration';
import { Table } from 'components/Table';
import { When } from 'components/When';
import {
	SelectFilter,
	multiSelectFilter,
} from 'components/Filters/SelectFilter';
import * as Styled from '../styled';

interface DataEntry {
	targetId: string;
	target: string;
	patientId: string;
	stimulusInIvs: string;
	stimulusInReadout: string;
	sequenceInformation: string;
}

interface Props {
	data: DataEntry[];
	workspaceDataId: string;
	error: boolean;
	trace: string;
	invalidTargets: any[] | null;
	loading: boolean;
}

export default function TargetsTable({
	data,
	workspaceDataId,
	error,
	trace,
	invalidTargets,
	loading,
}: Props) {
	const dispatch = useDispatch();
	const handleInputMouseLeave = useCallback(
		(targetId, target, sequenceInformation) => {
			const data = { target, sequenceInformation };
			dispatch(updateSingleTarget(data, workspaceDataId, targetId));
		},
		[workspaceDataId, dispatch]
	);
	const columns = useMemo(
		() => [
			{
				Header: 'Subject ID',
				accessor: 'subject ID',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TARGETS,
					}),
				filter: multiSelectFilter,
				Cell: ({ value }) => {
					return value && <span>{value}</span>;
				},
			},
			{
				Header: 'Stimulus In IVS',
				accessor: 'stimulus In IVS',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TARGETS,
					}),
				filter: multiSelectFilter,
				Cell: ({ value }) => {
					return value && <span>{value}</span>;
				},
			},
			{
				Header: 'Stimulus In Readout',
				accessor: 'stimulus In Readout',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TARGETS,
					}),
				filter: multiSelectFilter,
				Cell: ({ value }) => {
					return value && <span>{value}</span>;
				},
			},
			{
				Header: 'Target',
				accessor: 'target',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TARGETS,
					}),
				filter: multiSelectFilter,
				Cell: ({ row: { original } }) => {
					const { target, targetId } = original;
					const sequenceInformation =
						original['sequence Information'];
					return (
						target && (
							<input
								type='text'
								defaultValue={target}
								onBlur={e =>
									handleInputMouseLeave(
										targetId,
										e.target?.value,
										sequenceInformation
									)
								}
							/>
						)
					);
				},
			},
			{
				Header: 'Sequence Information',
				accessor: 'sequence Information',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.TARGETS,
					}),
				filter: multiSelectFilter,
				Cell: ({ row: { original } }) => {
					const { target, targetId } = original;
					const sequenceInformation =
						original['sequence Information'];
					return (
						target && (
							<input
								type='text'
								defaultValue={sequenceInformation}
								onBlur={e =>
									handleInputMouseLeave(
										targetId,
										target,
										e.target?.value
									)
								}
							/>
						)
					);
				},
			},
		],
		// eslint-disable-next-line
		[handleInputMouseLeave, data]
	);

	return (
		<Styled.Content>
			<When condition={!!data}>
				<Table
					data={data}
					columns={columns}
					withPagination={true}
					withFilters={!!data?.length}
					pageSize={12}
					loading={loading}
				/>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
				<When condition={!!invalidTargets?.length}>
					<Styled.Alert variant='danger'>
						These combinations (Stimulus in Ivs/Stimulus in Readout)
						doesn't exist in the plate layout:
						{invalidTargets?.join(' -- ')}
					</Styled.Alert>
				</When>
			</When>
		</Styled.Content>
	);
}
