import React, { Fragment, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { LazyLog } from 'react-lazylog';
import { When } from 'components/When';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { Button } from 'components/Button';
import ErrorFallback from 'components/ErrorPages/ErrorFallback';
import { checkTrainingDataSummary } from 'core/store/trainingsExvivo';
import * as Styled from '../styled';

const TABS = [
	{ title: 'Info', eventKey: 'infoReport', key: 'info' },
	{ title: 'Warnings', eventKey: 'warningsReport', key: 'warnings' },
	{ title: 'Error', eventKey: 'errorsReport', key: 'errors' },
];

export default function TDSummaryLogsModal({ closeModal, id }) {
	const dispatch = useDispatch();
	const { data: reports = {} } = useNamespace({
		namespace: namespaces.TRAINING_DATA_SUMMARY,
	});

	useEffect(() => {
		dispatch(checkTrainingDataSummary(id));
	}, [dispatch, id]);

	const hasReports = useMemo(() => {
		return !!Object.values(reports).filter(r => !!r).length;
	}, [reports]);

	return (
		<Fragment>
			<When condition={hasReports}>
				<Styled.TabsContainer>
					<Tabs>
						{TABS.map(report => (
							<Tab {...report}>
								<Styled.LogsContainer>
									<When
										condition={!!reports[report.eventKey]}>
										<ErrorBoundary
											FallbackComponent={ErrorFallback}>
											<LazyLog
												enableSearch
												text={reports[report.eventKey]}
												caseInsensitive
											/>
										</ErrorBoundary>
									</When>
								</Styled.LogsContainer>
							</Tab>
						))}
					</Tabs>
				</Styled.TabsContainer>
			</When>
			<Modal.Footer>
				<Button variant='danger' onClick={closeModal}>
					Close
				</Button>
			</Modal.Footer>
		</Fragment>
	);
}
