import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Form, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faRetweet } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { takeScreenshot } from 'helpers';
import {
	fetchModelTroubleshooting,
	reloadModelTroubleshooting,
} from 'core/store/trainingsInVitro';
import { RangeSliderComponent } from 'components/RangeSlider';
import { BarChart } from 'components/BarChart';
import { When } from 'components/When';
import { base64StringToBlobUrl } from 'helpers';
import { modelTroubleshootingfields } from './consts';
import { fetchClear } from 'core/store/repository';
import * as namespaces from 'core/consts/namespaces';
import * as Styled from './styled';

export default function ModelTroubleShooting({ data, trainingId, loading }) {
	const dispatch = useDispatch();
	const [formFields, setFormFields] = useState<any[]>(
		modelTroubleshootingfields
	);
	const [isReloadData, setIsReloadData] = useState<boolean>(false);
	useEffect(() => {
		fetchInitData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleDebounceFormValuesChange = debounce((value, name) => {
		setIsReloadData(false);
		const ind = formFields.findIndex(item => item.name === name);
		const updatedObj = { ...formFields[ind], value: value };
		setFormFields([
			...formFields.slice(0, ind),
			updatedObj,
			...formFields.slice(ind + 1),
		]);
	}, 500);

	useEffect(() => {
		const data = {
			spotCount: formFields.find(c => c.name === 'spotCount').value,
			spotIntensity: formFields.find(c => c.name === 'spotIntensity')
				.value,
			group: formFields.find(c => c.name === 'group').value,
		};
		if (!isReloadData) {
			return dispatch(fetchModelTroubleshooting(data, trainingId));
		}
	}, [formFields, dispatch, trainingId, isReloadData]);
	const fetchInitData = () => {
		const data = {
			spotCount: modelTroubleshootingfields.find(
				c => c.name === 'spotCount'
			)?.value,
			spotIntensity: modelTroubleshootingfields.find(
				c => c.name === 'spotIntensity'
			)?.value,
			group: modelTroubleshootingfields.find(c => c.name === 'group')
				?.value,
		};
		dispatch(fetchClear(namespaces.TRAINING_MODEL_TROUBLESHOOTING));
		dispatch(fetchModelTroubleshooting(data, trainingId));
	};
	const reloadInitialData = () => {
		dispatch(reloadModelTroubleshooting(trainingId));
		setIsReloadData(true);
		setFormFields(modelTroubleshootingfields);
	};
	const modelPredictions = !!data?.modelPredictions
		? Object.keys(data.modelPredictions).map(key => ({
				name: key.toUpperCase(),
				value: data.modelPredictions[key],
		  }))
		: [];

	const handleExport = async () => {
		try {
			const fullPlateTableId = 'model-troubleshooting';
			const image = await takeScreenshot(fullPlateTableId);
			saveAs(image, `test_images.png`);
		} catch (e) {
			return;
		}
	};
	return (
		<Styled.Container>
			<Styled.ButtonsWrap>
				<Styled.SuccessButton variant='success' onClick={handleExport}>
					Export &nbsp;
					<FontAwesomeIcon icon={faDownload} />
				</Styled.SuccessButton>
				<Styled.SuccessButton
					variant='success'
					onClick={reloadInitialData}
					disabled={loading}>
					Reload &nbsp;
					<FontAwesomeIcon icon={faRetweet} />
				</Styled.SuccessButton>
			</Styled.ButtonsWrap>

			<div>
				{formFields.map(item => {
					if (item.type === 'range') {
						return (
							<Styled.RangeSliderWrapper key={item.name}>
								<RangeSliderComponent
									onChange={evt =>
										handleDebounceFormValuesChange(
											Number(evt.target.value),
											item.name
										)
									}
									label={item.label}
									min={item.min}
									max={item.max}
									value={formFields[item.name] || item.value}
									step={item.step}
								/>
							</Styled.RangeSliderWrapper>
						);
					}
					if (item.type === 'select') {
						return (
							<Styled.FiledWrapper key={item.label}>
								<span>{item.label}</span>
								<Form.Control
									as='select'
									onChange={evt =>
										handleDebounceFormValuesChange(
											evt.target.value,
											item.name
										)
									}
									value={item.value}>
									{item.values.map(({ label, value }) => (
										<option key={value} value={value}>
											{label}
										</option>
									))}
								</Form.Control>
							</Styled.FiledWrapper>
						);
					}
					return (
						<Styled.FiledWrapper key={item.label}>
							<span>{item.label}</span>
							<Form.Control
								onChange={evt =>
									handleDebounceFormValuesChange(
										evt.target.value,
										item.name
									)
								}
								value={item.value}
								placeholder='Input text'></Form.Control>
						</Styled.FiledWrapper>
					);
				})}
			</div>
			<Styled.ContentWrapper id='model-troubleshooting'>
				<When condition={loading}>
					<Styled.LoadingWrap>
						<Spinner animation='border'></Spinner> Loading Data...
					</Styled.LoadingWrap>
				</When>
				<When condition={!!data?.modelImages?.length && !loading}>
					<Styled.ImagesTableWrap>
						<Styled.ImagesTable
							bordered
							id={'training-experiment-images'}>
							<tbody>
								{!!data?.modelImages?.length &&
									data.modelImages.map(({ name, wells }) => (
										<tr key={name}>
											<td>{name}</td>
											{wells.map(({ image }, index) => (
												<td key={index}>
													<Styled.TDContent>
														<img
															src={base64StringToBlobUrl(
																image,
																'image/png'
															)}
															alt={image}
														/>
													</Styled.TDContent>
												</td>
											))}
										</tr>
									))}
							</tbody>
						</Styled.ImagesTable>
					</Styled.ImagesTableWrap>
				</When>
				<When condition={!loading && !!modelPredictions}>
					<Styled.BarChartWrapper>
						<BarChart
							data={modelPredictions}
							title='Model predictions'
						/>
					</Styled.BarChartWrapper>
				</When>
			</Styled.ContentWrapper>
		</Styled.Container>
	);
}
