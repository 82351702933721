import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import { Button } from 'components/Button';
import { Avatar } from 'components/Avatar';
import { formatAvatarText } from 'helpers';
import UserPlusIcon from 'assets/workspaces/userPlus.svg';
import * as Styled from './styled';

interface User {
	firstName: string;
	lastName: string;
	email?: string;
	userId: string;
}

interface Props {
	user: User;
	selectedAsMember: boolean;
	onButtonClick: () => void;
	children?: React.ReactNode;
	selectedList?: User[];
}

export default function UserDetails({
	user,
	selectedAsMember,
	onButtonClick,
	children,
	selectedList,
}: Props) {
	const { firstName, lastName, email } = user;
	return (
		<Fragment>
			<When condition={selectedAsMember}>
				<Styled.SelectedUser>
					<Avatar size='60px'>
						{formatAvatarText(`${firstName} ${lastName}`)}
					</Avatar>
					<div>
						<span>
							{firstName} {lastName}
						</span>
						<span>{email}</span>
						{children}
					</div>
					<Button variant='link' onClick={onButtonClick}>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				</Styled.SelectedUser>
			</When>
			<When condition={!selectedAsMember}>
				<Styled.User>
					<Avatar size='42px'>
						{formatAvatarText(`${firstName} ${lastName}`)}
					</Avatar>
					<span>
						{firstName} {lastName}
					</span>
					<Button
						variant='link'
						onClick={onButtonClick}
						disabled={selectedList?.some(
							u => u.userId === user.userId
						)}>
						<Styled.ImageSvg src={UserPlusIcon} />
					</Button>
				</Styled.User>
			</When>
		</Fragment>
	);
}
