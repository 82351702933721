import React from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import * as Styled from '../styled';

export default function UpdateInputFileForm({
	handleSubmit,
	loading,
	trace,
	error,
	name,
	isTemplate,
	closeModal,
}) {
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<Row>
					<Col>
						<Form.Label>
							<b>Name</b>
						</Form.Label>
						<Field
							showValid={false}
							name='name'
							defaultValue={name}
							placeholder='Type a name for the Input File'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Field
							name='isTemplate'
							initialValue={isTemplate}
							type='checkbox'>
							{({ input }) => (
								<Styled.CheckBoxContainer>
									<Form.Check
										{...input}
										label='Save as Template?'
										checked={input.checked}
									/>
								</Styled.CheckBoxContainer>
							)}
						</Field>
					</Col>
				</Row>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
			</Styled.Content>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={closeModal}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Save</span>
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
