import html2canvas from 'html2canvas';
import { Upload } from 'tus-js-client';

export const formatDateAndTime = (date: Date | string): string =>
	new Intl.DateTimeFormat('default', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	}).format(typeof date === 'string' ? new Date(date) : date);

export async function takeScreenshot(containerId) {
	const element: HTMLElement | null = document.getElementById(containerId);
	if (element) {
		const canvas = await html2canvas(element, { useCORS: true });
		canvas.style.display = 'none';
		document.body.appendChild(canvas);
		const image = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream');
		return image;
	}
}

export const formatAvatarText = (text: String): string =>
	text
		.split(/\s/)
		.reduce((response, word) => (response += word.slice(0, 1)), '');

export const isEmpty = obj => {
	for (let x in obj) {
		if (obj.hasOwnProperty(x)) return false;
	}
	return true;
};

export const base64StringToBlobUrl = (
	base64String: string,
	contentType: string
): string => {
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: contentType });
	var url = URL.createObjectURL(blob);
	return url;
};

export const bytesToSize = (bytes: number): string => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return 'N/A';
	const i = parseInt(
		Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
		10
	);
	if (i === 0) return `${bytes} ${sizes[i]}`;
	return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const handleResumableUpload = (
	fileUploaded: Blob,
	fileName: string,
	endpoint: string,
	setState: any,
	state: any
): void => {
	setState({
		...state,
		isProgressBarDisplayed: true,
		zipping: false,
		fileName,
	});
	const metadata = {
		fileType: fileUploaded.type,
		name: fileName,
	};

	const resumableUpload = new Upload(fileUploaded, {
		endpoint,
		retryDelays: [0, 1000, 3000, 5000],
		//chunkSize: chunkSize,
		metadata,

		onError: function (error) {
			console.error(error);
		},
		onProgress: function (bytesUploaded, bytesTotal) {
			const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
			setState({
				isProgressBarDisplayed: true,
				zipping: false,
				fileName,
				percentage: +percentage,
				bytesUploaded: bytesToSize(bytesUploaded),
				bytesTotal: bytesToSize(bytesTotal),
			});
		},
		onSuccess: function () {
			setState({
				...state,
				fileName,
				bytesUploaded: bytesToSize(fileUploaded.size),
				bytesTotal: bytesToSize(fileUploaded.size),
				percentage: 100,
				zipping: false,
				isProgressBarDisplayed: true,
				isUploaded: true,
			});
		},
	});
	resumableUpload.findPreviousUploads().then(function (previousUploads) {
		if (previousUploads.length) {
			resumableUpload.resumeFromPreviousUpload(previousUploads[0]);
		}
		resumableUpload.start();
	});
};

export const fetchFileUsingSignedUrl = (url: string, isZip: boolean): any => {
	return fetch(url)
		.then(response => response.text())
		.then(data => {
			return data;
		});
};

export const fetchZipUsingSignedUrl = (url: string, isZip: boolean): any => {
	return fetch(url)
		.then(response => response.arrayBuffer())
		.then(data => {
			return data;
		});
};

export const padSingleDigit = num => {
	if (num < 10) {
		return `0${num}`;
	}
	return num;
};

export const platesRowsHeaders = rowsCounter =>
	Array.from(Array(rowsCounter).keys()).map(item => {
		if (item > 0) {
			return padSingleDigit(item);
		}
		return '';
	});
