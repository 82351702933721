import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import * as namespaces from 'core/consts/namespaces';
import useNamespace from 'hooks/useNamespace';
import {
	deleteStudy,
	fetchSapmlesWithSelectedWells,
} from 'core/store/InputGeneration';
import * as Styled from '../styled';

export default function DeleteStudy({
	closeModal,
	workspaceDataId,
	id,
	studyId,
	plateId,
}) {
	const dispatch = useDispatch();
	const handleDeleteStudy = () => {
		dispatch(deleteStudy(workspaceDataId, id));
	};

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.DELETED_WELLS,
		onSuccess: () => {
			closeModal();
			dispatch(fetchSapmlesWithSelectedWells(workspaceDataId, plateId));
		},
	});
	return (
		<Fragment>
			<Modal.Body>
				<b>Are you sure you want to delete the '{studyId}' Study ?</b>
			</Modal.Body>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='success' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.DeleteButton
					variant='danger'
					onClick={handleDeleteStudy}
					loading={loading}>
					Delete
				</Styled.DeleteButton>
			</Modal.Footer>
		</Fragment>
	);
}
