import React, { Fragment } from 'react';
import { When } from 'components/When';
import * as Styled from './styled';
import { prepareData } from './utils';
import ConfusionMatrixCell from './ConfusionMatrixCell';
export default function ConfusionMatrix({ rawData }) {
	const data = prepareData(rawData);
	const yTicks = [
		'No response',
		'Immune response',
		'Not evaluable',
		'sum_col',
	];
	const XTicks = [
		'',
		'No response',
		'Immune response',
		'Not evaluable',
		'sum_lin',
	];
	return (
		<Styled.ConfusionMatrixContainer>
			<When condition={!!data.length}>
				<Styled.XAxisTitle>Predicted</Styled.XAxisTitle>
				{data.map((cm, index) => {
					return (
						<Fragment key={index}>
							<Styled.ConfusionMatrixRow>
								<Styled.YTicks>{yTicks[index]}</Styled.YTicks>
								{cm.map((singleCm, i) => (
									<ConfusionMatrixCell
										key={i}
										{...singleCm}
									/>
								))}
							</Styled.ConfusionMatrixRow>
							<When condition={index === cm.length - 1}>
								<Styled.ConfusionMatrixRow>
									{XTicks.map((xTick, ind) => (
										<Styled.XTicks key={ind}>
											{xTick}
										</Styled.XTicks>
									))}
								</Styled.ConfusionMatrixRow>
							</When>
						</Fragment>
					);
				})}
				<Styled.YAxisTitle>Actual</Styled.YAxisTitle>
			</When>
		</Styled.ConfusionMatrixContainer>
	);
}
