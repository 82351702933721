import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';

import Sidebar from './sidebar';
import Footer from './footer';
import * as Styled from './styled';
import MobileLayout from './mobile';

export const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile ? children : null;
};

export const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 767 });
	return isDesktop ? children : null;
};

export default function Layout({ children }) {
	return (
		<Fragment>
			<Mobile>
				<MobileLayout>
					Mobile/Tablet devices are not supported for the moment
				</MobileLayout>
			</Mobile>
			<Desktop>
				<Styled.Layout>
					<Sidebar />
					<Styled.RightContainer>
						<Styled.Content>{children}</Styled.Content>
						<Footer />
					</Styled.RightContainer>
				</Styled.Layout>
			</Desktop>
		</Fragment>
	);
}
