import jwtDecode, { JwtPayload } from 'jwt-decode';

export function checkJwtToken(token: string): boolean {
	try {
		const { exp } = jwtDecode<JwtPayload>(token);
		if (!exp) return false;

		const expirationTime = exp * 1000 - 60000; // check minute earlier
		if (Date.now() >= expirationTime) {
			return false;
		}
	} catch (err) {
		return false;
	}
	return true;
}
