import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { A } from 'components/A';

export const Container = styled.div`
	background: ${p => p.theme.secondaryDark};
	display: grid;
	grid-template-rows: 65px 1fr;
	height: 100vh;
	overflow: auto;
	position: sticky;
	top: 0;
	width: 250px;
	z-index: 100;
`;

export const UpperSide = styled.a`
	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		position: fixed;
		left: 10px;
		width: 200px;
		top: 20px;
	}
`;

export const MenuContainer = styled.div`
	overflow: hidden;
	&:hover {
		overflow: auto;
	}
`;

export const MenuList = styled.ul`
	margin-top: 60px;
	padding: 0;
	list-style: none;
`;

const selectedMenuItem = css`
	background-color: ${p => p.theme.green_4};
	span {
		color: ${p => p.theme.green};
	}
	svg {
		color: ${p => p.theme.green}!important;
	}
	border-left: 2px solid ${p => p.theme.green};
`;

export const MenuItem = styled.li`
	border-left: 2px solid transparent;
	a {
		align-items: center;
	}
	${({ isSelected }) => isSelected && selectedMenuItem}
	&:hover {
		${selectedMenuItem}
	}
`;

export const ItemLink = styled(NavLink)`
	cursor: pointer;
	display: flex;
	padding: 9px 24px;
	text-decoration: none !important;
	font-size: 14px;
	svg {
		transition: fill 0.3s ease;
		color: ${p => p.theme.white};
		width: 26px;
		height: 26px;
	}
`;

export const Text = styled.span`
	font-weight: bold;
	color: ${p => p.theme.white};
	padding-left: 10px;
`;

export const UserSectionContainer = styled.div`
	background-color: ${p => p.theme.green};
	height: auto;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
export const TitleContainer = styled.div`
	color: ${p => p.theme.white};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-left: 5px;
	span,
	b {
		align-self: flex-start;
	}
`;

export const Link = styled(A)`
	color: ${p => p.theme.white};
	position: relative;
	left: 5px;
	bottom: 1px;
	display: inline-block;
	width: 110px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		font-weight: 500;
		color: ${p => p.theme.white};
	}
`;

export const UserInformation = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 15px;
`;

export const SignOutContainer = styled.div`
	display: flex;
	position: relative;
	background: ${p => p.theme.secondaryDark};
	border-radius: 44px;
	padding: 2px 12px 0 12px;
	cursor: pointer;
	width: 96%;
	svg {
		color: ${p => p.theme.white};
		width: 0.8em;
	}
`;
