import styled from 'styled-components';

export const A = styled.a`
	color: ${p => p.theme.textSecondary};
	cursor: pointer;
	font-size: 0.85em;
	text-decoration: none;
	margin-right: 10px;
	&:hover {
		text-decoration: none;
		color: ${p => p.theme.primary};
	}
`;
