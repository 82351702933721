import React, { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/Button';
import { Table } from 'components/Table';
import { When } from 'components/When';
import { ModalProvider } from 'components/Modal';
import * as namespaces from 'core/consts/namespaces';
import { ROLES } from 'core/consts/roles';
import { currentClusterSelector } from 'core/store/clusters';
import { formatDateAndTime } from 'helpers';
import { StatusFilter } from 'components/Filters/StatusFilter';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import {
	dateBetweenFilterFn,
	DateFilter,
} from 'components/Filters/DateFilter/DateFilter';
import { ReviewsnDataEntry } from '../types';
import DeleteReview from './DeleteReview';
import ReviewSubmitCloseButton from './ReviewSubmitCloseButton';
import ManageReviewButton from './ManageReviewButton';
import * as Styled from './styled';

interface Props {
	data: ReviewsnDataEntry[];
	loading: boolean;
	userId: string;
	predictionId: string;
}

export default function ReviewsTable({
	data,
	loading,
	userId,
	predictionId,
}: Props) {
	const theme = useTheme();
	const currentCluster = useSelector(currentClusterSelector);
	const canManageCurrentCluster =
		currentCluster?.role === ROLES.admin ||
		currentCluster?.role === ROLES.supervisor;

	const columns = useMemo(
		() => [
			{
				Header: 'Status',
				accessor: 'status',
				disableSortBy: true,
				Filter: StatusFilter,
				Cell: ({ value }) => (
					<Fragment>
						<When condition={value === 'InProgress'}>
							<Styled.ReviewInProgress>
								In Progress
							</Styled.ReviewInProgress>
						</When>
						<When condition={value === 'Done'}>
							<Styled.ReviewDone>Done</Styled.ReviewDone>
						</When>
					</Fragment>
				),
			},
			{
				Header: 'Created by',
				accessor: 'ownerName',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.REVIEWS,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'Name',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.REVIEWS,
					}),
				filter: multiSelectFilter,
				accessor: 'reviewName',
			},
			{
				Header: 'Reviewers',
				disableSortBy: true,
				disableFilters: true,
				Cell: ({ value }) =>
					!!value.length && <span>{value.length}</span>,
				accessor: 'reviewers',
			},
			{
				Header: 'Type',
				accessor: 'type',
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.REVIEWS,
					}),
				filter: multiSelectFilter,
				Cell: ({ value }) => <span>{value}</span>,
			},
			{
				Header: 'Time Created',
				accessor: 'startedOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
			},

			{
				Header: 'Time Finished',
				accessor: 'finishedOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({
					row: {
						original: { finishedOn, status },
					},
				}) =>
					finishedOn &&
					status !== 'InProgress' && (
						<span>{formatDateAndTime(finishedOn)}</span>
					),
			},
			{
				id: 'Close Review',
				disableSortBy: true,
				disableFilters: true,
				Cell: ({ row: { original: review } }) => (
					<ReviewSubmitCloseButton
						review={review}
						userId={userId}
						predictionId={predictionId}
						canManageCurrentCluster={canManageCurrentCluster}
					/>
				),
			},
			{
				id: 'config',
				disableSortBy: true,
				disableFilters: true,
				width: 25,
				Cell: ({ row: { original: review } }) => (
					<ManageReviewButton
						review={review}
						userId={userId}
						predictionId={predictionId}
					/>
				),
			},
			{
				id: 'delete',
				disableSortBy: true,
				disableFilters: true,
				width: 25,
				Cell: ({
					row: {
						original: { ownerId, reviewId, reviewName },
					},
				}) =>
					(ownerId === userId || canManageCurrentCluster) && (
						<ModalProvider
							title={`Delete '${reviewName}' Rewview`}
							component={DeleteReview}
							modalProps={{
								reviewId,
								reviewName,
								predictionId,
							}}
							modalStyles={{ size: 'lg' }}
							trigger={
								<Button variant='link'>
									<FontAwesomeIcon
										icon={faTrashAlt}
										color={theme.red_4}
									/>
								</Button>
							}
						/>
					),
			},
		],
		[theme, userId, predictionId, canManageCurrentCluster]
	);

	return (
		<Styled.Container>
			<Table
				data={data}
				columns={columns}
				loading={loading}
				withFilters={!!data?.length}
			/>
		</Styled.Container>
	);
}
