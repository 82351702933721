import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowCircleLeft,
	faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import CardProvider from 'components/Card';
import { Button as RButton } from 'components/Button';
import { When } from 'components/When';
import ReadoutCardContent from './Cards/ReadoutCardContent';
import FullPlateRow from '../FullPlateRow';
import * as Styled from '../styled';

export default function Readout({
	readout,
	cardClickHandler,
	changeColorHandler,
	workspaceData,
	handlePlateBarcodeChange,
	plateBarcode,
	columnHeader,
	rowsHeader,
	handleOnMouseDown,
	handleOnMouseUP,
	selectedCard,
	workspaceDataId,
	availablewells,
	availableWellsType,
	getSingleCardBySelectedWellPosition,
	visits,
	stimulusInIvsData,
	plateSize,
	loading,
}) {
	const theme = useTheme();
	const { plateId } = plateBarcode.currentPlateBarcode;
	const getWellColor = position => {
		const singleVisitSelectedWells = getSingleCardBySelectedWellPosition(
			position
		);
		return readout?.find(
			v => v?.stimulusInReadoutId === singleVisitSelectedWells?.id
		)?.color;
	};

	/* Generate Nested Visits wells array */
	const visitsWells = useMemo(() => {
		if (!!visits?.length) {
			const nestedVisitsData = visits
				?.map(wells => wells?.groupCells[plateId])
				.filter(wells => !!wells?.length);
			return nestedVisitsData.flatMap(wellName => wellName);
		}
		return null;
	}, [visits, plateId]);

	/* Generate Nested IVS wells array */
	const stimulusInIvsWells = useMemo(() => {
		if (!!stimulusInIvsData?.length) {
			const nestedStimulusInIvsData = stimulusInIvsData
				?.map(wells => wells?.ivsWells[plateId])
				.filter(wells => !!wells?.length);
			return nestedStimulusInIvsData.flatMap(wellName => wellName);
		}
		return null;
	}, [stimulusInIvsData, plateId]);

	return (
		<>
			<When condition={loading}>
				<Styled.LoadingWrap>
					<Spinner animation='border'></Spinner> Loading Data...
				</Styled.LoadingWrap>
			</When>
			<When condition={!loading}>
				<Styled.CardsContainer>
					{!!readout?.length &&
						readout.map(
							(
								{
									name,
									typeOfStimulusInReadout,
									stimulusFormatInReadout,
									color,
									stimulusInReadoutId,
								},
								index
							) => (
								<CardProvider
									key={name}
									component={ReadoutCardContent}
									cardProps={{
										name,
										typeOfStimulusInReadout,
										stimulusFormatInReadout,
										workspaceDataId,
										stimulusInReadoutId,
										plateId:
											plateBarcode?.currentPlateBarcode
												?.plateId,
									}}
									clickHandler={() =>
										cardClickHandler(
											index,
											stimulusInReadoutId
										)
									}
									changeColorHandler={() =>
										changeColorHandler(
											'StimulusInReadout',
											stimulusInReadoutId
										)
									}
									isSelected={selectedCard.index === index}
									color={color}
								/>
							)
						)}
					{!readout?.length && (
						<Styled.EmptyCardMessage>
							<p>Please Add a Stimulus In Readout</p>
						</Styled.EmptyCardMessage>
					)}
				</Styled.CardsContainer>
				<Styled.WellsContainer>
					<When
						condition={
							!!workspaceData?.plates &&
							!!workspaceData?.plates?.length
						}>
						<Styled.PlateBarcodes>
							<RButton
								variant='link'
								onClick={() =>
									handlePlateBarcodeChange(
										plateBarcode.index - 1
									)
								}
								disabled={plateBarcode.index === 1}>
								<FontAwesomeIcon
									icon={faArrowCircleLeft}
									color={theme.green}
								/>
							</RButton>
							<span>{`${plateBarcode.index}/${workspaceData?.plates?.length}`}</span>
							<RButton
								variant='link'
								onClick={() =>
									handlePlateBarcodeChange(
										plateBarcode.index + 1
									)
								}
								disabled={
									plateBarcode.index ===
									workspaceData?.plates?.length
								}>
								<FontAwesomeIcon
									icon={faArrowCircleRight}
									color={theme.green}
								/>
							</RButton>
							<p>Plate Barcode :</p>
							<span>
								{plateBarcode.currentPlateBarcode.plateBarcode}
							</span>
						</Styled.PlateBarcodes>
					</When>
					<Styled.FullPlateTable bordered id={'full-plate-images'}>
						<thead>
							<tr>
								{rowsHeader.map(rowHeader => (
									<th key={rowHeader}>{rowHeader}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{columnHeader.map((header, index) => (
								<FullPlateRow
									key={index}
									columnHeader={header}
									rowsHeader={rowsHeader.filter(
										element => element > 0
									)}
									handleOnMouseDown={handleOnMouseDown}
									handleOnMouseUP={handleOnMouseUP}
									getWellColor={getWellColor}
									availablewells={availablewells}
									availableWellsType={availableWellsType}
									id={selectedCard.id}
									getSingleCardBySelectedWellPosition={
										getSingleCardBySelectedWellPosition
									}
									visitsWells={visitsWells}
									stimulusInIvsWells={stimulusInIvsWells}
									plateSize={plateSize}
								/>
							))}
						</tbody>
					</Styled.FullPlateTable>
				</Styled.WellsContainer>
			</When>
		</>
	);
}
