import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalProvider } from 'components/Modal';
import { submitReview } from 'core/store/predictions';
import CloseReview from './CloseReview';
import { ELISPOT_COUNT_USERNAME } from 'core/consts/roles';
import { ReviewsnDataEntry } from '../types';
import * as Styled from './styled';

interface Props {
	review: ReviewsnDataEntry;
	userId: string;
	predictionId: string;
	canManageCurrentCluster: boolean;
}

export default function ReviewSubmitCloseButton({
	review,
	userId,
	predictionId,
	canManageCurrentCluster,
}: Props) {
	const dispatch = useDispatch();
	const handleSubmitReview = reviewId => {
		dispatch(submitReview({ reviewId }, predictionId));
	};
	const reviewer = review?.reviewers.find(u => u.userId === userId);
	const { ownerName } = review;
	const hasSubmittedReview = !!reviewer ? reviewer.submitted : false;
	const isReviewOwner = review?.ownerId === userId;
	const isCreatedBySpotCount = ownerName === ELISPOT_COUNT_USERNAME;
	const canCloseReview =
		(!!isReviewOwner &&
			!!hasSubmittedReview &&
			!!reviewer &&
			review?.ownerId === userId) ||
		(!!isReviewOwner && !hasSubmittedReview && !reviewer) ||
		(isCreatedBySpotCount &&
			canManageCurrentCluster &&
			!!hasSubmittedReview);
	if (
		review?.status === 'Done' &&
		(isReviewOwner || (isCreatedBySpotCount && canManageCurrentCluster))
	) {
		return (
			<Styled.SuccessButton disabled={true} variant='secondary'>
				Close Review
			</Styled.SuccessButton>
		);
	} else if (!!canCloseReview) {
		return (
			<ModalProvider
				component={CloseReview}
				title='Close Review'
				modalProps={{
					reviewId: review?.reviewId,
					reviewName: review?.reviewName,
					predictionId,
				}}
				trigger={
					<Styled.SuccessButton>
						Close Review &nbsp;
					</Styled.SuccessButton>
				}
			/>
		);
	} else if (
		!hasSubmittedReview &&
		(!!reviewer || (isCreatedBySpotCount && canManageCurrentCluster))
	) {
		return (
			<Styled.SubmitButton
				onClick={() => handleSubmitReview(review?.reviewId)}>
				Submit Review &nbsp;
			</Styled.SubmitButton>
		);
	} else if (
		!!hasSubmittedReview &&
		!!reviewer.length &&
		review?.ownerId !== userId
	) {
		return (
			<Styled.SuccessButton disabled={true} variant='secondary'>
				Submit Review
			</Styled.SuccessButton>
		);
	}

	return <span></span>;
}
