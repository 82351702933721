import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useFormState } from 'react-final-form';
import { SelectAdapter, Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import * as Styled from '../styled';
import { PLATE_SIZE } from 'core/consts';
export default function CreateInputFileForm({
	handleSubmit,
	loading,
	trace,
	error,
	getTemplates,
	templates,
	closeModal,
}) {
	const plateSizesOptions = PLATE_SIZE?.map(v => ({
		label: v,
		value: v,
	}));
	const { values } = useFormState();
	const [templateFiledOptions, setTemplateFiledOptions] = useState([]);
	useEffect(() => {
		if (!!values?.size && !templates) {
			getTemplates(values.size);
		}
		if (!values?.size || !templates?.length) {
			setTemplateFiledOptions([]);
		} else {
			setTemplateFiledOptions(
				templates.map(item => ({
					value: item.workspaceDataId,
					label: item.name,
				}))
			);
		}
	}, [getTemplates, values.size, templates]);
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<Row>
					<Col>
						<Form.Label>
							<b>Name</b>
						</Form.Label>
						<Field
							showValid={false}
							name='name'
							placeholder='Type a name for the Input File'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Plate Size</b>
						</Form.Label>
						<Field
							showValid={false}
							component={SelectAdapter}
							name='size'
							validate={validateRequired()}
							options={plateSizesOptions}
							placeholder='Select Data'
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Field
							name='isTemplate'
							initialValue={false}
							type='checkbox'>
							{({ input }) => (
								<Styled.CheckBoxContainer>
									<Form.Check
										{...input}
										label='Save as Template?'
										checked={input.checked}
									/>
								</Styled.CheckBoxContainer>
							)}
						</Field>
					</Col>
				</Row>
				<Row>
					<Col>
						<Field
							name='experimentType'
							initialValue={false}
							type='checkbox'>
							{({ input }) => (
								<Styled.CheckBoxContainer>
									<Form.Check
										{...input}
										label='Use Ex-vivo data?'
										checked={input.checked}
									/>
								</Styled.CheckBoxContainer>
							)}
						</Field>
					</Col>
				</Row>
				<When condition={!!templateFiledOptions?.length}>
					<Row>
						<Col>
							<Form.Label>
								<b>Templates</b>
							</Form.Label>
							<Field
								showValid={false}
								component={SelectAdapter}
								name='workspaceDataTemplateId'
								options={templateFiledOptions}
								placeholder='Select Data'
							/>
						</Col>
					</Row>
				</When>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
			</Styled.Content>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={closeModal}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Save</span>
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
