import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'core/store/auth';
import { Modal } from 'react-bootstrap';
import * as Styled from './styled';

export default function SignOutModal({ closeModal }) {
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<Fragment>
			<Modal.Body>
				<b>Are you sure you want to sign out?</b>
			</Modal.Body>
			<Modal.Footer>
				<Styled.CancelButton variant='success' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.DeleteButton variant='danger' onClick={handleLogout}>
					Sign Out
				</Styled.DeleteButton>
			</Modal.Footer>
		</Fragment>
	);
}
