import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/esm/helpers';
import queryString from 'query-string';
import { fetchWorkspaceDataList } from 'core/store/InputGeneration';
import { currentWorkspaceIdSelector } from 'core/store/auth';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import * as ROUTES from 'core/routes';
import { SubHeader } from 'components/SubHeader';
import { When } from 'components/When';
import { ModalProvider } from 'components/Modal';
import InputsResultsTable from './components/InputsResultsTable';
import TemplatesResultsTable from './components/TemplatesResultsTable';
import CreateInputFileModal from './components/CreateInputFileModal';
import * as Styled from './styled';

const TABS = {
	inputs: { eventKey: 'inputs', name: 'All inputs' },
	templates: { eventKey: 'templates', name: 'Templates' },
};

const DEFAULT_ACTIVE_TAB = TABS.inputs.eventKey;

export default function InputFilesGeneration() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const workspaceId = useSelector(currentWorkspaceIdSelector);
	const { view } = queryString.parse(location.search);
	const [tab, setTab] = useState(view || DEFAULT_ACTIVE_TAB);
	useEffect(() => {
		history.push(`${ROUTES.INPUT_FILE_GENERATION}?view=${tab}`);
		switch (tab) {
			case TABS.inputs.eventKey:
				return dispatch(fetchWorkspaceDataList(workspaceId));
			case TABS.templates.eventKey:
				return dispatch(fetchWorkspaceDataList(workspaceId));
		}
	}, [dispatch, history, tab, workspaceId]);

	const {
		data: workspaceDataListData,
		loading: workspaceDataListLoading,
	} = useNamespace({
		namespace: namespaces.WORKSPACE_DATA_LIST,
	});
	const templateData = useMemo(() => {
		if (!!workspaceDataListData) {
			return workspaceDataListData.filter(
				({ isTemplate }) => !!isTemplate
			);
		}
		return [];
	}, [workspaceDataListData]);
	return (
		<Fragment>
			<SubHeader pre='Input File Creation' />
			<Tab.Container
				defaultActiveKey={tab}
				onSelect={setTab as SelectCallback}>
				<Styled.TabBar>
					<Nav variant='tabs'>
						<Nav.Item>
							<Nav.Link {...TABS.inputs}>
								{TABS.inputs.name}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link {...TABS.templates}>
								{TABS.templates.name}
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						<Styled.CreateInputFileButtonWrapper>
							<ModalProvider
								component={CreateInputFileModal}
								title='Create Input File'
								trigger={
									<Styled.AddButton variant='success'>
										Create Input File
									</Styled.AddButton>
								}
								modalProps={{ workspaceId }}
							/>
						</Styled.CreateInputFileButtonWrapper>
					</div>
				</Styled.TabBar>
				<Tab.Content>
					<Tab.Pane {...TABS.inputs}>
						<When condition={workspaceDataListData}>
							<InputsResultsTable
								data={workspaceDataListData}
								loading={workspaceDataListLoading}
								workspaceId={workspaceId}
							/>
						</When>
					</Tab.Pane>
					<Tab.Pane {...TABS.templates}>
						<When condition={workspaceDataListData}>
							<TemplatesResultsTable
								data={templateData}
								loading={workspaceDataListLoading}
								workspaceId={workspaceId}
							/>
						</When>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</Fragment>
	);
}
