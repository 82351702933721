import React from 'react';
import { Form } from 'react-bootstrap';

import * as Styled from './styled';

export const CheckBox = props => {
	return (
		<Styled.Container color={props.color}>
			<Form.Check {...props} />
		</Styled.Container>
	);
};
