export const FORM_FIELDS = {
	radius: 'trainingConfiguration.radius',
	maskMode: 'trainingConfiguration.maskMode',
	mirroredMode: 'trainingConfiguration.mirroredMode',
	ignoreWarnings: 'trainingConfiguration.ignoreWarnings',
	gpus: 'trainingConfiguration.gpusNumber',
	mcDropoutRuns: 'trainingConfiguration.mcDropoutRuns',
	earlyStopping: 'trainingConfiguration.earlyStopping',
	earlyStoppingPatience: 'trainingConfiguration.earlyStoppingPatience',
};

export const CheckFields = [
	{ name: FORM_FIELDS.maskMode, label: 'Mask Mode' },
	{ name: FORM_FIELDS.mirroredMode, label: 'Mirrored Mode' },
	{ name: FORM_FIELDS.earlyStopping, label: 'Early Stopping' },
];
