import React, { Fragment, ReactElement, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import useModal from 'hooks/useModal';
import * as Styled from './styled';

interface Props {
	title: string;
	component: React.ComponentType<any>;
	trigger?: ReactElement;
	modalProps?: object;
	modalStyles?: object;
	openModalOnLoad?: boolean;
}

export function ModalProvider({
	title,
	component: Component,
	trigger,
	modalProps,
	modalStyles,
	openModalOnLoad,
}: Props) {
	const { open, openModal, closeModal } = useModal();
	useEffect(() => {
		if (!!openModalOnLoad) openModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModalOnLoad]);

	return (
		<Fragment>
			{!!trigger &&
				React.cloneElement(trigger, {
					onClick: openModal,
					type: 'button',
				})}

			<Styled.Modal show={open} onHide={closeModal} {...modalStyles}>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Component {...modalProps} closeModal={closeModal} />
			</Styled.Modal>
		</Fragment>
	);
}
