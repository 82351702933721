import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import format from 'string-template';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import * as namespaces from 'core/consts/namespaces';
import useNamespace from 'hooks/useNamespace';
import { deleteTraining } from 'core/store/trainingsInVitro';
import * as Styled from '../../styled';

export default function TrainingDeleteModal({ closeModal, id, name }) {
	const dispatch = useDispatch();
	const handleDelete = trainingDataId => {
		dispatch(deleteTraining(trainingDataId));
	};
	const namespace = format(namespaces.DELETED_TRAINING, {
		id,
	});
	const { error, trace, loading } = useNamespace({
		namespace,
		onSuccess: closeModal,
	});
	return (
		<Fragment>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Body>
				<b>Are you sure you want to delete {name} ?</b>
			</Modal.Body>
			<Modal.Footer>
				<Styled.SuccessButton variant='success' onClick={closeModal}>
					No
				</Styled.SuccessButton>
				<Styled.DeleteButton
					variant='danger'
					onClick={() => handleDelete(id)}
					loading={loading}>
					Delete
				</Styled.DeleteButton>
			</Modal.Footer>
		</Fragment>
	);
}
