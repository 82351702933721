export const modelTroubleshootingfields = [
	{
		type: 'range',
		name: 'spotIntensity',
		label: 'Intensity',
		min: -20,
		max: 20,
		value: 0,
		step: 0.1,
	},
	{
		type: 'range',
		name: 'spotCount',
		label: 'Count',
		min: -20,
		max: 20,
		value: 0,
		step: 0.1,
	},
	{
		type: 'select',
		name: 'group',
		label: 'Well Group',
		value: 'all',
		values: [
			{
				label: 'All',
				value: 'all',
			},
			{ label: 'Position', value: 'position' },
			{
				label: 'Negative Control',
				value: 'negative_control',
			},
			{
				label: 'Positive Control',
				value: 'positive_control',
			},
			{
				label: 'Ptwi',
				value: 'ptwi',
			},
		],
	},
];
