import styled, { css } from 'styled-components';
import { Button as RButton } from 'components/Button';

export const CardContent = styled.div`
	button:first-of-type {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	button:last-of-type {
		position: absolute;
		right: 40px;
		top: 10px;
	}
	button:nth-last-child(2) {
		position: absolute;
		right: 40px !important;
		top: 10px;
	}
`;

export const CardCell = styled.div`
	width: 44%;
	float: left;
	margin: 10px 1%;
	p {
		font-size: 14px;
		color: ${p => p.theme.gray_3};
		margin: 0;
		font-weight: 500;
	}
	span {
		font-size: 16px;
		color: ${p => p.theme.black};
		font-weight: 700;
	}
`;

export const Samples = styled.div`
	overflow: hidden;
	width: 100%;
`;

type ColorRectProps = {
	$color?: string;
};
export const ColorRect = styled(RButton)<ColorRectProps>`
	width: 20px;
	height: 20px;
	border-radius: 5px;
	padding: 0;
	${({ $color }) =>
		!!$color &&
		css`
			background-color: ${$color};
			:hover,
			:active,
			:focus {
				background-color: ${$color};
				box-shadow: none;
			}
		`}
`;

type CardProps = {
	$isSelected?: boolean;
};

export const Sample = styled.div<CardProps>`
	width: 96%;
	margin: 10px 2%;
	overflow: hidden;
	border-radius: 10px;
	border: 1px solid ${p => p.theme.gray_12};
	padding: 10px;
	position: relative;
	cursor: pointer;
	button {
		right: 10px !important;
	}
	${ColorRect} {
		right: 20px !important;
		bottom: 20px !important;
		top: auto !important;
	}
	${({ $isSelected }) =>
		$isSelected &&
		css`
			-webkit-box-shadow: inset 0px 0px 0px 4px
				${(p): string => p.theme.green_8};
			-moz-box-shadow: inset 0px 0px 0px 4px
				${(p): string => p.theme.green_8};
			box-shadow: inset 0px 0px 0px 4px ${(p): string => p.theme.green_8};
		`}
`;

export const EmptyCardMessage = styled.div`
	width: 100%;
	font-size: 24px;
	font-weight: 700;
	display: flex;
	padding: 20px 0;
	justify-content: center;
	align-items: center;
	cursor: auto;
	p {
		margin: 0;
	}
`;
