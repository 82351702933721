import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { createWorkspaceDataPlates } from 'core/store/InputGeneration';
import { SubHeader } from 'components/SubHeader';
import { Link } from 'components/Link';
import { When } from 'components/When';
import { Button as RButton } from 'components/Button';
import ToggleButtons from './ToggleButtons';
import { INPUT_FILE_GENERATION } from 'core/routes';
import { INPUT_GENERATION_VIEWS } from 'core/consts';
import * as Styled from './styled';

export default function PlateBarCode({ data, workspaceDataId }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [configPlateBarcode, setConfigPlateBarcode] = useState<any[]>([]);
	const [navigation, setNavigation] = useState({
		isNextButtonClicked: false,
		viewToNavigateTo: '',
	});
	useEffect(() => {
		if (data?.length)
			setConfigPlateBarcode(
				data.map(d => ({
					plateBarcode: d.plateBarcode,
					plateId: d.plateId,
				}))
			);
	}, [data]);
	const { success, error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_WORSKPACE_DATA_PLATES,
	});
	const { data: workspaceData } = useNamespace({
		namespace: namespaces.WORSKPACE_DATA,
	});
	useEffect(() => {
		if (!!success) {
			if (navigation.isNextButtonClicked) {
				history.push(
					`${INPUT_FILE_GENERATION}/${workspaceDataId}?view=${INPUT_GENERATION_VIEWS.plateLayout}`
				);
			} else {
				history.push(
					`${INPUT_FILE_GENERATION}/${workspaceDataId}?view=${navigation.viewToNavigateTo}`
				);
			}
		}
	}, [navigation, success, history, workspaceDataId]);

	const handlePlateBarcodeChange = (e, index) => {
		const updatedConfig = [
			...configPlateBarcode.slice(0, index),
			{
				...configPlateBarcode[index],
				plateBarcode: e.target.value,
			},
			...configPlateBarcode.slice(index + 1),
		];
		setConfigPlateBarcode(updatedConfig);
	};
	const handleAddPlateBarcode = () => {
		setConfigPlateBarcode([
			...configPlateBarcode,
			{
				plateBarcode: '',
				plateId: null,
			},
		]);
	};
	const handleRemovePlateBarcode = index => {
		setConfigPlateBarcode([
			...configPlateBarcode.slice(0, index),
			...configPlateBarcode.slice(index + 1),
		]);
	};

	const handleApplyPlateBarcodeClick = (view?: string) => {
		const formattedData = {
			plateBarcodes: configPlateBarcode.map((plateBarcode, index) => ({
				...plateBarcode,
				rank: index,
			})),
		};
		dispatch(createWorkspaceDataPlates(formattedData, workspaceDataId));
		setNavigation({ ...navigation, viewToNavigateTo: view || '' });
	};

	const BUTTONS = [
		{
			active: true,
			view: INPUT_GENERATION_VIEWS.plateBarcode,
			title: 'Plate Barcode',
		},
		{
			active: false,
			view: INPUT_GENERATION_VIEWS.plateLayout,
			title: 'Plate Layout',
			onClick: handleApplyPlateBarcodeClick,
		},
		{
			active: false,
			view: INPUT_GENERATION_VIEWS.sampleInfos,
			title: 'Sample Information',
			onClick: handleApplyPlateBarcodeClick,
		},
		{
			active: false,
			view: INPUT_GENERATION_VIEWS.target,
			title: 'Target',
			onClick: handleApplyPlateBarcodeClick,
		},
	];

	return (
		<>
			<SubHeader
				current={workspaceData?.name}
				pre={
					<span>
						<Link to={INPUT_FILE_GENERATION}>
							Input File Creation
						</Link>
					</span>
				}
			/>
			<Styled.Container>
				<Styled.ContainerHeaderWrapper>
					<ToggleButtons
						Buttons={BUTTONS}
						disabled={!configPlateBarcode?.length}
					/>
					<Styled.AddButtonWrap>
						<Styled.AddButton
							variant='success'
							onClick={handleAddPlateBarcode}>
							Add Plate Barcode{' '}
							<FontAwesomeIcon icon={faPlusSquare} />
						</Styled.AddButton>
					</Styled.AddButtonWrap>
				</Styled.ContainerHeaderWrapper>
				<Styled.PlateBarcodesWrap>
					{!!configPlateBarcode?.length &&
						configPlateBarcode.map(({ plateBarcode }, index) => {
							return (
								<Styled.SinglePlateBarcode key={index}>
									<input
										type='text'
										placeholder='Insert Plate Barcode'
										value={plateBarcode}
										onChange={e =>
											handlePlateBarcodeChange(e, index)
										}
									/>
									<RButton
										onClick={() =>
											handleRemovePlateBarcode(index)
										}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</RButton>
								</Styled.SinglePlateBarcode>
							);
						})}
				</Styled.PlateBarcodesWrap>
				<When condition={!!error}>
					<Styled.Error>{trace}</Styled.Error>
				</When>
				<Styled.NextButton
					variant='success'
					onClick={() => {
						handleApplyPlateBarcodeClick();
						setNavigation({
							...navigation,
							isNextButtonClicked: true,
						});
					}}
					loading={loading}
					disabled={!configPlateBarcode?.length}>
					Next
				</Styled.NextButton>
			</Styled.Container>
		</>
	);
}
