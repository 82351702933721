import React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import * as Styled from './styled';

interface Props {
	label: string;
	min: number;
	max: number;
	value: number;
	step: number;
	onChange: Function;
	disabled?: boolean;
}

export const RangeSliderComponent = ({
	label,
	min,
	max,
	value,
	step,
	onChange,
	disabled,
}: Props) => {
	const handleChange = changeEvent => {
		onChange(changeEvent);
	};
	const RANGE_VALUE = ((value - min) / (max - min)) * 100;
	return (
		<Styled.Container rangeValue={RANGE_VALUE}>
			<Styled.Label>{label}</Styled.Label>
			<Styled.LimitValue>{min}</Styled.LimitValue>
			<RangeSlider
				value={value}
				onChange={handleChange}
				min={min}
				max={max}
				step={step}
				variant='success'
				tooltipStyle={{ top: 30 }}
				disabled={disabled}
			/>
			<Styled.LimitValue>{max}</Styled.LimitValue>
		</Styled.Container>
	);
};
