import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClear, getNamespace } from 'core/store/repository';
const isEqual = require('lodash.isequal');

interface Options {
	namespace: string;
	onSuccess?: Function;
	autoClear?: boolean;
}

export default function useNamespace({
	namespace,
	onSuccess,
	autoClear = true,
}: Options) {
	const dispatch = useDispatch();
	const {
		data,
		success,
		loading,
		error,
		trace,
		isInitialFetch,
	} = useSelector(getNamespace(namespace), isEqual);

	useEffect(() => {
		if (success && onSuccess) {
			onSuccess();
		}
	}, [success, onSuccess, dispatch]);

	useEffect(() => {
		return () => {
			if (autoClear) {
				dispatch(fetchClear(namespace));
			}
		};
	}, [dispatch, namespace, autoClear]);

	return { data, success, loading, error, trace, isInitialFetch };
}
