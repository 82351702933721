import React from 'react';
import * as Styled from './styled';
import { Status } from './status';

const StatusList = {
	prediction: {
		Created: 'Created',
		Processing: 'Processing',
		Succeeded: 'Succeeded',
		Failed: 'Failed',
		Cancelled: 'Cancelled',
	},
	predictionData: {
		Created: 'Created',
		Checking: 'Checking',
		Preprocessing: 'Preprocessing',
		Ready: 'Ready',
		Invalid: 'Invalid',
	},
	review: {
		UnderReview: 'UnderReview',
		NotReviewed: 'NotReviewed',
		Reviewed: 'Reviewed',
	},
	training: {
		Created: 'Created',
		Training: 'Training',
		Evaluating: 'Evaluating',
		Succeeded: 'Succeeded',
		Failed: 'Failed',
		Cancelled: 'Cancelled',
	},
	trainingData: {
		Created: 'Created',
		Checking: 'Checking',
		Preprocessing: 'Preprocessing',
		Ready: 'Ready',
		Invalid: 'Invalid',
	},
};

export const StatusFilter = ({
	column: { filterValue, setFilter },
	filterType,
}) => {
	const selectedStatusList = StatusList[filterType];
	const setStatusFilter = filter => {
		if (!!filterValue && filterValue === selectedStatusList[filter]) {
			setFilter('');
		} else {
			setFilter(selectedStatusList[filter]);
		}
	};

	return (
		<Styled.Container>
			<Styled.List>
				{Object.keys(selectedStatusList).map(item => {
					return (
						<Styled.ListItem
							key={item}
							isActive={filterValue === selectedStatusList[item]}
							onClick={() => setStatusFilter(item)}>
							<Status status={selectedStatusList[item]} />
						</Styled.ListItem>
					);
				})}
			</Styled.List>
		</Styled.Container>
	);
};
