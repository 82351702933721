import styled from 'styled-components';
export * from '../styled';

export const DateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	/* react-datepicker custom style */
	.react-datepicker {
		border: none;
		box-shadow: ${p => p.theme.boxShadow};
		font-weight: 100;
	}
	.react-datepicker__header {
		background-color: ${p => p.theme.white};
		border-color: ${p => p.theme.white};
	}
	.react-datepicker__navigation,
	.react-datepicker__navigation--next,
	.react-datepicker__current-month,
	.react-datepicker__current-month--hasYearDropdown,
	.react-datepicker__current-month--hasMonthDropdown {
		display: none;
	}
	.react-datepicker__day--in-range {
		background-color: ${p => p.theme.green};
		border-radius: 50%;
		color: ${p => p.theme.white} !important;
		font-weight: bold;
		outline: none !important;
	}
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__month-text--in-selecting-range,
	.react-datepicker__quarter-text--in-selecting-range,
	.react-datepicker__year-text--in-selecting-range {
		background-color: rgb(146 200 72 / 55%);
		border-radius: 50%;
	}
	.react-datepicker__day--selected,
	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected {
		background-color: ${p => p.theme.primaryDark};
		border-radius: 50%;
		outline: none !important;
	}

	.react-datepicker__month-read-view {
		color: ${p => p.theme.green};
		background-color: ${p => p.theme.gray_6};
		border-radius: 3px;
	}
	.react-datepicker__month-read-view--down-arrow {
		border-top-color: ${p => p.theme.green};
		&:before {
			border-top-color: ${p => p.theme.green};
		}
	}
	.react-datepicker__year-dropdown,
	.react-datepicker__month-dropdown,
	.react-datepicker__month-year-dropdown {
		background-color: ${p => p.theme.white};
	}
	.react-datepicker__month-option,
	.react-datepicker__year-option {
		&:hover {
			background-color: ${p => p.theme.gray_6};
			color: ${p => p.theme.green};
		}
	}
	.react-datepicker__month-option--selected_month,
	.react-datepicker__year-option--selected_year {
		background-color: ${p => p.theme.green};
		color: ${p => p.theme.white};
		span {
			display: none;
		}
	}
`;
