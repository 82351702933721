import React from 'react';
import { useRouteMatch, match } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { formatAvatarText } from 'helpers';
import { When } from 'components/When';
import { Avatar } from 'components/Avatar';
import BionTechLogo from 'assets/logos/biontech-groupe.svg';
import {
	currentWorkspaceIdSelector,
	userFullNameSelector,
	userRoleSelector,
	logout,
} from 'core/store/auth';
import { currentWorkspaceSelector } from 'core/store/workspaces';
import { adminNavItems } from 'core/_nav';
import { HOME } from 'core/routes';
import { ROLES, ADMIN_PORTAL_WORKSPACE_ID } from 'core/consts/roles';
import { PortalWorkspace } from './components/PortalWorkspace';

import * as Styled from './styled';

export default function Sidebar() {
	const dispatch = useDispatch();
	const userRole = useSelector(userRoleSelector);
	const currentWorkspaceId = useSelector(currentWorkspaceIdSelector);
	const currentWorkspace = useSelector(
		currentWorkspaceSelector(currentWorkspaceId)
	);
	const userName = useSelector(userFullNameSelector);
	const adminMatch: match<any> | null = useRouteMatch('/admin/:section/');

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<Styled.Container>
			<Styled.UpperSide href={HOME}>
				<img src={BionTechLogo} alt='' />
			</Styled.UpperSide>
			<Styled.MenuContainer>
				<When
					condition={
						currentWorkspaceId === ADMIN_PORTAL_WORKSPACE_ID &&
						userRole === ROLES.admin
					}>
					<Styled.MenuList>
						{adminNavItems.map(item => {
							const isSelected =
								adminMatch?.url === item.link ||
								item.subPages.includes(adminMatch?.url || '');
							return (
								<Styled.MenuItem
									key={item.name}
									isSelected={isSelected}>
									<Styled.ItemLink to={item.link}>
										<FontAwesomeIcon icon={item.icon} />
										<Styled.Text>{item.name}</Styled.Text>
									</Styled.ItemLink>
								</Styled.MenuItem>
							);
						})}
					</Styled.MenuList>
				</When>
				<When
					condition={
						!!currentWorkspaceId &&
						currentWorkspaceId !== ADMIN_PORTAL_WORKSPACE_ID
					}>
					<PortalWorkspace />
				</When>
			</Styled.MenuContainer>
			<Styled.UserSectionContainer>
				<Avatar size='60px'>{formatAvatarText(userName)}</Avatar>
				<Styled.UserInformation>
					<Styled.TitleContainer>
						<b>{userName}</b>
						<span>{userRole}</span>
					</Styled.TitleContainer>
					<Styled.SignOutContainer onClick={handleLogout}>
						<FontAwesomeIcon icon={faSignOutAlt} />
						<Styled.Link>
							Sign out of{' '}
							{currentWorkspace?.workspaceName || 'portal'}
						</Styled.Link>
					</Styled.SignOutContainer>
				</Styled.UserInformation>
			</Styled.UserSectionContainer>
		</Styled.Container>
	);
}
