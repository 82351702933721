import React, { useRef } from 'react';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'components/ProgressBar';
import { When } from 'components/When';
import * as Styled from './styled';

const FORM_FIELDS = {
	name: 'name',
	imagesFolder: 'imagesFolder',
	edaFolder: 'edaFolder',
	reacFolder: 'reacFolder',
	isValidationData: 'isValidationData',
};

export default function AddTrainingDataForm({
	imagesPathUploadProgress,
	setImagesPathUploadProgress,
	edaUploadProgress,
	setEdaUploadProgress,
	reacUploadProgress,
	setReacUploadProgress,
	invitroTrainingData,
	isNameDisabled,
	handleNameChange,
	handleIsValidationChange,
	isNameUsed,
	zipFile,
	children,
}) {
	const hiddenImagesFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenImagesFileInputRef = useRef<HTMLInputElement>(null);
	const hiddenEdaFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenEdaFileInputRef = useRef<HTMLInputElement>(null);
	const hiddenReacFolderInputRef = useRef<HTMLInputElement>(null);
	const hiddenReacFileInputRef = useRef<HTMLInputElement>(null);
	const handleInputFileChange = (e: any, state, setState): void => {
		zipFile(state, setState, e.target, state.endpoint);
	};

	const handleClick = ref => {
		if (!!ref?.current) ref.current.click();
	};
	return (
		<Styled.FormWrap>
			<Styled.Content>
				<Row>
					<Col>
						<Form.Label>
							<b>Name</b>
						</Form.Label>
						<Form.Control
							name={FORM_FIELDS.name}
							placeholder='Type a name for the invitro training data'
							type='text'
							onChange={handleNameChange}
							value={invitroTrainingData.name}
							disabled={isNameDisabled}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={imagesPathUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={imagesPathUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!imagesPathUploadProgress.isProgressBarDisplayed &&
								!imagesPathUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>Images Path</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenImagesFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											imagesPathUploadProgress,
											setImagesPathUploadProgress
										)
									}
									ref={hiddenImagesFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenImagesFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											imagesPathUploadProgress,
											setImagesPathUploadProgress
										)
									}
									ref={hiddenImagesFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								imagesPathUploadProgress.isProgressBarDisplayed &&
								!imagesPathUploadProgress.zipping
							}>
							<ProgressBar
								fileName={imagesPathUploadProgress.fileName}
								percentage={
									imagesPathUploadProgress?.percentage
								}
								now={`${imagesPathUploadProgress?.bytesUploaded}`}
								size={`${imagesPathUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<When condition={edaUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={edaUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!edaUploadProgress.isProgressBarDisplayed &&
								!edaUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>EDA</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenEdaFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											edaUploadProgress,
											setEdaUploadProgress
										)
									}
									ref={hiddenEdaFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenEdaFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											edaUploadProgress,
											setEdaUploadProgress
										)
									}
									ref={hiddenEdaFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								edaUploadProgress.isProgressBarDisplayed &&
								!edaUploadProgress.zipping
							}>
							<ProgressBar
								fileName={edaUploadProgress.fileName}
								percentage={edaUploadProgress?.percentage}
								now={`${edaUploadProgress?.bytesUploaded}`}
								size={`${edaUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>

				<Row>
					<Col>
						<When condition={reacUploadProgress.zipping}>
							<Styled.Status>
								<Spinner animation='border'></Spinner>
								<span>Zipping...</span>
							</Styled.Status>
						</When>
						<When condition={reacUploadProgress.zipError}>
							<Styled.Alert variant='danger'>
								Error when zipping the folder
							</Styled.Alert>
						</When>
						<When
							condition={
								!reacUploadProgress.isProgressBarDisplayed &&
								!reacUploadProgress.zipping
							}>
							<Styled.UploadFileButtonWrap>
								<Styled.FormLabel>Reac</Styled.FormLabel>
								<Styled.UploadFolderButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenReacFolderInputRef)
									}>
									Select a Folder
									<FontAwesomeIcon icon={faFolder} />
								</Styled.UploadFolderButton>
								<input
									type='file'
									/* @ts-expect-error */
									webkitdirectory=''
									mozdirectory=''
									onChange={e =>
										handleInputFileChange(
											e,
											reacUploadProgress,
											setReacUploadProgress
										)
									}
									ref={hiddenReacFolderInputRef}
								/>
								<Styled.UploadFileButton
									disabled={isNameUsed}
									onClick={() =>
										handleClick(hiddenReacFileInputRef)
									}>
									Select a File
									<FontAwesomeIcon icon={faFile} />
								</Styled.UploadFileButton>
								<input
									type='file'
									onChange={e =>
										handleInputFileChange(
											e,
											reacUploadProgress,
											setReacUploadProgress
										)
									}
									ref={hiddenReacFileInputRef}
								/>
							</Styled.UploadFileButtonWrap>
						</When>
						<When
							condition={
								reacUploadProgress.isProgressBarDisplayed &&
								!reacUploadProgress.zipping
							}>
							<ProgressBar
								fileName={reacUploadProgress.fileName}
								percentage={reacUploadProgress?.percentage}
								now={`${reacUploadProgress?.bytesUploaded}`}
								size={`${reacUploadProgress?.bytesTotal}`}
							/>
						</When>
					</Col>
				</Row>
				<Row>
					<Col>
						<Styled.UploadFileButtonWrap>
							<Styled.FormLabel>Data For</Styled.FormLabel>
							<Form.Check
								name={FORM_FIELDS.isValidationData}
								type='radio'
								label='Training'
								checked={!invitroTrainingData.isValidationData}
								onChange={() => handleIsValidationChange(false)}
							/>
							<Form.Check
								name={FORM_FIELDS.isValidationData}
								type='radio'
								label='validation'
								checked={invitroTrainingData.isValidationData}
								onChange={() => handleIsValidationChange(true)}
							/>
						</Styled.UploadFileButtonWrap>
					</Col>
				</Row>
			</Styled.Content>
			{children}
		</Styled.FormWrap>
	);
}
