export const FORM_FIELDS = {
	model: 'trainingModelParameters.model',
	baseCnn: 'trainingModelParameters.baseCNN',
	variant: 'trainingModelParameters.modelVariant',
	inputShape: 'trainingModelParameters.inputShape',
	learningRate: 'trainingModelParameters.learningRate',
	batchSize: 'trainingModelParameters.batchSize',
	classes: 'trainingModelParameters.classesNumber',
	epochs: 'trainingModelParameters.epochsNumber',
	savedModels: 'trainingModelParameters.savedModels',
	loss: 'trainingModelParameters.loss',
	optimizer: 'trainingModelParameters.optimizer',
	inputMode: 'trainingModelParameters.inputMode',
	dropout: 'trainingModelParameters.dropout',
	weightDecay: 'trainingModelParameters.weightDecay',
};

export const NumericFields = [
	{
		name: FORM_FIELDS.inputShape,
		label: 'Input Shape',
		min: 0,
		max: 256,
		step: 1,
	},
	{
		name: FORM_FIELDS.learningRate,
		label: 'Learning Rate',
		step: '0.0001',
	},
	{ name: FORM_FIELDS.batchSize, label: 'Batch Size' },
	{ name: FORM_FIELDS.classes, label: 'Number of classes' },
	{ name: FORM_FIELDS.epochs, label: 'Number of Epochs' },
	{ name: FORM_FIELDS.savedModels, label: 'Saved Models' },
	{
		name: FORM_FIELDS.dropout,
		label: 'Dropout',
		step: '0.1',
		min: '0',
		max: '1',
	},
	{
		name: FORM_FIELDS.weightDecay,
		label: 'Weight Decay',
		step: '0.0001',
		min: '0',
		max: '0.1',
	},
];

export const SelectionFields = [
	{ name: FORM_FIELDS.loss, label: 'Loss', optionsField: 'loss' },
	{
		name: FORM_FIELDS.optimizer,
		label: 'Optimizer',
		optionsField: 'optimizers',
	},
];
