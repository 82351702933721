import styled from 'styled-components';
import { Button as RButton } from 'react-bootstrap';
import SplashScreen from 'assets/img/splash-screen.png';

export const Container = styled.div`
	background-image: url(${SplashScreen});
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
	min-height: 100vh;
	text-align: center;

	h1 {
		color: ${p => p.theme.green};
		max-width: 45%;
		margin: 70px 0;
		font-size: 50px;
	}
`;

export const LogoContainer = styled.div`
	margin-top: 60px;
`;

export const Title = styled.span`
	color: ${p => p.theme.green_2};
	font-size: 40px;
	letter-spacing: 4px;
	position: relative;
	top: 11px;
	left: 10px;
`;

export const LogoIcon = styled.img`
	width: 50px;
`;

export const Logo = styled.img`
	width: 85px;
	position: relative;
	top: 30px;
	right: 50px;
`;

export const ButtonContainer = styled.div`
	display: flex;
`;

export const Button = styled(RButton)`
	border: none;
	font-size: 1.25rem;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	margin: 0 10px;
	padding: 5px 25px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const BottomContainer = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: space-evenly;
	width: 40%;
	p {
		color: ${p => p.theme.white};
		font-size: 12px;
	}
`;
