import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload } from '@fortawesome/free-solid-svg-icons';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	fetchWorkspaceData,
	uploadTargetFile,
	fetchTargets,
	downloadTargets,
} from 'core/store/InputGeneration';
import { SubHeader } from 'components/SubHeader';
import { Link } from 'components/Link';
import { INPUT_FILE_GENERATION } from 'core/routes';
import { INPUT_GENERATION_VIEWS } from 'core/consts';
import ToggleButtons from './ToggleButtons';
import TargetsTable from './TargetsComponents';
import * as Styled from './styled';

const BUTTONS = [
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.plateBarcode,
		title: 'Plate Barcode',
	},
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.plateLayout,
		title: 'Plate Layout',
	},
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.sampleInfos,
		title: 'Sample Information',
	},
	{
		active: true,
		view: INPUT_GENERATION_VIEWS.target,
		title: 'Target',
	},
];

export default function Target({ workspaceDataId }) {
	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const { data: workspaceData } = useNamespace({
		namespace: namespaces.WORSKPACE_DATA,
	});
	const { data: targets, loading: targetsLoading } = useNamespace({
		namespace: namespaces.TARGETS,
	});
	const { data: downloadedTargets } = useNamespace({
		namespace: namespaces.DOWNLOADED_TARGETS,
	});
	const {
		error: errorUploadingTarget,
		trace: traceUploadingTarget,
		data: dataUploadingTarget,
	} = useNamespace({
		namespace: namespaces.UPLOADED_TARGET,
	});
	useEffect(() => {
		dispatch(fetchWorkspaceData(workspaceDataId));
		dispatch(fetchTargets(workspaceDataId));
		// eslint-disable-next-line
	}, [dispatch]);
	useEffect(() => {
		try {
			if (!!downloadedTargets) {
				const cleanDownloadedTargets = downloadedTargets.map(
					({ target, ...rest }) => rest
				);
				const columnNames = Object.keys(cleanDownloadedTargets[0])
					.map(
						colName =>
							`${colName.charAt(0).toUpperCase()}${colName.slice(
								1
							)}`
					)
					.join(',');
				const tsvData = cleanDownloadedTargets
					.map(e => {
						return Object.values(e)
							.map(value => {
								const val =
									!!value && typeof value === 'string'
										? value.replace(/\n/g, ' ')
										: value;
								return val;
							})
							.join(',');
					})
					.join('\n');
				const fileContent = columnNames + '\n' + tsvData;
				const content = new Blob([fileContent], {
					type: 'text/csv;charset=utf-8',
				});
				saveAs(content, `targets_${workspaceDataId}.csv`);
			}
		} catch (e) {
			console.log(e);
			return;
		}
	}, [downloadedTargets, workspaceDataId]);

	const handleClick = () => {
		if (!!inputRef?.current) inputRef.current.click();
	};

	const handleFileChange = event => {
		const selectedFile = event.target.files && event.target.files[0];
		if (!selectedFile) {
			return;
		}
		const formData = new FormData();
		formData.append('Spreadsheetfile', selectedFile);
		dispatch(uploadTargetFile(formData, workspaceDataId));
	};
	const handleDownloadTarget = () => {
		dispatch(downloadTargets(workspaceDataId));
	};

	return (
		<>
			<SubHeader
				current={workspaceData?.name}
				pre={
					<span>
						<Link to={INPUT_FILE_GENERATION}>
							Input File Creation
						</Link>
					</span>
				}
			/>
			<Styled.Container>
				<Styled.ContainerHeaderWrapper>
					<ToggleButtons Buttons={BUTTONS} />
					<Styled.TargetInput
						ref={inputRef}
						type='file'
						onChange={handleFileChange}
					/>
					<Styled.AddButton variant='success' onClick={handleClick}>
						Upload File
						<FontAwesomeIcon icon={faUpload} />
					</Styled.AddButton>
					<Styled.DownloadButton
						variant='success'
						onClick={handleDownloadTarget}>
						<FontAwesomeIcon icon={faDownload} />
					</Styled.DownloadButton>
				</Styled.ContainerHeaderWrapper>
				<TargetsTable
					data={targets}
					workspaceDataId={workspaceDataId}
					error={errorUploadingTarget}
					trace={traceUploadingTarget}
					invalidTargets={dataUploadingTarget?.invalidTargets ?? null}
					loading={targetsLoading}
				/>
			</Styled.Container>
		</>
	);
}
