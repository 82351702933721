import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { deleteWorkspaceData } from 'core/store/InputGeneration';
import { When } from 'components/When';
import * as Styled from '../../styled';

export default function InputGenerationDeleteModal({
	closeModal,
	workspaceId,
	workspaceDataId,
	name,
}) {
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.DELETED_WORSKPACE_DATA,
		onSuccess: closeModal,
	});
	const dispatch = useDispatch();
	const handleDelete = () => {
		dispatch(deleteWorkspaceData(workspaceDataId, workspaceId));
	};
	return (
		<Fragment>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Body>
				<b>Are you sure you want to delete {name} ?</b>
			</Modal.Body>
			<Modal.Footer>
				<Styled.AddButton variant='success' onClick={closeModal}>
					No
				</Styled.AddButton>
				<Styled.DeleteButton
					variant='danger'
					loading={loading}
					onClick={handleDelete}>
					Delete
				</Styled.DeleteButton>
			</Modal.Footer>
		</Fragment>
	);
}
