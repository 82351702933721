import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import * as namespaces from 'core/consts/namespaces';
import useNamespace from 'hooks/useNamespace';
import {
	resetWells,
	fetchVisitsWithSelectedWells,
} from 'core/store/InputGeneration';
import * as Styled from '../../styled';

export default function ResetVisit({
	closeModal,
	workspaceDataId,
	visitId,
	name,
	plateId,
}) {
	const dispatch = useDispatch();
	const handleResetVisit = () => {
		dispatch(resetWells('visit', visitId, workspaceDataId, plateId));
	};

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.RESETED_WELLS,
		onSuccess: () => {
			closeModal();
			dispatch(fetchVisitsWithSelectedWells(workspaceDataId, plateId));
		},
	});
	return (
		<Fragment>
			<Modal.Body>
				<b>Are you sure you want to reset the '{name}' Visit ?</b>
			</Modal.Body>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='success' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.DeleteButton
					variant='danger'
					onClick={handleResetVisit}
					loading={loading}>
					Reset
				</Styled.DeleteButton>
			</Modal.Footer>
		</Fragment>
	);
}
