import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 18px 10px 22px;
	background-color: transparent;
	height: 60px;
	margin-bottom: 15px;
	a {
		color: inherit;
	}
`;

export const CurrentPage = styled.span`
	font-weight: bold;
	color: ${p => p.theme.green};
`;

export const RightContainer = styled.div`
	margin-left: auto;
	position: relative;
`;

export const FaqLink = styled(NavLink)`
	cursor: pointer;
	position: absolute;
	top: 11px;
	left: -28px;
	svg {
		transition: fill 0.3s ease;
		color: ${p => p.theme.green};
		width: 18px !important;
		height: 18px;
	}
`;
export const RightElementWrapper = styled.div`
	float: right;
`;
