import React, { useEffect, useMemo, useState } from 'react';
import { RangeSliderComponent } from 'components/RangeSlider';
import { Form } from 'react-bootstrap';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { When } from 'components/When';
import * as Styled from '../styled';
import {
	blobsConfigOptions,
	watershedConfigOptions,
	algorithm,
} from './spotCountConfigOptions';

export default function ExperimentConfig({
	onPreviewClick,
	onApplyClick,
	selectedImage,
	previewedImageUrl,
	isSpotLoading,
	isImageLoading,
	hasError,
	onAssignZeroClick,
	onAssignTntcClick,
	spotParametersData,
	previewedSpotCount,
}) {
	const [config, setConfig] = useState({});
	const [applyForAll, setApplyForAll] = useState(false);
	const [selectedAlgo, setSelectedAlgo] = useState(
		algorithm?.values[0].value
	);
	const [dataOptions, setDataOptions] = useState<any[]>([]);
	const { loading: assignToZeroLoading } = useNamespace({
		namespace: namespaces.ASSIGN_TO_ZERO_PATCH,
	});
	const { loading: assignToTNTCLoading } = useNamespace({
		namespace: namespaces.ASSIGN_TO_TNTC_PATCH,
	});
	useEffect(() => {
		if (!!spotParametersData?.algorithm) {
			setSelectedAlgo(spotParametersData.algorithm.toLowerCase());
		}
	}, [spotParametersData]);
	useEffect(() => {
		const spotParametersAlgo = !!spotParametersData?.algorithm
			? spotParametersData.algorithm.toLowerCase()
			: '';
		const [{ value: watershed }, { value: blobs }] = algorithm.values;
		if (selectedAlgo === watershed && spotParametersAlgo !== watershed) {
			setDataOptions(watershedConfigOptions);
		}
		if (selectedAlgo === blobs && spotParametersAlgo !== blobs) {
			setDataOptions(blobsConfigOptions);
		}

		if (selectedAlgo === watershed && spotParametersAlgo === selectedAlgo) {
			const wtershedData = watershedConfigOptions.map(conf => {
				return {
					...conf,
					value: spotParametersData[conf.name],
				};
			});
			setDataOptions(wtershedData);
		}

		if (selectedAlgo === blobs && spotParametersAlgo === selectedAlgo) {
			const blobsData = blobsConfigOptions.map(conf => {
				return {
					...conf,
					value: spotParametersData[conf.name],
				};
			});
			setDataOptions(blobsData);
		}
	}, [spotParametersData, selectedAlgo]);
	useEffect(() => {
		const obj = {};
		dataOptions.forEach(conf => {
			obj[conf.name] = conf.value;
		});
		setConfig(obj);
	}, [dataOptions]);

	const handleChange = (value, name) => {
		const obj = { ...config, [name]: value };
		setConfig(obj);
		const ind = dataOptions.findIndex(d => d.name === name);
		const updatedObj = { ...dataOptions[ind], value: value };
		const spotCountConfig = [
			...dataOptions.slice(0, ind),
			updatedObj,
			...dataOptions.slice(ind + 1),
		];
		setDataOptions(spotCountConfig);
	};

	const handleChangeAlgorithm = evt => {
		setSelectedAlgo(evt.target.value);
	};
	const errors = useMemo(() => {
		const list: any = [];
		if (+config['minRadius'] >= +config['maxRadius']) {
			list.push({
				msg: 'min radius can not be grater then max Radius',
			});
		}
		if (+config['minArea'] >= +config['maxArea']) {
			list.push({
				msg: 'Min Area can not be grater then Max Area',
			});
		}
		if (+config['minIntensity'] >= +config['maxIntensity']) {
			list.push({
				msg: 'min Intensity can not be grater then max Intensity',
			});
		}
		return list;
	}, [config]);
	const handleForAllChange = evt => {
		setApplyForAll(evt.target.checked);
	};
	const renderConfig = item => {
		const disabledThreshold =
			item.name === 'threshold' && config['thType'] !== 'Manual';

		if (item.type === 'select') {
			return (
				<Styled.InputConfigContent key={item.label}>
					<span>{item.label}</span>
					<Form.Control
						as='select'
						onChange={evt =>
							handleChange(evt.target.value, item.name)
						}
						value={item.value}>
						{item.values.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Form.Control>
				</Styled.InputConfigContent>
			);
		}
		return (
			<Styled.ConfigContent key={item.name} isHidden={disabledThreshold}>
				<RangeSliderComponent
					onChange={evt =>
						handleChange(Number(evt.target.value), item.name)
					}
					label={item.label}
					min={item.min}
					max={item.max}
					value={config[item.name] || item.value}
					step={item.step}
					disabled={disabledThreshold}
				/>
			</Styled.ConfigContent>
		);
	};
	const isPreviewButtonDisabled =
		!!errors.length || isSpotLoading || isImageLoading;
	const isApplyButtonDisabled = !!errors.length || isSpotLoading;
	return (
		<Styled.RightContent>
			<Styled.ConfigContainer>
				<Styled.InputConfigContent key='watershed'>
					<span>{algorithm.label}</span>
					<Form.Control
						as='select'
						onChange={handleChangeAlgorithm}
						value={selectedAlgo}>
						{algorithm.values.map(({ label, value }) => (
							<option key={label} value={value}>
								{label}
							</option>
						))}
					</Form.Control>
				</Styled.InputConfigContent>
				{dataOptions.map(renderConfig)}
				<Styled.SaveButtonWrap>
					<Styled.SaveButton
						variant='success'
						onClick={() => onPreviewClick(config, selectedAlgo)}
						disabled={isPreviewButtonDisabled}
						loading={isImageLoading}>
						Preview
					</Styled.SaveButton>
				</Styled.SaveButtonWrap>
				<Styled.ErrorContainer>
					{errors.map((err, index) => (
						<Styled.Error key={index}>{err.msg}</Styled.Error>
					))}
				</Styled.ErrorContainer>
				<When condition={hasError}>
					<Styled.Error>
						something went wrong please try again
					</Styled.Error>
				</When>
			</Styled.ConfigContainer>
			<Styled.ResultImageContainer>
				<When condition={!previewedImageUrl?.length}>
					<img src={selectedImage} alt='' />
				</When>
				<When condition={!!previewedImageUrl?.length}>
					<Styled.ApplyWrapper>
						<Styled.SaveButton
							variant='success'
							onClick={() =>
								onApplyClick(config, selectedAlgo, applyForAll)
							}
							loading={isSpotLoading}
							disabled={isApplyButtonDisabled}>
							Apply
						</Styled.SaveButton>
						<Form.Check
							type='checkbox'
							checked={applyForAll}
							label='For All'
							onChange={handleForAllChange}
						/>
					</Styled.ApplyWrapper>
					<img src={previewedImageUrl} alt='spot count' />
					<Styled.PreviewedSpotNumber>
						{previewedSpotCount}
					</Styled.PreviewedSpotNumber>
				</When>
				<Styled.ImageButtonWrap>
					<Styled.AssignButton
						variant='success'
						onClick={onAssignZeroClick}
						loading={assignToZeroLoading}>
						Assign {'<<0>>'}
					</Styled.AssignButton>
					<Styled.AssignButton
						variant='success'
						onClick={onAssignTntcClick}
						loading={assignToTNTCLoading}>
						Assign {'<<TNTC>>'}
					</Styled.AssignButton>
				</Styled.ImageButtonWrap>
			</Styled.ResultImageContainer>
		</Styled.RightContent>
	);
}
