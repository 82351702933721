import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { deleteEvaluation } from 'core/store/predictions';
import * as Styled from '../styled';

export default function DeleteReview({
	closeModal,
	reviewId,
	evaluationId,
	expertLabelUid,
	predictionId,
}) {
	const dispatch = useDispatch();
	const handleDelete = () => {
		const data = { reviewId, evaluationId };
		dispatch(deleteEvaluation(data, predictionId, expertLabelUid));
	};

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.DELETED_EVALUATION,
		onSuccess: closeModal,
	});

	return (
		<Fragment>
			<Modal.Body>
				<b>{'Are you sure you want to delete this evaluation?'}</b>
			</Modal.Body>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='success' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.DeleteButton
					variant='danger'
					onClick={handleDelete}
					loading={loading}>
					Delete
				</Styled.DeleteButton>
			</Modal.Footer>
		</Fragment>
	);
}
