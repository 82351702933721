import styled from 'styled-components';
import { Alert as AAlert, Row as BRow } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';

export const Container = styled.div`
	width: calc(100vw - 308px);
	min-height: 75vh;
	overflow-x: scroll;
	margin: 20px;
	background-color: ${p => p.theme.white};
	position: relative;
	top: -20px;
	table {
		th {
			white-space: nowrap;
		}
		th:last-of-type
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1),
		th:nth-last-child(2)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1),
		th:nth-last-child(3)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1) {
			position: absolute;
			left: -23.5rem;
		}
		th:last-of-type
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1)
			div,
		th:nth-last-child(2)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1)
			div,
		th:nth-last-child(3)
			div:first-of-type
			div:nth-child(2)
			div:first-of-type
			div:nth-child(1)
			div {
			position: relative;
			left: 0;
		}
		tr {
			cursor: pointer;
		}
		th:first-of-type {
			width: 1px;
			padding: 0 8px;
		}
	}
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px 10px;
	margin: 8px 0;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;

export const SubmitButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px 10px;
	margin: 8px 0;
	background-color: ${p => p.theme.indigo};
	border-radius: 44px;
`;

export const ReviewDone = styled.span`
	color: ${p => p.theme.green};
	font-weight: 700;
`;

export const ReviewInProgress = styled.span`
	color: ${p => p.theme.orange};
	font-weight: 700;
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const DeleteButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.red};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const CloseButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;

export const Content = styled.div`
	padding: 10px;
	input[type='text'] {
		margin: 5px 0 10px 0;
	}
	p {
		color: ${p => p.theme.green};
		margin-top: 10px;
	}
`;

export const Row = styled(BRow)`
	margin: 10px 0;
`;

export const InputWrapper = styled.div`
	position: relative;
	input[name='search'] {
		width: 100%;
		padding: 7px 10px;
		border-radius: 20px;
		border: none;
		background-color: ${p => p.theme.gray_6};
		margin-bottom: 10px;
	}
	svg {
		width: 25px !important;
		height: 25px;
		position: absolute;
		right: 15px;
		top: 7px;
		color: ${p => p.theme.border};
	}
`;

export const UsersWrap = styled.div`
	max-height: 250px;
	margin-bottom: 20px;
	overflow-x: overlay;
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: ${p => p.theme.white};
	}
	::-webkit-scrollbar-thumb {
		background: ${p => p.theme.gray_2};
		border-radius: 7px;
	}
`;

export const NotReviewedStatus = styled.span`
	color: ${p => p.theme.red_4};
	font-weight: 700;
`;

export const ReviewedStatus = styled.span`
	color: ${p => p.theme.green};
	font-weight: 700;
`;

export const UnderReviewStatus = styled.span`
	color: ${p => p.theme.orange};
	font-weight: 700;
`;

export const ConflictStatus = styled.span`
	color: ${p => p.theme.orange};
	font-weight: 700;
`;

export const ProjectSettingWrap = styled.span`
	.modal-footer {
		justify-content: space-around;
	}
`;
