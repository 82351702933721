import React from 'react';
import { Form } from 'react-bootstrap';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';
import Select from 'react-select';
import FormControl from 'react-bootstrap/FormControl';
import ValidationErrorAlert from 'components/Form/Errors/ValidationErrorAlert';

export function FormContainer(props) {
	return (
		<FinalForm
			{...props}
			subscription={{
				hasSubmitErrors: true,
				hasValidationErrors: true,
				pristine: true,
				submitError: true,
				submitFailed: true,
				submitting: true,
			}}
		/>
	);
}

export const InputAdapter = ({
	input,
	meta,
	invalid = meta => meta.touched && meta.invalid,
	valid = meta => false,
	...rest
}) => (
	<Form.Control
		{...input}
		{...rest}
		isInvalid={invalid(meta)}
		isValid={valid(meta)}
	/>
);

export const FormFeedbackAdapter = ({ name }) => (
	<FinalField
		name={name}
		subscription={{ error: true, submitError: true }}
		render={({ meta: { error, submitError } }) =>
			error || submitError ? (
				<FormControl.Feedback type='invalid'>
					{error || submitError}
				</FormControl.Feedback>
			) : null
		}
	/>
);

export const Field = ({ name, showValid = true, ...props }) => {
	const valid = meta => meta?.visited && meta?.valid;
	return (
		<div>
			<FinalField
				valid={showValid ? valid : () => false}
				name={name}
				{...props}
			/>
			{props?.validate && <FormFeedbackAdapter name={name} />}
		</div>
	);
};

export const SelectAdapter = ({
	input,
	multiple,
	options,
	meta,
	placeholder = 'Select...',
	invalid = meta => meta.touched && meta.invalid,
	valid = meta => false,
	...rest
}) => (
	<Form.Control
		{...input}
		as='select'
		isInvalid={invalid(meta)}
		isValid={valid(meta)}
		className={!input.value ? 'is-placeholder' : null}
		multiple={multiple}
		value={!input.value && multiple ? [] : input.value}
		{...rest}>
		{placeholder && (
			<option className='is-placeholder' disabled value=''>
				{placeholder}
			</option>
		)}
		{options[0]?.hasOwnProperty('groupOptions')
			? /* options have groups */
			  options.map((group, groupIndex) => (
					<optgroup label={group.label} key={groupIndex}>
						{group.groupOptions.map((option, optionIndex) => (
							<option
								key={`${groupIndex}-${optionIndex}`}
								value={option.value}>
								{option.label}
							</option>
						))}
					</optgroup>
			  ))
			: /* options don't have groups */
			  options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
			  ))}
	</Form.Control>
);

export const SearchableSelectAdapter = ({
	input,
	meta,
	placeholder = 'Select...',
	invalid = meta.touched && meta.invalid,
	error,
	...rest
}) => {
	return (
		<div>
			<Select {...input} placeholder={placeholder} {...rest} searchable />
			<ValidationErrorAlert
				hasValidationErrors={invalid}
				submitFailed={invalid}
				msg={error}
			/>
		</div>
	);
};
