import React, { useState, useEffect } from 'react';
import { Collapse as BCollapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faCog } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { When } from 'components/When';
import * as Styled from './styled';

interface Props {
	children?: React.ReactNode;
	name: string;
	icon: IconProp;
	isSelected: boolean;
	shouldRenderSetting?: boolean;
	onSettingClick?: Function;
	currentCluster: boolean;
}

export const Collapse = ({
	name,
	children,
	icon,
	isSelected,
	shouldRenderSetting = true,
	onSettingClick,
	currentCluster,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);
	useEffect(() => {
		if (currentCluster) {
			setIsOpen(true);
		}
	}, [currentCluster, setIsOpen]);

	return (
		<Styled.CollapseContainer>
			<Styled.TitleContainer onClick={toggle} isSelected={isSelected}>
				<Styled.Container>
					<FontAwesomeIcon icon={icon} />
					<Styled.Text> {name} </Styled.Text>
				</Styled.Container>
				<Styled.IconContainer>
					<When condition={shouldRenderSetting}>
						<Styled.SettingIcon
							icon={faCog}
							onClick={onSettingClick}
						/>
					</When>
					{isOpen ? (
						<Styled.Icon icon={faSortUp} />
					) : (
						<Styled.CloseIcon icon={faSortDown} />
					)}
				</Styled.IconContainer>
			</Styled.TitleContainer>
			<BCollapse in={isOpen}>
				<div>{children}</div>
			</BCollapse>
		</Styled.CollapseContainer>
	);
};
