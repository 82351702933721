import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { fetchWorkspaceData } from 'core/store/InputGeneration';
import { INPUT_GENERATION_VIEWS } from 'core/consts';
import PlateBarCode from './components/PlateBarCode';
import PlateLayout from './components/PlateLayout';
import Target from './components/Target';
import SampleInformations from './components/SampleInformations';

export default function InputFilesGenerationDetails() {
	const location = useLocation();
	const dispatch = useDispatch();
	const { workspaceDataId }: { workspaceDataId: string } = useParams();
	const { view } = queryString.parse(location.search);

	useEffect(() => {
		dispatch(fetchWorkspaceData(workspaceDataId));
		// eslint-disable-next-line
	}, []);
	const { data } = useNamespace({
		namespace: namespaces.WORSKPACE_DATA,
	});
	const plates = !!data && !!data?.plates ? data.plates : [];
	if (view === INPUT_GENERATION_VIEWS.plateBarcode) {
		return <PlateBarCode data={plates} workspaceDataId={workspaceDataId} />;
	}
	if (view === INPUT_GENERATION_VIEWS.plateLayout) {
		return <PlateLayout workspaceDataId={workspaceDataId} />;
	}
	if (view === INPUT_GENERATION_VIEWS.target) {
		return <Target workspaceDataId={workspaceDataId} />;
	}
	if (view === INPUT_GENERATION_VIEWS.sampleInfos) {
		return <SampleInformations workspaceDataId={workspaceDataId} />;
	}
}
