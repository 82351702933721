import React from 'react';
import { HOME } from 'core/routes';
import { Link } from 'components/Link';
import * as Styled from './styled';

interface Props {
	message: string;
	image: string;
}

export default function ErrorPage({ message, image }: Props) {
	return (
		<Styled.ErrorContainer>
			<Styled.ImageContainer>
				<Styled.ImageError src={image} />
				<Styled.ErrorTitle>{message}</Styled.ErrorTitle>
				<Link to={HOME}>Go back</Link>
			</Styled.ImageContainer>
		</Styled.ErrorContainer>
	);
}
