import axios from 'axios';
import { logout } from 'core/store/auth';
import setupMock from './mock';
import { checkJwtToken } from 'core/store/auth/utils';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL ?? window['API_URL'],
});

export const handleResponse = data => {
	if (data.status === 'success') {
		return data.result;
	} else {
		if (data.errorCode === 'UNKNOWN_ERROR') {
			console.error(data.errorMessage);
			//todo:redirect to oops page
		} else {
			throw new Error(data.errorMessage);
		}
	}
};

export const handleSpotResponse = response => {
	if (response.status === 200) {
		return response.data;
	} else {
		throw new Error('Internal Server Error');
	}
};

export const get = (url, params?) => {
	return axiosInstance.get(url, params).then(
		response => {
			return handleResponse(response.data);
		},
		error => {
			return Promise.reject(error);
			//todo:redirect to oops page
		}
	);
};

export const post = (url, data?) => {
	return axiosInstance.post(url, data).then(
		response => {
			return handleResponse(response.data);
		},
		error => {
			return Promise.reject(error);
			//todo:redirect to oops page
		}
	);
};

export const setupAxiosInterceptor = store => {
	axiosInstance.interceptors.request.use(
		config => {
			const {
				auth: { token },
			} = store.getState();
			if (token && checkJwtToken(token)) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		err => Promise.reject(err)
	);

	axiosInstance.interceptors.response.use(
		response => response,
		error => {
			if (error.response && error.response.status === 401) {
				store.dispatch(logout());
			}
			return Promise.reject(error);
		}
	);
};

setupMock(axiosInstance);

export { axiosInstance as Api };
