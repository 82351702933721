export const CHANNELS = {
	predictions: 'predictions',
	predictionsData: 'predictionsData',
	trainings: 'trainings',
	trainingsData: 'trainingsData',
	experiments: 'experiments',
};
export const CHANNEL_NAME = 'elispot';
const APP_KEY = '1649b0394320e68e5ac8';
const PUSHER_CLUSTER = 'eu';

export const PUSHER_CONFIG = {
	clientKey: APP_KEY,
	cluster: PUSHER_CLUSTER,
};
