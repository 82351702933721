import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faDownload } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	fetchWorkspaceData,
	fetchStudies,
	updateSampleWells,
	fetchSapmlesWithSelectedWells,
	downloadSampleInfos,
	ChangeCardColor,
} from 'core/store/InputGeneration';
import { SubHeader } from 'components/SubHeader';
import { ModalProvider } from 'components/Modal';
import { Link } from 'components/Link';
import { INPUT_FILE_GENERATION } from 'core/routes';
import {
	INPUT_GENERATION_VIEWS,
	COLUMNS_HEADER,
	ROWS_COUNTER,
} from 'core/consts';
import { platesRowsHeaders } from 'helpers';
import { handleMouseUpWellsSelection } from './helpers';
import ToggleButtons from './ToggleButtons';
import CreateStudyModal from './SampleInformationsComponents/CreateStudy/CreateStudyModal';
import SampleInformationsComponents from './SampleInformationsComponents';
import * as Styled from './styled';

const BUTTONS = [
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.plateBarcode,
		title: 'Plate Barcode',
	},
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.plateLayout,
		title: 'Plate Layout',
	},
	{
		active: true,
		view: INPUT_GENERATION_VIEWS.sampleInfos,
		title: 'Sample Information',
	},
	{
		active: false,
		view: INPUT_GENERATION_VIEWS.target,
		title: 'Target',
	},
];

export default function SampleInformations({ workspaceDataId }) {
	const dispatch = useDispatch();
	const [selectedSample, setSelectedSample] = useState(null);
	const [firstSelectedWell, setFirstSelectedWell] = useState('');
	const [selectedWells, setSelectedWells] = useState<any[]>([]);
	const [isSelectionChanged, setIsSelectionChanged] = useState(false);
	const [plateBarcode, setPlateBarcode] = useState({
		index: 1,
		size: 0,
		currentPlateBarcode: {
			plateId: null,
			plateBarcode: null,
			availableSampleWells: [] as any[],
			availableStimulusInIvsWells: [] as any[],
			availableStimulusInReadoutWells: [] as any[],
		},
	});

	const { data: workspaceData } = useNamespace({
		namespace: namespaces.WORSKPACE_DATA,
	});

	const { data: studies } = useNamespace({
		namespace: namespaces.STUDIES,
	});

	const {
		data: samplesSelectedWells,
		isInitialFetch: samplesWithSelectedWellsLoading,
	} = useNamespace({
		namespace: namespaces.SAMPLES_WITH_SELECTED_WELLS,
	});

	useNamespace({
		namespace: namespaces.UPDATED_WELLS,
		onSuccess: () => {
			dispatch(fetchWorkspaceData(workspaceDataId));
		},
	});

	useNamespace({
		namespace: namespaces.RESETED_WELLS,
		onSuccess: () => {
			dispatch(fetchWorkspaceData(workspaceDataId));
		},
	});

	useNamespace({
		namespace: namespaces.DELETED_WELLS,
		onSuccess: () => {
			dispatch(fetchWorkspaceData(workspaceDataId));
			clearCardSelection();
		},
	});

	const { data: downloadedSampleInfos } = useNamespace({
		namespace: namespaces.DOWNLOADED_SAMPLE_INFOS,
	});

	const { success: changedColorSuccess } = useNamespace({
		namespace: namespaces.CHANGED_COLOR,
	});

	useEffect(() => {
		if (workspaceData?.plates?.length) {
			setPlateBarcode({
				...plateBarcode,
				size: workspaceData.size,
				currentPlateBarcode:
					workspaceData.plates[plateBarcode.index - 1],
			});
		}
		// eslint-disable-next-line
	}, [workspaceData]);

	useEffect(() => {
		if (
			workspaceData?.studies?.length &&
			(!studies || changedColorSuccess)
		) {
			dispatch(fetchStudies(workspaceDataId));
		}
	}, [
		plateBarcode,
		dispatch,
		workspaceDataId,
		workspaceData,
		studies,
		changedColorSuccess,
	]);

	useEffect(() => {
		if (!!plateBarcode?.currentPlateBarcode?.plateId) {
			dispatch(
				fetchSapmlesWithSelectedWells(
					workspaceDataId,
					plateBarcode.currentPlateBarcode.plateId
				)
			);
		}
	}, [dispatch, plateBarcode.currentPlateBarcode.plateId, workspaceDataId]);

	useEffect(() => {
		dispatch(fetchWorkspaceData(workspaceDataId));
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		setSelectedWells(
			!!samplesSelectedWells
				? samplesSelectedWells.map(wells => ({
						selectedWells: wells?.selectedWells,
						id: wells?.sampleId,
				  }))
				: []
		);
		// eslint-disable-next-line
	}, [selectedSample, samplesSelectedWells]);
	const handlePlateBarcodeChange = index => {
		setPlateBarcode({
			...plateBarcode,
			index,
			currentPlateBarcode: workspaceData.plates[index - 1],
		});
		setSelectedSample(null);
	};

	const clearCardSelection = () => {
		setSelectedSample(null);
	};
	const getSingleCardBySelectedWellPosition = position =>
		selectedWells?.find(({ selectedWells: wells }) =>
			wells?.find(pos => pos === position)
		);

	const handleOnMouseDown = (position, isWellSelectable) => {
		if (!!isWellSelectable && !!selectedSample) {
			setFirstSelectedWell(position);
		}
	};

	const handleOnMouseUP = (position, availableWellsType) => {
		const singleCardSelectedWells = getSingleCardBySelectedWellPosition(
			position
		);
		handleMouseUpWellsSelection(
			selectedSample,
			position,
			firstSelectedWell,
			singleCardSelectedWells,
			setPlateBarcode,
			plateBarcode,
			setSelectedWells,
			selectedWells,
			ROWS_COUNTER[plateBarcode.size],
			COLUMNS_HEADER[plateBarcode.size],
			availableWellsType,
			setIsSelectionChanged
		);
	};

	const handleUpdateWells = () => {
		setIsSelectionChanged(false);
		const tmpWells = selectedWells.find(({ id }) => id === selectedSample);
		const formattedData = {
			wells: tmpWells.selectedWells,
		};
		const studyId = studies?.find(({ samples }) =>
			samples?.find(({ id }) => id === tmpWells?.id)
		)?.id;

		dispatch(
			updateSampleWells(
				formattedData,
				workspaceDataId,
				workspaceData.plates[plateBarcode.index - 1].plateId,
				studyId,
				tmpWells.id
			)
		);
	};
	useEffect(() => {
		if (isSelectionChanged) {
			handleUpdateWells();
		}
		// eslint-disable-next-line
	}, [isSelectionChanged]);
	const handleSampleClick = id => {
		setSelectedSample(id);
		setPlateBarcode({
			...plateBarcode,
			currentPlateBarcode: workspaceData.plates[plateBarcode.index - 1],
		});
	};
	const handleDownloadSample = () => {
		dispatch(downloadSampleInfos(workspaceDataId));
	};
	const changeColorHandler = (type, id, studyId) => {
		const data = {
			studyId,
		};
		dispatch(ChangeCardColor(data, workspaceDataId, type, id));
	};
	useEffect(() => {
		try {
			if (!!downloadedSampleInfos) {
				const columnNames = Object.keys(downloadedSampleInfos[0])
					.map(colName => {
						if (colName.slice(0, 3) === 'mes') {
							return `${colName.replace('mes', 'MES')}`;
						}
						return `${colName
							.charAt(0)
							.toUpperCase()}${colName.slice(1)}`;
					})
					.join(',');
				const tsvData = downloadedSampleInfos
					.map(e => {
						return Object.values(e)
							.map(value => {
								const val =
									!!value && typeof value === 'string'
										? value.replace(/\n/g, ' ')
										: value;
								return val;
							})
							.join(',');
					})
					.join('\n');
				const fileContent = columnNames + '\n' + tsvData;
				const content = new Blob([fileContent], {
					type: 'text/csv;charset=utf-8',
				});
				saveAs(content, `sample_infos_${workspaceDataId}.csv`);
			}
		} catch (e) {
			return;
		}
	}, [downloadedSampleInfos, workspaceDataId]);
	const columnHeader = !!plateBarcode.size
		? COLUMNS_HEADER[plateBarcode.size]
		: [];

	const rowsHeader = platesRowsHeaders(ROWS_COUNTER[plateBarcode.size]);

	return (
		<>
			<SubHeader
				current={workspaceData?.name}
				pre={
					<span>
						<Link to={INPUT_FILE_GENERATION}>
							Input File Creation
						</Link>
					</span>
				}
			/>
			<Styled.Container>
				<Styled.ContainerHeaderWrapper>
					<ToggleButtons Buttons={BUTTONS} />
					<ModalProvider
						component={CreateStudyModal}
						title='Add Study'
						trigger={
							<Styled.AddButton variant='success'>
								Add Study
								<FontAwesomeIcon icon={faPlusSquare} />
							</Styled.AddButton>
						}
						modalProps={{
							plateId: plateBarcode.currentPlateBarcode.plateId,
							workspaceDataId,
						}}
					/>
					<Styled.DownloadButton
						variant='success'
						onClick={handleDownloadSample}>
						<FontAwesomeIcon icon={faDownload} />
					</Styled.DownloadButton>
				</Styled.ContainerHeaderWrapper>
				<Styled.Content>
					<SampleInformationsComponents
						studies={studies}
						workspaceData={workspaceData}
						handlePlateBarcodeChange={handlePlateBarcodeChange}
						changeColorHandler={changeColorHandler}
						plateBarcode={plateBarcode}
						columnHeader={columnHeader}
						rowsHeader={rowsHeader}
						handleOnMouseDown={handleOnMouseDown}
						handleOnMouseUP={handleOnMouseUP}
						workspaceDataId={workspaceDataId}
						availablewells={
							plateBarcode.currentPlateBarcode
								.availableSampleWells
						}
						availableWellsType='availableSampleWells'
						getSingleCardBySelectedWellPosition={
							getSingleCardBySelectedWellPosition
						}
						handleSampleClick={handleSampleClick}
						selectedSample={selectedSample}
						plateSize={plateBarcode.size}
						loading={samplesWithSelectedWellsLoading}
					/>
				</Styled.Content>
			</Styled.Container>
		</>
	);
}
