import React, { useEffect, useMemo } from 'react';
import {
	useTable,
	usePagination,
	useSortBy,
	useFilters,
	useGlobalFilter,
} from 'react-table';
import { useDispatch } from 'react-redux';
import { updateFilter } from 'core/store/filter';
import { Pagination, Spinner } from 'react-bootstrap';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import useWindowSize from 'hooks/useWindowSize';
import SortInactiveSvg from 'assets/img/Elispot_filter_sort_inactive_state.svg';
import SortActiveSvg from 'assets/img/Elispot_filter_sort_active_state.svg';
import * as Styled from './styled';

interface Props {
	columns: any[];
	data: any[];
	onRowClick?: Function;
	withPagination?: boolean;
	loading?: boolean;
	withFilters?: boolean;
	filterName?: string;
	pageSize?: number;
	rowProps?: Function;
}
export function Table({
	columns,
	data,
	withPagination = false,
	onRowClick = f => f,
	loading = false,
	withFilters = false,
	filterName = '',
	pageSize = 16,
	rowProps = () => ({}),
}: Props) {
	const TABLE_ROW_HEIGHT = 35;
	const DEFAULT_PAGE_SIZE = 16;
	const MINIMUM_PAGE_HEIGHT = 1020;
	const { height } = useWindowSize();
	const computedPageSize = useMemo(() => {
		const _pageSize = pageSize || DEFAULT_PAGE_SIZE;
		return height > MINIMUM_PAGE_HEIGHT
			? (height - MINIMUM_PAGE_HEIGHT) / TABLE_ROW_HEIGHT + _pageSize
			: _pageSize;
	}, [height, pageSize]);

	const {
		getTableProps,
		headerGroups,
		prepareRow,
		page,
		rows,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		pageOptions,
		previousPage,
		state: { pageIndex, filters },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: computedPageSize },
		},

		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);
	const dispatch = useDispatch();
	const handleRowClick = (event, row) => {
		const nodeName = event.target.nodeName;
		if (nodeName === 'TD') {
			onRowClick(row);
		}
	};
	const fromData = withPagination ? page : rows;
	const columnsNumber = headerGroups[0]?.headers?.length;
	useEffect(() => {
		if (!!filterName.length) {
			return dispatch(updateFilter(filterName, filters));
		}
	}, [filters, filterName, dispatch]);
	return (
		<Styled.Container>
			<Styled.Table {...getTableProps()} size='sm' responsive hover>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(
								({
									render,
									isSorted,
									isSortedDesc,
									getSortByToggleProps,
									getHeaderProps,
									canFilter,
									filterValue,
								}) => {
									const headerProps = getHeaderProps(
										getSortByToggleProps()
									);
									const renderSort = () => {
										if (isSorted) {
											if (isSortedDesc) {
												return (
													<Styled.SortIcon
														icon={faSortDown}
													/>
												);
											}
											return (
												<Styled.SortUpIcon
													icon={faSortUp}
												/>
											);
										}
									};

									const renderFilter = () => {
										if (withFilters && canFilter) {
											return render('Filter');
										}
									};
									const hasFilter = filterValue?.length;
									return (
										<th key={headerProps.key}>
											<Styled.HeaderContent>
												<div {...headerProps}>
													{render('Header')}
													{renderSort()}
												</div>
												<When
													condition={
														withFilters && canFilter
													}>
													<Styled.Dropdown>
														<Styled.DropdownToggle>
															<Styled.Image
																src={
																	hasFilter
																		? SortActiveSvg
																		: SortInactiveSvg
																}
																alt=''
																isActive={
																	hasFilter
																}
															/>
														</Styled.DropdownToggle>
														<Styled.DropdownMenu>
															{renderFilter()}
														</Styled.DropdownMenu>
													</Styled.Dropdown>
												</When>
											</Styled.HeaderContent>
										</th>
									);
								}
							)}
						</tr>
					))}
				</thead>
				<tbody>
					<When condition={!fromData.length}>
						{loading && (
							<tr>
								<td colSpan={columnsNumber}>
									<Spinner
										as='span'
										animation='border'
										role='status'
										variant='success'
									/>
								</td>
							</tr>
						)}
					</When>
					<When condition={!fromData.length && !loading}>
						<tr>
							<td colSpan={columnsNumber}>There is no data</td>
						</tr>
					</When>

					<When condition={!!fromData.length}>
						{fromData.map(row => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps(rowProps(row))}
									onClick={event =>
										handleRowClick(event, row)
									}>
									{row.cells.map(cell => {
										return (
											<td {...cell.getCellProps()}>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							);
						})}
					</When>
				</tbody>
			</Styled.Table>
			<When condition={withPagination}>
				<Styled.PaginationWrap>
					<Pagination>
						<Pagination.Item
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}>
							{'<<'}
						</Pagination.Item>
						<Pagination.Item
							onClick={previousPage}
							disabled={!canPreviousPage}>
							Prev
						</Pagination.Item>
						<Pagination.Item
							onClick={nextPage}
							disabled={!canNextPage}>
							Next
						</Pagination.Item>
						<Pagination.Item
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}>
							{'>>'}
						</Pagination.Item>
						<Styled.PageInfo>
							<span>
								Page &nbsp;
								<b>
									{pageIndex + 1} of {pageOptions.length}
								</b>
							</span>
						</Styled.PageInfo>
					</Pagination>
				</Styled.PaginationWrap>
			</When>
		</Styled.Container>
	);
}
