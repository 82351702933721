import React, { useEffect, Fragment, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { WORKSPACE_HOME } from 'core/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDownload,
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { When } from 'components/When';
import { projectIdSelector } from 'core/store/auth';
import {
	experimentDetailsSelector,
	fetchPredictionsResults,
	fetchPrediction,
	fetchReviews,
	inProgressReviewSelector,
	fetchEvaluations,
	predictionResultsSelector,
	fetchPredictionsResultsFamily,
	predictionResultsFamliySelector,
	fetchFullPlate,
	fetchCompactView,
} from 'core/store/predictions';
import { currentProjectSelector } from 'core/store/clusters';
import { singleFilterSelector } from 'core/store/filter';
import { takeScreenshot } from 'helpers';
import useNamespace from 'hooks/useNamespace';
import { Link } from 'components/Link';
import { SubHeader } from 'components/SubHeader';
import PusherMessage from 'components/PusherMessage';
import { PREDICTIONS } from 'core/routes';
import * as namespaces from 'core/consts/namespaces';
import { TABLE_LEGEND_SQUARES, PLATE_VIEW_DATA_OPTIONS } from 'core/consts';
import { CHANNELS } from 'core/consts/pusherConfig';
import ExperimentDetailsTable from './components/ExperimentDetailsTable';
import CompactView from './components/CompactView';
import ExperimentView from './components/ExperimentView';
import EvaluationTable from './components/EvaluationTable';
import FullPlate from './components/FullPlate';
import * as Styled from './styled';

interface Params {
	predictionId: string;
	experimentId: string;
}

export default function ExperimentDetails() {
	const { predictionId, experimentId }: Params = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedView, setSelectedView] = useState({
		...PLATE_VIEW_DATA_OPTIONS[0],
	});
	const { projectId } = useSelector(projectIdSelector);
	const currentProject = useSelector(currentProjectSelector(projectId));
	const experimentDetails = useSelector(
		experimentDetailsSelector(experimentId)
	);
	const currentStimulusInIVS = !!experimentDetails
		? experimentDetails['Stimulus in IVS']
		: null;
	const folderName = experimentDetails?.['Plate Barcode'];
	const inProgressReview = useSelector(inProgressReviewSelector);

	const reviews = useNamespace({
		namespace: namespaces.REVIEWS,
	});
	const { data, loading } = useNamespace({
		namespace: namespaces.EVALUATIONS,
	});

	const { loading: predictionResultsFamilyLoading } = useNamespace({
		namespace: namespaces.PREDICTION_RESULTS_FAMILY,
	});
	const predictionResultsFamilyData = useSelector(
		predictionResultsFamliySelector
	);

	const { data: { predictionDataId, predictionName } = {} } = useNamespace({
		namespace: namespaces.SINGLE_PREDICTION,
	});

	const { data: fullPlate, loading: fullPlateLoading } = useNamespace({
		namespace: namespaces.PREDICTION_FULL_PLATE,
	});

	const { data: compactView, loading: compactViewLoading } = useNamespace({
		namespace: namespaces.COMPACT_VIEW,
	});

	const filterName = 'experiments';
	const experimentsFilters = useSelector(singleFilterSelector(filterName));
	const results = useSelector(predictionResultsSelector);
	const filteredData =
		!!results && !!experimentsFilters
			? results.filter(r => {
					return (
						experimentsFilters
							//.filter(fe => !!fe.value.length)
							.every(fe => {
								return fe.value.includes(
									(!!r[fe.id] ||
										r[fe.id] === 0 ||
										r[fe.id] === false) &&
										r[fe.id].toString()
								);
							})
					);
			  })
			: results;
	const currentExperimentIndex =
		!!filteredData &&
		!!filteredData.length &&
		filteredData.findIndex(o => o.Unique_ID === experimentId);
	const previousExperimentId =
		!!currentExperimentIndex &&
		!!filteredData[currentExperimentIndex - 1] &&
		filteredData[currentExperimentIndex - 1]['Unique_ID'];
	const nextExperimentId =
		!!filteredData &&
		!!filteredData[currentExperimentIndex + 1] &&
		filteredData[currentExperimentIndex + 1]['Unique_ID'];

	const experimentExistsInCurrentFamily =
		!!predictionResultsFamilyData &&
		!!predictionResultsFamilyData.length &&
		predictionResultsFamilyData.filter(
			item => item.Unique_ID === experimentId
		);

	const refreshResultsFamily = useMemo(() => {
		return (
			!experimentExistsInCurrentFamily ||
			!experimentExistsInCurrentFamily.length ||
			!predictionResultsFamilyData
		);
	}, [experimentExistsInCurrentFamily, predictionResultsFamilyData]);

	const displayExperiments = useMemo(() => {
		return (
			!!predictionResultsFamilyData?.length &&
			!predictionResultsFamilyLoading &&
			!!compactView?.familyRows &&
			!compactViewLoading
		);
	}, [
		predictionResultsFamilyLoading,
		predictionResultsFamilyData,
		compactViewLoading,
		compactView,
	]);
	useEffect(() => {
		if (!projectId) {
			history.push(WORKSPACE_HOME);
		}
		if (!!projectId) {
			dispatch(fetchReviews(predictionId));
			dispatch(fetchPrediction(predictionId));
			if (!!refreshResultsFamily) {
				dispatch(
					fetchPredictionsResultsFamily(predictionId, experimentId)
				);
				dispatch(fetchCompactView(predictionId, experimentId));
			}
			if (!experimentDetails) {
				dispatch(fetchPredictionsResults(predictionId));
			}
			if (!!experimentDetails) {
				dispatch(
					fetchEvaluations(predictionId, experimentDetails.Unique_ID)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, projectId, experimentDetails]);
	const refreshCompactView = () =>
		dispatch(fetchCompactView(predictionId, experimentId));
	if (!experimentDetails) return <div />;

	const handleExport = async () => {
		try {
			const experimentTableId = 'experiment-images';
			const fullPlateTableId = 'full-plate-images';
			const image = !fullPlateView
				? await takeScreenshot(experimentTableId)
				: await takeScreenshot(fullPlateTableId);
			if (!fullPlateView) {
				saveAs(image, `${experimentDetails.Unique_ID}_images.png`);
			} else {
				saveAs(image, `${folderName}_images.png`);
			}
		} catch (e) {
			return;
		}
	};

	const refreshExperiments = ({ predictionId: receivedPredictionId }) => {
		if (predictionId === receivedPredictionId) {
			return dispatch(fetchPredictionsResults(predictionId));
		}
		return;
	};
	const viewChangeHandler = event => {
		switch (event?.target?.value) {
			case PLATE_VIEW_DATA_OPTIONS[1].value:
				dispatch(
					fetchFullPlate(
						predictionId,
						experimentDetails['Plate Barcode']
					)
				);
				setSelectedView({
					...PLATE_VIEW_DATA_OPTIONS[1],
				});
				break;
			case PLATE_VIEW_DATA_OPTIONS[2].value:
				dispatch(fetchCompactView(predictionId, experimentId));
				setSelectedView({ ...PLATE_VIEW_DATA_OPTIONS[2] });
				break;

			default:
				dispatch(fetchCompactView(predictionId, experimentId));
				setSelectedView({ ...PLATE_VIEW_DATA_OPTIONS[0] });
				break;
		}
	};

	const compactViewSelected =
		selectedView.value === PLATE_VIEW_DATA_OPTIONS[0].value;
	const fullPlateView =
		selectedView.value === PLATE_VIEW_DATA_OPTIONS[1].value;
	const experimentViewSelected =
		selectedView.value === PLATE_VIEW_DATA_OPTIONS[2].value;

	return (
		<Fragment>
			<PusherMessage
				callback={refreshExperiments}
				event={CHANNELS.experiments}
			/>
			<When condition={!!displayExperiments}>
				<SubHeader
					pre={
						<span>
							Projects {'> '}
							<Link to={`${PREDICTIONS}?view=results`}>
								{currentProject?.projectName}
							</Link>
							{'> '}
							<Link to={`${PREDICTIONS}/${predictionId}`}>
								{predictionName}
							</Link>
						</span>
					}
					current='View'
				/>
				<When condition={!compactViewLoading && !fullPlateLoading}>
					<Styled.ButtonsWrap>
						<When condition={!fullPlateView}>
							<Styled.NexTPreviousWrap>
								<When condition={!!previousExperimentId}>
									<Styled.PreviousLink
										to={`${PREDICTIONS}/${predictionId}/${previousExperimentId}`}>
										<FontAwesomeIcon icon={faChevronLeft} />
										Previous &nbsp;{' '}
									</Styled.PreviousLink>
								</When>
								<When condition={!previousExperimentId}>
									<Styled.DisabledPreviousLink
										to='#'
										disabled>
										<FontAwesomeIcon icon={faChevronLeft} />
										Previous &nbsp;{' '}
									</Styled.DisabledPreviousLink>
								</When>
								<When condition={!!nextExperimentId}>
									<Styled.NextLink
										to={`${PREDICTIONS}/${predictionId}/${nextExperimentId}`}>
										Next{' '}
										<FontAwesomeIcon
											icon={faChevronRight}
										/>
									</Styled.NextLink>
								</When>
								<When condition={!nextExperimentId}>
									<Styled.DisabledNextLink to='#' disabled>
										Next{' '}
										<FontAwesomeIcon
											icon={faChevronRight}
										/>
									</Styled.DisabledNextLink>
								</When>
							</Styled.NexTPreviousWrap>
							<When condition={!!compactView?.familyRows?.length}>
								<Styled.TableLegend>
									<p>Table Legend</p>
									{TABLE_LEGEND_SQUARES.map(square => (
										<Styled.ColorSquareWrap
											key={square.color}>
											<Styled.ColorSquare
												bg={square.color}
											/>
											<p> {square.text}</p>
										</Styled.ColorSquareWrap>
									))}
								</Styled.TableLegend>
							</When>
						</When>
						<When
							condition={
								!!fullPlateView &&
								!!fullPlate?.length &&
								!fullPlateLoading
							}>
							<Styled.TableLegend>
								<p>Table Legend</p>
								{TABLE_LEGEND_SQUARES.map(square => (
									<Styled.ColorSquareWrap key={square.color}>
										<Styled.ColorSquare bg={square.color} />
										<p> {square.text}</p>
									</Styled.ColorSquareWrap>
								))}
							</Styled.TableLegend>
						</When>
						<Styled.SuccessButton
							variant='success'
							onClick={handleExport}>
							Export &nbsp;
							<FontAwesomeIcon icon={faDownload} />
						</Styled.SuccessButton>
						<Styled.SelectWrapper>
							<select
								name='view'
								onChange={viewChangeHandler}
								value={selectedView.value}>
								{PLATE_VIEW_DATA_OPTIONS.map(option => (
									<option
										value={option.value}
										key={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</Styled.SelectWrapper>
					</Styled.ButtonsWrap>
				</When>
				<When condition={!!compactViewSelected}>
					<Styled.Container>
						<ExperimentDetailsTable
							experiment={experimentDetails}
						/>
						<When
							condition={
								!compactViewLoading &&
								!!compactView?.familyRows?.length
							}>
							<CompactView
								compactViewData={compactView?.familyRows}
								predictionDataId={predictionDataId}
								folderName={folderName}
								predictionId={predictionId}
								refreshCompactView={refreshCompactView}
								currentExperimentId={experimentId}
								tableHeader={compactView?.numbers}
							/>
						</When>
						<When condition={!!data}>
							<EvaluationTable
								data={data}
								loading={loading}
								currentReview={inProgressReview}
								predictionId={predictionId}
								experiment={experimentDetails}
								reviews={reviews.data}
							/>
						</When>
					</Styled.Container>
				</When>
				<When condition={!!experimentViewSelected}>
					<Styled.Container>
						<ExperimentDetailsTable
							experiment={experimentDetails}
						/>
						<When
							condition={
								!compactViewLoading &&
								!!compactView?.familyRows?.length
							}>
							<ExperimentView
								compactViewData={compactView?.familyRows}
								predictionDataId={predictionDataId}
								folderName={folderName}
								predictionId={predictionId}
								refreshCompactView={refreshCompactView}
								currentExperimentId={experimentId}
								tableHeader={compactView?.numbers}
							/>
						</When>
						<When condition={!!data}>
							<EvaluationTable
								data={data}
								loading={loading}
								currentReview={inProgressReview}
								predictionId={predictionId}
								experiment={experimentDetails}
								reviews={reviews.data}
							/>
						</When>
					</Styled.Container>
				</When>
				<When condition={!!fullPlateView}>
					<When condition={!!fullPlate?.length && !fullPlateLoading}>
						<FullPlate
							fullplateData={fullPlate}
							folderName={folderName}
							predictionDataId={predictionDataId}
							currentStimulusInIVS={currentStimulusInIVS}
						/>
					</When>
				</When>
			</When>
			<When
				condition={
					!!predictionResultsFamilyLoading ||
					!!fullPlateLoading ||
					!!compactViewLoading
				}>
				<Styled.LoadingWrap>
					<Spinner animation='border'></Spinner> Loading Data ...
				</Styled.LoadingWrap>
			</When>
		</Fragment>
	);
}
