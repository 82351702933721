import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html,
    body {
        margin: 0;
        background: ${p => p.theme.background};
        font-family: 'Nunito Sans', sans-serif;
    }   
    p {
        color : ${p => p.theme.text};
    }
`;
