import React from 'react';
import { When } from 'components/When';
import { SPOT_COUNT_MINIO_URL } from 'core/consts';
import * as Styled from '../styled';

export default function ExperimentImage({
	img,
	imageIndex,
	missingImage,
	singleCount,
	imageKey,
	spotCountImagePath,
	hiddenImage,
	getImageUrl,
	handleImageClick,
	isValidExp,
	rowIndex,
	uniqueId,
	borderColor,
	imageExtension,
}) {
	const getPreCalculatedImgUrl = preCalculatedImg => {
		if (!!preCalculatedImg?.length) {
			const url = `${SPOT_COUNT_MINIO_URL}/${preCalculatedImg}`;
			return url;
		}
		return '';
	};
	const preCalculatedImgUrl = getPreCalculatedImgUrl(spotCountImagePath);
	const count = singleCount === -1 ? 'TNTC' : singleCount;
	const isCountExists = singleCount !== 'N/A' && singleCount !== -9;
	if (!img || !!hiddenImage) return <div></div>;
	return (
		<Styled.ImageSpotCountWrapper borderColor={borderColor}>
			<Styled.ImageContainer>
				<Styled.Image
					src={getImageUrl(img, missingImage, imageExtension)}
					onClick={() =>
						isValidExp &&
						!missingImage &&
						handleImageClick(
							img,
							rowIndex,
							imageIndex,
							uniqueId,
							imageKey,
							preCalculatedImgUrl,
							count,
							imageExtension
						)
					}
				/>
			</Styled.ImageContainer>
			<When condition={isCountExists}>
				<Styled.SpotNumber>{count}</Styled.SpotNumber>
			</When>
		</Styled.ImageSpotCountWrapper>
	);
}
