import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field, SelectAdapter, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import * as Styled from './styled';
import {
	NumericFields,
	SelectionFields,
	FORM_FIELDS,
} from './consts/modelParamsFields';

export default function ModelParamsSubForm({ form, trainingParams }) {
	const { models = [], baseCnns = [] } = trainingParams;
	const modelOptions = models.map(value => ({
		label: value,
		value,
	}));
	const baseCnnsOptions = baseCnns.map(value => ({
		label: value,
		value,
	}));

	return (
		<Styled.Content>
			<b>Model Parameters</b>
			<Styled.Fields>
				<Row>
					<Col xs='6'>
						<Form.Group>
							<Form.Label>Model</Form.Label>
							<Field
								name={FORM_FIELDS.model}
								showValid={false}
								component={SelectAdapter}
								type='select'
								options={modelOptions || []}
								validate={validateRequired()}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs='6'>
						<Form.Group>
							<Form.Label>Base CNN</Form.Label>
							<Field
								name={FORM_FIELDS.baseCnn}
								showValid={false}
								component={SelectAdapter}
								type='select'
								options={baseCnnsOptions || []}
								validate={validateRequired()}
							/>
						</Form.Group>
					</Col>
				</Row>
				{NumericFields.map(field => (
					<Styled.CheckContainer key={field.name}>
						<Form.Label>{field.label}</Form.Label>
						<Field
							showValid={false}
							name={field.name}
							key={field.name}
							component={InputAdapter}
							type='number'
							min={field.min || '0'}
							max={field.max || undefined}
							validate={validateRequired()}
							step={field.step || '1'}
						/>
					</Styled.CheckContainer>
				))}
				{SelectionFields.map(field => (
					<Row key={field.name}>
						<Col xs='6'>
							<Form.Group>
								<Form.Label>{field.label}</Form.Label>
								<Field
									name={field.name}
									showValid={false}
									component={SelectAdapter}
									validate={validateRequired()}
									type='select'
									options={
										trainingParams[field.optionsField]?.map(
											value => ({
												label: value,
												value,
											})
										) || []
									}
								/>
							</Form.Group>
						</Col>
					</Row>
				))}
			</Styled.Fields>
		</Styled.Content>
	);
}
