import React from 'react';
import { PLATE_SIZE } from 'core/consts';
import WellImage from 'assets/img/well.png';
import * as Styled from './styled';

interface Props {
	id: null | string;
	columnHeader: string;
	rowsHeader: number[];
	handleOnMouseDown: Function;
	handleOnMouseUP: Function;
	getWellColor: Function;
	availablewells: string[];
	availableWellsType: string;
	getSingleCardBySelectedWellPosition: Function;
	visitsWells?: null | string[];
	stimulusInIvsWells?: null | string[];
	redaoutsWells?: null | string[];
	plateSize?: number;
}

export default function FullPlateRow({
	id,
	columnHeader,
	rowsHeader,
	handleOnMouseDown,
	handleOnMouseUP,
	getWellColor,
	availablewells,
	availableWellsType,
	getSingleCardBySelectedWellPosition,
	visitsWells,
	stimulusInIvsWells,
	redaoutsWells,
	plateSize,
}: Props) {
	return (
		<tr>
			<td>{columnHeader}</td>
			{rowsHeader.map(index => {
				const selectedId = getSingleCardBySelectedWellPosition(
					`${columnHeader}${index}`
				)?.id;
				const isWellAvailable =
					!!availablewells.find(
						elem => elem === `${columnHeader}${index}`
					) ?? true;
				const isWellSelectable = !!(
					!selectedId ||
					isWellAvailable ||
					selectedId === id
				);

				/* Is Well selected in Visits  */
				const isWellSelectedInVisits = !!visitsWells?.length
					? !!visitsWells.find(
							elem => elem === `${columnHeader}${index}`
					  )
					: false;
				/* Is Well selected in IVS  */
				const isWellSelectedInIvs = !!stimulusInIvsWells?.length
					? !!stimulusInIvsWells.find(
							elem => elem === `${columnHeader}${index}`
					  )
					: false;
				/* Is Well selected in Readout  */
				const isWellSelectedInReadout = !!redaoutsWells?.length
					? !!redaoutsWells.find(
							elem => elem === `${columnHeader}${index}`
					  )
					: false;

				return (
					<Styled.FullPlateColmun key={`${columnHeader}${index}`}>
						<Styled.FullPlateImageWrap
							$isSelected={!!selectedId}
							$color={getWellColor(`${columnHeader}${index}`)}
							$isWellSelectable={isWellSelectable}
							$isWellParentCardSelected={selectedId === id}
							$isWellSelectedInVisits={isWellSelectedInVisits}
							$isWellSelectedInIvs={isWellSelectedInIvs}
							$isWellSelectedInReadout={isWellSelectedInReadout}
							$isHugePlate={plateSize === PLATE_SIZE[1]}
							onMouseDown={() =>
								handleOnMouseDown(
									`${columnHeader}${index}`,
									isWellSelectable
								)
							}
							onMouseUp={() =>
								handleOnMouseUP(
									`${columnHeader}${index}`,
									availableWellsType
								)
							}>
							<Styled.Well
								$isHugePlate={plateSize === PLATE_SIZE[1]}
								src={WellImage}
								alt={`${columnHeader}-${index}`}
								draggable='false'
							/>
						</Styled.FullPlateImageWrap>
					</Styled.FullPlateColmun>
				);
			})}
		</tr>
	);
}
