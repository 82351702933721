import React, { useMemo, useEffect, useState, useRef, Fragment } from 'react';
import { encode } from 'js-base64';
import { When } from 'components/When';
import { Api } from 'core/api';
import * as Styled from './styled';

export default function Curves({ selectedTrainingId }) {
	const [runs, setRuns] = useState<string[]>([]);
	const [isFrameLoading, setFrameLoading] = useState<boolean>(true);
	const frameRef = useRef<HTMLFrameElement>(null);

	const handleIframeLoad = () => {
		setFrameLoading(false);
	};

	useEffect(() => {
		const frame = frameRef.current;
		frame?.addEventListener('load', handleIframeLoad);
		return () => frame?.removeEventListener('load', handleIframeLoad);
	}, []);

	useEffect(() => {
		const fetchTensorBoardRuns = async () => {
			const { data: runs } = await Api.get(
				'https://tensorboard.elispot.dev.deepchain.bio/data/runs'
			);
			setRuns(runs);
		};

		fetchTensorBoardRuns();
	}, []);

	const selectionState = useMemo(() => {
		const selectionStateObj = {};
		runs.forEach(path => {
			selectionStateObj[path] = path.split('/')[0] === selectedTrainingId;
		});

		const selectionStateStr = JSON.stringify(selectionStateObj);
		return encode(selectionStateStr, true);
	}, [selectedTrainingId, runs]);

	const tensorBoardUrl = `https://tensorboard.elispot.dev.deepchain.bio/#scalars&runSelectionState=${selectionState}`;

	return (
		<Fragment>
			<When condition={isFrameLoading}>
				<Styled.LoadingContainer>
					<small>
						<b>Loading TensorBoard server...</b>
					</small>
				</Styled.LoadingContainer>
			</When>
			<Styled.IFrame
				src={tensorBoardUrl}
				title='TensorBoard Curves'
				ref={frameRef}
			/>
		</Fragment>
	);
}
