import React from 'react';
import { useDispatch } from 'react-redux';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { editWorkspaceData } from 'core/store/InputGeneration';
import UpdateInputFileForm from './UpdateInputFileForm';
import { FormContainer } from 'components/Form';

export default function UpdateInputFileModal({
	closeModal,
	workspaceId,
	workspaceDataId,
	name,
	isTemplate,
}) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.EDITED_WORSKPACE_DATA,
		onSuccess: closeModal,
	});

	const handleEditInputFile = data => {
		dispatch(editWorkspaceData(data, workspaceId, workspaceDataId));
	};

	return (
		<FormContainer
			formID='update-input-file'
			onSubmit={handleEditInputFile}
			component={props => (
				<UpdateInputFileForm
					{...props}
					loading={loading}
					closeModal={closeModal}
					trace={trace}
					error={error}
					name={name}
					isTemplate={isTemplate}
				/>
			)}
		/>
	);
}
