import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './styled';

export function Status({ status }) {
	const theme = useTheme();

	const textPops = useMemo(() => {
		switch (status) {
			case 'Succeeded':
			case 'Ready':
			case 'Reviewed':
				return { color: theme.green };
			case 'Created':
				return { color: theme.green_2 };
			case 'Checking':
			case 'Preprocessing':
			case 'Processing':
			case 'Training':
			case 'Evaluating':
				return { color: theme.yellow };
			case 'Failed':
			case 'Invalid':
				return { color: theme.red_2 };
			case 'stopped':
				return { color: theme.gray_2 };
			case 'UnderReview':
				return { color: theme.orange };
			case 'NotReviewed':
				return { color: theme.red_4 };
			default:
				return { color: theme.red_2 };
		}
	}, [status, theme]);
	return <Styled.Text {...textPops}>{status}</Styled.Text>;
}
