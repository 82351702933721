import styled, { css } from "styled-components";
import { Button as RButton } from "components/Button";
import { Alert as AAlert, Table as BTable } from "react-bootstrap";
import CheckedSvg from "assets/img/checked.svg";

export const Alert = styled(AAlert)`
  margin: 10px;
`;

export const Table = styled(BTable)`
  background-color: ${(p) => p.theme.white};
  max-width: 100%;
  th {
    text-align: center;
  }
  td {
    width: 100px;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    padding: 10px !important;
  }
  td:first-of-type {
    padding: 10px 0;
    width: 40px;
  }
  p {
    color: ${(p) => p.theme.green};
    font-weight: 400;
    margin: 5px;
  }
`;

export const RightContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 20px;
`;

export const ConfigContainer = styled.div`
  background-color: ${(p) => p.theme.white};
  box-shadow: ${(p) => p.theme.boxShadow_2};
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  padding: 10px 10px 30px 10px;
  border-radius: 12px;
  position: relative;
`;

export const ConfigContent = styled.div`
  width: 100%;
  border-radius: 6px;
  background: ${(p) => p.theme.gray_11};
  padding: 8px 0;
  margin: 5px 0;
  opacity: ${(p) => (p.isHidden ? "0.3" : 1)};
`;

export const InputConfigContent = styled.div`
  flex: 50%;
  margin-bottom: 10px;
  display: flex;
  background: ${(p) => p.theme.gray_11} !important;
  span {
    width: 28%;
    position: relative;
    top: 7px;
    left: 2%;
  }
  select,
  input {
    background: ${(p) => p.theme.gray_11} !important;
    outline: none;
    color: ${(p) => p.theme.green} !important;
    width: 70%;
    border: none;
    border-color: unset !important;
    box-shadow: none !important;
  }
  input {
    border: 1px solid ${(p) => p.theme.gray_3}!important;
    color: ${(p) => p.theme.primaryDark} !important;
  }
`;

export const ResultImageContainer = styled.div`
	background-color: ${(p) => p.theme.white};
	box-shadow: ${(p) => p.theme.boxShadow_2};
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
`;

export const ImageButtonWrap = styled.div`
  position: relative;
  margin-top: 25px;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.gray_12};
`;

export const AssignButton = styled(RButton)`
  position: relative;
  float: right;
  border: none;
  font-size: 14px;
  margin: 15px 10px 0;
  padding: 5px 15px;
  border-radius: 44px;
  background-color: ${(p) => p.theme.gray_10};
  color: ${(p) => p.theme.gray_2};
  &:hover,
  &:active,
  &:focus {
    background-color: ${(p) => p.theme.gray_5}!important;
    box-shadow: none !important;
  }
`;

export const ModalContent = styled.div`
  & .modal-footer {
    border-top: none;
    justify-content: center;
  }
`;

export const CompleteImage = styled.div`
  background: url(${(props) => props.img});
  background-size: cover;
  width: 60%;
  padding-bottom: 60%;
  margin: 10px 20%;
`;

export const Image = styled.img`
  width: 96%;
  position: relative;
`;

export const SvgImage = styled.img`
  width: 20px;
  position: relative;
  right: 10px;
`;

export const ImageSpotCountWrapper = styled.div`
  padding: 0 !important;
  overflow: hidden;
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 4px solid ${borderColor}!important;
    `};
`;

export const ImageContainer = styled.div`
  word-break: break-word;
  margin-top: 3px;
  margin-right: 0.8%;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const SpotNumber = styled.p`
  color: ${(p) => p.theme.green};
  float: right;
  margin: 0;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SubText = styled.p`
  font-size: 10px;
`;

export const DeleteButton = styled(RButton)`
  border: none;
  font-size: 14px;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.red};
  border-radius: 44px;
  &:hover {
    background-color: ${(p) => p.theme.red_2};
  }
`;

export const ApproveButton = styled(RButton)`
  border: none;
  font-size: 14px;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.green};
  border-radius: 44px;
  &:hover {
    background-color: ${(p) => p.theme.green};
  }
`;

export const CancelButton = styled(RButton)`
  border: none;
  font-size: 14px;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.gray_9};
  color: ${(p) => p.theme.primaryDark};
  border-radius: 44px;
  &:hover {
    background-color: ${(p) => p.theme.red_2};
  }
`;

export const TD = styled.td`
  width: 146px;
  text-align: center;
  border: 1px solid ${(p) => p.theme.gray_12};
  ${({ isSelected }) =>
    isSelected &&
    css`
      -webkit-box-shadow: inset 0px 0px 0px 10px ${(p) => p.theme.green_4};
      -moz-box-shadow: inset 0px 0px 0px 10px ${(p) => p.theme.green_4};
      box-shadow: inset 0px 0px 0px 10px ${(p) => p.theme.green_4};
    `}
`;

export const Container = styled.div`
  display: flex;
  padding: 15px;
  background-color: ${(p) => p.theme.gray_11};
  min-height: 80vh;
`;

export const ReviewsContainer = styled.div`
  padding: 35px 15px 15px;
  background-color: ${(p) => p.theme.gray_11};
  table {
    background-color: ${(p) => p.theme.white};
  }
`;

export const ReviewsTitleWrap = styled.div`
  background-color: ${(p) => p.theme.white};
  overflow: hidden;
  padding: 12px;
  h3 {
    float: left;
    font-size: 1.25rem;
    margin-top: 5px;
  }
`;

export const SuccessButton = styled(RButton)`
  border: none;
  font-size: 14px;
  padding: 8px 40px;
  background-color: ${(p) => p.theme.green};
  border-radius: 44px;
  float: right;
`;

export const RotatedText = styled.span`
  transform: rotate(90deg);
  display: inline-block;
  text-align: center;
  white-space: nowrap;
`;

export const SaveButtonWrap = styled.div`
  position: relative;
  width: 100%;
`;

export const SaveButton = styled(RButton)`
  position: relative;
  float: right;
  border: none;
  font-size: 14px;
  margin: 15px 10px 0;
  padding: 5px 30px;
  background-color: ${(p) => p.theme.green};
  border-radius: 44px;
  &:hover {
    background-color: ${(p) => p.theme.green_2};
  }
`;

export const ModalSaveButton = styled(RButton)`
  border: none;
  font-size: 14px;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.green};
  border-radius: 44px;
  &:hover {
    background-color: ${(p) => p.theme.green_2};
  }
`;

export const Error = styled.span`
  border: none;
  font-size: 14px;
  padding: 5px 30px;
  color: ${(p) => p.theme.red_2};
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrap = styled.div`
  overflow-x: scroll;
  table {
    th {
      white-space: nowrap;
    }
  }
`;

export const Header = styled.td`
  color: ${(p) => (p.isIVS ? p.theme.green : "")};
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  & .__react_component_tooltip {
    max-width: 30vw;
  }
`;

export const TR = styled.tr`
  ${({ isHighlighed, isValidExp }) => {
    if (isHighlighed && isValidExp) {
      return css`
        td:first-of-type {
          background: ${(p) => p.theme.green_4};
        }
      `;
    }
    if (!isHighlighed && !isValidExp) {
      return css`
        td:first-of-type {
          background: ${(p) => p.theme.gray};
        }
      `;
    }
    if (isHighlighed && !isValidExp) {
      return css`
        td:first-of-type {
          background: ${(p) => p.theme.gray_2};
          color: ${(p) => p.theme.white};
        }
      `;
    }
  }}
`;

export const CustomColumn = styled.td`
  border-bottom: 1px solid ${(p) => p.theme.gray_12} !important;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 5px solid ${(p) => p.theme.green_4}!important;
    `}
`;

export const Content = styled.div`
  padding: 10px;
  input[type="text"],
  select {
    margin: 5px 0 10px 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 5%;
  span {
    margin-right: 10px;
  }
  button {
    width: 44%;
  }
`;

export const CapitalizedSpan = styled.span`
  text-transform: capitalize;
`;

export const ApplyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  button {
    float: left;
    margin: 0;
  }
  & .form-check {
    margin-left: 50px;
  }
  input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin: 0 0 0 -30px;
  }
  label {
    position: relative;
    border-radius: 50%;
    left: -28px;
    z-index: 0;
    padding-left: 40px;
    vertical-align: middle;
  }
  label:before {
    content: "";
    position: absolute;
    left: 0;
    top: -0;
    width: 24px;
    height: 24px;
    transition: transform 0.28s ease;
    border: 2px solid ${(p) => p.theme.gray};
    border-radius: 6px;
  }
  input[type="checkbox"]:checked ~ label::before {
    border: 2px solid ${(p) => p.color || p.theme.green};
    background: url(${CheckedSvg}) no-repeat center center;
    background-size: cover;
    width: 24px;
    height: 23px;
    border-radius: 6px;
  }
`;

export const LoadingWrap = styled.div`
  position: absolute;
  height: calc(100vh - 63px);
  width: calc(100% - 250px);
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.white};
`;

export const PreviewedSpotNumber = styled.p`
  color: ${(p) => p.theme.green};
  font-weight: 700;
  margin: 10px 0 0 0;
`;

export const FullPlateContainer = styled.div`
  padding: 15px;
  background-color: ${(p) => p.theme.gray_11};
  min-height: 80vh;
`;

export const DisplayStimulus = styled.div`
  width: 100%;
  background: ${(p) => p.theme.white};
  overflow: hidden;
  padding: 14px 0 0 20px;
  p {
    float: left;
    font-weight: 600;
  }
`;

export const SingleStimulus = styled.div`
  float: left;
  margin-left: 20px;
  cursor: pointer;
  svg {
    float: left;
    margin-right: 10px;
    margin-top: 4px;
    width: 20px !important;
  }
`;

export const FullPlateTable = styled(BTable)`
  background-color: ${(p) => p.theme.white};
  max-width: 100%;
  th {
    text-align: center;
  }
  td {
    width: 100px;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
  }
  td:first-of-type {
    padding: 10px 0;
    width: 40px;
  }
  img {
    width: 80px;
  }
  p {
    color: ${(p) => p.theme.green};
    font-weight: 400;
    margin: 5px 0;
  }
`;
export const FullPlateImageWrap = styled.div`
  padding: 10px 10px 0 10px !important;
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 4px solid ${borderColor}!important;
    `};
`;

export const FullPlateColmun = styled.td`
  padding: 0 !important;
`;
