import styled, { css } from 'styled-components';
import { Alert as AAlert, Form as BForm } from 'react-bootstrap';
import { Button as RButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'components/Form';

export const Wrapper = styled.div`
	margin-top: 10px;
`;

export const Container = styled.div`
	margin: 20px;
	background-color: ${p => p.theme.white};
	width: calc(100vw - 290px);
	position: relative;
	top: -20px;
	table {
		tr {
			cursor: pointer;
		}
		th:first-of-type {
			width: 1px;
			padding: 0 8px;
		}
	}
`;

export const TabBar = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 20px 0 20px;
	& .nav {
		width: 50%;
		& .nav-item {
			width: 150px;
			position: relative;
			left: 40px;
			&:nth-child(2) {
				left: 80px;
			}
		}
	}
	& .nav-link {
		text-align: center;
		font-weight: bold;
		color: ${p => p.theme.border};
		border-bottom: 5px solid ${p => p.theme.gray_9};
		&:hover {
			border-color: transparent;
			border-bottom: 5px solid ${p => p.theme.gray_9};
		}
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: transparent;
		border-color: transparent;
		color: ${p => p.theme.green};
		border-bottom: 5px solid ${p => p.theme.green};
		svg {
			color: ${p => p.theme.green};
		}
	}
	& .nav-tabs {
		${({ canViewData }) =>
			!canViewData &&
			css`
				border-bottom: none;
			`}
	}
`;
export const Content = styled.div`
	padding: 10px;
	input[type='text'],
	input[type='file'],
	select {
		margin: 5px 0 10px 0;
	}
	input[type='file'] {
		width: 100%;
	}
`;

export const FormWrap = styled.div`
	input[type='file'] {
		display: none;
	}
	label {
		margin-bottom: 0;
	}
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const DataCell = styled.td`
	button {
		width: 50% !important;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 0 5%;
	span {
		margin-right: 10px;
	}
	button {
		width: 44%;
	}
`;

export const SearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;
	top: 12px;
	color: ${p => p.theme.border};
`;

export const Input = styled(Field)`
	border-radius: 80px;
	border: none;
	background: ${p => p.theme.gray_6};
	padding: 0 60px;
`;

export const AddButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const SaveButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const DeleteButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px 20px;
	background-color: ${p => p.theme.red};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const LogButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 5px;
	border-radius: 44px;
	background-color: ${p => p.theme.gray_10};
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-top-left-radius: 44px;
	border-bottom-left-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const WarningButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.yellow_2};
	border-top-right-radius: 44px;
	border-bottom-right-radius: 44px;
`;

export const Form = styled(BForm)`
	position: relative;
`;

export const UnderReviewStatus = styled.span`
	color: ${p => p.theme.orange};
	font-weight: 700;
`;

export const NotReviewedStatus = styled.span`
	color: ${p => p.theme.red_4};
	font-weight: 700;
`;

export const ReviewedStatus = styled.span`
	color: ${p => p.theme.green};
	font-weight: 700;
`;

export const Status = styled.div`
	display: flex;
	align-items: center;
	span {
		font-weight: 700;
		margin-left: 20px;
		line-height: 40px;
	}
`;

export const FormLabel = styled.p`
	font-size: 20px;
	font-weight: 700;
	color: ${p => p.theme.secondaryDark};
`;

export const UploadFolderButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 80px;
	margin: 15px 0;
	background-color: ${p => p.theme.white};
	color: ${p => p.theme.secondaryDark};
	border: 3px solid ${p => p.theme.secondaryDark};
	border-radius: 44px;
	width: 100%;
	&:hover {
		background-color: ${p => p.theme.secondaryDark};
		color: ${p => p.theme.white};
		border: 3px solid ${p => p.theme.secondaryDark};
	}
	&:disabled {
		background-color: ${p => p.theme.gray_10};
		border: none;
	}
`;

export const UploadFileButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 80px;
	margin: 15px 0;
	background-color: ${p => p.theme.white};
	color: ${p => p.theme.green};
	border: 3px solid ${p => p.theme.green};
	border-radius: 44px;
	width: 100%;
	&:hover {
		background-color: ${p => p.theme.green};
		border: 3px solid ${p => p.theme.green};
	}
	&:disabled {
		background-color: ${p => p.theme.gray_10};
		border: none;
	}
`;

export const UploadFileButtonWrap = styled.div`
	width: 100%;
	${UploadFileButton}, ${UploadFolderButton} {
		width: 35%;
		float: left;
		margin: 15px 2%;
		padding: 10px 40px;
		svg {
			margin-left: 10px;
			margin-top: -3px;
		}
	}
	${FormLabel} {
		width: 20%;
		float: left;
		margin-top: 20px;
	}
`;
