import styled from 'styled-components';

export const CardContent = styled.div`
	button:first-of-type {
		position: absolute;
		right: 45px;
		top: 10px;
	}
	button {
		position: absolute;
		right: 10px;
		top: 10px;
	}
`;

export const CardCell = styled.div`
	width: 44%;
	float: left;
	margin: 10px 1%;
	p {
		font-size: 14px;
		color: ${p => p.theme.gray_3};
		margin: 0;
		font-weight: 500;
	}
	span {
		font-size: 16px;
		color: ${p => p.theme.black};
		font-weight: 700;
	}
`;
