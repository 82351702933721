import React, { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import get from 'lodash.get';
import { useTheme } from 'styled-components';
import useNamespace from 'hooks/useNamespace';
import { isEmpty } from 'helpers';
import { CheckBox } from 'components/CheckBox';
import { When } from 'components/When';
import * as Styled from './styled';

const SORT = {
	ASC: 'ascending sort',
	DESC: 'descending sort',
};

const initialSort = {
	[SORT.ASC]: false,
	[SORT.DESC]: false,
};

export function multiSelectFilter(rows, columnIds, filterValue) {
	return filterValue.length === 0
		? rows
		: rows.filter(row =>
				filterValue.includes(String(get(row.original, columnIds[0])))
		  );
}

export const SelectFilter = ({
	column: { setFilter, id, Header, filterValue, clearSortBy },
	setSortBy,
	namespace,
	customData,
}) => {
	const theme = useTheme();
	const [search, setSearch] = useState('');
	const [checkBoxList, setCheckboxList] = useState({});
	const [sortList, setSortList] = useState(initialSort);
	const [selectAll, setSelectAll] = useState(false);
	const [clearAll, setClearAll] = useState(false);

	const { data = [] } = useNamespace({ namespace });

	useEffect(() => {
		const tableData = customData ? customData : data;
		const keys = tableData
			.map(item => item[id])
			.filter(item => {
				return (
					(!!item || item === 0 || item === false) &&
					(item
						.toString()
						.toLowerCase()
						.includes(search.toLowerCase()) ||
						!search)
				);
			});
		const obj = {};

		keys.forEach(key => {
			obj[key] = false;
		});
		setCheckboxList(obj);
		// eslint-disable-next-line
	}, [search]);

	const handleCheckboxChange = (filter: string) => {
		setCheckboxList(old => ({ ...old, [filter]: !old[filter] }));

		if (filterValue?.includes(filter)) {
			const newList = filterValue.filter(function (value) {
				return value !== filter;
			});
			return setFilter(newList);
		}
		setFilter((old = []) => [...old, filter]);
	};

	const setAllCheckboxes = evt => {
		const isChecked = evt.target.checked;
		setSelectAll(isChecked);
		setClearAll(false);
		setCheckboxList(old => {
			const obj = {};
			Object.keys(old).forEach(key => {
				obj[key] = isChecked;
			});
			return obj;
		});
		if (isChecked) {
			setFilter(Object.keys(checkBoxList).map(key => key));
		} else {
			setFilter([]);
		}
	};

	const clearAllCheckboxes = evt => {
		const isChecked = evt.target.checked;
		setClearAll(isChecked);
		setSelectAll(false);
		if (isChecked) {
			setCheckboxList(old => {
				const obj = {};
				Object.keys(old).forEach(key => {
					obj[key] = !isChecked;
				});
				return obj;
			});
			setFilter([]);
		}
	};

	const setSort = (evt, name) => {
		const isChecked = evt.target.checked;
		const isDesc = name === SORT.DESC;

		setSortList(old => {
			const sort = isDesc
				? { [SORT.DESC]: isChecked, [SORT.ASC]: !isChecked }
				: { [SORT.DESC]: !isChecked, [SORT.ASC]: isChecked };
			return { ...old, ...sort };
		});

		if (isChecked) {
			setSortBy([{ id, desc: isDesc }]);
		}
	};

	const resetSort = () => {
		setSortList(initialSort);
		clearSortBy();
	};

	return (
		<Styled.Container>
			<Styled.Header>
				<Styled.Title>Sort By {Header}</Styled.Title>
				<Styled.Link onClick={resetSort}>Clear selection</Styled.Link>
			</Styled.Header>
			<Styled.SelectContainer>
				<Styled.List>
					{Object.keys(sortList).map(item => (
						<Styled.ListItem key={item}>
							<CheckBox
								type='checkbox'
								checked={sortList[item]}
								label={item}
								onChange={e => setSort(e, item)}
							/>
						</Styled.ListItem>
					))}
				</Styled.List>
				<Styled.SubHeader>
					<Styled.Title>Filter by {Header}</Styled.Title>
				</Styled.SubHeader>
				<Styled.FilterContent>
					<Styled.FilterCheckBoxContainer>
						<CheckBox
							type='checkbox'
							label='Select all'
							checked={selectAll}
							onChange={setAllCheckboxes}
						/>
						<Styled.ClearAllCheckBox
							type='checkbox'
							label='Clear selection'
							checked={clearAll}
							onChange={clearAllCheckboxes}
							color={theme.red_5}
						/>
					</Styled.FilterCheckBoxContainer>
					<Styled.Input
						type='text'
						placeholder='Search'
						onChange={e => setSearch(e.target.value)}
					/>
					<Styled.SearchIcon icon={faSearch} />
				</Styled.FilterContent>
				<Styled.List>
					{Object.keys(checkBoxList).map(item => (
						<Styled.ListItem key={item}>
							<CheckBox
								type='checkbox'
								checked={checkBoxList[item]}
								id={item}
								label={item}
								onChange={() => handleCheckboxChange(item)}
							/>
						</Styled.ListItem>
					))}
					<When condition={isEmpty(checkBoxList)}>
						<Styled.ListItem>
							<Styled.Text>Entry not found</Styled.Text>
						</Styled.ListItem>
					</When>
				</Styled.List>
			</Styled.SelectContainer>
		</Styled.Container>
	);
};
