import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	createSample,
	fetchSapmlesWithSelectedWells,
} from 'core/store/InputGeneration';
import CreateSampleForm from './CreateSampleForm';
import { FormContainer } from 'components/Form';

export default function CreateSampleModal({
	closeModal,
	workspaceDataId,
	plateId,
	id,
}) {
	const dispatch = useDispatch();
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_SAPMLE,
		onSuccess: () => {
			closeModal();
			dispatch(fetchSapmlesWithSelectedWells(workspaceDataId, plateId));
		},
	});
	const handleCreateSample = data => {
		const formatedData = {
			...data,
			sampleDate: moment(data.sampleDate).format('MM.DD.yyyy'),
		};
		dispatch(createSample(formatedData, workspaceDataId, id));
	};

	return (
		<FormContainer
			formID='create-sample'
			onSubmit={handleCreateSample}
			component={props => (
				<CreateSampleForm
					{...props}
					loading={loading}
					closeModal={closeModal}
					trace={trace}
					error={error}
				/>
			)}
		/>
	);
}
