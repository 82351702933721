import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { When } from 'components/When';
import { CheckBox } from 'components/CheckBox';
import * as namespaces from 'core/consts/namespaces';
import useNamespace from 'hooks/useNamespace';
import { downloadPlateLayout } from 'core/store/InputGeneration';
import * as Styled from '../styled';

export default function DownloadPlateLayoutModal({
	closeModal,
	workspaceDataId,
}) {
	const dispatch = useDispatch();
	const [isAssociatedGroupsEnabled, setIsAssociatedGroupsEnabled] = useState(
		true
	);
	const handleCheckboxChange = (checked: boolean) => {
		setIsAssociatedGroupsEnabled(checked);
	};
	const handleDeleteVisit = () => {
		dispatch(
			downloadPlateLayout(workspaceDataId, isAssociatedGroupsEnabled)
		);
	};

	const { data, error, trace, loading } = useNamespace({
		namespace: namespaces.DOWNLOADED_PLATELAYOUTS,
		onSuccess: closeModal,
	});

	useEffect(() => {
		try {
			if (!!data) {
				const columnNames = Object.keys(data[0])
					.map(
						colName =>
							`${colName.charAt(0).toUpperCase()}${colName.slice(
								1
							)}`
					)
					.join(',');
				const tsvData = data
					.map(e => {
						return Object.values(e)
							.map(value => {
								const val =
									!!value && typeof value === 'string'
										? value.replace(/\n/g, ' ')
										: value;
								return val;
							})
							.join(',');
					})
					.join('\n');
				const fileContent = columnNames + '\n' + tsvData;
				const content = new Blob([fileContent], {
					type: 'text/csv;charset=utf-8',
				});
				saveAs(content, `plateLayout_${workspaceDataId}.csv`);
			}
		} catch (e) {
			return;
		}
	}, [data, workspaceDataId]);

	return (
		<Fragment>
			<Modal.Body>
				<CheckBox
					type='checkbox'
					checked={isAssociatedGroupsEnabled}
					label='Include Associated Groups ?'
					onChange={e => handleCheckboxChange(e.target.checked)}
				/>
			</Modal.Body>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Footer>
				<Styled.CancelButton variant='success' onClick={closeModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.SaveButton
					variant='success'
					onClick={handleDeleteVisit}
					loading={loading}>
					Download
				</Styled.SaveButton>
			</Modal.Footer>
		</Fragment>
	);
}
