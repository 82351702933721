import { AxiosRequestConfig } from 'axios';
import { produce } from 'immer';
import { createSelector } from 'reselect';
import reducerRegistry from '../reducerRegistry';

export const FETCH_INIT = 'app/repo/FETCH_INIT';
export const FETCH_SUCCESS = 'app/repo/FETCH_SUCCESS';
export const FETCH_ERROR = 'app/repo/FETCH_ERROR';
export const FETCH_LATEST = 'app/repo/FETCH_LATEST';
export const FETCH_CLEAR = 'app/repo/FETCH_CLEAR';
export const UPDATE_REPOSITORY = 'app/repo/UPDATE_REPOSITORY';
export const SAVE_UPDATE_REPOSITORY = 'app/repo/SAVE_UPDATE_REPOSITORY';
export const UPDATE_REPOSITORY_FAILED = 'app/repo/UPDATE_REPOSITORY_FAILED';

export const repoInitialState = {};
const reducerName = 'repository';

const namespaceInitialState = () => ({
	data: [],
	error: false,
	loading: false,
	isInitialFetch: false,
	success: false,
	trace: null,
});

export default function reducer(state = repoInitialState, action) {
	switch (action.type) {
		case FETCH_INIT:
		case FETCH_LATEST:
			return produce(state, draft => {
				if (!state[action.options.namespace]) {
					if (!draft[action.options.namespace]) {
						draft[
							action.options.namespace
						] = namespaceInitialState();
					}
					draft[action.options.namespace].isInitialFetch = true;
				} else {
					draft[action.options.namespace].isInitialFetch = false;
				}
				draft[action.options.namespace].loading = true;
			});
		case FETCH_CLEAR:
			return produce(state, draft => {
				delete draft[action.namespace];
			});
		case FETCH_SUCCESS:
			return produce(state, draft => {
				draft[action.namespace].data = action.payload;
				draft[action.namespace].loading = false;
				draft[action.namespace].success = true;
				draft[action.namespace].isInitialFetch = false;
			});
		case FETCH_ERROR:
			return produce(state, draft => {
				draft[action.namespace].error = true;
				draft[action.namespace].loading = false;
				draft[action.namespace].isInitialFetch = false;
				draft[action.namespace].trace = action.message;
			});
		case SAVE_UPDATE_REPOSITORY:
			return produce(state, draft => {
				if (!draft[action.namespace]) {
					draft[action.namespace] = namespaceInitialState();
				}
				draft[action.namespace] = action.newValue;
			});
		default:
			return state;
	}
}

reducerRegistry.register(reducerName, reducer);

export interface fetchOptions {
	url: string;
	namespace: string;
	config?: AxiosRequestConfig;
	successCb?: Function;
	errorCb?: Function;
	autoClear?: boolean;
	selector?: (state: any, ...args: any[]) => any; // for formatting urls based on redux store
}

export function fetchInit(options: fetchOptions) {
	return {
		type: FETCH_INIT,
		options,
	};
}

export function fetchLatest(options: fetchOptions) {
	return {
		type: FETCH_LATEST,
		options,
	};
}

export function fetchClear(namespace: string) {
	return {
		type: FETCH_CLEAR,
		namespace,
	};
}

interface UpdateRepoOptions {
	namespace: string;
	compute: Function;
}

export function updateRepository(options: UpdateRepoOptions) {
	return {
		type: UPDATE_REPOSITORY,
		options,
	};
}

export const repositorySelector = state => state.repository;

export const getData = (namespace: string, fallbackValue: any = []) => {
	return createSelector(repositorySelector, repo => {
		if (!repo[namespace]) return fallbackValue;
		return repo[namespace].data;
	});
};

export const getLoadingState = (namespace: string) => {
	return createSelector(repositorySelector, repo => {
		if (!repo[namespace]) return false;
		return repo[namespace].loading;
	});
};

export const getErrorState = (namespace: string) => {
	return createSelector(repositorySelector, repo => {
		if (!repo[namespace]) return false;
		return repo[namespace].error;
	});
};

export const getNamespace = (namespace: string) => {
	return createSelector(repositorySelector, repo => {
		if (!repo[namespace]) return {};
		return repo[namespace];
	});
};
