import styled from 'styled-components';

export const TabBar = styled.div`
	line-height: 30px;
	margin-top: -15px;
	& .nav {
		& .nav-item {
			width: 22%;
			position: relative;
			left: 40px;
			&:nth-child(2) {
				left: 80px;
			}
			&:nth-child(3) {
				left: 120px;
			}
			&:nth-child(4) {
				left: 160px;
			}
		}
	}
	& .nav-link {
		text-align: center;
		font-weight: bold;
		color: ${p => p.theme.border};
		border-bottom: 5px solid ${p => p.theme.gray_9};
		&:hover {
			border-color: transparent;
			border-bottom: 5px solid ${p => p.theme.gray_9};
		}
	}
	& .nav-link.active {
		color: ${p => p.theme.white};
		background-color: transparent;
		border-color: transparent;
		color: ${p => p.theme.green};
		border-bottom: 5px solid ${p => p.theme.green};
		svg {
			color: ${p => p.theme.green};
		}
	}
`;
