import React, {
	Fragment,
	useEffect,
	useMemo,
	useCallback,
	useState,
} from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { fetchFileUsingSignedUrl, fetchZipUsingSignedUrl } from 'helpers';
import { Link } from 'components/Link';
import * as ROUTES from 'core/routes';
import { SubHeader } from 'components/SubHeader';
import { Table } from 'components/Table';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	fetchPDPreprocessingSummary,
	fetchPredictionDataById,
	fetchPredictionDataFileUrl,
} from 'core/store/predictions';
import FoldersPath from './components/FoldersPath';
import * as Styled from './styled';

enum FileNamesEnum {
	CleanedFile = 'cleanedFile',
	DroppedFile = 'droppedFile',
	EdaFile = 'edaFile',
}

export default function PredictionDataDetails() {
	const { predictionDataId }: { predictionDataId: string } = useParams();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [fileInfos, setFileInfos] = useState({ fileName: '', fileUrl: '' });
	const pageSize = 13;
	const { data: reports = {} } = useNamespace({
		namespace: namespaces.PREDICTION_DATA_SUMMARY,
	});

	const { data: predictionDataFileUrl = {} } = useNamespace({
		namespace: namespaces.PREDICTION_DATA_FILE_URL,
		autoClear: true,
	});

	const { data: singlePrediction = {} } = useNamespace({
		namespace: namespaces.SINGLE_PREDICTION_DATA,
	});
	useEffect(() => {
		if (!!predictionDataFileUrl?.url)
			setFileInfos({
				...fileInfos,
				fileUrl: predictionDataFileUrl.url,
			});
		// eslint-disable-next-line
	}, [predictionDataFileUrl]);

	useEffect(() => {
		if (!!fileInfos.fileUrl && !!fileInfos.fileName) {
			const { fileName, fileUrl } = fileInfos;
			setFileInfos({ fileName: '', fileUrl: '' });
			if (fileName === FileNamesEnum.EdaFile) {
				fetchZipUsingSignedUrl(fileUrl, true)
					.then(result => {
						const content = new Blob([result], {
							type: 'application/octet-stream',
						});
						saveAs(content, `generated_eda.zip`);
					})
					.catch(err => console.error(err));
			} else {
				fetchFileUsingSignedUrl(fileUrl, false)
					.then(result => {
						try {
							const fileData = JSON.parse(result);
							const columnNames = Object.keys(fileData[0]).join(
								'\t'
							);
							const tsvData = fileData
								.map(e => {
									return Object.values(e)
										.map(value => {
											const val =
												!!value &&
												typeof value === 'string'
													? value.replace(/\n/g, ' ')
													: value;
											return val;
										})
										.join('\t');
								})
								.join('\n');
							const fileContent = columnNames + '\n' + tsvData;
							const content = new Blob([fileContent], {
								type: 'text/tsv;charset=utf-8',
							});
							saveAs(
								content,
								`${fileName}_preprocessing_${predictionDataId}.tsv`
							);
						} catch (e) {
							return;
						}
					})
					.catch(err => console.error(err));
			}
		}
	}, [predictionDataId, dispatch, predictionDataFileUrl, fileInfos]);

	useEffect(() => {
		dispatch(fetchPredictionDataById(predictionDataId));
	}, [predictionDataId, dispatch]);
	const isRowActive = row => {
		return (
			!row.well_requirements_not_met &&
			!row.missing_images &&
			!row.missing_platebarcode
		);
	};

	const data = useMemo(() => {
		try {
			return JSON.parse(reports.cleaned);
		} catch (e) {
			return [];
		}
	}, [reports.cleaned]);
	const formatMissingImagesStr = useCallback(str => {
		return !!str.length && str.length > 20
			? `${str.substring(0, 20)}...`
			: str;
	}, []);

	const columns = useMemo(() => {
		try {
			return Object.keys(data[0]).map(key => ({
				Header: key,
				accessor: key,
				Filter: props =>
					SelectFilter({
						...props,
						customData: data,
					}),
				Cell: ({
					value,
					row: {
						original: { Unique_ID, missing_images_str },
					},
				}) => {
					if (key === 'missing_images_str') {
						const cellValue = missing_images_str ?? '';
						const formattedValue = formatMissingImagesStr(
							cellValue
						);
						const tooltipId = `${Unique_ID}-tooltip`;
						return (
							<div>
								<span data-tip='data-tip' data-for={tooltipId}>
									{formattedValue}
								</span>
								<ReactTooltip
									effect='solid'
									place='top'
									multiline
									textColor='#FFF'
									id={tooltipId}
									html={true}
									getContent={() => {
										return cellValue;
									}}
								/>
							</div>
						);
					}
					const cellValue = value ?? '';
					return cellValue.toString();
				},
				filter: multiSelectFilter,
			}));
		} catch (e) {
			return [];
		}
	}, [data, formatMissingImagesStr]);

	useEffect(() => {
		dispatch(fetchPDPreprocessingSummary(predictionDataId));
	}, [dispatch, predictionDataId]);

	const downloadFile = fileName => {
		setFileInfos({ ...fileInfos, fileName });
		dispatch(fetchPredictionDataFileUrl(predictionDataId, fileName));
	};

	return (
		<Fragment>
			<SubHeader
				pre={
					<Link to={`${ROUTES.PREDICTIONS}?view=data`}>
						Prediction Data
					</Link>
				}
				current={singlePrediction?.name}
				rightContent={
					<ButtonGroup>
						<Styled.SuccessButton
							variant='success'
							onClick={() =>
								downloadFile(FileNamesEnum.CleanedFile)
							}>
							Cleaned &nbsp;
							<FontAwesomeIcon icon={faDownload} />
						</Styled.SuccessButton>
						<Styled.DownloadButton
							variant='success'
							onClick={() =>
								downloadFile(FileNamesEnum.DroppedFile)
							}>
							Dropped &nbsp;
							<FontAwesomeIcon icon={faDownload} />
						</Styled.DownloadButton>
						<Styled.WarningButton
							variant='warning'
							onClick={() => downloadFile(FileNamesEnum.EdaFile)}>
							&nbsp; &nbsp; EDA &nbsp;
							<FontAwesomeIcon icon={faDownload} />
							&nbsp; &nbsp;
						</Styled.WarningButton>
					</ButtonGroup>
				}
			/>
			<Styled.Container>
				<Table
					data={data}
					columns={columns}
					withPagination={true}
					withFilters={!!data?.length}
					pageSize={pageSize}
					rowProps={row => ({
						style: {
							background: isRowActive(row.values)
								? theme.white
								: theme.gray_8,
						},
					})}
				/>
			</Styled.Container>
			<FoldersPath flodersData={singlePrediction} />
		</Fragment>
	);
}
