import reducerRegistry from '../reducerRegistry';

export const CHANGE_LANG = 'app/i18n/CHANGE_LANG';
export const i18nInitialState = 'en';
const reducerName = 'lang';

export default function reducer(state = i18nInitialState, action) {
	switch (action.type) {
		case CHANGE_LANG:
			return action.lang;
		default:
			return state;
	}
}

reducerRegistry.register(reducerName, reducer);

export const langSelector = state => state.lang;
