export const blobsConfigOptions = [
	{
		label: 'Maximum Sigma',
		min: 1,
		max: 10,
		value: 5,
		step: 1,
		name: 'maxSigma',
	},
	{
		label: 'Intensity',
		min: 0.1,
		max: 1,
		value: 0.1,
		step: 0.1,
		name: 'intensityThreshold',
	},
	{
		label: 'Minimum Radius',
		min: 0,
		max: 100,
		value: 1,
		step: 0.1,
		name: 'minRadius',
	},
	{
		label: 'Maximum Radius',
		min: 0.1,
		max: 100,
		value: 5,
		step: 0.1,
		name: 'maxRadius',
	},
	{
		label: 'Method',
		type: 'select',
		value: 'A',
		values: ['A', 'B', 'C'],
		name: 'method',
	},
	{
		label: 'Min Intensity',
		min: 0,
		max: 1,
		value: 0.1,
		step: 0.1,
		name: 'minIntensity',
	},
	{
		label: 'Max Intensity',
		min: 0,
		max: 1,
		value: 1,
		step: 0.1,
		name: 'maxIntensity',
	},
	{
		label: 'Mask Radius',
		min: 0,
		max: 100,
		value: 95,
		step: 1,
		name: 'maskPercent',
	},
	{
		label: 'Image size',
		type: 'select',
		value: '256',
		values: ['256', '512'],
		name: 'imageSize',
	},
];

export const watershedConfigOptions = [
	{
		label: 'Mask Radius',
		min: 0,
		max: 100,
		value: 95,
		step: 1,
		name: 'maskPercent',
	},
	{
		label: 'Min Intensity',
		min: 0,
		max: 1,
		value: 0.1,
		step: 0.1,
		name: 'minIntensity',
	},
	{
		label: 'Max Intensity',
		min: 0,
		max: 1,
		value: 1,
		step: 0.1,
		name: 'maxIntensity',
	},
	{
		label: 'Minimum Area',
		min: 0,
		max: 500,
		value: 1,
		step: 1,
		name: 'minArea',
	},
	{
		label: 'Maximum Area',
		min: 1,
		max: 500,
		value: 5,
		step: 1,
		name: 'maxArea',
	},
	{
		label: 'Image size',
		type: 'select',
		value: '256',
		values: ['256', '512'],
		name: 'imageSize',
	},
];

export const algorithm = {
	label: 'Algorithm',
	type: 'select',
	value: 'watershed',
	values: [
		{ label: 'Watershed', value: 'watershed' },
		{ label: 'Blobs', value: 'blobs' },
	],
	name: 'algorithm',
};
