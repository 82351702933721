import styled from 'styled-components';
import { Button as RButton } from 'components/Button';

export const Container = styled.div`
	display: flex;
	background-color: ${p => p.theme.white};
	flex-direction: column;
	width: 35rem;
	border-radius: 12px;
	box-shadow: 0 0 6px ${p => p.theme.lightDark};
	position: absolute;
	left: -30.5rem;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	height: 35px;
	background-color: ${p => p.theme.primaryDark};
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
`;

export const Title = styled.span`
	color: ${p => p.theme.white};
	font-weight: bold;
	margin: 7px;
	text-transform: capitalize;
	white-space: nowrap;
`;

export const Link = styled.a`
	color: ${p => p.theme.white};
	margin: 7px;
	text-decoration: underline;
	text-transform: capitalize;
	font-weight: 100;
	white-space: nowrap;
	&:hover {
		color: ${p => p.theme.green};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	button {
		width: 100px;
		margin: 10px;
	}
`;
export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const List = styled.ul`
	padding: 15px;
	list-style: none;
	max-height: 200px;
	overflow: auto;
`;

export const ListItem = styled.li`
	&:hover {
		background-color: ${p => p.theme.gray_6};
	}
`;
