import styled, { css } from 'styled-components';
import { Button as RButton } from 'components/Button';
import { Link } from 'components/Link';
import SelectArrow from 'assets/img/Gray_Select_Arrow.svg';

export const Container = styled.div`
	margin: 20px;
	background-color: ${p => p.theme.white};
	width: calc(100vw - 308px);
	max-height: 85vh;
	table {
		margin-bottom: 5px;
		th {
			padding: 10px 10px 10px;
			border-bottom: none;
		}
		td {
			padding: 10px 10px 20px;
		}
	}
`;

export const SuccessButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	:first-of-type {
		margin-left: 20px;
	}
`;

export const SelectWrapper = styled.div`
	float: right;
	select {
		background: ${p => p.theme.white} url(${SelectArrow}) no-repeat scroll
			91% center/12px auto !important;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		border: 2px solid ${p => p.theme.green};
		border-radius: 20px;
		padding: 7px 30px;
	}
	select:hover {
		cursor: pointer;
	}
	option:hover,
	option:focus,
	option:active,
	option:checked {
		background: ${p => p.theme.green}!important;
		color: ${p => p.theme.white};
		border-radius: 20px;
	}
`;

export const NextLink = styled(Link)`
	border-top-right-radius: 44px;
	border-bottom-right-radius: 44px;
	border-left: 1px solid ${p => p.theme.green}!important;
	color: ${p => p.theme.green};
`;

export const DisabledNextLink = styled(Link)`
	border-top-right-radius: 44px;
	border-bottom-right-radius: 44px;
	border-left: 1px solid ${p => p.theme.green}!important;
	color: ${p => p.theme.gray};
	cursor: not-allowed;
`;

export const PreviousLink = styled(Link)`
	border-top-left-radius: 44px;
	border-bottom-left-radius: 44px;
	border-right: 1px solid ${p => p.theme.green}!important;
	color: ${p => p.theme.green};
`;

export const DisabledPreviousLink = styled(Link)`
	border-top-left-radius: 44px;
	border-bottom-left-radius: 44px;
	border-right: 1px solid ${p => p.theme.green}!important;
	color: ${p => p.theme.gray};
	cursor: not-allowed;
`;

export const NexTPreviousWrap = styled.div`
	float: left;
	margin: 7px 20px 0 0;
	a {
	text-decoration: none;
		border: 2px solid ${p => p.theme.green};
		font-size: 14px;
		font-weight: bold;
		padding: 10px 25px;
	}
	a svg {
		margin: 0 5px;
	}
	${NextLink}:hover,${PreviousLink}:hover {
		background: ${p => p.theme.green};
		color: ${p => p.theme.white};
	}
	${DisabledNextLink}:hover,${DisabledPreviousLink}:hover {
		background: ${p => p.theme.white};
		color: ${p => p.theme.gray};
	}
`;

export const ButtonsWrap = styled.div`
	overflow: hidden;
	padding: 12px;
	&& ${SuccessButton} {
		float: right;
	}
`;
export const ImageSvg = styled.img`
	width: 1.25em;
	margin: 0 5px;
`;

export const LoadingWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
`;

export const TableLegend = styled.div`
	padding-left: 10px;
	float: left;
	p {
		line-height: 41px;
		margin: 0 15px 0 0;
		float: left;
		font-weight: 600;
	}
`;

export const ColorSquareWrap = styled.div`
	margin-top: 10px;
	float: left;
	p {
		float: left;
		line-height: 20px;
		margin-left: 10px;
		font-weight: 400;
	}
`;
export const ColorSquare = styled.div`
	width: 30px;
	height: 20px;
	float: left;
	${({ bg }) =>
		bg &&
		css`
			background-color: ${bg};
		`}
`;
