import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	padding-top: 10px;
	input[type='checkbox'] {
		opacity: 0;
		z-index: 1;
	}
	label {
		position: relative;
		border-radius: 50%;
		left: -28px;
		top: -3.3px;
		z-index: 0;
	}
	label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		margin: 4px;
		width: 18px;
		height: 18px;
		transition: transform 0.28s ease;
		border: 2px solid ${p => p.theme.gray};
		background: transparent;
		border-radius: 50%;
	}
	label:after {
		content: '';
		display: block;
		width: 10px;
		height: 5px;
		border-bottom: 2px solid ${p => p.theme.gray};
		border-left: 2px solid ${p => p.theme.gray};
		transform: rotate(-45deg) scale(1);
		position: absolute;
		top: 9px;
		left: 8px;
	}
	input[type='checkbox']:checked ~ label::before {
		color: ${p => p.theme.white};
		border: 2px solid ${p => p.color || p.theme.green};
		background: ${p => p.color || p.theme.green};
	}

	input[type='checkbox']:checked ~ label::after {
		-webkit-transform: rotate(-45deg) scale(1);
		transform: rotate(-45deg) scale(1);
		border-bottom: 2px solid ${p => p.theme.white};
		border-left: 2px solid ${p => p.theme.white};
	}

	label {
		min-height: 34px;
		display: block;
		padding-left: 40px;
		margin-bottom: 0;
		font-weight: normal;
		cursor: pointer;
		vertical-align: sub;
	}

	input[type='checkbox']:focus + label::before {
		outline: 0;
	}
`;
