import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { When } from 'components/When';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import {
	trainingParamsSelector,
	partitionedTrainingDataSelector,
} from 'core/store/trainingsExvivo';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import ValidationErrorAlert from 'components/Form/Errors/ValidationErrorAlert';
import DataAugmentationSubForm from './DataAugmentationSubForm';
import ModelParamsSubForm from './ModelParamsSubForm';
import TrainingConfigSubForm from './TrainingConfigSubForm';
import * as Styled from './styled';
import DataSelectionSubForm from './DataSelectionSubForm';

export default function NewTrainingConfigurationForm({
	form,
	handleSubmit,
	submitting,
	pristine,
	hasValidationErrors,
	submitFailed,
}) {
	const [trainingData, validationData] = useSelector(
		partitionedTrainingDataSelector
	);
	const trainingParams = useSelector(trainingParamsSelector);
	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_ADDED_TRAINING,
	});
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.Content>
				<b>Name</b>
				<Row>
					<Col xs='6'>
						<Field
							showValid={false}
							name='name'
							placeholder='Type a name for the training'
							component={InputAdapter}
							validate={validateRequired()}
						/>
					</Col>
				</Row>
			</Styled.Content>
			<DataSelectionSubForm
				trainingData={trainingData}
				validationData={validationData}
			/>
			<DataAugmentationSubForm />
			<ModelParamsSubForm form={form} trainingParams={trainingParams} />
			<TrainingConfigSubForm trainingParams={trainingParams} />
			<Styled.ButtonWrapper>
				<ValidationErrorAlert
					hasValidationErrors={hasValidationErrors}
					submitFailed={submitFailed}
				/>
				<When condition={error}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
				<Styled.SuccessButton
					type='submit'
					variant='success'
					disabled={submitting || pristine}
					loading={loading}>
					<span>Add new training</span>
					<FontAwesomeIcon icon={faPlay} />
				</Styled.SuccessButton>
			</Styled.ButtonWrapper>
		</Form>
	);
}
