import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import { WELLS_IMAGES_BG_COLOR } from 'core/consts';
import ExperimentImage from './ExperimentImage';
import * as Styled from '../styled';

export default function ExperimentRow({
	rowData,
	getImageUrl,
	rowIndex,
	experimentId,
	handleImageClick,
	selectedImage,
	spotsType,
}) {
	const theme = useTheme();
	const {
		missingImages,
		missingImagesStr,
		missingPlatebarcode,
		ptwiId,
		rowName,
		uniqueId,
		wellRequirementsNotMet,
		data,
	} = rowData;

	const dataWithBgColor = data.map(well => {
		if (!!well.imageKey.length) {
			const borderColor = WELLS_IMAGES_BG_COLOR.find(wellColor => {
				return well.imageKey.startsWith(wellColor.type);
			});
			return { ...well, borderColor: borderColor?.color };
		}
		return well;
	});
	const isValidExp =
		!missingImages && !missingPlatebarcode && !wellRequirementsNotMet;
	const tooltipId = `${rowIndex}-tooltip`;
	const tooltipContent = useMemo(() => {
		const content: any[] = [];
		if (!!missingImages) {
			content.push(
				`The images for the following wells are missing: ${missingImagesStr}`
			);
		}
		if (!!missingPlatebarcode) {
			content.push('Plate barcode folder missing');
		}
		if (!!wellRequirementsNotMet) {
			content.push('Minimum number for well requirement not met');
		}
		return content;
	}, [
		missingImages,
		missingPlatebarcode,
		wellRequirementsNotMet,
		missingImagesStr,
	]);

	const headerName = rowName === spotsType[0] ? ptwiId : rowName;
	const isHighlighed =
		experimentId === uniqueId &&
		rowName !== '' &&
		!spotsType.includes(rowName);
	return (
		<Styled.TR
			key={rowIndex}
			isHighlighed={isHighlighed}
			isValidExp={isValidExp && rowName !== ''}>
			<Styled.Header isIVS={rowName === spotsType[0]}>
				{rowName !== spotsType[1] ? headerName : ''}
				<When condition={!!tooltipContent.length}>
					<span data-tip='data-tip' data-for={tooltipId}>
						<FontAwesomeIcon
							icon={faExclamationTriangle}
							color={theme.red_5}
						/>
					</span>
					<ReactTooltip
						effect='solid'
						place='top'
						multiline
						textColor='#FFF'
						id={tooltipId}
						html={true}
						getContent={() => {
							return tooltipContent.join('<br />');
						}}
					/>
				</When>
			</Styled.Header>
			{dataWithBgColor.map(
				(
					{
						count,
						imageName,
						missingImage,
						imageKey,
						spotCountImagePath,
						hiddenImage,
						uniqueId: wellUniqueId,
						borderColor,
						imageExtension,
					},
					key
				) => {
					return (
						<Styled.TD
							key={key}
							isSelected={
								selectedImage.imageName === imageName &&
								selectedImage.colIndex === key &&
								selectedImage.rowIndex === rowIndex
							}>
							<ExperimentImage
								img={imageName}
								imageIndex={key}
								missingImage={missingImage}
								singleCount={count}
								imageKey={imageKey}
								spotCountImagePath={spotCountImagePath}
								hiddenImage={hiddenImage}
								getImageUrl={getImageUrl}
								handleImageClick={handleImageClick}
								isValidExp={isValidExp}
								rowIndex={rowIndex}
								uniqueId={wellUniqueId}
								borderColor={borderColor}
								imageExtension={imageExtension}
							/>
						</Styled.TD>
					);
				}
			)}
		</Styled.TR>
	);
}
