import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { When } from 'components/When';
import { CheckBox } from 'components/CheckBox';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import * as Styled from '../styled';

export default function ProjectSettingsModal({
	closeModal,
	applySettings,
	hidePredictionSettingsModal,
}) {
	const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
	const { error, trace, success, loading } = useNamespace({
		namespace: namespaces.PREDICTION_SETTINGS,
		onSuccess: closeModal,
	});
	const handleCheckBoxChange = evt => {
		const isChecked = evt.target.checked;
		setIsCheckBoxChecked(isChecked);
	};

	const handleApplySettings = () => {
		applySettings();
		if (success) {
			closeModal();
		}
	};
	const handleCloseModal = () => {
		if (isCheckBoxChecked) {
			hidePredictionSettingsModal();
		}
		closeModal();
	};

	return (
		<Styled.ProjectSettingWrap>
			<Modal.Body>
				<b>
					{`The spot counting settings for the detection of NE cases have been modified. Would you like to run a NE cases detection based on new spot counts settings?`}
				</b>
			</Modal.Body>
			<When condition={error}>
				<Styled.Alert variant='danger'>{trace}</Styled.Alert>
			</When>
			<Modal.Footer>
				<CheckBox
					type='checkbox'
					checked={isCheckBoxChecked}
					label={`Don't show again`}
					onChange={e => handleCheckBoxChange(e)}
				/>
				<Styled.CancelButton
					variant='success'
					onClick={handleCloseModal}>
					Cancel
				</Styled.CancelButton>
				<Styled.CloseButton
					onClick={handleApplySettings}
					loading={loading}>
					Yes
				</Styled.CloseButton>
			</Modal.Footer>
		</Styled.ProjectSettingWrap>
	);
}
