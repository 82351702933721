import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 100%;

	.range-slider__tooltip__label {
		background: ${p => p.theme.green};
		border-radius: 8px;
		padding: 0 10px;
	}

	span.range-slider__wrap {
		width: 40%;
		float: left;
		margin: 0 5%;
		position: relative;
	}
	input[type='range'] {
		width: 100%;
		${({ rangeValue }) =>
			rangeValue > 0 &&
			css`
				background: linear-gradient(
					to right,
					${p => p.theme.green} 0%,
					${p => p.theme.green} ${rangeValue}%,
					${p => p.theme.gray} ${rangeValue}%,
					${p => p.theme.gray} 100%
				);
			`}
		${({ rangeValue }) =>
			rangeValue === 0 &&
			css`
				background: ${p => p.theme.gray};
			`}
		border-radius: 7px;
		height: 7px;
		outline: none;
		-webkit-appearance: none;
	}
	input[type='range']::-webkit-slider-thumb {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		-webkit-appearance: none;

		background: ${p => p.theme.green};
	}
	.range-slider__tooltip {
		position: absolute;
		top: 0 !important;
		pointer-events: none;
		color: ${p => p.theme.white};
		transform: translateX(-50%);
		${({ rangeValue }) =>
			rangeValue > 0 &&
			css`
				left: calc(
					${rangeValue}% + ${(rangeValue / 100) * -30 + 15}px
				) !important;
			`}
		${({ rangeValue }) =>
			rangeValue === 0 &&
			css`
				left: 15px !important;
			`}
	}
`;

export const LimitValue = styled.div`
	width: 10%;
	float: left;
	text-align: center;
`;

export const Label = styled.span`
	width: 28%;
	margin-left: 2%;
	float: left;
`;
