import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormContainer } from 'components/Form';
import { When } from 'components/When';
import {
	fetchPredifinedComments,
	predefinedCommentsSelector,
	createEvaluation,
} from 'core/store/predictions';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import CreateEvaluationForm from './CreateEvaluationForm';
import * as Styled from '../styled';

export default function CreateEvaluationModal({
	closeModal,
	reviewId,
	predictionId,
	Unique_ID,
}) {
	const dispatch = useDispatch();
	const predefinedComments = useSelector(predefinedCommentsSelector);
	const predefinedCommentsOptions = predefinedComments?.map(v => ({
		label: v,
		value: v,
	}));

	useEffect(() => {
		return dispatch(fetchPredifinedComments());
	}, [dispatch]);

	const handleAddNewEvaluation = ({
		label,
		borderCase,
		responseStrength,
		subject,
		comment,
		includeInTraining,
	}) => {
		const evaluationData = {
			expertLabelUid: Unique_ID,
			borderCase: borderCase === 'true' ? true : false,
			label,
			responseStrength,
			subject,
			comment: !!comment ? comment : '',
			predictionId,
			reviewId,
			includeInTraining,
		};
		dispatch(createEvaluation(evaluationData));
	};

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.NEW_ADDED_EVALUATION,
		onSuccess: closeModal,
	});

	return (
		<FormContainer
			formID='start-prediction'
			onSubmit={handleAddNewEvaluation}
			component={props => (
				<CreateEvaluationForm
					{...props}
					commentOptions={predefinedCommentsOptions}>
					<When condition={error}>
						<Styled.Alert variant='danger'>{trace}</Styled.Alert>
					</When>
					<Modal.Footer>
						<Styled.ButtonWrapper>
							<Styled.CancelButton
								variant='danger'
								onClick={closeModal}>
								Cancel
							</Styled.CancelButton>
							<Styled.ModalSaveButton
								variant='success'
								type='submit'
								loading={loading}>
								<span>Validate</span>
							</Styled.ModalSaveButton>
						</Styled.ButtonWrapper>
					</Modal.Footer>
				</CreateEvaluationForm>
			)}
		/>
	);
}
