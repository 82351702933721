import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/esm/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { When } from 'components/When';
import { Link } from 'components/Link';
import * as ROUTES from 'core/routes';
import {
	fetchTraining,
	setAsChosenModel,
	fetchTrainingEvaluation,
} from 'core/store/trainingsExvivo';
import { Button } from 'components/Button';
import { SubHeader } from 'components/SubHeader';
import TrainingConfiguration from './components/TrainingConfiguration';
import ModelTroubleShooting from './components/ModelTroubleShooting';
import ConfusionMatrix from './components/ConfusionMatrix';
import Curves from './components/Curves';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import * as Styled from './styled';

const TABS = {
	curves: { eventKey: 'curves', title: 'Curves' },
	confusionMatrix: { eventKey: 'cm', title: 'Confusion Matrix' },
	trainingConfiguration: { eventKey: 'tc', title: 'Training Configuration' },
	modelTroubleShooting: { eventKey: 'ts', title: 'Model Troubleshooting' },
};

export default function TrainingExVivoDetails() {
	const dispatch = useDispatch();
	const { trainingId }: { trainingId: string } = useParams();
	const [tab, setTab] = useState(TABS.curves.eventKey);
	const { data: training } = useNamespace({
		namespace: namespaces.SINGLE_TRAINING,
	});
	const { data: { confusionMatrix } = {} } = useNamespace({
		namespace: namespaces.TRAINING_EVALUATION,
	});
	const {
		data: modelTroubleShootingData,
		loading: modelTroubleShootingLoading,
	} = useNamespace({
		namespace: namespaces.TRAINING_MODEL_TROUBLESHOOTING,
	});

	useEffect(() => {
		dispatch(fetchTraining(trainingId));
		dispatch(fetchTrainingEvaluation(trainingId));
	}, [dispatch, trainingId]);

	const handleChooseModelClick = () => {
		dispatch(setAsChosenModel(trainingId));
	};

	return (
		<When condition={training?.trainingId}>
			<SubHeader
				current={training?.trainingName}
				pre={
					<Link to={`${ROUTES.TRAININGS_EX_VIVO}?view=results`}>
						Trainings ExVivo
					</Link>
				}
				rightContent={
					<When condition={!training?.isChosenModel}>
						<Button
							variant='success'
							onClick={handleChooseModelClick}>
							Choose as model to use &nbsp;
							<FontAwesomeIcon icon={faStar} />
						</Button>
					</When>
				}
			/>
			<Tab.Container
				defaultActiveKey={tab}
				onSelect={setTab as SelectCallback}>
				<Styled.TabBar>
					<Nav variant='tabs'>
						<Nav.Item>
							<Nav.Link {...TABS.curves}>
								{TABS.curves.title}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link {...TABS.confusionMatrix}>
								{TABS.confusionMatrix.title}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link {...TABS.trainingConfiguration}>
								{TABS.trainingConfiguration.title}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link {...TABS.modelTroubleShooting}>
								{TABS.modelTroubleShooting.title}
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Styled.TabBar>
				<Tab.Content>
					<Tab.Pane {...TABS.curves}>
						<Curves selectedTrainingId={trainingId} />
					</Tab.Pane>
					<Tab.Pane {...TABS.confusionMatrix}>
						<ConfusionMatrix rawData={confusionMatrix} />
					</Tab.Pane>
					<Tab.Pane {...TABS.trainingConfiguration}>
						<TrainingConfiguration data={training} />
					</Tab.Pane>
					<Tab.Pane {...TABS.modelTroubleShooting}>
						<ModelTroubleShooting
							data={modelTroubleShootingData}
							trainingId={trainingId}
							loading={modelTroubleShootingLoading}
						/>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</When>
	);
}
