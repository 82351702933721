import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPen } from '@fortawesome/free-solid-svg-icons';
import { INPUT_FILE_GENERATION } from 'core/routes';
import * as namespaces from 'core/consts/namespaces';
import { formatDateAndTime } from 'helpers';
import { Table } from 'components/Table';
import {
	multiSelectFilter,
	SelectFilter,
} from 'components/Filters/SelectFilter';
import {
	dateBetweenFilterFn,
	DateFilter,
} from 'components/Filters/DateFilter/DateFilter';
import { ModalProvider } from 'components/Modal';
import { INPUT_GENERATION_VIEWS } from 'core/consts';
import InputGenerationDeleteModal from './DeleteModals/InputGenerationDeleteModal';
import UpdateInputFileModal from './UpdateInputFileModal';
import { InputResultEntry } from '../types';
import * as Styled from '../styled';

interface Props {
	data: InputResultEntry[];
	loading: boolean;
	workspaceId: string;
}

export default function InputsResultsTable({
	data,
	loading,
	workspaceId,
}: Props) {
	const theme = useTheme();
	const history = useHistory();
	const handleRowClick = ({ original: { workspaceDataId } }) => {
		history.push(
			`${INPUT_FILE_GENERATION}/${workspaceDataId}?view=${INPUT_GENERATION_VIEWS.plateBarcode}`
		);
	};
	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				disableSortBy: true,
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.WORKSPACE_DATA_LIST,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'User',
				accessor: 'userName',
				disableSortBy: true,
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.WORKSPACE_DATA_LIST,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'Plate Size',
				accessor: 'size',
				disableSortBy: true,
				Filter: props =>
					SelectFilter({
						...props,
						namespace: namespaces.WORKSPACE_DATA_LIST,
					}),
				filter: multiSelectFilter,
			},
			{
				Header: 'Time Created',
				accessor: 'createdOn',
				Filter: DateFilter,
				filter: dateBetweenFilterFn,
				Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
			},
			{
				id: 'edit',
				disableSortBy: true,
				disableFilters: true,
				width: 25,
				Cell: ({
					row: {
						original: { workspaceDataId, name, isTemplate },
					},
				}) => (
					<ModalProvider
						title='Edit Input File'
						component={UpdateInputFileModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							workspaceId,
							workspaceDataId,
							name,
							isTemplate,
						}}
						trigger={
							<FontAwesomeIcon icon={faPen} color={theme.green} />
						}
					/>
				),
			},
			{
				id: 'delete',
				disableSortBy: true,
				disableFilters: true,
				width: 25,
				Cell: ({
					row: {
						original: { workspaceDataId, name },
					},
				}) => (
					<ModalProvider
						title='Confirm Deletion of Input File'
						component={InputGenerationDeleteModal}
						modalStyles={{ size: 'lg' }}
						modalProps={{
							workspaceId,
							workspaceDataId,
							name,
						}}
						trigger={
							<FontAwesomeIcon
								icon={faTrashAlt}
								color={theme.red_4}
							/>
						}
					/>
				),
			},
		],
		[theme, workspaceId]
	);

	return (
		<Styled.Container>
			<Table
				columns={columns}
				data={data}
				loading={loading}
				onRowClick={handleRowClick}
				withFilters={!!data.length}
			/>
		</Styled.Container>
	);
}
