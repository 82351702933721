import { useCallback } from 'react';
import { useEvent, useChannel } from '@harelpls/use-pusher';
import { CHANNEL_NAME } from 'core/consts/pusherConfig';

export default function PusherMessage({ callback, event }) {
	const channel = useChannel(CHANNEL_NAME);
	const handleMessageSent = useCallback(callback, [callback]);
	useEvent(channel, event, handleMessageSent);

	return null;
}
