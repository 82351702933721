import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import arrayMutators from 'final-form-arrays';
import { When } from 'components/When';
import { Button } from 'components/Button';
import { FormContainer } from 'components/Form';
import { fetchAllProjectMembers } from 'core/store/clusters';
import { projectIdSelector, clusterIdSelector } from 'core/store/auth';
import { updateReview } from 'core/store/predictions';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import EditReviewForm from './ManageReviewForm';
import * as Styled from '../styled';

export default function EditReviewModal({
	closeModal,
	reviewers,
	reviewName,
	type,
	predictionId,
	reviewId,
}) {
	const dispatch = useDispatch();
	const { projectId } = useSelector(projectIdSelector);
	const { clusterId } = useSelector(clusterIdSelector);
	const users = useNamespace({
		namespace: namespaces.SELECTED_PROJECT_ALL_MEMBERS,
		autoClear: false,
	});
	useEffect(() => {
		return dispatch(fetchAllProjectMembers(clusterId, projectId));
	}, [dispatch, clusterId, projectId]);

	const { error, trace, loading } = useNamespace({
		namespace: namespaces.UPDATED_REVIEW,
		onSuccess: closeModal,
	});

	const reviewersList = reviewers.map(r => ({
		user: r,
	}));

	const reviewTypeValue = type === 'Blind' ? '2' : '1';
	const initialFormState = {
		reviewers: reviewersList,
		type: reviewTypeValue,
		name: reviewName,
	};

	const handleEditReview = data => {
		const { type, reviewers, name } = data;
		const reviewersData = reviewers?.map(({ user: { userId } }) => userId);
		const reviewData = {
			reviewId,
			name,
			reviewers: reviewersData,
			type: parseInt(type),
		};
		dispatch(updateReview(reviewData, predictionId));
	};

	return (
		<FormContainer
			formID='data-training'
			onSubmit={handleEditReview}
			mutators={{
				...arrayMutators,
			}}
			initialValues={initialFormState}
			component={props => (
				<EditReviewForm {...props} users={users.data}>
					<When condition={error}>
						<Styled.Alert variant='danger'>{trace}</Styled.Alert>
					</When>
					<Modal.Footer>
						<Button variant='danger' onClick={closeModal}>
							Cancel
						</Button>
						<Button
							variant='success'
							type='submit'
							loading={loading}>
							Save changes
						</Button>
					</Modal.Footer>
				</EditReviewForm>
			)}
		/>
	);
}
